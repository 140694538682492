import React from "react";
import classNames from "classnames";

const KpsPageContent = ({
  children,
  renderHeader,
  className,
  contentClassName,
  ...otherProps
}) => (
  <section className={classNames("page-content", className)} {...otherProps}>
    <header className="page-content-header">
      {renderHeader ? renderHeader() : null}
    </header>
    <div className={classNames("page-content-wrapper", contentClassName)}>
      {children}
    </div>
  </section>
);

export default KpsPageContent;
