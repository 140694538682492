import { TriggerBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { exportInvoices } from "./actions";
import React from "react";

const ExportInvoices = ({ filters, loading }) => (
  <TriggerBuilder
    submitAction={exportInvoices}
    submitParams={filters}
    loading={loading}
    renderTrigger={(fireTrigger, state) => (
      <Button
        compact
        content="Export"
        icon="download"
        onClick={fireTrigger}
        loading={state.submitting}
      />
    )}
  />
);

export default ExportInvoices;
