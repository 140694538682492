import { apiPost, apiGet, apiDelete, apiPut } from "@redriver/cinnamon";
import { MobileUserStatus } from "constants/enums";

const NAMESPACE = "MOBILE_USERS";

const ActionTypes = {
  GetMobileUsers: `${NAMESPACE}/GET_MOBILE_USERS`,
  CreateMobileUser: `${NAMESPACE}/CREATE_MOBILE_USER`,
  DeleteMobileUser: `${NAMESPACE}/DELETE_MOBILE_USER`,
  GetNonRegisteredTeamMembers: `${NAMESPACE}/NON_REGISTERED_TEAM_MEMBERS`,
  SetPassword: `${NAMESPACE}/SET_PASSWORD`,
  ChangeStatus: `${NAMESPACE}/CHANGE_STATUS`,
  EditMobileUser: `${NAMESPACE}/EDIT_USER`,
};

export const listMobileUsers = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;
  return apiGet(ActionTypes.GetMobileUsers, `mobile-users`, {
    pageSize,
    pageNumber,
    search,
  });
};

export const createMobileUser = ({ status, teamMemberId, password, notes }) => {
  const data = {
    isActive: status == MobileUserStatus.Active,
    teamMemberId,
    password,
    notes,
  };
  return apiPost(ActionTypes.CreateMobileUser, `mobile-users`, data);
};

export const getNonRegisteredTeamMembers = ({ search }) =>
  apiGet(
    ActionTypes.GetNonRegisteredTeamMembers,
    `mobile-users/unregistered-team-members`,
    { search, take: 10 }
  );

export const deleteMobileUser = (id) =>
  apiDelete(ActionTypes.DeleteMobileUser, `mobile-users/${id}`);

export const setMobileUserPassword = (data, id) =>
  apiPut(ActionTypes.SetPassword, `mobile-users/${id}/password`, data);

export const changeMobileUserStatus = ({ isActive, id }) =>
  apiPut(ActionTypes.SetPassword, `mobile-users/${id}/status`, { isActive });

export const editMobileUser = (data, id) =>
  apiPut(ActionTypes.EditMobileUser, `mobile-users/${id}`, data);
