import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { deleteMobileUser } from "./actions";

const DeleteMobileUser = ({
  mobileUser,
  onRefresh,
  as: As = Button,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={deleteMobileUser}
      submitParams={mobileUser.id}
      onSubmitted={onRefresh}
      renderTrigger={(open) => (
        <As {...props} onClick={open}>
          Delete User
        </As>
      )}
      renderModal={(modalProps) => (
        <Modal.Delete {...modalProps} header="Delete Mobile User">
          <p>
            Are you sure you wish to delete {mobileUser.firstName}{" "}
            {mobileUser.lastName}?
          </p>
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteMobileUser;
