import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { DepartmentRoutes } from "constants/routes";
import DepartmentListPage from "./DepartmentListPage";

const DepartmentRouter = () => (
  <Switch>
    <Route
      exact
      path={DepartmentRoutes.Departments}
      component={DepartmentListPage}
    />
    <Route render={() => <Redirect to={DepartmentRoutes.Departments} />} />
  </Switch>
);

export default DepartmentRouter;
