import { Breadcrumb } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { KpsPageContent } from "components/containers";
import { Actions, Targets } from "constants/Permissions";
import { ContractRoutes } from "constants/routes";
import { ContractDetails } from "features/Contracts";
import React from "react";
import { useParams } from "react-router-dom";

const ContractDetailsPage = () => {
  const { id } = useParams();

  return (
    <KpsPageContent
      renderHeader={() => (
        <PermissionCheck target={Targets.Contract} action={Actions.View}>
          <Breadcrumb>
            <Breadcrumb.Item
              link={ContractRoutes.Contracts}
              icon="chevron left"
            >
              Return to Contracts
            </Breadcrumb.Item>
          </Breadcrumb>
        </PermissionCheck>
      )}
    >
      <ContractDetails contractId={id} />
    </KpsPageContent>
  );
};

export default ContractDetailsPage;
