import React from "react";
import { Button } from "semantic-ui-react";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { addVatRate } from "./actions";
import VatForm from "./VatForm";

const AddVatRate = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addVatRate}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.VatRate} action={Actions.Create}>
        <As onClick={showModal} {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add VAT Rate">
        <VatForm {...formProps} />
      </Modal.Add>
    )}
  />
);

export default AddVatRate;
