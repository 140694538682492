import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";
import ListEnquiriesPage from "./ListEnquiriesPage";
import EnquiryDetailsPage from "./EnquiryDetailsPage";

const EnquiryRouter = () => (
  <Switch>
    <Route exact path={EnquiryRoutes.Enquiries} component={ListEnquiriesPage} />
    <Route path={EnquiryRoutes.Enquiry()} component={EnquiryDetailsPage} />
    <Route path={EnquiryRoutes.EnquiryQuote()} component={EnquiryDetailsPage} />
    <Route render={() => <Redirect to={EnquiryRoutes.Enquiries} />} />
  </Switch>
);

export default EnquiryRouter;
