import { Form, Format, Modal, ModalBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { createContractInvoice, getContractInvoiceDetails } from "./actions";
import { VATDropdown } from "components/forms";
import React, { Fragment, useState } from "react";
import { DateTime } from "luxon";
import { InvoiceInformationModal } from "components/modals";

const InvoiceSelectedJobs = ({
  as: As = Menu.Item,
  contractId,
  selectedJobs,
  selectedItems = [],
  onSubmitted,
  ...props
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <ModalBuilder
      withForm
      loadAction={getContractInvoiceDetails}
      loadParams={{ contractId, jobIds: selectedJobs, itemIds: selectedItems }}
      loadTransform={(data) => ({
        ...data,
        dueDate: DateTime.local().plus({ days: 30 }).toISODate(),
        ...(selectedItems.length > 0 && {
          lines: data.lines.filter(l => selectedItems.includes(l.jobItemId))
        })
      })}
      submitAction={createContractInvoice}
      submitParams={contractId}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps, state, events) => {
        const formData = formProps.value;
        return (
          <Fragment>
            <Modal.Add
              {...modalProps}
              submitLabel="Save"
              header="Create Invoice"
              size="fullscreen"
              onSubmit={() =>
                hasChanged ? setHasSubmitted(true) : events.onSubmit()
              }
              submitDisabled={formData.lines?.length === 0}
            >
              <Form
                {...formProps}
                onChange={(field, data, applyChanges) => {
                  formProps.onChange(field, data, applyChanges);
                  if (field === "lines") {
                    setHasChanged(true);
                  }
                }}
              >
                <Form.Group className="invoice-header">
                  <div className="customer-reference">
                    <label>{formData.customer}</label>
                    <label>{formData.reference}</label>
                  </div>
                  <div className="order-number">
                    <Form.Input label="Site ID" field="orderNumber" fluid />
                  </div>
                  <div className="same-width">
                    <Form.Input
                      label="KPS Contract Number"
                      field="kpsContractNumber"
                      fluid
                    />
                  </div>
                  <div className="same-width">
                    <Form.Input
                      label="Customer Contract Number"
                      field="customerContractNumber"
                      fluid
                    />
                  </div>
                  <div className="same-width">
                    <Form.Input
                      label="Quantity Surveyor"
                      field="quantitySurveyor"
                      fluid
                    />
                  </div>
                  <div className="due-date">
                    <Form.Date label="Due Date" field="dueDate" local fluid />
                  </div>
                  <div>
                    <Form.Input
                      field="invoiceType"
                      label="Invoice Type"
                      width={40}
                      required
                      readOnly                    
                    />
                  </div>
                </Form.Group>
                <div className="invoice-line-list">
                  <Form.Array field="lines" arrayKey="jobItemId">
                    <Form.Group>
                      <Form.Numeric
                        field="quantity"
                        label="QTY"
                        width={5}
                        required
                      />
                      <div className="job-field">
                        <Form.Input
                          field="reference"
                          label="Job Reference"
                          width={40}
                          required
                          readOnly
                        />
                      </div>
                      <div className="job-field">
                        <Form.Input
                          field="jobType"
                          label="Job Type"
                          readOnly
                          renderReadOnly={({ formState }) =>
                            formState.fields.jobTypeDescription
                          }
                          fluid
                        />
                      </div>
                      <div className="description">
                        <Form.TextArea
                          field="description"
                          label="Description"
                          fluid
                          rows={1}
                        />
                      </div>
                      <Form.Numeric
                        field="value"
                        label="Net Price"
                        decimalPlaces={2}
                        formatValue
                        width={10}
                        required
                      />
                      <VATDropdown
                        field="vatRateId"
                        label="Vat Rate"
                        optionField="vatRate"
                        width={22}
                        required
                      />
                      <Form.Numeric
                        field="grossPrice"
                        label="Total"
                        decimalPlaces={2}
                        formatValue
                        subLabel="£"
                        subLabelPosition="left"
                        readOnly
                      />
                      <Form.Trigger
                        field="value"
                        target="grossPrice"
                        event="onChange"
                        action="change"
                        value={(formState) => (
                          formState.fields.vatRate
                            ? formState.fields.value *
                            (1 + formState.fields.vatRate?.vatValue)
                            : null
                        )}
                      />
                      <Form.Trigger
                        field="vatRateId"
                        target="grossPrice"
                        event="onChange"
                        action="change"
                        value={(formState) => (
                          formState.fields.value
                            ? formState.fields.value *
                            (1 + formState.fields.vatRate?.vatValue)
                            : null
                        )}
                      />
                      <Form.ArrayRemover
                        type="button"
                        className="remove-button"
                        icon="trash"
                      />
                    </Form.Group>
                  </Form.Array>
                </div>
                <Form.Group className="invoice-total full-width">
                  <span>Total Invoice</span>
                  <Format.Number
                    className="net-price"
                    value={(formData.lines || []).reduce(
                      (a, b) => a + parseFloat(b.value ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                  <Format.Number
                    className="vat"
                    value={(formData.lines || []).reduce(
                      (a, b) =>
                        a +
                        parseFloat(b.value ?? 0) *
                        parseFloat(b.vatRate?.vatValue ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                  <Format.Number
                    className="total"
                    value={(formData.lines || []).reduce(
                      (a, b) => a + parseFloat(b.grossPrice ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                </Form.Group>
              </Form>
            </Modal.Add>
            <InvoiceInformationModal
              visible={hasChanged && hasSubmitted}
              onCancel={() => {
                events.onCancel();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
              onSubmitted={() => {
                events.onSubmit();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default InvoiceSelectedJobs;
