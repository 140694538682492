import React from "react";
import { ListJobs } from "features/Jobs";
import { KpsPageContent } from "components/containers";

const JobListPage = () => (
  <KpsPageContent>
    <ListJobs />
  </KpsPageContent>
);

export default JobListPage;
