import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { addDepartment } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import DepartmentForm from "./DepartmentForm";

const AddDepartment = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addDepartment}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.Department} action={Actions.Create}>
        <As onClick={showModal} compact {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Department">
        <DepartmentForm formProps={formProps} />
      </Modal.Add>
    )}
  />
);

export default AddDepartment;
