import { SidePanel } from "@redriver/cinnamon";
import { withPermissions } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { JobStatus } from "constants/enums";
import React, { Fragment, useState } from "react";
import { Button } from "semantic-ui-react";

const JobActions = ({
  job,
  onChangeTpoStatus,
  onSelectAddress,
  onEditJobDetails,
  onCancelJob,
  hasPermission
}) => {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const access = {
    canEditJob: hasPermission(Targets.Job, Actions.Edit) && !job.contractId,
    canCancelJob: hasPermission(Targets.Job, Actions.Delete) && job.status !== JobStatus.Invoiced
  }

  if (!Object.values(access).some((x) => x)) return null;

  return (
    <React.Fragment>
      <Button compact onClick={() => setSidePanelOpen(true)}>
        Actions
      </Button>
      <SidePanel
        closeIcon
        onClose={() => setSidePanelOpen(false)}
        open={sidePanelOpen}
        className="actions-side-panel"
      >
        <div className="ui header filters-title">
          <div className="title-text">Actions</div>
        </div>
        {access.canEditJob && (
          <Fragment>
            <div className="actions">
              <div>
                <a
                  onClick={() => {
                    onSelectAddress();
                    setSidePanelOpen(false);
                  }}
                >
                  Change Address
                </a>
              </div>
            </div>
            <div className="actions">
              <div>
                <a
                  onClick={() => {
                    onChangeTpoStatus();
                    setSidePanelOpen(false);
                  }}
                >
                  Update TPO Status
                </a>
              </div>
            </div>
            <div className="actions">
              <div>
                <a
                  onClick={() => {
                    onEditJobDetails();
                    setSidePanelOpen(false);
                  }}
                >
                  Edit Job Details
                </a>
              </div>
            </div>
          </Fragment>
        )}
        {access.canCancelJob && (
          <div className="actions">
          <div>
            <a
              onClick={() => {
                onCancelJob();
                setSidePanelOpen(false);
              }}
            >
              Cancel Job
            </a>
          </div>
        </div>
        )}
      </SidePanel>
    </React.Fragment>
  );
};

export default withPermissions(JobActions);
