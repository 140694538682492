import React from "react";
import { KpsPageContent } from "components/containers";
import ListVatRates from "features/Admin/ListVatRates";

const VatRatesListPage = () => (
  <KpsPageContent>
    <ListVatRates />
  </KpsPageContent>
);

export default VatRatesListPage;
