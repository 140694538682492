import React from "react";

const SignalRHubContext = React.createContext({
  hubConnection: null,
  invokeMethod: () => {
    return;
  },
});

export default SignalRHubContext;
