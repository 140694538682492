import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";

const NAMESPACE = "ENQUIRIES/DETAILS/CURRENT_QUOTE";

export const ActionTypes = {
  GetEnquiryCurrentQuote: `${NAMESPACE}/GET_ENQUIRY_CURRENT_QUOTE`,
  CreateEnquiryQuote: `${NAMESPACE}/CREATE_ENQUIRY_QUOTE`,
  AddQuoteItem: `${NAMESPACE}/ADD_QUOTE_ITEM`,
  EditQuoteItem: `${NAMESPACE}/EDIT_QUOTE_ITEM`,
  DeleteQuoteItem: `${NAMESPACE}/DELETE_QUOTE_ITEM`,
  MarkAsQuote: `${NAMESPACE}/MARK_AS_QUOTE`,
  GenerateQuote: `${NAMESPACE}/GENERATE_QUOTE`,
  ConvertToJob: `${NAMESPACE}/CONVERT_TO_JOB`,
  EmailQuote: `${NAMESPACE}/EMAIL_QUOTE`,
  QuoteEmailTemplate: `${NAMESPACE}/QUOTE_EMAIL_TEMPLATE`,
  GenerateEstimate: `${NAMESPACE}/GENERATE_ESTIMATE`,
  DownloadRiskAssessment: `${NAMESPACE}/DOWNLOAD_RISK_ASSESSMENT`,
  UpdateQuoteNotes: `${NAMESPACE}/UPDATE_QUOTE_NOTES`,
  GetQuoteNotes: `${NAMESPACE}/GET_QUOTE_NOTES`,
  GetEnquiryImages: `${NAMESPACE}/GET_ENQUIRY_IMAGES`,
};

export const getEnquiryCurrentQuote = ({ enquiryId }) =>
  apiGet(
    ActionTypes.GetEnquiryCurrentQuote,
    `enquiries/${enquiryId}/current-quote`
  );

export const createEnquiryQuote = (data, { enquiryId }) =>
  apiPost(ActionTypes.CreateEnquiryQuote, `enquiries/${enquiryId}/quotes`, {
    ...data,
    items: data.items.map((i) => ({
      ...i,
      id: typeof i.id == "string" ? i.id : null,
    })),
  });

export const addQuoteItem = (formData, { enquiryId }) =>
  apiPost(
    ActionTypes.AddQuoteItem,
    `enquiries/${enquiryId}/current-quote/items`,
    formData
  );

export const editQuoteItem = (formData, { enquiryId }) =>
  apiPut(
    ActionTypes.EditQuoteItem,
    `enquiries/${enquiryId}/current-quote/items/${formData.id}`,
    formData
  );

export const deleteQuoteItem = ({ enquiryId, itemId }) =>
  apiDelete(
    ActionTypes.DeleteQuoteItem,
    `enquiries/${enquiryId}/current-quote/items/${itemId}`
  );

export const markAsQuote = ({ quoteId }) =>
  apiPost(ActionTypes.MarkAsQuote, `quotes/${quoteId}/mark-as-quote`);

export const generateQuote =
  ({ quoteId, asDocx = false }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiPost(
        ActionTypes.GenerateQuote,
        `quotes/${quoteId}/generate`,
        {
          asDocx,
        },
        { preventResponseHandling: true }
      )
    );
    if (result) {
      saveFile(result);
    }
    return result;
  };

export const convertToJob = ({ selectedIds, quoteId }) => {
  return apiPost(ActionTypes.ConvertToJob, `quotes/${quoteId}/convert-to-job`, {
    selectedItems: selectedIds,
  });
};

export const emailQuote = (data, quoteId) => {
  return apiPost(ActionTypes.EmailQuote, `quotes/${quoteId}/email`, data);
};

export const getQuoteEmailTemplate = (quoteId) => {
  return apiGet(
    ActionTypes.QuoteEmailTemplate,
    `quotes/${quoteId}/email-template`
  );
};

export const generateEstimate =
  ({ enquiryId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiPost(
        ActionTypes.GenerateEstimate,
        `enquiries/${enquiryId}/estimate/generate`,
        {},
        { preventResponseHandling: true }
      )
    );
    if (result) {
      saveFile(result);
    }
    return result;
  };

export const downloadRiskAssessment =
  ({ enquiryId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadRiskAssessment,
        `enquiries/${enquiryId}/risk-assessment`,
        {},
        { preventResponseHandling: true }
      )
    );
    if (result.success) {
      saveFile(result);
    }
    return result;
  };

export const updateQuoteNotes = (formData, { enquiryId }) =>
  apiPut(
    ActionTypes.UpdateQuoteNotes,
    `enquiries/${enquiryId}/current-quote/notes`,
    formData
  );

export const getQuoteNotes = ({ enquiryId }) =>
  apiGet(
    ActionTypes.GetQuoteNotes,
    `enquiries/${enquiryId}/current-quote/notes`
  );

export const getEnquiryImages = ({ pagination }, { enquiryId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetEnquiryImages, `enquiries/${enquiryId}/images`, {
    pageSize,
    pageNumber,
  });
};
