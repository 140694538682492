import { ActionMenu } from "@redriver/cinnamon";
import React from "react";
import AddJobFromContractPlots from "./AddJobFromContractPlots";
import AddContractPlot from "./AddContractPlot";
import { withPermissions } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import DeleteContractPlots from "./DeleteContractPlots";

const ContractPlotsPanelActions = ({
  contractId,
  pricingType,
  data,
  selectedItems,
  onSubmitted,
  hasPermission,
}) => {
  const access = {
    canCreatePlots: hasPermission(Targets.ContractPlot, Actions.Create),
    canDeletePlots: hasPermission(Targets.ContractPlot, Actions.Delete),
    canCreateJobs: hasPermission(Targets.ContractJob, Actions.Create),
  };

  if (!Object.values(access).some((x) => x)) return null;

  const selected = data.filter(
    (x) =>
      (selectedItems.selected.includes(x.id) || selectedItems.allSelected) &&
      !selectedItems.deselected.includes(x.id)
  );

  return (
    <ActionMenu>
      {access.canCreatePlots && (
        <AddContractPlot
          content="Add Plot"
          contractId={contractId}
          pricingType={pricingType}
          onAdded={onSubmitted}
        />
      )}
      {access.canDeletePlots && (
        <DeleteContractPlots
          content="Delete Plots"
          contractPlotIds={selectedItems.selected}
          contractId={contractId}
          onDeleted={onSubmitted}
          disabled={selected <= 0 || selected.some((i) => i.hasJob)}
        />
      )}
      {access.canCreateJobs && (
        <AddJobFromContractPlots
          content="Create Job"
          contractId={contractId}
          selected={selectedItems.selected}
          onAdded={onSubmitted}
          disabled={selected <= 0 || selected.some((i) => i.hasJob)}
        />
      )}
    </ActionMenu>
  );
};

export default withPermissions(ContractPlotsPanelActions);
