import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/LIST_CUSTOMERS";

const ActionTypes = {
  GetCustomers: `${NAMESPACE}/GET_CUSTOMERS`,
  AddCustomer: `${NAMESPACE}/ADD_CUSTOMER`,
  DeleteCustomer: `${NAMESPACE}/DELETE_CUSTOMER`,
  ReinstateCustomer: `${NAMESPACE}/REINSTATE_CUSTOMER`,
};

export const getCustomers = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetCustomers, "customers", {
    pageSize,
    pageNumber,
    ...filters,
  });
};

export const addCustomer = (request) => {
  return apiPost(ActionTypes.AddCustomer, "customers", request);
};

export const deleteCustomer = (customerId) => {
  return apiDelete(ActionTypes.DeleteCustomer, `customers/${customerId}`);
};

export const reinstateCustomer = (customerId) =>
  apiPut(ActionTypes.ReinstateCustomer, `customers/${customerId}/reinstate`);
