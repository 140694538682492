import React, { useState } from "react";
import { getCustomerQuotes } from "./actions";
import {
  ListBuilder,
  FormBuilder,
  Format,
  Form,
  useAuthClaims,
} from "@redriver/cinnamon";
import { Table, Image } from "semantic-ui-react";
import { TablePanel, HeaderBar } from "components/containers";
import { useHistory } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";
import { EnquiryTabKeys } from "constants/index";
import { Targets, Actions } from "constants/Permissions";
import { claimExists } from "components/auth/utils";
import CheckIcon from "assets/icons/check.svg";
import { CustomerTypes } from "constants/enums";
import classNames from "classnames";
import { EnquiryStatusTintMapping } from "constants/mappings";

const CustomerQuotes = ({ customer }) => {
  const [searchFilters, setFilters] = useState({});
  const history = useHistory();
  const { permissions } = useAuthClaims();
  return (
    <ListBuilder
      withTable
      loadAction={getCustomerQuotes}
      loadParams={{ customerId: customer.id, searchFilters }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar name="Quotes" isSearchBar>
                <FormBuilder
                  value={searchFilters}
                  onChange={setFilters}
                  renderForm={(formProps) => (
                    <Form {...formProps}>
                      <Form.Input
                        field="search"
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No quotes found"
            selectable
            customTable
          >
            <Table className="quotes-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Site Address</Table.HeaderCell>
                  <Table.HeaderCell>Latest</Table.HeaderCell>
                  <Table.HeaderCell>Department</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tableProps.data.map((quote) => {
                  let className = null;
                  let onClick = null;
                  let tickImage = null;
                  if (quote.isCurrentQuote) {
                    tickImage = <Image src={CheckIcon} />;
                    if (claimExists(permissions, Targets.Quote, Actions.View)) {
                      className = "current-quote";
                      onClick = () => {
                        history.push(
                          EnquiryRoutes.EnquiryQuote(
                            quote.enquiryId,
                            EnquiryTabKeys.Quote
                          )
                        );
                      };
                    }
                  }
                  let tint = EnquiryStatusTintMapping[quote.status];
                  const highlight =
                    quote.customerType == CustomerTypes.Residential
                      ? "residential-green"
                      : "commercial-blue";
                  return (
                    <Table.Row
                      key={quote.id}
                      className={classNames(
                        highlight ? `highlight-${highlight}` : null,
                        tint ? `tint-${tint}` : null
                      )}
                    >
                      <Table.Cell onClick={onClick} className={className}>
                        {quote.ref}
                      </Table.Cell>
                      <Table.Cell>
                        {quote.siteAddress
                          ? quote.siteAddress.shortAddress
                          : ""}
                      </Table.Cell>
                      <Table.Cell>{tickImage}</Table.Cell>
                      <Table.Cell>{quote.department}</Table.Cell>
                      <Table.Cell>{quote.status}</Table.Cell>
                      <Table.Cell>
                        <Format.DateTime
                          value={quote.createdUtc}
                          format="DD MMM YYYY HH:mm:ss"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default CustomerQuotes;
