import React from "react";
import { ListTemplateDocuments } from "features/Admin";
import { KpsPageContent } from "components/containers";

const TemplateDocumentListPage = () => (
  <KpsPageContent>
    <ListTemplateDocuments />
  </KpsPageContent>
);

export default TemplateDocumentListPage;
