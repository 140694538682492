import React from "react";
import { KpsPageContent } from "components/containers";
import { ListMobileUsers } from "features/Admin";

const MobileUserListPage = () => (
  <KpsPageContent>
    <ListMobileUsers />
  </KpsPageContent>
);

export default MobileUserListPage;
