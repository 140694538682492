import { useSelector } from "react-redux";
import { getAuthClaims } from "@redriver/cinnamon";
import { claimExists } from "./utils";

const usePermission = (target, action, any = false) => {
  const { permissions } = useSelector(getAuthClaims);
  return claimExists(permissions, target, action, any);
};

export default usePermission;
