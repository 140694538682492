import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Segment } from "semantic-ui-react";
import {
  getAvailableResourcesForDay,
  assignResource,
  unassignResource,
} from "./actions";
import { produce } from "immer";
import CheckIcon from "assets/icons/check.svg";
import ResourceAllocationGrid from "./ResourceAllocationGrid";
import { useSignalRHub, useSignalRCallback } from "modules/signalr";
import { DateTime } from "luxon";
import { SignalRHubs } from "constants/routes";
import { Scheduling } from "constants/signalRMethods";
import { ResourceSchedulingState } from "constants/enums";
import { ResourceFilterTypes, ShowOnlyOptions } from "./constants";
import { CustomerTypesDropdown } from "components/forms";
import { Form, useFormField, debounce } from "@redriver/cinnamon";
import { IconButton } from "components/buttons";
import RefreshIcon from "assets/icons/refresh.svg";

const shouldProcessScheduledJob = (departmentsFilter, job) => {
  // check job matches filters
  let shouldProcessScheduledJob = true;
  if (
    departmentsFilter.length > 0 &&
    !departmentsFilter.includes(job.departmentId)
  ) {
    shouldProcessScheduledJob = false;
  }

  return shouldProcessScheduledJob;
};

const ResourceAllocation = ({ date, departmentsFilter }) => {
  const dispatch = useDispatch();
  const [resources, setResources] = useState([]);
  const [filters, setFilters] = useState({
    filterType: ResourceFilterTypes.Resources,
    showOnly: "All",
  });
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hubConnection] = useSignalRHub(SignalRHubs.Scheduling);

  const processAssignResource = useCallback(
    (data) => {
      const resourceIndex = resources.findIndex((x) => x.id == data.resourceId);
      if (resourceIndex >= 0) {
        if (
          resources[resourceIndex].assignedJobSheets.every(
            (x) => x.jobSheetId != data.jobSheetId
          )
        ) {
          const nextResources = produce(resources, (draft) => {
            draft[resourceIndex].assignedJobSheets.push({
              jobSheetId: data.jobSheetId,
              jobRef: data.jobRef,
            });
          });
          setResources(nextResources);
        }
      }

      const jobIndex = jobs.findIndex((x) => x.jobSheetId == data.jobSheetId);
      if (jobIndex >= 0) {
        if (
          jobs[jobIndex].assigned == null ||
          !jobs[jobIndex].assigned.includes(data.resourceName)
        ) {
          const nextJobs = produce(jobs, (draft) => {
            if (draft[jobIndex].assigned == null) {
              draft[jobIndex].assigned = [];
            }
            draft[jobIndex].assigned.push(data.resourceName);
          });
          setJobs(nextJobs);
        }
      }
    },
    [resources, jobs]
  );

  const processUnassignResource = useCallback(
    (data) => {
      const resourceIndex = resources.findIndex((x) => x.id == data.resourceId);
      if (resourceIndex >= 0) {
        if (
          resources[resourceIndex].assignedJobSheets.some(
            (x) => x.jobSheetId == data.jobSheetId
          )
        ) {
          const nextResources = produce(resources, (draft) => {
            draft[resourceIndex].assignedJobSheets = draft[
              resourceIndex
            ].assignedJobSheets.filter((x) => x.jobSheetId != data.jobSheetId);
          });
          setResources(nextResources);
        }
      }

      const jobIndex = jobs.findIndex((x) => x.jobSheetId == data.jobSheetId);
      if (jobIndex >= 0) {
        if (
          jobs[jobIndex].assigned != null &&
          jobs[jobIndex].assigned.includes(data.resourceName)
        ) {
          const nextJobs = produce(jobs, (draft) => {
            draft[jobIndex].assigned = draft[jobIndex].assigned.filter(
              (name) => name != data.resourceName
            );
          });
          setJobs(nextJobs);
        }
      }
    },
    [resources, jobs]
  );

  const processUnscheduleJob = useCallback(
    (data) => {
      if (data.date === date) {
        const nextJobs = produce(jobs, (draft) => {
          return draft.filter((x) => x.jobSheetId != data.oldJobSheetId);
        });
        setJobs(nextJobs);
        // remove assignments to job being unassigned
        const nextResources = produce(resources, (draft) => {
          const resourcesToRemove = [];
          for (let i = 0; i < draft.length; i++) {
            if (
              draft[i].assignedJobSheets.some(
                (x) => x.jobSheetId == data.oldJobSheetId
              )
            ) {
              const index = draft[i].assignedJobSheets.findIndex(
                (x) => x.jobSheetId == data.oldJobSheetId
              );
              if (index !== -1) {
                draft[i].assignedJobSheets.splice(index, 1);
              }
              if (
                !jobs
                  .filter((x) => x.jobSheetId != data.oldJobSheetId)
                  .includes((x) => x.assigned.includes(draft[i].id)) &&
                !departmentsFilter.includes(draft[i].departmentId)
              ) {
                resourcesToRemove.push(draft[i].id);
              }
            }
          }
          resourcesToRemove.forEach((id) => {
            draft.splice(
              draft.findIndex((x) => x.id == id),
              1
            );
          });
        });
        setResources(nextResources);
      }
    },
    [jobs, resources, date, departmentsFilter]
  );

  const processScheduleJob = useCallback(
    (data) => {
      if (data.date === date) {
        const shouldProcessJob = shouldProcessScheduledJob(
          departmentsFilter,
          data.job
        );

        if (!shouldProcessJob) {
          return;
        }

        if (jobs.every((x) => x.jobSheetId != data.job.jobSheetId)) {
          const nextJobs = produce(jobs, (draft) => {
            draft.push(data.job);
            draft.sort((a, b) => {
              if (a.jobRef < b.jobRef) {
                return -1;
              }
              if (a.jobRef > b.jobRef) {
                return 1;
              }
              return 0;
            });
          });
          setJobs(nextJobs);
        }

        if (data.assignedTeamMembers?.length > 0) {
          const nextResources = produce(resources, (draft) => {
            data.assignedTeamMembers.forEach((x) => {
              draft.push({
                ...x,
                assignedJobSheets: [],
                availability: ResourceSchedulingState.Available, // If assigned to a job they are available
              });
            });
            draft.forEach((member) => {
              if (data.assignedTeamMembers.find((x) => x.id == member.id)) {
                member.assignedJobSheets.push({
                  jobSheetId: data.job.jobSheetId,
                  jobRef: data.job.jobRef,
                });
              }
            });

            draft.sort((a, b) => (a.name > b.name ? 1 : -1));
          });
          setResources(nextResources);
        }
      } else {
        // check to see if job has been scheduled on different day
        const job = jobs.find((x) => x.jobSheetId == data.job.jobSheetId);
        if (job) {
          processUnscheduleJob({ ...job, date, oldJobSheetId: job.jobSheetId });
        }
        // remove other assignments on resources with matching job sheet
        const nextResources = produce(resources, (draft) => {
          const resourcesToRemove = [];
          for (let i = 0; i < draft.length; i++) {
            if (
              draft[i].assignedJobSheets.some(
                (x) => x.jobSheetId == data.job.jobSheetId
              )
            ) {
              const index = draft[i].assignedJobSheets.findIndex(
                (x) => x.jobSheetId == data.job.jobSheetId
              );
              if (index !== -1) {
                draft[i].assignedJobSheets.splice(index, 1);
              }
              if (
                !jobs
                  .filter((x) => x.jobSheetId != data.job.jobSheetId)
                  .includes((x) => x.assigned.includes(draft[i].id)) &&
                !departmentsFilter.includes(draft[i].departmentId)
              ) {
                resourcesToRemove.push(draft[i].id);
              }
            }
          }
          resourcesToRemove.forEach((id) => {
            draft.splice(
              draft.findIndex((x) => x.id == id),
              1
            );
          });
        });
        setResources(nextResources);
      }
    },
    [jobs, resources, departmentsFilter, date, processUnscheduleJob]
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.AssignResource,
    processAssignResource
  );
  useSignalRCallback(
    hubConnection,
    Scheduling.UnassignResource,
    processUnassignResource
  );
  useSignalRCallback(hubConnection, Scheduling.ScheduleJob, processScheduleJob);
  useSignalRCallback(
    hubConnection,
    Scheduling.UnscheduleJob,
    processUnscheduleJob
  );

  const debounceGetResources = useMemo(() => {
    const getResources = (date, departmentsFilter, filters) => {
      setLoading(true);
      dispatch(
        getAvailableResourcesForDay(date, departmentsFilter, filters)
      ).then((data) => {
        if (data.response) {
          const { jobs, resources } = data.response;
          setResources(resources);
          setJobs(jobs);
        }

        setLoading(false);
      });
    };

    return debounce(getResources, 500);
  }, [dispatch]);

  useEffect(() => {
    debounceGetResources(date, departmentsFilter, filters);
  }, [date, departmentsFilter, filters, debounceGetResources]);

  const addResourceAssignment = useCallback(
    (resourceId, jobSheetId) => {
      const resourceIndex = resources.findIndex((x) => x.id == resourceId);
      if (
        resourceIndex >= 0 &&
        resources[resourceIndex].assignedJobSheets.every(
          (x) => x.jobSheetId != jobSheetId
        )
      ) {
        const next = produce(resources, (items) => {
          items[resourceIndex].assignedJobSheets.push({ jobSheetId });
        });
        setResources(next);
        dispatch(assignResource({ jobSheetId, resourceId }));
      }
    },
    [dispatch, resources]
  );

  const updateJobSheetScheduleNote = useCallback(
    (jobSheetId, note) => {
      const next = produce(jobs, (draft) => {
        const index = draft.findIndex((x) => x.jobSheetId == jobSheetId);
        if (index >= 0) {
          draft[index].note = note;
        }
      });
      setJobs(next);
    },
    [jobs]
  );

  const updateResourceNote = useCallback(
    (id, note) => {
      const next = produce(resources, (draft) => {
        const index = draft.findIndex((x) => x.id == id);
        if (index >= 0) {
          draft[index].note = note;
        }
      });
      setResources(next);
    },
    [resources]
  );

  const removeResourceAssignment = useCallback(
    (resourceId, jobSheetId) => {
      const resourceIndex = resources.findIndex((x) => x.id == resourceId);
      if (
        resourceIndex >= 0 &&
        resources[resourceIndex].assignedJobSheets.some(
          (x) => x.jobSheetId == jobSheetId
        )
      ) {
        const next = produce(resources, (items) => {
          const jobIndex = items[resourceIndex].assignedJobSheets.findIndex(
            (x) => x.jobSheetId == jobSheetId
          );
          if (jobIndex >= 0) {
            items[resourceIndex].assignedJobSheets.splice(jobIndex, 1);
          }
        });
        setResources(next);
        dispatch(unassignResource({ jobSheetId, resourceId }));
      }
    },
    [dispatch, resources]
  );

  return (
    <Segment className="resource-allocation" loading={loading}>
      <div className="resource-allocation-header">
        <h4>
          Resource Allocation on{" "}
          {DateTime.fromISO(date).toFormat("dd MMM yyyy")}{" "}
          <img
            src={CheckIcon}
            alt="preloaded icon"
            style={{ display: "none" }}
          />
        </h4>
        <Form
          value={filters}
          onChange={(field, data, applyChanges) =>
            setFilters(applyChanges(filters))
          }
          style={{ display: "flex", marginLeft: "1rem", gap: "1rem" }}
        >
          <ResourceTypeFilter field="filterType" />
          <Form.Input field="search" placeholder="Search" />
          <CustomerTypesDropdown
            field="customerType"
            placeholder="Select customer type"
            clearable
          />
          <Form.Dropdown field="showOnly" options={ShowOnlyOptions} />
          <IconButton
            size="small"
            icon={RefreshIcon}
            onClick={() =>
              debounceGetResources(date, departmentsFilter, filters)
            }
            style={{ height: "32px", padding: "0.5em" }}
          />
        </Form>
      </div>

      {resources.length == 0 ? (
        <div>No resources available to assign</div>
      ) : jobs.length == 0 ? (
        <div>No jobs found</div>
      ) : (
        <ResourceAllocationGrid
          resources={resources}
          jobs={jobs}
          addResourceAssignment={addResourceAssignment}
          removeResourceAssignment={removeResourceAssignment}
          updateResourceNote={updateResourceNote}
          updateJobSheetScheduleNote={updateJobSheetScheduleNote}
          date={date}
        />
      )}
    </Segment>
  );
};

const ResourceTypeFilter = ({ field }) => {
  const [resourceFilterType, setResourceFilterType] = useFormField(field);
  return (
    <div className="overview-type-filter">
      {Object.keys(ResourceFilterTypes).map((type) => (
        <div
          className={
            resourceFilterType == ResourceFilterTypes[type] ? "active" : null
          }
          onClick={() => setResourceFilterType(ResourceFilterTypes[type])}
          key={type}
          style={{ height: "32px", display: "flex", alignItems: "center" }}
        >
          {type}
        </div>
      ))}
    </div>
  );
};

export default ResourceAllocation;
