import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteCustomerAddress } from "./actions";
import { FormatAddress } from "components/display";
import DeleteIcon from "assets/icons/rubbish-bin.svg";
import { Image } from "semantic-ui-react";

const DeleteCustomerAddress = ({ address, customerId, onDeleted }) => (
  <ModalBuilder
    submitAction={deleteCustomerAddress}
    submitParams={{ customerId, addressId: address.id }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => (
      <Image
        src={DeleteIcon}
        title="Delete Address"
        onClick={(e) => {
          showModal();

          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      />
    )}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete address">
        Are you sure you wish to delete address <b>{FormatAddress(address)}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteCustomerAddress;
