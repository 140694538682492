import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { updateVatRate } from "./actions";
import VatForm from "./VatForm";

const EditVatRate = ({ onUpdated, onCancel, rate }) => (
  <ModalBuilder
    withForm
    initialData={rate}
    submitAction={updateVatRate}
    submitParams={rate.id}
    onSubmitted={onUpdated}
    onCancel={onCancel}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit VAT Rate">
        <VatForm {...formProps} />
      </Modal.Edit>
    )}
  />
);

export default EditVatRate;
