import React from "react";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { listMobileUsers } from "./actions";
import { HeaderBar, TablePanel } from "components/containers";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { Icon } from "semantic-ui-react";
import MobileUserActions from "./MobileUserActions";
import FilterUsers from "./FilterUsers";
import AddMobileUser from "./AddMobileUser";

const ListMobileUsers = () => {
  return (
    <ListBuilder
      withTable
      loadAction={listMobileUsers}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name="Mobile Users"
                rightContent={
                  <PermissionCheck
                    target={Targets.MobileUsers}
                    action={Actions.Create}
                  >
                    <AddMobileUser
                      content="Add User"
                      onRefresh={events.onRefresh}
                    />
                  </PermissionCheck>
                }
              >
                <FilterUsers
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No users found"
            selectable
          >
            <Table.Column
              width="5%"
              render={(user) => (
                <Icon
                  circular
                  inverted
                  color="grey"
                  name="user"
                  size="small"
                  disabled={user.status != "Active"}
                />
              )}
            />
            <Table.Column
              title="Name"
              render={(user) => user.firstName + " " + user.lastName}
              width="20%"
            />
            <Table.Column
              title="Username"
              render={(user) => user.username}
              width="20%"
            />
            <Table.Column
              title="Department"
              render={(user) => user.departmentName}
            />
            <Table.Column
              title="Status"
              render={(user) => (user.isActive ? "Active" : "Locked Out")}
              width="10%"
            />
            <PermissionCheck
              target={Targets.MobileUsers}
              action={[Actions.Edit, Actions.Delete]}
              any
            >
              <Table.Column
                hoverable
                width="10%"
                align="right"
                render={(user) => (
                  <MobileUserActions
                    mobileUser={user}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default ListMobileUsers;
