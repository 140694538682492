import React from "react";
import { Segment, Header, Divider } from "semantic-ui-react";
import { PageHeader } from "@redriver/cinnamon";
import { UserDetails } from "features/System";
import { KpsPageContent } from "components/containers";

const AccountPage = () => (
  <KpsPageContent>
    <PageHeader>My Account</PageHeader>
    <Segment padded>
      <Header as="h4">Account Details</Header>
      <Divider hidden />
      <UserDetails />
      <Divider section />
    </Segment>
  </KpsPageContent>
);

export default AccountPage;
