import { apiGet, apiPut, refreshJwt } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/EDIT_USER";

const ActionTypes = {
  GetUser: `${NAMESPACE}/GET_USER`,
  SaveUser: `${NAMESPACE}/SAVE_USER`,
};

export const getUser = (userId) =>
  apiGet(ActionTypes.GetUser, `users/${userId}`);

export const saveUser = (data, userId) => (dispatch, getState) => {
  const currentUserId = getState().auth?.claims?.userId;
  return new Promise((resolve) => {
    dispatch(apiPut(ActionTypes.SaveUser, `users/${userId}`, data)).then(
      (res) => {
        if (currentUserId == userId.toLowerCase().replace(/-/g, "")) {
          dispatch(refreshJwt(true));
        }
        resolve(res);
      }
    );
  });
};
