import React from "react";
import { Table } from "@redriver/cinnamon";
import DeleteProductCategory from "./DeleteProductCategory";
import EditProductCategory from "./EditProductCategory";

const ProductCategoryActions = ({ productCategory, onRefresh }) => (
  <Table.Menu openOnFocus>
    <EditProductCategory
      productCategory={productCategory}
      onEdited={onRefresh}
      as={Table.MenuItem}
    >
      Edit Product Category
    </EditProductCategory>
    <DeleteProductCategory
      productCategory={productCategory}
      onDeleted={onRefresh}
      as={Table.MenuItem}
    >
      Delete Product Category
    </DeleteProductCategory>
  </Table.Menu>
);

export default ProductCategoryActions;
