export const ResourceFilterTypes = {
  Resources: "Resources",
  Jobs: "Jobs",
};

export const ShowOnlyOptions = [
  { value: "All", text: "Show All" },
  { value: "Assigned", text: "Show Only Assigned" },
  { value: "Unassigned", text: "Show Only Unassigned" },
];
