import React from "react";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import {
  DepartmentsTypeAhead,
  ProductCategoriesTypeAhead,
} from "components/forms";
import { ClearLabel } from "components/controls";

export const FieldNames = {
  Search: "search",
  DepartmentId: "departmentId",
  DepartmentDisplay: "department",
  CategoryId: "categoryId",
  CategoryDisplay: "category",
};

const ProductsFilters = ({ formProps, resetFilters }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="filter-side-panel"
    >
      <Form {...formProps}>
        <div className="ui header filters-title">
          <div className="title-text">Filters</div>
          <ClearLabel onClick={resetFilters} />
        </div>
        <Grid columns={2}>
          <Grid.Column>
            <DepartmentsTypeAhead
              field={FieldNames.DepartmentId}
              fluid
              label="Department"
              placeholder="Select department"
              clearable
              textField={FieldNames.DepartmentDisplay}
            />
          </Grid.Column>
          <Grid.Column>
            <ProductCategoriesTypeAhead
              field={FieldNames.CategoryId}
              fluid
              label="Category"
              placeholder="Select category"
              clearable
              textField={FieldNames.CategoryDisplay}
            />
          </Grid.Column>
        </Grid>
      </Form>
    </SidePanel>
  );
};

export default ProductsFilters;
