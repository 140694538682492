import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import {
  DeleteDocumentModal,
  DownloadDocumentModal,
  EditDocumentModal,
} from "components/modals";
import { Actions, Targets } from "constants/Permissions";
import React from "react";
import {
  deleteEnquiryDocument,
  getEnquiryDocument,
  updateEnquiryDocument,
} from "./actions";
import { EnquiryDocumentTypesDropdown } from "components/forms";
import { EnquiryDocumentType } from "constants/enums";

const EnquiryDocumentTableActions = ({ document, enquiryId, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.EnquiryDocument} action={Actions.View}>
      <DownloadDocumentModal
        document={document}
        action={getEnquiryDocument}
        actionParams={{ enquiryId }}
        as={Table.MenuItem}
      >
        Download Document
      </DownloadDocumentModal>
    </PermissionCheck>
    {!(
      document.type === EnquiryDocumentType.Estimate ||
      document.type === EnquiryDocumentType.Quote ||
      document.type === EnquiryDocumentType.JobSheet
    ) && (
      <PermissionCheck target={Targets.EnquiryDocument} action={Actions.Edit}>
        <EditDocumentModal
          document={document}
          submitAction={updateEnquiryDocument}
          submitParams={{ enquiryId }}
          onUpdated={onRefresh}
          documentTypeDropdown={
            <EnquiryDocumentTypesDropdown
              field="type"
              label="Type"
              placeholder="Select document type"
              required
              fluid
            />
          }
          as={Table.MenuItem}
        >
          Edit Document
        </EditDocumentModal>
      </PermissionCheck>
    )}
    <PermissionCheck target={Targets.EnquiryDocument} action={Actions.Delete}>
      <DeleteDocumentModal
        document={document}
        action={deleteEnquiryDocument}
        actionParams={{ enquiryId }}
        onDeleted={onRefresh}
        as={Table.MenuItem}
      >
        Delete Document
      </DeleteDocumentModal>
    </PermissionCheck>
  </Table.Menu>
);

export default EnquiryDocumentTableActions;
