import React, { useState } from "react";
import { ModalBuilder, Modal, Form, Format } from "@redriver/cinnamon";
import { Grid } from "semantic-ui-react";
import { DateTime } from "luxon";
import { spreadJob } from "./actions";
import { useDispatch } from "react-redux";
import { produce } from "immer";

const options = new Array(9)
  .fill(true)
  .map((_, i) => ({ value: i + 2, text: `${i + 2} Days` }));

const SpreadJob = ({ job, visible, onClose, onSubmitted }) => {
  const [formData, setFormData] = useState({
    from: job.date,
  });
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const items = job.items || [];

  return (
    <ModalBuilder
      visible={visible}
      onSubmit={() => {
        if (formData.from != formData.to) {
          const request = {
            jobId: job.jobId,
            jobSheetId: job.id,
            jobItemIds: job.items.map((x) => x.id),
            from: formData.from,
            to: formData.to,
          };
          setSubmitting(true);
          dispatch(spreadJob(request))
            .then(() => {
              if (onSubmitted) {
                onSubmitted();
              }
              onClose();
              setFormData({
                from: job.date,
              });
            })
            .finally(() => setSubmitting(false));
        }
      }}
      onCancel={() => {
        onClose();
        setFormData({
          from: job.date,
        });
      }}
      initialData={{
        from: job.date,
      }}
      renderModal={(modalProps) => (
        <Modal.Edit
          {...modalProps}
          submitting={submitting}
          slowSubmitting={submitting}
          header="Spread Job"
          submitLabel="Confirm"
        >
          <Form
            value={formData}
            onChange={(field, data, applyChanges) => {
              // manually work out the next state as the 'to' and 'quick' fields affect each others value
              const nextState = applyChanges(formData);
              const modifiedNextState = produce(nextState, (draft) => {
                if (field == "quick") {
                  draft.to = draft.quick
                    ? DateTime.fromISO(draft.from)
                        .plus({ days: draft.quick - 1 })
                        .toISODate()
                    : null;
                }
                if (field == "to") {
                  if (draft.to && draft.from) {
                    const daysDiff =
                      DateTime.fromISO(draft.to).diff(
                        DateTime.fromISO(draft.from),
                        "days"
                      ).values.days + 1;
                    const quickOption = options.find(
                      (x) => x.value == daysDiff
                    );
                    draft.quick =
                      quickOption != null ? quickOption.value : null;
                  } else {
                    draft.quick = null;
                  }
                }
                if (field == "from" && !data) {
                  // auto reset from field to job date when blanked
                  draft.from = job.date;
                }
              });

              setFormData(modifiedNextState);
            }}
          >
            <section className="spread-scope-of-work">
              <header>
                <span>Scope of work</span>
                <Format.Number value={job.price} format="GBP" />
              </header>
              {items.map((x) => (
                <div className="job-item" key={x.id}>
                  <span>
                    {x.quantity} x {x.productName}
                  </span>
                  {x.description && (
                    <span className="description"> - {x.description}</span>
                  )}
                </div>
              ))}
              {items.length == 0 && <span className="no-items">No items</span>}
            </section>

            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Date
                    field="from"
                    label="Start Date"
                    local
                    required
                    minDate={DateTime.fromISO(job.date).toMoment()}
                    maxDate={
                      formData.to
                        ? DateTime.fromISO(formData.to).toMoment()
                        : null
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Date
                    field="to"
                    label="Finish Date"
                    local
                    required
                    minDate={
                      formData.from
                        ? DateTime.fromISO(formData.from).toMoment()
                        : null
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Dropdown
                    field="quick"
                    label="Quick Day Selector"
                    options={options}
                    placeholder="Select days"
                    clearable
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default SpreadJob;
