import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { CustomerRoutes } from "constants/routes";
import CustomerListPage from "./CustomerListPage";
import CustomerDetailsPage from "./CustomerDetailsPage";

const CustomerRouter = () => (
  <Switch>
    <Route exact path={CustomerRoutes.Customers} component={CustomerListPage} />
    <Route path={CustomerRoutes.Customer()} component={CustomerDetailsPage} />
    <Route render={() => <Redirect to={CustomerRoutes.Customers} />} />
  </Switch>
);

export default CustomerRouter;
