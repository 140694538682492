import React, { Fragment } from "react";
import { Form } from "@redriver/cinnamon";
import {
  ContractPlotsTypeAhead,
  ContractPricingTypesDropdown,
  ProductsTypeAhead,
  VATDropdown,
} from "components/forms";
import { StandardVatRateId, ZeroRateVatId } from "constants/vatRates";

const ContractJobItemForm = ({ job, ...formProps }) => {
  const { id, contractId, hasContractPlot } = job;
  const formData = formProps.value;
  const productOrMiscError = "Select Product or Miscellaneous Item";

  return (
    <Form {...formProps}>
      {!hasContractPlot ? (
        <Fragment>
          <ContractPricingTypesDropdown
            field="jobType"
            label="Job Type"
            clearable
          />
          <hr />
          <div className="product-options">
            <div style={{ marginRight: "2rem" }}>
              <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                Select a Product
              </p>
              <ProductsTypeAhead
                field="productId"
                optionField="productItem"
                label="Product"
                inline={true}
                required={!formData?.miscItem}
                requiredError={productOrMiscError}
                categoryId={formData?.productCategoryId}
                textField="productName"
                clearable
              />
            </div>
            <div>
              <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                Enter a Miscellaneous Item
              </p>
              <Form.Input
                field="miscItem"
                label="Miscellaneous Item"
                inline
                required={!formData?.productId}
                requiredError={productOrMiscError}
              />
            </div>
            <Form.Trigger
              field="productId"
              event="onSet"
              action="clear"
              target="miscItem"
            />
            <Form.Trigger
              field="miscItem"
              event="onSet"
              action="clear"
              target="productId"
            />
          </div>
        </Fragment>
      ) : (
        <div className="product-options">
          <div style={{ marginRight: "2rem" }}>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              Select a Plot
            </p>
            <ContractPlotsTypeAhead
              field="contractPlotId"
              textField="contractPlotDescription"
              label="Plot Number"
              contractId={contractId}
              jobId={id}
              inline={true}
              searchSuggestions
              clearable
              required
            />
          </div>
          <div>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              Enter a Product
            </p>
            <Form.Input field="miscItem" label="Product" inline required />
          </div>
        </div>
      )}
      <hr />
      <Form.TextArea field="description" label="Description" rows={2} fluid />
      <Form.Group>
        <Form.Numeric field="quantity" label="Quantity" width={12} required />
        <Form.Numeric
          field="unitPrice"
          label="Price"
          decimalPlaces={2}
          formatValue={formData?.unitPrice != null}
          subLabel="£"
          subLabelPosition="left"
          width={15}
        />
        <VATDropdown
          field="vatRateId"
          unknownValueOptions={[
            { text: "Standard Rate", value: StandardVatRateId },
            { text: "Zero Rate", value: ZeroRateVatId },
          ]}
          label="VAT Rate"
          clearable
          width={16}
        />
      </Form.Group>
      {!hasContractPlot && (
        <Form.Trigger
          field="productId"
          target="unitPrice"
          action="change"
          value={({ fields }) =>
            fields.productId ? fields.productItem?.price : null
          }
        />
      )}
      <Form.Trigger
        field="productId"
        target="vatRateId"
        action="change"
        value={({ fields }) =>
          fields.productId
            ? fields.productItem?.vatRateId ?? StandardVatRateId
            : fields.miscItem
            ? StandardVatRateId
            : null
        }
      />
    </Form>
  );
};

export default ContractJobItemForm;
