import { Form } from "@redriver/cinnamon";
import {
  ContractPricingInvoicingTypeFields,
  ContractPricingType,
  ContractPricingTypeFields,
} from "constants/enums";
import React from "react";
import { Grid } from "semantic-ui-react";
import ContractPricingHeader from "./ContractPricingHeader";
import ContractPricingItem from "./ContractPricingItem";

const excludePricePerPlot = [ContractPricingInvoicingTypeFields.PricePerPlot];

const ContractPricing = () => (
  <Form.Object field="contractPricing">
    <Grid.Row columns={2}>
      <Grid.Column className="total-value">
        <Form.Numeric
          field="totalValue"
          label="Contract Total Value"
          decimalPlaces={2}
          formatValue
          subLabel="£"
          subLabelPosition="left"
          width="50%"
        />
      </Grid.Column>
    </Grid.Row>
    <ContractPricingHeader />
    {Object.keys(ContractPricingTypeFields).map((field) => (
      <Form.Trigger
        key={field}
        field={field}
        target="totalValue"
        action="change"
        value={({ fields }) =>
          parseFloat(fields.PlotWorksFencing?.value || 0) +
          parseFloat(fields.PlotWorksLandscaping?.value || 0) +
          parseFloat(fields.PosWorksFencing?.value || 0) +
          parseFloat(fields.PosWorksLandscaping?.value || 0) +
          parseFloat(fields.AdditionalWorks?.value || 0) +
          parseFloat(fields.InfrastructureWorks?.value || 0) +
          parseFloat(fields.MaintenanceWorks?.value || 0)
        }
      />
    ))}
    <ContractPricingItem
      field={ContractPricingTypeFields.PlotWorksFencing}
      label={ContractPricingType.PlotWorksFencing}
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.PlotWorksLandscaping}
      label={ContractPricingType.PlotWorksLandscaping}
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.PosWorksFencing}
      label={ContractPricingType.PosWorksFencing}
      hiddenInvoiceTypes={excludePricePerPlot}
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.PosWorksLandscaping}
      label={ContractPricingType.PosWorksLandscaping}
      hiddenInvoiceTypes={excludePricePerPlot}
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.AdditionalWorks}
      label={ContractPricingType.AdditionalWorks}
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.InfrastructureWorks}
      label={ContractPricingType.InfrastructureWorks}
      hiddenInvoiceTypes={excludePricePerPlot}
      isClaimBased
    />
    <ContractPricingItem
      field={ContractPricingTypeFields.MaintenanceWorks}
      label={ContractPricingType.MaintenanceWorks}
      hiddenInvoiceTypes={excludePricePerPlot}
      isClaimBased
    />
  </Form.Object>
);

export default ContractPricing;
