import React, { useCallback, useEffect } from "react";
import AvailabilityBlock from "./AvailabilityBlock";
import ResourceJobDetails from "./ResourceJobDetails";
import { useAuthClaims } from "@redriver/cinnamon";
import { claimExists } from "components/auth/utils";
import { Actions, Targets } from "constants/Permissions";
import { ResourceSchedulingState, PartOfDay } from "constants/enums";
import { Popup, Input } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { setJobSheetScheduleNote, setResourceNote } from "./actions";

const StateWidths = {
  MaternityPaternity: 150,
};

const ResourceAllocationGrid = ({
  resources,
  jobs,
  addResourceAssignment,
  removeResourceAssignment,
  updateResourceNote,
  updateJobSheetScheduleNote,
  date,
}) => {
  const dispatch = useDispatch();
  const permissions = useAuthClaims().permissions;
  const canEdit = claimExists(
    permissions,
    Targets.ResourceScheduling,
    Actions.Edit
  );

  const labelWidth = resources.reduce((acc, r) => {
    const maxSize = r.absences?.reduce(
      (acc, a) =>
        StateWidths[a.availability] > acc ? StateWidths[a.availability] : acc,
      110
    );
    return maxSize > acc ? maxSize : acc;
  }, 110);

  const width = resources.some(
    (r) =>
      r.absences != null &&
      r.absences.filter((a) => a.partOfDay != PartOfDay.FullDay).length > 1
  )
    ? 150
    : labelWidth;

  const [resourceNotes, setLocalResourceNotes] = React.useState({});

  const setResourceNotes = useCallback(
    (notes, id) => {
      updateResourceNote(id, notes[id]);
      setLocalResourceNotes(notes);
    },
    [updateResourceNote]
  );

  useEffect(() => {
    setLocalResourceNotes(
      resources.reduce((acc, r) => {
        acc[r.id] = r.note ?? "";
        return acc;
      }, {})
    );
  }, [resources, setLocalResourceNotes]);

  const [jobSheetScheduleNotes, setLocalJobSheetScheduleNotes] = React.useState(
    {}
  );

  const setJobSheetScheduleNotes = useCallback(
    (notes, jobSheetId) => {
      updateJobSheetScheduleNote(jobSheetId, notes[jobSheetId]);
      setLocalJobSheetScheduleNotes(notes);
    },
    [updateJobSheetScheduleNote]
  );

  useEffect(() => {
    setLocalJobSheetScheduleNotes(
      jobs.reduce((acc, j) => {
        acc[j.jobSheetId] = j.note ?? "";
        return acc;
      }, {})
    );
  }, [jobs, setLocalJobSheetScheduleNotes]);

  return (
    <section
      className="resource-grid"
      style={{
        gridTemplateColumns: `180px 180px repeat(${jobs.length}, ${width}px)`,
        gridGap: "3px",
      }}
    >
      <div
        className="header-notes"
        style={{
          gridRow: 1,
          gridColumn: "1 / span 2",
        }}
      >
        <header>Notes</header>
      </div>

      {jobs.map((j, idx) => (
        <div
          className="header"
          style={{
            gridRow: 1,
            gridColumn: idx + 3,
            gridColumnEnd: "span 1",
          }}
          key={j.jobSheetId}
        >
          <ResourceJobDetails job={j} />
          <Input
            onChange={(e) => {
              setJobSheetScheduleNotes(
                {
                  ...jobSheetScheduleNotes,
                  [j.jobSheetId]: e.target.value,
                },
                j.jobSheetId
              );
            }}
            onBlur={(e) => {
              if (e.target.value != null) {
                dispatch(
                  setJobSheetScheduleNote(
                    j.jobId,
                    j.jobSheetId,
                    date,
                    e.target.value
                  )
                );
              }
            }}
            value={jobSheetScheduleNotes[j.jobSheetId] ?? ""}
          />
        </div>
      ))}
      {resources.map((r, idx) => (
        <React.Fragment key={"row-" + r.id}>
          <div
            className="resource"
            style={{
              gridRow: idx + 2,
              gridColumn: 1,
            }}
            key={r.id}
          >
            <div
              className="department-colour-display"
              style={{ background: r.departmentDisplayColour }}
            >
              {" "}
            </div>
            <div className="resource-name">{r.name}</div>
            {r.absences?.length > 0 && (
              <Popup
                flowing
                hoverable
                content={r.absences
                  .map(
                    (a) => a.reason ?? ResourceSchedulingState[a.availability]
                  )
                  .reduce((acc, a) => {
                    if (!acc.includes(a)) {
                      acc.push(a);
                    }
                    return acc;
                  }, [])
                  .join(", ")}
                trigger={<div className="absence-indicator">!</div>}
              />
            )}
          </div>
          <div
            style={{
              gridColumn: 2,
              gridRow: idx + 2,
            }}
            className="notes"
          >
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                setResourceNotes(
                  {
                    ...resourceNotes,
                    [r.id]: e.target.value,
                  },
                  r.id
                );
              }}
              onBlur={(e) => {
                if (e.target.value != null) {
                  dispatch(setResourceNote(r.id, date, e.target.value));
                }
              }}
              value={resourceNotes[r.id] ?? ""}
            />
          </div>
          {jobs.map((j, jobIndex) => (
            <AvailabilityBlock
              key={`${r.id}-block-${j.jobSheetId}`}
              resource={r}
              job={j}
              onAdd={addResourceAssignment}
              onRemove={removeResourceAssignment}
              gridRow={idx + 2}
              gridColumn={jobIndex + 3}
              editable={canEdit}
              otherJobAssignments={r.assignedJobSheets?.filter((e) => {
                return !jobs.some((j) => j.jobSheetId === e.jobSheetId);
              })}
            />
          ))}
        </React.Fragment>
      ))}
    </section>
  );
};

export default ResourceAllocationGrid;
