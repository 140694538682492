import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Image, Segment } from "semantic-ui-react";

const BannerPanel = ({
  className,
  title,
  infoItemList,
  additionalInfoItemList,
  footerContent,
  rightContent,
  ...otherProps
}) => {
  const css = classNames("banner-panel", className);
  return (
    <Segment {...otherProps} className={css}>
      <header>
        <h3 className="title">{title}</h3>
        <p>
          {infoItemList &&
            infoItemList.map((item, i) => {
              if (item.renderItem) {
                return (
                  <React.Fragment key={i}>{item.renderItem()}</React.Fragment>
                );
              }
              if ((!!item.text || item.showIfEmpty) && !item.hidden) {
                return (
                  <span
                    key={i}
                    title={item.tooltip}
                    style={{ maxWidth: item.maxWidth }}
                  >
                    {item.icon && (
                      <Image src={item.icon} className="banner-icon" />
                    )}
                    {item.header && <strong>{item.header}: </strong>}
                    {item.onClick ? (
                      <a className="main" onClick={item.onClick}>
                        <span>{item.text}</span>
                      </a>
                    ) : (
                      <span>{item.text}</span>
                    )}
                  </span>
                );
              }
            })}
        </p>
        {additionalInfoItemList && (
          <div className="banner-panel-additional-info">
            <p>
              {additionalInfoItemList.map((item, i) => {
                if (item.renderItem) {
                  return (
                    <React.Fragment key={i}>{item.renderItem()}</React.Fragment>
                  );
                }
                if ((!!item.text || item.showIfEmpty) && !item.hidden) {
                  return (
                    <span
                      key={i}
                      title={item.tooltip}
                      style={{ maxWidth: item.maxWidth }}
                    >
                      {item.icon && (
                        <Image src={item.icon} className="banner-icon" />
                      )}
                      {item.header && <strong>{item.header}: </strong>}
                      {item.onClick ? (
                        <a className="main" onClick={item.onClick}>
                          <span>{item.text}</span>
                        </a>
                      ) : (
                        <span>{item.text}</span>
                      )}
                    </span>
                  );
                }
              })}
            </p>
          </div>
        )}
        {footerContent && (
          <div className="banner-panel-footer">{footerContent}</div>
        )}
      </header>
      {rightContent && (
        <footer className="banner-panel-right">{rightContent}</footer>
      )}
    </Segment>
  );
};

BannerPanel.propTypes = {
  title: PropTypes.node,
  infoItemList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      text: PropTypes.string,
      renderItem: PropTypes.func,
      showIfEmpty: PropTypes.bool,
    })
  ),
  footerContent: PropTypes.node,
  rightContent: PropTypes.node,
};

export default BannerPanel;
