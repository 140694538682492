import React from "react";
import { KpsPageContent } from "components/containers";
import { ListContracts } from "features/Contracts";

const ContractListPage = () => (
  <KpsPageContent>
    <ListContracts />
  </KpsPageContent>
);

export default ContractListPage;
