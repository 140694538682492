import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_VAT_RATES";

const ActionTypes = {
  GetVatRates: `${NAMESPACE}/GET_VAT_RATES`,
  GetVatRate: `${NAMESPACE}/GET_VAT_RATE`,
  AddVatRate: `${NAMESPACE}/ADD_VAT_RATE`,
  UpdateVatRate: `${NAMESPACE}/UPDATE_VAT_RATE`,
  DeleteVatRate: `${NAMESPACE}/DELETE_VAT_RATE`,
};

export const getVatRates = () => {
  return apiGet(ActionTypes.GetVatRates, `vat-rates`);
};

export const getVatRate = (rateId) => {
  return apiGet(ActionTypes.GetVatRate, `vat-rates/${rateId}`);
};

export const addVatRate = (data) => {
  data.value = data.value / 100;
  return apiPost(ActionTypes.AddVatRate, `vat-rates`, data);
};

export const updateVatRate = (data, rateId) => {
  data.value = data.value / 100;
  return apiPut(ActionTypes.UpdateVatRate, `vat-rates/${rateId}`, data);
};

export const deleteVatRate = (rateId) => {
  return apiDelete(ActionTypes.DeleteVatRate, `vat-rates/${rateId}`);
};
