import { apiGet, authenticate } from "@redriver/cinnamon";

const NAMESPACE = "AzureAdSignIn";

const ActionTypes = {
  GET_TOKENS: `${NAMESPACE}/GET_TOKENS`,
};

export const getTokens = (code) =>
  apiGet(
    ActionTypes.GET_TOKENS,
    "auth/azure-ad-sign-in",
    {
      code,
    },
    {
      auth: false,
    }
  );

export const requestLogin = (code) => async (dispatch) => {
  // make the login api call
  const result = await dispatch(getTokens(code));

  const { success, response } = result;
  if (success) {
    // authenticate the user session
    const { accessToken, refreshToken } = response.tokens;
    dispatch(authenticate(accessToken, refreshToken));
  }

  return result;
};
