import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { uploadContractPlots } from "./actions";

const UploadContractPlots = ({
  as: As = Button,
  contractId,
  pricingType,
  onUploaded,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [result, setResult] = useState(null);

  return (
    <React.Fragment>
      <As onClick={() => setVisible(true)} {...props} />
      <ModalBuilder
        withForm
        visible={visible}
        submitAction={uploadContractPlots}
        submitParams={{ contractId, pricingType }}
        onSubmitted={(response) => setResult(response)}
        onCancelled={() => {
          setVisible(false);
          setResult(null);
        }}
        renderModal={(modalProps, formProps) => (
          <Modal.Add
            {...modalProps}
            header="Upload Plots"
            open={modalProps.open || !!result}
            onSubmit={
              result
                ? () => {
                    setVisible(false);
                    setResult(null);
                    onUploaded();
                  }
                : modalProps.onSubmit
            }
            submitLabel={result ? "Ok" : "Upload"}
            submitIcon={result ? "check" : "upload"}
          >
            {result ? (
              <React.Fragment>
                <p>Plots imported successfully.</p>
                <p>
                  <strong>{`Total Imported: ${result.totalImported}`}</strong>
                </p>
                <p>
                  <strong>{`Total Updated: ${result.totalUpdated}`}</strong>
                </p>
                <p>
                  <strong>{`Total Processed: ${result.totalProcessed}`}</strong>
                </p>
              </React.Fragment>
            ) : (
              <Form {...formProps}>
                <Form.FileUpload
                  field="csv"
                  label="Upload a file - please note this must be of type .csv"
                  fileExtensionTypes={["csv"]}
                  fileTypesError="Incorrect file type uploaded"
                  required
                  fluid
                />
              </Form>
            )}
          </Modal.Add>
        )}
      />
    </React.Fragment>
  );
};

export default UploadContractPlots;
