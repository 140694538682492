import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/DOCUMENTS";

export const ActionTypes = {
  GetContractDocuments: `${NAMESPACE}/GET_CONTRACT_DOCUMENTS`,
  AddContractDocument: `${NAMESPACE}/ADD_CONTRACT_DOCUMENT`,
  GetContractDocument: `${NAMESPACE}/GET_CONTRACT_DOCUMENT`,
  DeleteContractDocument: `${NAMESPACE}/DELETE_CONTRACT_DOCUMENT`,
  UpdateContractDocument: `${NAMESPACE}/UPDATE_CONTRACT_DOCUMENT`,
};

export const getContractDocuments = (
  { filters, pagination },
  { contractId, category, type }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetContractDocuments,
    `contracts/${contractId}/documents`,
    {
      pageSize,
      pageNumber,
      category,
      type,
      ...filters,
    }
  );
};

export const addContractDocument = (data, { contractId, category }) => {
  const formData = new FormData();
  formData.append("category", category);
  formData.append("name", data.name ?? "");
  formData.append("description", data.description ?? "");
  formData.append("type", data.type);
  (data.files || []).forEach((f) => {
    formData.append(`files`, f);
  });

  return apiPost(
    ActionTypes.AddContractDocument,
    `contracts/${contractId}/documents`,
    formData,
    { contentType: null }
  );
};

export const getContractDocument = ({ contractId, docId }) => {
  return apiGet(
    ActionTypes.GetContractDocument,
    `contracts/${contractId}/documents/${docId}`,
    {},
    { preventResponseHandling: true }
  );
};

export const deleteContractDocument = ({ contractId, docId }) => {
  return apiDelete(
    ActionTypes.DeleteContractDocument,
    `contracts/${contractId}/documents/${docId}`
  );
};

export const updateContractDocument = (
  formData,
  { contractId, documentId }
) => {
  const { type, description } = formData;
  return apiPut(
    ActionTypes.UpdateContractDocument,
    `contracts/${contractId}/documents/${documentId}`,
    {
      type,
      description,
    }
  );
};
