import React, { useEffect, useMemo, useRef } from "react";

import { Format, useAuthClaims } from "@redriver/cinnamon";
import { DateTime } from "luxon";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { Segment, Ref } from "semantic-ui-react";
import classNames from "classnames";
import { claimExists } from "components/auth/utils";
import { Targets, Actions } from "constants/Permissions";
import { JobStatus } from "constants/enums";

import { range } from "modules/helpers";
import JobCard from "./JobCard";
import { getScheduledJobs } from "./actions";
import { getJobSchedulingState } from "./selectors";

const ScheduledJobs = ({ setJobCardWidth }) => {
  const dispatch = useDispatch();
  const selectedWeek = useSelector(getJobSchedulingState).selectedWeek;

  const { permissions } = useAuthClaims();

  const canEdit = claimExists(permissions, Targets.JobScheduling, Actions.Edit);

  const departmentsFilter = useSelector(
    getJobSchedulingState
  ).departmentsFilter;

  const searchFilter = useSelector(getJobSchedulingState).searchFilter;
  const contractManagerFilter = useSelector(
    getJobSchedulingState
  ).contractManagerFilter;

  useEffect(() => {
    dispatch(
      getScheduledJobs(
        selectedWeek,
        departmentsFilter,
        searchFilter,
        contractManagerFilter.value
      )
    );
  }, [
    dispatch,
    departmentsFilter,
    selectedWeek,
    searchFilter,
    contractManagerFilter.value,
  ]);

  const daysOfWeek = useMemo(
    () =>
      range(0, 6).map((i) =>
        DateTime.fromISO(selectedWeek).plus({ days: i }).toISODate()
      ),
    [selectedWeek]
  );

  const schedule = useSelector(getJobSchedulingState).schedule;
  const loading = Object.keys(schedule).length == 1;

  const scheduledJobsRef = useRef(null);

  useEffect(() => {
    if (scheduledJobsRef) {
      setJobCardWidth(scheduledJobsRef.current.childNodes[0].clientWidth - 2); // 2px to account for divider
    }
  }, [scheduledJobsRef, setJobCardWidth]);

  return (
    <Ref innerRef={scheduledJobsRef}>
      <Segment
        className="scheduled-jobs"
        loading={loading}
        style={{ gridTemplateColumns: `repeat(${daysOfWeek.length}, 1fr)` }}
      >
        {daysOfWeek.map((day, i) => {
          const isToday =
            DateTime.local().toFormat("EEE dd LLL") ==
            DateTime.fromISO(day).toFormat("EEE dd LLL");
          const isLast = daysOfWeek.length === i + 1;
          return (
            <div
              className={classNames(
                "scheduled-day-header",
                isLast ? "last-day" : null
              )}
              key={"header-" + i}
              style={{ gridColumn: i + 1, gridRow: 1 }}
            >
              <h4 className={isToday ? "today" : null}>
                {DateTime.fromISO(day).toFormat("EEE dd LLL")}
              </h4>
              <div className="totals">
                {schedule[day] ? schedule[day].length : 0} Jobs &nbsp;•&nbsp;
                <Format.Number
                  value={
                    schedule[day]
                      ? schedule[day].reduce(
                          (total, job) =>
                            total +
                            job.items.reduce(
                              (subTotal, item) => subTotal + item.netPrice,
                              0
                            ),
                          0
                        )
                      : 0
                  }
                  format="GBP"
                />
              </div>
              {isToday && (
                <div className="today-text">
                  <span>Today</span>
                </div>
              )}
            </div>
          );
        })}

        {daysOfWeek.map((day, i) => {
          const isLast = daysOfWeek.length === i + 1;
          return (
            <div
              className={classNames(
                "scheduled-day",
                isLast ? "last-day" : null
              )}
              key={i}
              style={{ gridColumn: i + 1, gridRow: 2 }}
            >
              <Droppable droppableId={day} isCombineEnabled={true}>
                {(droppableProvided) => (
                  <div
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    className="jobs-droppable"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: 1,
                    }}
                  >
                    {schedule &&
                      schedule[day] &&
                      schedule[day].map((job, i) => {
                        return (
                          <Draggable
                            draggableId={`${job.id}`}
                            index={i}
                            key={job.id}
                            isDragDisabled={
                              !canEdit ||
                              job.jobStatusText == JobStatus.Completed ||
                              job.jobStatusText == JobStatus.Invoiced
                            }
                          >
                            {(provided) => (
                              <JobCard
                                job={job}
                                containerRef={scheduledJobsRef}
                                innerRef={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              />
                            )}
                          </Draggable>
                        );
                      })}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          );
        })}
      </Segment>
    </Ref>
  );
};

export default ScheduledJobs;
