import React, { useState, useEffect } from "react";
import {
  ModalBuilder,
  Modal,
  ListBuilder,
  TileGrid,
  Form,
} from "@redriver/cinnamon";
import { updateSelectedAddress } from "./actions";
import { AddressTile } from "components/addresses";
import { getCustomerAddresses } from "features/Customers/CustomerDetails/CustomerAddresses/actions";
import { CustomerTypes } from "constants/enums";
import RealEstateIcon from "assets/icons/real-estate.svg";
import CertificationIcon from "assets/icons/certification.svg";
import { useDispatch } from "react-redux";
import { LinePanel } from "components/containers";
import CustomerAddressForm from "features/Customers/CustomerDetails/CustomerAddresses/CustomerAddressForm";

const JobSelectAddressModal = ({ visible, job, closeModal }) => {
  const initialFormData = {
    createNew: false,
    selectedAddressId: job?.address?.id,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formValid, setFormValid] = useState(true);
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (job.siteAddress && job.siteAddress.id) {
      setFormData((prevState) => ({
        ...prevState,
        selectedAddressId: job.siteAddress.id,
      }));
    }
  }, [job]);

  const onFormChange = (field, data, applyChanges) => {
    setFormData(applyChanges(formData));
  };

  return (
    <ModalBuilder
      visible={visible}
      onSubmit={() => {
        setModalSubmitting(true);
        dispatch(updateSelectedAddress(job.id, formData)).then(() => {
          setModalSubmitting(false);
          setFormData({
            ...initialFormData,
            selectedAddressId: job.siteAddress ? job.siteAddress.id : null,
          });
          closeModal(true);
        });
      }}
      onCancel={() => {
        setFormData({
          ...initialFormData,
          selectedAddressId: job.siteAddress ? job.siteAddress.id : null,
        });
        closeModal();
      }}
      renderModal={(modalProps) => {
        const selectAddress = (id) => {
          setFormData({ ...formData, selectedAddressId: id, createNew: false });
        };
        const highlight =
          job.customerType == CustomerTypes.Residential
            ? "faded-green"
            : "faded-blue";
        return (
          <Modal
            {...modalProps}
            submitting={modalSubmitting}
            header="Job Address"
            submitLabel="Save"
            className="job-address-modal"
            size="small"
            submitDisabled={!formValid}
          >
            <Form
              value={formData}
              onChange={onFormChange}
              onValidated={setFormValid}
            >
              <header>Select Existing Address</header>
              <ListBuilder
                withTiles
                loadAction={getCustomerAddresses}
                loadParams={{ customerId: job.customerId }}
                renderList={(tileProps) => {
                  return (
                    <TileGrid
                      {...tileProps}
                      data={[...tileProps.data, { id: "0", newAddress: true }]}
                      dataKey="id"
                      size="large"
                      render={(item) =>
                        item.newAddress ? (
                          <LinePanel
                            highlight={formData.createNew ? highlight : "blue"}
                            tint="blue"
                            className="new-address"
                            dashed={!formData.createNew}
                            highlightFull={true}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                selectedAddressId: null,
                                createNew: true,
                              });
                            }}
                          >
                            Create New Address
                          </LinePanel>
                        ) : (
                          <AddressTile
                            highlight={highlight}
                            tint={highlight}
                            address={item.address}
                            onClick={() => selectAddress(item.id)}
                            highlightFull={
                              item.id == formData.selectedAddressId
                            }
                            primaryAddress={item.isPrimaryAddress}
                            backgroundUrl={
                              job.customerType == CustomerTypes.Residential
                                ? RealEstateIcon
                                : CertificationIcon
                            }
                          />
                        )
                      }
                    />
                  );
                }}
              />
              <Form.If condition={({ fields }) => fields.createNew}>
                <header>Create New Address</header>
                <CustomerAddressForm />
              </Form.If>
            </Form>
          </Modal>
        );
      }}
    />
  );
};

export default JobSelectAddressModal;
