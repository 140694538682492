import React from "react";
import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { Menu, Message } from "semantic-ui-react";
import { scheduleJobItems } from "./actions";

const ScheduleItemsModal = ({
  as: As = Menu.Item,
  job,
  selectedIds,
  data,
  onSubmitted,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={scheduleJobItems}
      submitParams={{ jobId: job.id, jobItemIds: selectedIds }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header="Schedule Items"
            submitLabel="Schedule"
          >
            {selectedIds.some(
              (id) => data.find((item) => item.id === id)?.isScheduled
            ) && (
              <Message warning>
                <Message.Header>
                  Items you have selected are already scheduled
                </Message.Header>
                {data
                  .filter(
                    (item) => item.isScheduled && selectedIds.includes(item.id)
                  )
                  .map((item) => (
                    <p key={item.id}>
                      <span aria-hidden="true">•</span> {item.productName} -{" "}
                      {item.description}
                    </p>
                  ))}
              </Message>
            )}
            <Form {...formProps}>
              <Form.Date local field="date" label="Date" />
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default ScheduleItemsModal;
