import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteProductCategory } from "./actions";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";

const DeleteProductCategory = ({
  as: As = Button,
  productCategory,
  onDeleted,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteProductCategory}
    submitParams={productCategory.id}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.ProductCategory} action={Actions.Delete}>
        <As onClick={showModal} {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete Product Category">
        Are you sure you wish to delete <b>{productCategory.name}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteProductCategory;
