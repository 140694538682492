import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { QuoteItemForm } from "components/enquiries";
import { editJobItem } from "./actions";
import ContractJobItemForm from "./ContractJobItemForm";

const EditJobItemModal = ({
  initialData,
  onSubmitted,
  disabled,
  job,
  jobItemId,
}) => {
  return (
    <ModalBuilder
      initialData={{
        ...initialData,
        unitPrice: initialData.netPrice / initialData.quantity,
      }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => {
        return (
          <Button
            compact
            icon="pencil"
            disabled={disabled}
            onClick={showModal}
          />
        );
      }}
      submitAction={editJobItem}
      submitParams={{ jobId: job.id, jobItemId }}
      withForm
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit {...modalProps} header="Edit Job Item" size="large">
            <div className={"sow-item-modal"}>
              {job.enquiryId ? (
                <QuoteItemForm {...formProps} financialsRequired />
              ) : (
                <ContractJobItemForm {...formProps} job={job} />
              )}
            </div>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EditJobItemModal;
