import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_TEMPLATE_DOCUMENTS";

export const ActionTypes = {
  GetTemplateDocuments: `${NAMESPACE}/GET_TEMPLATE_DOCUMENTS`,
  AddTemplateDocument: `${NAMESPACE}/ADD_TEMPLATE_DOCUMENT`,
  GetTemplateDocument: `${NAMESPACE}/GET_TEMPLATE_DOCUMENT`,
  GetTemplateDocumentDetails: `${NAMESPACE}/GET_TEMPLATE_DOCUMENT_DETAILS`,
  SaveTemplateDocumentDetails: `${NAMESPACE}/SAVE_TEMPLATE_DOCUMENT_DETAILS`,
  DeleteTemplateDocument: `${NAMESPACE}/DELETE_TEMPLATE_DOCUMENT`,
};

export const getTemplateDocuments = ({ pagination }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetTemplateDocuments, "template-documents", {
    pageSize,
    pageNumber,
  });
};

export const addTemplateDocument = (data) => {
  const formData = new FormData();
  formData.append("fileName", data.fileName);
  formData.append("description", data.description ?? "");
  formData.append("type", data.type);
  formData.append("file", data.file);
  formData.append("departmentIds", JSON.stringify(data.departmentIds));

  return apiPost(
    ActionTypes.AddTemplateDocument,
    `template-documents`,
    formData,
    { contentType: null }
  );
};

export const downloadTemplateDocument = ({ id }) =>
  apiGet(
    ActionTypes.GetTemplateDocument,
    `template-documents/${id}/download`,
    {},
    { preventResponseHandling: true }
  );

export const getTemplateDocumentDetails = (id) =>
  apiGet(ActionTypes.GetTemplateDocument, `template-documents/${id}`);

export const saveTemplateDocumentDetails = (data, id) =>
  apiPut(
    ActionTypes.SaveTemplateDocumentDetails,
    `template-documents/${id}`,
    data
  );

export const deleteTemplateDocument = (id) =>
  apiDelete(ActionTypes.DeleteTemplateDocument, `template-documents/${id}`);
