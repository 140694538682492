import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { addUser } from "./actions";
import { RoleDropdown } from "../lookups";

const AddUser = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addUser}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal
        {...modalProps}
        header="Add User"
        submitLabel="Add"
        submitIcon="mail"
      >
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.Input label="First Name" field="firstName" fluid required />
            <Form.Input label="Last Name" field="lastName" fluid required />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Email label="Email" field="email" fluid required />
            <RoleDropdown
              label="Role"
              field="roleIds"
              fluid
              required
              clearable
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="PeopleHR ID" field="peopleHrId" fluid />
            <div className="field ui" />
          </Form.Group>
        </Form>
      </Modal>
    )}
  />
);

export default AddUser;
