import React, { useCallback } from "react";
import DonutWidget from "./DonutWidget";
import { getContractStatusData } from "./actions";
import { contractColours } from "./colours";
import DashboardWidgetClear from "./DashboardWidgetClear";
import { DashboardWidgetKeys } from "constants/index.js";

const ContractStatusWidget = ({ filters, updateFilter }) => {
  const baseFilters = {
    from: filters.from,
    to: filters.to,
    contractJobType: filters.contractJobType,
  };

  const transform = useCallback(
    (data) =>
      data.map((x) => ({
        ...x,
        colour: contractColours[x.status],
      })),
    []
  );
  const setContractStatusFilter = useCallback(
    (status, displayValue) => {
      if (updateFilter) {
        updateFilter({
          name: "contractStatus",
          value: status,
          displayValue: displayValue,
        });
      }
    },
    [updateFilter]
  );

  return (
    <DonutWidget
      id={DashboardWidgetKeys.ContractStatus}
      title="Contracts"
      action={getContractStatusData}
      filterParams={baseFilters}
      transform={transform}
      onGroupClick={(item) => {
        if (filters.contractStatus == item.status) {
          setContractStatusFilter(null);
        } else {
          setContractStatusFilter(item.status, item.name);
        }
      }}
      headerContent={
        filters.contractStatus && (
          <DashboardWidgetClear onClear={() => setContractStatusFilter(null)} />
        )
      }
      highlightValue={filters.contractStatus}
      highlightFieldName="status"
    />
  );
};

export default ContractStatusWidget;
