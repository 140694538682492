import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import FilterCustomers from "./FilterCustomers";
import TableCustomers from "./TableCustomers";
import TileCustomers from "./TileCustomers";
import { HeaderBar, LinePanel } from "components/containers";
import { PermissionCheck } from "components/auth";
import { SplitViewButton } from "components/buttons";
import { Actions, Targets } from "constants/Permissions";
import AddCustomer from "./AddCustomer";

const ListCustomers = () => {
  const [filters, setFilters] = useState({});
  const [view, setView] = useState("tile");
  return (
    <React.Fragment>
      <LinePanel className="header">
        <HeaderBar
          name="Customers"
          rightContent={
            <div className="right-content">
              <React.Fragment>
                <PermissionCheck
                  target={Targets.Customer}
                  action={Actions.Create}
                >
                  <AddCustomer
                    renderTrigger={(showModal) => (
                      <Button
                        content="Add Customer"
                        onClick={showModal}
                        className="customer-header-button"
                      />
                    )}
                  />
                </PermissionCheck>

                <SplitViewButton value={view} onChange={(v) => setView(v)} />
              </React.Fragment>
            </div>
          }
        >
          <FilterCustomers value={filters} onChange={setFilters} />
        </HeaderBar>
      </LinePanel>
      {view == "row" ? (
        <TableCustomers filters={filters} />
      ) : (
        <TileCustomers filters={filters} />
      )}
    </React.Fragment>
  );
};

export default ListCustomers;
