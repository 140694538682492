import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateEnquiryDetails } from "./actions";
import { EnquiryStatusesLookupDropdown } from "../EnquiryStatusesLookup";
import {
  UsersTypeAhead,
  DepartmentsTypeAhead,
  EnquirySourcesLookupDropdown,
} from "components/forms";
import { Grid } from "semantic-ui-react";
import { CustomerTypes, EnquiryStatus } from "constants/enums";

const EnquiryEditDetailsModal = ({ enquiry, visible, closeModal }) => {
  return (
    <ModalBuilder
      initialData={enquiry}
      submitAction={updateEnquiryDetails}
      onSubmitted={() => closeModal(true)}
      onCancel={closeModal}
      submitParams={{ enquiryId: enquiry.id }}
      withForm
      visible={visible}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit header="Edit Enquiry Details" {...modalProps}>
            <Form {...formProps}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <EnquiryStatusesLookupDropdown
                      label="Status"
                      field="status"
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <EnquirySourcesLookupDropdown
                      field="sourceId"
                      label="Source"
                      fluid
                      clearable
                      placeholder="Select source"
                      unknownValueOptions={
                        enquiry.sourceId
                          ? [{ text: enquiry.source, value: enquiry.sourceId }]
                          : null
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                <Form.If
                  condition={({ fields }) =>
                    fields.status == EnquiryStatus.Lost
                  }
                >
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        field="lossReason"
                        label="Reason for Loss"
                        required
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Form.If>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <DepartmentsTypeAhead
                      label="Department"
                      field="departmentId"
                      fluid
                      clearable
                      unknownValueOptions={
                        enquiry.departmentId
                          ? [
                              {
                                text: enquiry.department,
                                value: enquiry.departmentId,
                              },
                            ]
                          : null
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <UsersTypeAhead
                      label="Contract Manager"
                      placeholder="Select contract manager"
                      field={"ownerId"}
                      fluid
                      clearable
                      unknownValueOptions={
                        enquiry.ownerId
                          ? [{ text: enquiry.owner, value: enquiry.ownerId }]
                          : null
                      }
                    />
                  </Grid.Column>
                </Grid.Row>
                {enquiry.customerType == CustomerTypes.Commercial && (
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        field="orderNumber"
                        label="Order Number"
                        fluid
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EnquiryEditDetailsModal;
