import React from "react";
import ContractInvoiceValueWidget from "./ContractInvoiceValueWidget";
import ContractJobStatusWidget from "./ContractJobStatusWidget";
import ContractsByValueWidget from "./ContractsByValueWidget";
import ContractCustomerWidget from "./ContractCustomerWidget";
import ContractStatusWidget from "./ContractStatusWidget";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";

const ContractDashboard = ({ filters, updateFilter }) => {
  return (
    <PermissionCheck target={Targets.Contract} action={Actions.View}>
      <ContractStatusWidget filters={filters} updateFilter={updateFilter} />
      <ContractCustomerWidget filters={filters} updateFilter={updateFilter} />
      <ContractsByValueWidget filters={filters} updateFilter={updateFilter} />
      <ContractInvoiceValueWidget filters={filters} />
      <ContractJobStatusWidget filters={filters} />
    </PermissionCheck>
  );
};

export default ContractDashboard;
