import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LightboxImage = ({ src, ...otherProps }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Image
        {...otherProps}
        src={src}
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      />
      {isOpen && (
        <Lightbox mainSrc={src} onCloseRequest={() => setOpen(false)} />
      )}
    </React.Fragment>
  );
};

export default LightboxImage;
