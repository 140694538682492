import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteInvoice } from "./actions";

const DeleteInvoiceModal = ({ invoice, onDeleted, onCancel }) => (
  <ModalBuilder
    submitAction={deleteInvoice}
    submitParams={invoice.id}
    onSubmitted={onDeleted}
    onCancel={onCancel}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete Invoice">
        Are you sure you wish to delete <b>{invoice.reference}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteInvoiceModal;
