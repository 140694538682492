import React from "react";
import { Button } from "semantic-ui-react";
import { Format, ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteQuoteItem } from "./actions";
import classNames from "classnames";

const QuoteItem = ({ itemNumber, item, onEdit, onDeleted, enquiryId }) => {
  return (
    <QuoteTableRow
      itemNumber={itemNumber}
      name={item.productName}
      quantity={item.quantity ?? "N/A"}
      price={
        item.unitPrice != null ? (
          <Format.Number value={item.unitPrice * item.quantity} format="GBP" />
        ) : (
          "N/A"
        )
      }
      vat={
        item.vat != null ? (
          <Format.Number value={item.vat * item.quantity} format="GBP" />
        ) : (
          "N/A"
        )
      }
      cost={
        item.unitCost != null ? (
          <Format.Number value={item.unitCost * item.quantity} format="GBP" />
        ) : (
          "N/A"
        )
      }
      total={
        item.unitPrice != null ? (
          <Format.Number
            value={((item.unitPrice ?? 0) + (item.vat ?? 0)) * item.quantity}
            format="GBP"
          />
        ) : (
          "N/A"
        )
      }
      optional={item.description}
      action={
        <DeleteItem
          className="actions"
          item={item}
          enquiryId={enquiryId}
          onDeleted={onDeleted}
        />
      }
      className="quote-item"
      onClick={() => onEdit(item)}
    />
  );
};

export const QuoteTableRow = ({
  itemNumber,
  name,
  quantity,
  price,
  vat,
  cost,
  total,
  optional,
  action,
  className,
  onClick,
}) => {
  const css = classNames("quote-row", className);
  return (
    <section className={css}>
      <div className="itemNumber">{itemNumber}</div>
      <div
        style={{ width: "90%", display: "flex", flexWrap: "wrap" }}
        onClick={onClick}
      >
        <div className="productName">{name}</div>
        <div className="productNumber">{quantity}</div>
        <div className="productNumber">{price}</div>
        <div className="productNumber">{vat}</div>
        <div className="productNumber">{cost}</div>
        <div className="productNumber">{total}</div>
        {optional && <div style={{ width: "48%" }}>{optional}</div>}
      </div>
      <div
        className="itemNumber"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {action}
      </div>
    </section>
  );
};

const DeleteItem = ({ enquiryId, item, onDeleted, className }) => (
  <ModalBuilder
    submitAction={deleteQuoteItem}
    submitParams={{ enquiryId, itemId: item.id }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => (
      <Button
        className={classNames("compact", className)}
        icon="trash"
        title="Delete"
        onClick={showModal}
        floated="right"
      />
    )}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete">
        Are you sure you wish to delete {item.productName}?
      </Modal.Confirmation>
    )}
  />
);

export default QuoteItem;
