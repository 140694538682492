export default {
  Lookup: "l",
  User: "u",
  Role: "r",
  Setting: "s",
  Customer: "cu",
  CustomerComment: "cuco", // cspell: disable-line
  Enquiry: "en",
  EnquiryDocument: "endo", // cspell: disable-line
  Department: "d",
  ProductCategory: "pc",
  Product: "p",
  Job: "j",
  Quote: "q",
  VatRate: "vr",
  Invoice: "i",
  ResourceScheduling: "rs",
  JobScheduling: "js",
  TemplateDocument: "tedo", // cspell: disable-line
  CalendarConfiguration: "cc",
  XeroConfiguration: "xc",
  Contract: "ct",
  ContractDocument: "cdo",
  ContractPlot: "cp",
  ContractJob: "cj",
  MobileUsers: "mu",
  JobFulfilmentOfWorks: "jfw",
  CustomerWorkWalletId: "cuww", // cspell: disable-line
};
