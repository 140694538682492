import React, { useState } from "react";
import { Dropdown as SDropdown } from "semantic-ui-react";
import PropTypes from "prop-types";

const PageSize = ({ options, value, onChange }) => {
  const [open, setOpen] = useState(false);

  const mappedOptions = options.map(x => ({ text: x, value: x }));

  return (
    <div className="page-size" onClick={() => setOpen(!open)}>
      <span>Viewing </span>
      <SDropdown
        open={open}
        compact
        onClick={() => setOpen(!open)}
        options={mappedOptions}
        value={value}
        onChange={(e, d) => {
          onChange(d.value);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
      />
    </div>
  );
};

PageSize.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default PageSize;
