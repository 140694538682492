import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateStatus } from "./actions";
import { JobStatusesLookupDropdown } from "../JobStatusesLookup";

const JobChangeStatusModal = ({ job, visible, closeModal }) => {
  return (
    <ModalBuilder
      initialData={job}
      submitAction={updateStatus}
      onSubmitted={() => closeModal(true)}
      onCancel={closeModal}
      submitParams={{ jobId: job.id }}
      withForm
      visible={visible}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit header="Change Job Status" {...modalProps}>
            <Form {...formProps}>
              <Form.Group widths="equal">
                <JobStatusesLookupDropdown
                  label="Status"
                  field="status"
                  fluid
                />
              </Form.Group>
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default JobChangeStatusModal;
