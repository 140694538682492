import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { EmailQuoteForm } from "components/forms";
import "react-multi-email/dist/style.css";
import { emailQuote, getQuoteEmailTemplate } from "./actions";

const EmailQuoteModal = ({ visible, currentQuote, closeModal }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getQuoteEmailTemplate}
      loadParams={currentQuote.id}
      loadTransform={(data) => ({
        ...data,
        toAddress: data.recipient,
      })}
      submitAction={emailQuote}
      submitParams={currentQuote.id}
      onSubmitted={closeModal}
      visible={visible}
      onCancel={closeModal}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal {...modalProps} header="Send Quote Email" submitLabel="Send">
            <EmailQuoteForm formProps={formProps} />
          </Modal>
        );
      }}
    />
  );
};

export default EmailQuoteModal;
