import React from "react";
import EnquiryBanner from "./EnquiryBanner";
import { getEnquiry } from "./actions";
import { ContentBuilder, useAuthClaims } from "@redriver/cinnamon";
import EnquiryCommentPanel from "./EnquiryCommentPanel";
import { TabbedContainer } from "components/containers";
import { Targets, Actions } from "constants/Permissions";
import EnquiryEmailPanel from "./EnquiryEmailPanel";
import { EnquiryTabKeys } from "constants/index";
import EnquiryDocumentsPanel from "./Documents/EnquiryDocumentsPanel";
import EnquiryCurrentQuotePanel from "./CurrentQuote/EnquiryCurrentQuotePanel";
import EnquiryEquipmentPanel from "./Equipment/EnquiryEquipmentPanel";
import EnquiryDescriptionPanel from "./EnquiryDescriptionPanel";
import EnquiryVisitsPanel from "./Visits/EnquiryVisitsPanel";
import { claimExists } from "components/auth/utils";

const EnquiryDetails = ({ enquiryId, tab }) => {
  const { permissions } = useAuthClaims();

  const tabs = [
    {
      key: EnquiryTabKeys.Overview,
      title: "Overview",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <section style={{ display: "flex", marginTop: "-1rem" }}>
          <div className="enquiry-panel">
            <EnquiryDescriptionPanel
              enquiryId={enquiryId}
              enquiry={enquiry}
              onRefresh={onRefresh}
            />
          </div>
          <div className="enquiry-panel">
            <EnquiryCommentPanel
              enquiryId={enquiryId}
              enquiry={enquiry}
              onRefresh={onRefresh}
            />
          </div>
        </section>
      ),
      target: Targets.Enquiry,
      action: Actions.View,
    },
    {
      key: EnquiryTabKeys.Email,
      title: "Email",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <EnquiryEmailPanel enquiryId={enquiry.id} onRefresh={onRefresh} />
      ),
      hidden: (enquiry) => !enquiry.hasEmail,
      target: Targets.Enquiry,
      action: Actions.View,
    },
    {
      key: EnquiryTabKeys.Documents,
      title: "Documents",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <EnquiryDocumentsPanel enquiryId={enquiry.id} onRefresh={onRefresh} />
      ),
      target: Targets.EnquiryDocument,
      action: Actions.View,
    },
    {
      key: EnquiryTabKeys.Quote,
      title: "Estimate/Quote",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <EnquiryCurrentQuotePanel enquiry={enquiry} onRefresh={onRefresh} />
      ),
    },
    {
      key: EnquiryTabKeys.Visits,
      title: "Visits",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <EnquiryVisitsPanel enquiry={enquiry} onRefresh={onRefresh} />
      ),
      target: Targets.Enquiry,
      action: Actions.View,
    },
    {
      key: EnquiryTabKeys.Equipment,
      title: "Equipment",
      renderContent: ({ onRefresh }, { enquiry }) => (
        <EnquiryEquipmentPanel enquiryId={enquiry.id} onRefresh={onRefresh} />
      ),
      target: Targets.Enquiry,
      action: Actions.View,
    },
  ];

  const filteredTabs = tabs.filter(
    (t) =>
      (!t.target && !t.action) || claimExists(permissions, t.target, t.action)
  );

  return (
    <ContentBuilder
      loadAction={getEnquiry}
      loadParams={{ enquiryId }}
      initialData={{ id: enquiryId }}
      renderContent={(enquiry, state, events) => {
        const refilteredTabs = filteredTabs.filter(
          (t) => !t.hidden || !t.hidden(enquiry)
        );
        const tabIndex = tab
          ? refilteredTabs.findIndex((i) => i.key == tab)
          : 0;

        return (
          <div>
            <EnquiryBanner enquiry={enquiry} onRefresh={events.onRefresh} />
            <TabbedContainer
              enquiry={{ ...enquiry, id: enquiryId }}
              onRefresh={events.onRefresh}
              tabs={refilteredTabs}
              initialTabIndex={tabIndex}
            />
          </div>
        );
      }}
    />
  );
};

export default EnquiryDetails;
