import React from "react";
import { CustomerTypes, EnquiryStatus } from "constants/enums";
import { ClearLabel } from "components/controls";
import { FieldNames } from "./EnquiriesFilters";
import { DateTime } from "luxon";

const FieldMappings = {
  [FieldNames.Status]: "Status",
  [FieldNames.CustomerTypes]: "Customer Type",
  [FieldNames.DepartmentDisplay]: "Department",
  [FieldNames.ContractManagerDisplay]: "Contract Manager",
  [FieldNames.CreatedDateFrom]: "Created Date From",
  [FieldNames.CreatedDateTo]: "Created Date To",
};

const FormatDisplay = (item) => {
  let value = item.value;
  switch (item.label) {
    case FieldNames.Status:
      value = item.value.map((x) => EnquiryStatus[x]).join(", ");
      break;
    case FieldNames.CustomerTypes:
      value = item.value.map((x) => CustomerTypes[x]).join(", ");
      break;
    case FieldNames.StartDateFrom:
    case FieldNames.StartDateTo:
      value = DateTime.fromISO(item.value).toFormat("DD");
      break;
  }

  return `${FieldMappings[item.label] || item.label}: ${value}`;
};

const EnquiriesFilterDisplay = ({ filters, resetFilters }) => {
  const items = filters
    ? Object.keys(filters)
        .filter(
          (f) =>
            f !== FieldNames.Search &&
            f !== FieldNames.ContractManager &&
            f !== FieldNames.Department
        )
        .map((k) => {
          return {
            label: k,
            value: filters[k],
          };
        })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items
        .filter((i) => i.value != null && i.value != "")
        .map((item) => (
          <div
            className="filter-display-item"
            key={item.label}
            title={FormatDisplay(item)}
          >
            {FormatDisplay(item)}
          </div>
        ))}
      <ClearLabel onClick={resetFilters} />
    </div>
  );
};

export default EnquiriesFilterDisplay;
