import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { addTemplateDocument } from "./actions";
import {
  DepartmentsTypeAhead,
  TemplateDocumentTypeDropdown,
} from "components/forms";
import { TemplateDocumentTypes } from "constants/enums";

const AddTemplateDocument = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addTemplateDocument}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Template Document">
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.FileUpload
              field="file"
              label="Upload a file"
              fileExtensionTypes={
                formProps.value.type == TemplateDocumentTypes.TermsAndConditions
                  ? ["docx", "doc"]
                  : null
              }
              fileTypesError="Incorrect file type uploaded"
              required
              fluid
            ></Form.FileUpload>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="File Name" field="fileName" fluid required />
            <Form.Trigger
              field="file"
              event="onChange"
              action="change"
              target="fileName"
              value={(formState) =>
                formState.fields.file ? formState.fields.file.name : ""
              }
            />
            <TemplateDocumentTypeDropdown
              field="type"
              required
              fluid
              label="Type"
              placeholder="Select document type"
            />
          </Form.Group>
          <Form.If
            condition={({ fields }) =>
              fields.type == TemplateDocumentTypes.TermsAndConditions
            }
          >
            <div style={{ marginBottom: "1rem" }}>
              <p className="cin form-error">
                Warning: File type of the Terms and Conditions document must be
                doc/docx
              </p>
            </div>
          </Form.If>
          <Form.Group widths="equal">
            <Form.Input label="Description" field="description" fluid />
          </Form.Group>
          <Form.Group widths="equal">
            <DepartmentsTypeAhead
              label="Department"
              field="departmentIds"
              fluid
              required
              multiple
              clearable
            />
          </Form.Group>
        </Form>
      </Modal.Add>
    )}
  />
);

export default AddTemplateDocument;
