import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_DEPARTMENTS";

const ActionTypes = {
  GetDepartments: `${NAMESPACE}/GET_DEPARTMENTS`,
  AddDepartment: `${NAMESPACE}/ADD_DEPARTMENT`,
  DeleteDepartment: `${NAMESPACE}/DELETE_DEPARTMENT`,
  UpdatedDepartment: `${NAMESPACE}/UPDATE_DEPARTMENT`,
  GetUsedDepartmentColours: `${NAMESPACE}/GET_USED_DEPARTMENT_COLOURS`,
};

export const getDepartments = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;

  return apiGet(ActionTypes.GetDepartments, `departments`, {
    pageNumber,
    pageSize,
    search,
  });
};

export const addDepartment = (data) =>
  apiPost(ActionTypes.AddDepartment, `departments`, data);

export const deleteDepartment = (departmentId) =>
  apiDelete(ActionTypes.DeleteDepartment, `departments/${departmentId}`);

export const updateDepartment = (data, departmentId) =>
  apiPut(ActionTypes.UpdatedDepartment, `departments/${departmentId}`, data);

export const getUsedDepartmentColours = () =>
  apiGet(ActionTypes.GetUsedDepartmentColours, `departments/used-colours`);
