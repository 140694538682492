import React from "react";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form, Format, useFormField } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import { ClearLabel } from "components/controls";
import { JobStatusesLookupDropdown } from "../JobStatusesLookup";
import { CustomerTypesLookupDropdown } from "features/Customers/CustomerTypesLookup";
import { UsersTypeAhead, DepartmentsDropdown } from "components/forms";
import { DateTime } from "luxon";

export const FieldNames = {
  Search: "search",
  Status: "status",
  CustomerTypes: "customerType",
  Department: "departmentId",
  DepartmentDisplay: "department",
  ContractManager: "ownerId",
  ContractManagerDisplay: "contractManager",
  StartDateFrom: "startDateFromUtc",
  StartDateTo: "startDateToUtc",
};

const JobsFilters = ({ onChangeFilters }) => {
  const [, setStartDateFromUtc] = useFormField("startDateFromUtc");
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="filter-side-panel"
    >
      <div className="ui header filters-title">
        <div className="title-text">Filters</div>
        <ClearLabel onClick={() => onChangeFilters({})} />
      </div>
      <Grid columns={2}>
        <Grid.Column>
          <JobStatusesLookupDropdown
            field={FieldNames.Status}
            fluid
            multiple
            label="Status"
            placeholder="Select status"
            showHidden
          />
        </Grid.Column>
        <Grid.Column>
          <CustomerTypesLookupDropdown
            field={FieldNames.CustomerTypes}
            fluid
            multiple
            label="Customer Types"
            placeholder="Select customer type"
          />
        </Grid.Column>
        <Grid.Column>
          <DepartmentsDropdown
            field={FieldNames.Department}
            fluid
            label="Department"
            placeholder="Select department"
            clearable
            textField={FieldNames.DepartmentDisplay}
          />
        </Grid.Column>
        <Grid.Column>
          <UsersTypeAhead
            label="Contract Manager"
            placeholder="Select contract manager"
            field={FieldNames.ContractManager}
            fluid
            clearable
            textField={FieldNames.ContractManagerDisplay}
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Date
            instant
            fluid
            field={FieldNames.StartDateFrom}
            label="Start Date From"
            placeholder="Select date"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Date
            instant
            fluid
            field={FieldNames.StartDateTo}
            label="Start Date To"
            placeholder="Select date"
          />
        </Grid.Column>
        <Grid.Column>
          <div className="ui.form field">
            <label>Quick Date Selector</label>
            <div
              onClick={() =>
                setStartDateFromUtc(
                  DateTime.local().startOf("day").toUTC().toISO()
                )
              }
              className="quick-date-select"
            >
              <Format.DateTime
                value={DateTime.local().toJSDate()}
                format="ddd D-MMM YYYY"
              />{" "}
              (Today)
            </div>
            <div
              onClick={() =>
                setStartDateFromUtc(
                  DateTime.local()
                    .startOf("day")
                    .plus({ days: 1 })
                    .toUTC()
                    .toISO()
                )
              }
              className="quick-date-select"
            >
              <Format.DateTime
                value={DateTime.local().plus({ days: 1 }).toJSDate()}
                format="ddd D-MMM YYYY"
              />{" "}
              (Tomorrow)
            </div>
            <div
              onClick={() =>
                setStartDateFromUtc(
                  DateTime.local()
                    .startOf("day")
                    .plus({ days: 2 })
                    .toUTC()
                    .toISO()
                )
              }
              className="quick-date-select"
            >
              <Format.DateTime
                value={DateTime.local().plus({ days: 2 }).toJSDate()}
                format="ddd D-MMM YYYY"
              />
            </div>
            <div
              onClick={() =>
                setStartDateFromUtc(
                  DateTime.local()
                    .startOf("day")
                    .plus({ days: 3 })
                    .toUTC()
                    .toISO()
                )
              }
              className="quick-date-select"
            >
              <Format.DateTime
                value={DateTime.local().plus({ days: 3 }).toJSDate()}
                format="ddd D-MMM YYYY"
              />
            </div>
            <div
              onClick={() =>
                setStartDateFromUtc(
                  DateTime.local()
                    .startOf("day")
                    .plus({ days: 4 })
                    .toUTC()
                    .toISO()
                )
              }
              className="quick-date-select"
            >
              <Format.DateTime
                value={DateTime.local().plus({ days: 4 }).toJSDate()}
                format="ddd D-MMM YYYY"
              />
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </SidePanel>
  );
};

export default JobsFilters;
