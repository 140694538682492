import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const ProductActions = ({ product: product, onEdit, onDelete }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.Product} action={Actions.Edit}>
      <Table.MenuItem onClick={() => onEdit(product)}>
        Edit Product
      </Table.MenuItem>
    </PermissionCheck>
    <PermissionCheck target={Targets.Product} action={Actions.Edit}>
      <Table.MenuItem onClick={() => onDelete(product)}>
        Delete Product
      </Table.MenuItem>
    </PermissionCheck>
  </Table.Menu>
);

export default ProductActions;
