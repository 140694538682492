import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { VatRateRoutes } from "constants/routes";
import VatRatesListPage from "./VatRatesListPage";

const VatRateRouter = () => (
  <Switch>
    <Route exact path={VatRateRoutes.VatRates} component={VatRatesListPage} />
    <Route render={() => <Redirect to={VatRateRoutes.VatRates} />} />
  </Switch>
);

export default VatRateRouter;
