import React from "react";
import { ListQuotes } from "features/Quotes";
import { KpsPageContent } from "components/containers";

const QuoteListPage = () => (
  <KpsPageContent>
    <ListQuotes />
  </KpsPageContent>
);

export default QuoteListPage;
