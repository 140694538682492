import React from "react";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import { ClearLabel } from "components/controls";
import { EnquiryStatusesLookupDropdown } from "../EnquiryStatusesLookup";
import { CustomerTypesLookupDropdown } from "features/Customers/CustomerTypesLookup";
import { UsersTypeAhead, DepartmentsTypeAhead } from "components/forms";

export const FieldNames = {
  Search: "search",
  Status: "status",
  CustomerTypes: "customerType",
  Department: "departmentId",
  DepartmentDisplay: "department",
  ContractManager: "ownerId",
  ContractManagerDisplay: "contractManager",
  CreatedDateFrom: "createdDateFromLocal",
  CreatedDateTo: "createdDateToLocal",
};

const EnquiriesFilters = ({ formProps, resetFilters }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="filter-side-panel"
    >
      <Form {...formProps}>
        <div className="ui header filters-title">
          <div className="title-text">Filters</div>
          <ClearLabel onClick={resetFilters} />
        </div>
        <Grid columns={2}>
          <Grid.Column>
            <EnquiryStatusesLookupDropdown
              field={FieldNames.Status}
              fluid
              multiple
              label="Status"
              placeholder="Select status"
            />
          </Grid.Column>
          <Grid.Column>
            <CustomerTypesLookupDropdown
              field={FieldNames.CustomerTypes}
              fluid
              multiple
              label="Customer Types"
              placeholder="Select customer type"
            />
          </Grid.Column>
          <Grid.Column>
            <DepartmentsTypeAhead
              field={FieldNames.Department}
              fluid
              label="Department"
              placeholder="Select department"
              clearable
              textField={FieldNames.DepartmentDisplay}
            />
          </Grid.Column>
          <Grid.Column>
            <UsersTypeAhead
              label="Contract Manager"
              placeholder="Select contract manager"
              field={FieldNames.ContractManager}
              fluid
              clearable
              textField={FieldNames.ContractManagerDisplay}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              local
              fluid
              field={FieldNames.CreatedDateFrom}
              label="Created Date From"
              placeholder="Select date"
              isClearable
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              local
              fluid
              field={FieldNames.CreatedDateTo}
              label="Created Date To"
              placeholder="Select date"
              isClearable
            />
          </Grid.Column>
        </Grid>
      </Form>
    </SidePanel>
  );
};

export default EnquiriesFilters;
