import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/RISK_ASSESSMENTS";

const ActionTypes = {
  GetOrCreateWorkWalletId: `${NAMESPACE}/GET_OR_CREATE_WORK_WALLET_ID`,
};

export const getOrCreateWorkWalletId = ({ contractId }) => {
  return apiPost(
    ActionTypes.GetOrCreateWorkWalletId,
    `contracts/${contractId}/risk-assessments`
  );
};
