import React from "react";
import { apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "JOBS/JOB_STATUS_LOOKUP";

const ActionTypes = {
  GetStatuses: `${NAMESPACE}/GET_STATUSES`,
};

const getJobStatuses = (showHidden) =>
  apiGet(ActionTypes.GetStatuses, `lookups/job-statuses`, { showHidden });

export const JobStatusesLookupDropdown = ({ showHidden = false, ...props }) => (
  <Form.Dropdown
    {...props}
    lookupAction={getJobStatuses}
    lookupParams={showHidden}
  />
);
