import React from "react";
import { Route } from "react-router-dom";
import {
  AuthenticatedSwitch,
  PrivateRoute,
  PublicRoute,
  NavigationLogger,
  ResponsiveScreen,
  AuthenticatedApp,
} from "@redriver/cinnamon";
import { EnvironmentBanner } from "components/banners";
import { AppRoutes, SystemRoutes } from "constants/routes";
import LoginPage from "./system/LoginPage";
import LogoutPage from "./system/LogoutPage";
import AzureSignInPage from "./system/AzureAdSignInPage";
import EdenApp from "./EdenApp";
import "styles/index.scss";

/**
 * Top-level app routes only
 * Define sub-path routes inside top-level page components
 */
const Shell = () => (
  <AuthenticatedApp>
    <NavigationLogger />
    <ResponsiveScreen />
    <EnvironmentBanner />
    <AuthenticatedSwitch>
      {/* system routes */}
      <Route exact path={SystemRoutes.Login} component={LoginPage} />
      <Route exact path={SystemRoutes.Logout} component={LogoutPage} />
      <Route
        exact
        path={SystemRoutes.AzureAdSignIn}
        component={AzureSignInPage}
      />

      {/* app routes */}
      <PublicRoute exact path={AppRoutes.Root} component={LoginPage} />
      <PrivateRoute component={EdenApp} redirect={SystemRoutes.Login} />
    </AuthenticatedSwitch>
  </AuthenticatedApp>
);

export default Shell;
