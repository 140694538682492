import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ProductCategoryRoutes } from "constants/routes";
import ProductCategoriesListPage from "./ProductCategoriesListPage";

const ProductCategoriesRouter = () => (
  <Switch>
    <Route
      exact
      path={ProductCategoryRoutes.ProductCategories}
      component={ProductCategoriesListPage}
    />
    <Route
      render={() => <Redirect to={ProductCategoryRoutes.ProductCategories} />}
    />
  </Switch>
);

export default ProductCategoriesRouter;
