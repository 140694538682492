import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/DETAILS";

export const ActionTypes = {
  GetContractBanner: `${NAMESPACE}/GET_CONTRACT_BANNER`,
  GetContractDetails: `${NAMESPACE}/GET_CONTRACT_DETAILS`,
  UpdateContract: `${NAMESPACE}/UPDATE_CONTRACT`,
  UpdateSiteAddress: `${NAMESPACE}/UPDATE_SITE_ADDRESS`,
  UpdateContractDetails: `${NAMESPACE}/EDIT_CONTRACT_DETAILS`,
  GetContractComments: `${NAMESPACE}/GET_CONTRACT_COMMENTS`,
  AddContractComment: `${NAMESPACE}/ADD_CONTRACT_COMMENT`,
};

export const getContractBanner = (contractId) =>
  apiGet(ActionTypes.GetContractBanner, `contracts/${contractId}/banner`);

export const getContractDetails = (contractId) =>
  apiGet(ActionTypes.GetContractDetails, `contracts/${contractId}`);

export const editContract = (contract) => {
  const { id, ...contractData } = contract;
  return apiPut(ActionTypes.UpdateContract, `contracts/${id}`, {
    ...contractData,
  });
};

export const editContractSiteAddress = (contractId, { selectedAddressId }) =>
  apiPut(
    ActionTypes.UpdateSiteAddress,
    `contracts/${contractId}/site-address`,
    {
      customerAddressId: selectedAddressId,
    }
  );

export const editContractDetails = (data, contractId) => {
  const {
    contractPricing,
    siteManager,
    quantitySurveyor,
    accountsContact,
    ...formData
  } = data;
  delete formData.pricings;

  let manager = null;
  if (siteManager && siteManager.name) {
    manager = siteManager;
  }

  let surveyor = null;
  if (quantitySurveyor && quantitySurveyor.name) {
    surveyor = quantitySurveyor;
  }

  let accounts = null;
  if (accountsContact && accountsContact.name) {
    accounts = accountsContact;
  }

  let pricings = [];
  if (contractPricing) {
    Object.entries(contractPricing).map((p) => {
      return p[1]?.value
        ? pricings.push({
            type: p[0],
            ...p[1],
          })
        : null;
    });
  }

  return apiPut(
    ActionTypes.UpdateContractDetails,
    `contracts/${contractId}/details`,
    {
      siteManager: manager,
      quantitySurveyor: surveyor,
      accountsContact: accounts,
      pricings,
      ...formData,
      totalValue: contractPricing?.totalValue,
    }
  );
};

export const getContractComments = ({ contractId }) =>
  apiGet(ActionTypes.GetContractComments, `contracts/${contractId}/comments`);

export const addContractComment = ({ comment }, { contractId }) => {
  return apiPost(
    ActionTypes.AddContractComment,
    `contracts/${contractId}/comments`,
    {
      text: comment,
    }
  );
};
