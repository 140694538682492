import React from "react";
import { Actions, Targets } from "constants/Permissions";
import { addEnquiryComment, getEnquiryComments } from "./actions";
import { CommentPanel } from "components/comments";
import { ContentBuilder } from "@redriver/cinnamon";

const EnquiryCommentPanel = ({ enquiryId }) => (
  <ContentBuilder
    loadAction={getEnquiryComments}
    loadParams={{ enquiryId }}
    renderContent={(data, state, events) => (
      <CommentPanel
        header="History &amp; Comments"
        addPermissionTarget={Targets.Enquiry}
        addPermissionAction={Actions.Edit}
        submitAction={addEnquiryComment}
        submitParams={{ enquiryId }}
        placeholder="Add comment"
        noItemsFoundText="No comments found."
        items={data}
        onSubmitted={events.onRefresh}
        loading={state.loading}
      />
    )}
  />
);

export default EnquiryCommentPanel;
