import { apiGet } from "@redriver/cinnamon";
import { DateTime } from "luxon";
import { saveFile } from "modules/helpers";

const NAMESPACE = "QUOTES/LIST";

const ActionTypes = {
  GetQuotes: `${NAMESPACE}/GET_QUOTES`,
  DownloadQuote: `${NAMESPACE}/DOWNLOAD_QUOTE`,
};

export const getQuotes = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { createdDateFromUtc, createdDateToUtc, ...otherFilters } = filters;

  const createdDateFrom = createdDateFromUtc
    ? DateTime.fromISO(createdDateFromUtc).toISODate()
    : null;

  const createdDateTo = createdDateToUtc
    ? DateTime.fromISO(createdDateToUtc).toISODate()
    : null;

  return apiGet(ActionTypes.GetQuotes, "quotes", {
    pageSize,
    pageNumber,
    createdDateFrom,
    createdDateTo,
    ...otherFilters,
  });
};

export const downloadQuote =
  ({ quoteId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiGet(
        ActionTypes.DownloadQuote,
        `quotes/${quoteId}/download`,
        {},
        { preventResponseHandling: true }
      )
    );
    if (result) {
      saveFile(result);
    }
  };
