import React, { useState } from "react";
import { Button, Image } from "semantic-ui-react";
import {
  ContentBuilder,
  ListBuilder,
  Format,
  useAuthClaims,
} from "@redriver/cinnamon";
import { getEnquiryDescription } from "../actions";
import {
  getEnquiryCurrentQuote,
  getEnquiryImages,
  getQuoteNotes,
} from "./actions";
import {
  ExpandableContentPanel,
  LightPanel,
  LightboxImage,
} from "components/containers";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import LeftArrow from "assets/icons/left-arrow-white.svg";
import RightArrow from "assets/icons/right-arrow-white.svg";
import QuoteItem, { QuoteTableRow } from "./QuoteItem";
import { claimExists } from "components/auth/utils";
import AddQuoteItemModal from "./AddQuoteItemModal";
import EditQuoteItemModal from "./EditQuoteItemModal";
import UpdateQuoteNotesModal from "./UpdateQuoteNotesModal";

const EnquiryEditCurrentQuotePanel = ({ enquiry, setEditing, onRefresh }) => {
  const { permissions } = useAuthClaims();
  const [editItem, setEditItem] = useState(null);
  const [editItemPanelOpen, setEditItemPanelOpen] = useState(false);

  const onItemEdit = (item) => {
    setEditItem(item);
    setEditItemPanelOpen(true);
  };

  const onCloseEdit = () => {
    setEditItem(null);
    setEditItemPanelOpen(false);
  };

  const canViewSidePanel =
    claimExists(permissions, Targets.Enquiry, Actions.View) ||
    claimExists(permissions, Targets.EnquiryDocument, Actions.View);

  const [notesUpdatedUtc, setNotesUpdatedUtc] = useState(null);

  return (
    <div className="enquiry-current-quote-panel">
      {canViewSidePanel && (
        <div className="side-bar">
          <PermissionCheck target={Targets.Enquiry} action={Actions.View}>
            <ExpandableContentPanel
              header="Enquiry Description"
              expandedByDefault={true}
            >
              <ContentBuilder
                loadAction={getEnquiryDescription}
                loadParams={{ enquiryId: enquiry.id }}
                initialData={[]}
                renderContent={(data) => {
                  return <pre>{data.description}</pre>;
                }}
              />
            </ExpandableContentPanel>
          </PermissionCheck>
          <PermissionCheck
            target={Targets.EnquiryDocument}
            action={Actions.View}
          >
            <ListBuilder
              loadAction={getEnquiryImages}
              loadParams={{ enquiryId: enquiry.id }}
              dataTransform={(data) => data.results}
              totalTransform={(data) => data.totalResults}
              initialPagination={{ pageSize: 1, pageNumber: 1 }}
              renderList={(data, state, events) => {
                const totalPages =
                  state.total > 0
                    ? Math.ceil(state.total / state.pagination.pageSize)
                    : 0;
                return (
                  <ExpandableContentPanel header={`Images (${totalPages})`}>
                    <div className="enquiry-images">
                      {data.length == 0 ? (
                        <p>No images have been added</p>
                      ) : (
                        <React.Fragment>
                          <div className="enquiry-images-button">
                            <Button
                              style={{
                                visibility:
                                  state.pagination.pageNumber > 1
                                    ? "visible"
                                    : "hidden",
                              }}
                              onClick={() =>
                                events.onChangePagination({
                                  pageSize: 1,
                                  pageNumber: state.pagination.pageNumber - 1,
                                })
                              }
                            >
                              <Image
                                src={LeftArrow}
                                alt="Previous Image"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                          <div className="enquiry-images-img">
                            {data.map((img) => (
                              <LightboxImage src={img.url} key={img.blobId} />
                            ))}
                          </div>
                          <div className="enquiry-images-button">
                            <Button
                              style={{
                                visibility:
                                  state.pagination.pageNumber < totalPages
                                    ? "visible"
                                    : "hidden",
                              }}
                              onClick={() =>
                                events.onChangePagination({
                                  pageSize: 1,
                                  pageNumber: state.pagination.pageNumber + 1,
                                })
                              }
                            >
                              <Image
                                src={RightArrow}
                                alt="Next Image"
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </ExpandableContentPanel>
                );
              }}
            />
          </PermissionCheck>
          <PermissionCheck target={Targets.Quote} action={Actions.View}>
            <ExpandableContentPanel
              header="Quote Notes"
              expandedByDefault={true}
            >
              {enquiry.hasQuote && (
                <ContentBuilder
                  loadAction={getQuoteNotes}
                  loadParams={{ enquiryId: enquiry.id, notesUpdatedUtc }}
                  initialData={{}}
                  renderContent={(data) => {
                    return <pre>{data.notes}</pre>;
                  }}
                />
              )}
            </ExpandableContentPanel>
          </PermissionCheck>
        </div>
      )}
      <div className="scope-of-work">
        <ContentBuilder
          loadAction={getEnquiryCurrentQuote}
          loadParams={{ enquiryId: enquiry.id }}
          renderContent={(data, state, events) => {
            return (
              <React.Fragment>
                {editItemPanelOpen && (
                  <EditQuoteItemModal
                    enquiryId={enquiry.id}
                    visible={editItemPanelOpen}
                    initialData={editItem}
                    showModifiers={enquiry.showModifiers}
                    onCancel={onCloseEdit}
                    onSubmitted={() => {
                      events.onRefresh();
                      onRefresh();
                      onCloseEdit();
                    }}
                  />
                )}
                <LightPanel
                  headerText="Scope of Work"
                  rightHeaderContent={
                    <React.Fragment>
                      {enquiry.hasQuote && (
                        <UpdateQuoteNotesModal
                          enquiryId={enquiry.id}
                          quoteNotes={data.notes}
                          onSubmitted={() => {
                            setNotesUpdatedUtc(Date.now());
                            events.onRefresh();
                          }}
                        />
                      )}
                      <AddQuoteItemModal
                        enquiryId={enquiry.id}
                        departmentId={enquiry.departmentId}
                        departmentName={enquiry.department}
                        showModifiers={enquiry.showModifiers}
                        onSubmitted={() => {
                          events.onRefresh();
                          onRefresh();
                        }}
                      />
                      <Button compact onClick={() => setEditing(false)}>
                        Finish Editing
                      </Button>
                    </React.Fragment>
                  }
                >
                  <QuoteTableRow
                    itemNumber="Item"
                    name="Product &amp; Details"
                    quantity="Quantity"
                    price="Price"
                    vat="VAT"
                    cost="Cost"
                    total="Total"
                    className="table-header"
                  />

                  {data.items &&
                    data.items.map((item, i) => {
                      return (
                        <QuoteItem
                          key={i}
                          item={item}
                          itemNumber={i + 1}
                          onEdit={onItemEdit}
                          onDeleted={() => {
                            events.onRefresh();
                            onRefresh();
                          }}
                          enquiryId={enquiry.id}
                        />
                      );
                    })}

                  <QuoteTableRow
                    quantity="Total"
                    price={
                      data.items != null ? (
                        <Format.Number
                          value={data.items.reduce((acc, item) => {
                            acc += item.unitPrice * item.quantity;
                            return acc;
                          }, 0)}
                          format="GBP"
                        />
                      ) : (
                        0
                      )
                    }
                    vat={
                      data.items != null ? (
                        <Format.Number
                          value={data.items.reduce((acc, item) => {
                            acc += item.vat * item.quantity;
                            return acc;
                          }, 0)}
                          format="GBP"
                        />
                      ) : (
                        0
                      )
                    }
                    cost={
                      data.items != null ? (
                        <Format.Number
                          value={data.items.reduce((acc, item) => {
                            acc += item.unitCost * item.quantity;
                            return acc;
                          }, 0)}
                          format="GBP"
                        />
                      ) : (
                        0
                      )
                    }
                    total={
                      data.items != null ? (
                        <Format.Number
                          value={data.items.reduce((acc, item) => {
                            acc +=
                              ((item.unitPrice ?? 0) + (item.vat ?? 0)) *
                              item.quantity;
                            return acc;
                          }, 0)}
                          format="GBP"
                        />
                      ) : (
                        0
                      )
                    }
                    className="table-footer"
                  />
                </LightPanel>
              </React.Fragment>
            );
          }}
        />
      </div>
    </div>
  );
};
export default EnquiryEditCurrentQuotePanel;
