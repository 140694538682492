import React from "react";
import { ActionBar } from "@redriver/cinnamon";
import { ContentPanel } from "components/containers";
import classNames from "classnames";

const LightPanel = ({
  headerText,
  middleHeaderContent,
  rightHeaderContent,
  children,
  className,
}) => (
  <ContentPanel className={classNames("light-panel", className)}>
    <header>
      <ActionBar>
        <ActionBar.Item>{headerText}</ActionBar.Item>
        {middleHeaderContent}
        {rightHeaderContent && (
          <ActionBar.Item fluid align="right">
            {rightHeaderContent}
          </ActionBar.Item>
        )}
      </ActionBar>
    </header>
    <div className="light-panel-content">{children}</div>
  </ContentPanel>
);

export default LightPanel;
