import React from "react";
import classNames from "classnames";

const SplitPage = ({ left, right, className }) => (
  <section className={classNames("split-page", className)}>
    <div className="left-content">{left}</div>
    <div className="right-content">{right}</div>
  </section>
);

export default SplitPage;
