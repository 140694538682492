import React, { useState } from "react";
import { ModalBuilder, Modal, Table, Format } from "@redriver/cinnamon";
import { completeItems } from "./actions";
import { useDispatch } from "react-redux";

const CompleteJobSheet = ({ job, visible, onClose, onSubmitted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState({
    selected: [],
    deselected: [],
  });
  const dispatch = useDispatch();

  return (
    <ModalBuilder
      visible={visible}
      onCancel={onClose}
      onSubmit={() => {
        const selectedIds = job.items
          .filter(
            (x) =>
              (selectedItems.selected.includes(x.id) ||
                selectedItems.allSelected) &&
              !selectedItems.deselected.includes(x.id) &&
              !x.jobItemId
          )
          .map((x) => x.id);
        if (selectedIds && selectedIds.length > 0) {
          setSubmitting(true);
          dispatch(completeItems(job.jobId, selectedIds))
            .then(() => {
              if (onSubmitted) {
                onSubmitted();
              }
              setSelectedItems({
                selected: [],
                deselected: [],
              });
              onClose();
            })
            .finally(() => setSubmitting(false));
        } else {
          setSelectedItems({
            selected: [],
            deselected: [],
          });
          onClose();
        }
      }}
      onSubmitted={onSubmitted}
      renderModal={(modalProps) => {
        return (
          <Modal.Edit
            {...modalProps}
            submitting={submitting}
            slowSubmitting={submitting}
            header="Complete Job Sheet"
            submitLabel="Complete"
            className="complete-job-sheet"
          >
            <Table data={job.items} dataKey="id">
              {(item) => (
                <React.Fragment>
                  <Table.SelectableColumn
                    className={item?.isComplete ? "item-complete" : null}
                    canSelect={(item) => !item.isComplete}
                    value={selectedItems}
                    onChange={setSelectedItems}
                    totalItems={job.items.length}
                  />
                  <Table.Column field="productName" title="Product" />
                  <Table.Column field="description" title="Description" />
                  <Table.Column field="quantity" title="Quantity" />
                  <Table.Column
                    field="netPrice"
                    title="Net Price"
                    component={NetPrice}
                  />
                </React.Fragment>
              )}
            </Table>
          </Modal.Edit>
        );
      }}
    />
  );
};

const NetPrice = ({ item }) => {
  return <Format.Number value={item.netPrice} format="GBP" />;
};

export default CompleteJobSheet;
