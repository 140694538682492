import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { updateDepartment } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import DepartmentForm from "./DepartmentForm";

const EditDepartment = ({
  as: As = Button,
  onEdited,
  department,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={department}
    submitAction={updateDepartment}
    onSubmitted={onEdited}
    submitParams={department.id}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.Department} action={Actions.Edit}>
        <As onClick={showModal} {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Department">
        <DepartmentForm formProps={formProps} />
      </Modal.Edit>
    )}
  />
);

export default EditDepartment;
