import React from "react";
import { Grid } from "semantic-ui-react";
import { SidePanel, Form } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import { ClearLabel } from "components/controls";
import { CustomerTypesLookupDropdown } from "features/Customers/CustomerTypesLookup";
import { DepartmentsTypeAhead, UsersTypeAhead } from "components/forms";

export const FieldNames = {
  Search: "search",
  CustomerTypes: "customerType",
  Department: "departmentId",
  DepartmentDisplay: "department",
  InvoicedDateFrom: "invoicedDateFromUtc",
  InvoicedDateTo: "invoicedDateToUtc",
  ExportedDateFrom: "exportedDateFromUtc",
  ExportedDateTo: "exportedDateToUtc",
  ContractManager: "ownerId",
  ContractManagerDisplay: "contractManager",
  UnexportedOnly: "unexportedOnly",
};

const InvoiceFilters = ({ formProps, resetFilters }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="filter-side-panel"
    >
      <Form {...formProps}>
        <div className="ui header filters-title">
          <div className="title-text">Filters</div>
          <ClearLabel onClick={resetFilters} />
        </div>
        <Grid columns={2}>
          <Grid.Column>
            <CustomerTypesLookupDropdown
              field={FieldNames.CustomerTypes}
              fluid
              multiple
              label="Customer Types"
              placeholder="Select customer type"
            />
          </Grid.Column>
          <Grid.Column>
            <DepartmentsTypeAhead
              field={FieldNames.Department}
              fluid
              label="Department"
              placeholder="Select department"
              clearable
              textField={FieldNames.DepartmentDisplay}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              instant
              fluid
              field={FieldNames.InvoicedDateFrom}
              label="Invoiced Date From"
              placeholder="Select date"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              instant
              fluid
              field={FieldNames.InvoicedDateTo}
              label="Invoiced Date To"
              placeholder="Select date"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              instant
              fluid
              field={FieldNames.ExportedDateFrom}
              label="Exported Date From"
              placeholder="Select date"
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Date
              instant
              fluid
              field={FieldNames.ExportedDateTo}
              label="Exported Date To"
              placeholder="Select date"
            />
          </Grid.Column>
          <Grid.Column>
            <UsersTypeAhead
              label="Contract Manager"
              placeholder="Select contract manager"
              field={FieldNames.ContractManager}
              fluid
              clearable
              textField={FieldNames.ContractManagerDisplay}
            />
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginBottom: "0 !important", marginTop: "2.2rem" }}>
              <Form.Checkbox
                label="Show Unexported Invoices"
                field={FieldNames.UnexportedOnly}
                fluid
              />
            </div>
          </Grid.Column>
        </Grid>
      </Form>
    </SidePanel>
  );
};

export default InvoiceFilters;
