import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ContractRoutes } from "constants/routes";
import ContractListPage from "./ContractListPage";
import ContractDetailsPage from "./ContractDetailsPage";

const ContractRouter = () => (
  <Switch>
    <Route exact path={ContractRoutes.Contracts} component={ContractListPage} />
    <Route path={ContractRoutes.Contract()} component={ContractDetailsPage} />
    <Route render={() => <Redirect to={ContractRoutes.Contracts} />} />
  </Switch>
);

export default ContractRouter;
