import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const EditDocumentModal = ({
  as: As = Button,
  document,
  submitAction,
  submitParams,
  onUpdated,
  documentTypeDropdown,
  extraContent,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      initialData={document}
      submitAction={submitAction}
      submitParams={{ ...submitParams, documentId: document.id }}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Document">
          <Form {...formProps}>
            <Form.Group widths="equal">
              {documentTypeDropdown}
              <Form.Input field="description" label="Description" fluid />
            </Form.Group>
            {extraContent}
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditDocumentModal;
