import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/JOBS";

const ActionTypes = {
  GetContractJobs: `${NAMESPACE}/GET_CONTRACT_JOBS`,
  CreateContractJob: `${NAMESPACE}/CREATE_CONTRACT_JOB`,
  DeleteContractJob: `${NAMESPACE}/DELETE_CONTRACT_JOB`,
  CompleteContractJobs: `${NAMESPACE}/COMPLETE_CONTRACT_JOBS`,
  UncompleteContractJobs: `${NAMESPACE}/UNCOMPLETE_CONTRACT_JOBS`,
  InvoiceContractJobs: `${NAMESPACE}/INVOICE_CONTRACT_JOBS`,
  GetContractInvoiceDetails: `${NAMESPACE}/GET_CONTRACT_INVOICE_DETAILS`,
  CreateContractInvoice: `${NAMESPACE}/CREATE_CONTRACT_INVOICE`,
  ConvertContractJobs: `${NAMESPACE}/CONVERT_CONTRACT_JOBS`,
};

export const getContractJobs = ({ filters, pagination }, contractId) =>
  apiGet(ActionTypes.GetContractJobs, `contracts/${contractId}/jobs`, {
    ...pagination,
    ...filters,
  });

export const createContractJob = (contractId) =>
  apiPost(ActionTypes.CreateContractJob, `contracts/${contractId}/jobs`);

export const deleteContractJob = ({ contractId, jobId }) =>
  apiDelete(
    ActionTypes.DeleteContractJob,
    `contracts/${contractId}/jobs/${jobId}`
  );

export const completeContractJobs = ({ contractId, selectedIds }) =>
  apiPut(
    ActionTypes.CompleteContractJobs,
    `contracts/${contractId}/jobs/complete`,
    {
      jobIds: selectedIds,
    }
  );

export const uncompleteContractJobs = ({ contractId, selectedIds }) =>
  apiPut(
    ActionTypes.UncompleteContractJobs,
    `contracts/${contractId}/jobs/uncomplete`,
    {
      jobIds: selectedIds,
    }
  );

export const getContractInvoiceDetails = ({ contractId, jobIds, itemIds }) =>
  apiGet(
    ActionTypes.GetContractInvoiceDetails,
    `contracts/${contractId}/invoices`,
    {
      jobIds,
      itemIds
    }
  );

export const createContractInvoice = (data, contractId) =>
  apiPost(
    ActionTypes.CreateContractInvoice,
    `contracts/${contractId}/invoices`,
    {
      ...data,
    }
  );

export const convertContractJobs = ({ jobIds, contractId }) => {
  return apiPut(
    ActionTypes.ConvertContractJobs,
    `contracts/${contractId}/jobs/convert`,
    {
      jobIds,
    }
  );
};
