import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SystemSettingsRoutes } from "constants/routes";
import SystemPage from "./SystemPage";

const SystemRouter = () => (
  <Switch>
    <Route exact path={SystemSettingsRoutes.System} component={SystemPage} />
    <Route render={() => <Redirect to={SystemSettingsRoutes.System} />} />
  </Switch>
);

export default SystemRouter;
