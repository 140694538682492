import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteVatRate } from "./actions";

const DeleteVatRate = ({ rate, onDeleted, onCancel }) => (
  <ModalBuilder
    submitAction={deleteVatRate}
    submitParams={rate.id}
    onSubmitted={onDeleted}
    onCancel={onCancel}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete VAT Rate">
        Are you sure you wish to delete <b>{rate.name}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteVatRate;
