import { useCallback, useEffect, useRef, useState } from "react";

const createContextFromEvent = (e) => {
  const left = e.clientX;
  const top = e.clientY;
  const right = left + 1;
  const bottom = top + 1;

  return {
    getBoundingClientRect: () => ({
      left,
      top,
      right,
      bottom,

      height: 0,
      width: 0,
    }),
    key: `${left},${top},${right},${bottom}`,
  };
};

export const useSegmentContextMenu = (id, containerRef) => {
  const [open, setOpen] = useState(false);
  const [clientBounds, setClientBounds] = useState({
    getBoundingClientRect: () => ({}),
  });

  const contextRef = useRef();
  useEffect(() => {
    contextRef.current = clientBounds;
  }, [clientBounds]);

  const handler = useCallback(
    (e) => {
      if (e.srcElement.id !== id && e.srcElement.offsetParent?.id !== id) {
        setOpen(false);
      }
    },
    [id]
  );

  const closeOnEventHandler = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener("contextmenu", handler);
    window.addEventListener("customContextMenuOpened", closeOnEventHandler);
    return () => {
      window.removeEventListener("contextmenu", handler);
      window.removeEventListener(
        "customContextMenuOpened",
        closeOnEventHandler
      );
    };
  }, [handler, closeOnEventHandler]);

  useEffect(() => {
    const ref = containerRef?.current;
    ref?.addEventListener("scroll", closeOnEventHandler);
    return () => {
      ref?.removeEventListener("scroll", closeOnEventHandler);
    };
  }, [containerRef, closeOnEventHandler]);

  const onContextMenu = (e) => {
    e.preventDefault();

    const event = new Event("customContextMenuOpened");
    window.dispatchEvent(event);

    setOpen(true);
    setClientBounds(createContextFromEvent(e));
  };

  const onClose = () => {
    setOpen(false);
  };

  return [onContextMenu, open, onClose, contextRef];
};
