import { ListBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button } from "semantic-ui-react";
import { getSwimlaneContracts } from "./actions";
import ContractCard from "./ContractCard";

const ContractLane = ({ status, filters, updated, flexBasis = "100%" }) => (
  <div style={{ flexBasis: flexBasis }}>
    <ListBuilder
      loadAction={getSwimlaneContracts}
      loadParams={{ status, updated, ...filters }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 6, pageNumber: 1 }}
      renderList={(data, state, events) => (
        <div>
          {data && data.length ? (
            <React.Fragment>
              {data.map((x) => (
                <ContractCard key={x.id} item={x} useJobTypeColour />
              ))}
              {state.hasMore && (
                <div className="load-more">
                  <Button
                    loading={state.loadingMore}
                    onClick={events.onLoadMore}
                    size="tiny"
                  >
                    Load More
                  </Button>
                </div>
              )}
            </React.Fragment>
          ) : (
            <p>No contracts</p>
          )}
        </div>
      )}
    />
  </div>
);

export default ContractLane;
