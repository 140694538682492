import React, { useContext, useEffect } from "react";
import { Segment } from "semantic-ui-react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Label,
} from "recharts";
import { ContentBuilder } from "@redriver/cinnamon";
import { DashboardContext } from "./DashboardContext";

const BarWidget = ({
  id,
  title,
  action,
  filterParams = {},
  transform = (x) => x,
  headerContent,
  valueField = "total",
  categoryField = "name",
  label = "Total",
  colours = [],
  style,
  onBarClick,
  highlightValue,
  highlightFieldName,
  renderCustomTooltip,
}) => {
  const renderDefaultTooltipContent = (item) => {
    return (
      <React.Fragment>
        <p>{item[categoryField]}</p>
        <p>{item[valueField]}</p>
      </React.Fragment>
    );
  };

  return (
    <Segment className="graph-widget bar-widget" style={style}>
      <header>
        <h4 className="title">{title}</h4>
        {headerContent}
      </header>
      <footer>
        <ContentBuilder
          loadAction={action}
          loadTransform={transform}
          loadParams={filterParams}
          renderContent={(data, state, events) => {
            const hasData =
              !Array.isArray(data) ||
              (data.length > 0 && data.some((x) => x[valueField] > 0));
            if (!hasData) {
              return <span className="no-data">No data found</span>;
            }

            return (
              <BarChartContent
                id={id}
                onRefresh={events.onRefresh}
                categoryField={categoryField}
                colours={colours}
                data={data}
                highlightFieldName={highlightFieldName}
                highlightValue={highlightValue}
                label={label}
                onBarClick={onBarClick}
                renderCustomTooltip={renderCustomTooltip}
                renderDefaultTooltipContent={renderDefaultTooltipContent}
                valueField={valueField}
              />
            );
          }}
        />
      </footer>
    </Segment>
  );
};

const BarChartContent = ({
  id,
  data,
  categoryField,
  label,
  renderCustomTooltip,
  renderDefaultTooltipContent,
  valueField,
  colours,
  highlightValue,
  highlightFieldName,
  onBarClick,
  onRefresh,
}) => {
  const { registerReload, unregisterReload } = useContext(DashboardContext);

  useEffect(() => {
    if (id) {
      registerReload(id, onRefresh);
    }
    return () => unregisterReload(id);
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart data={Array.isArray(data) ? data : []}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={categoryField} type="category" />
        <YAxis>
          <Label value={label} angle={-90} position="center" dx={-20} />
        </YAxis>
        <Tooltip
          content={({ payload }) => {
            const item = payload?.length > 0 ? payload[0].payload : null;
            if (!item) {
              return null;
            }
            return (
              <div
                style={{
                  backgroundColor: "white",
                  border: "solid 1px black",
                  padding: "0.5em",
                }}
              >
                {renderCustomTooltip
                  ? renderCustomTooltip(item)
                  : renderDefaultTooltipContent(item)}
              </div>
            );
          }}
        />
        <Bar dataKey={valueField} maxBarSize={100}>
          {(Array.isArray(data) ? data : []).map((x, i) => (
            <Cell
              key={i + x.name}
              fill={colours[i % colours.length]}
              opacity={
                highlightValue &&
                highlightFieldName &&
                x[highlightFieldName] != highlightValue
                  ? 0.25
                  : 1
              }
              onClick={() => {
                if (onBarClick) {
                  onBarClick(x);
                }
              }}
              style={{ cursor: onBarClick ? "pointer" : "auto" }}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarWidget;
