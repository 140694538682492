import React, { useState } from "react";
import { Segment, Image } from "semantic-ui-react";
import SingleArrow from "assets/icons/sort-single-arrow.svg";
import classNames from "classnames";
import { Content } from "components/cinnamonOverrides";
const ExpandableContentPanel = ({
  children,
  className,
  header,
  expandedByDefault = false,
}) => {
  const css = classNames("expandable-content-panel", className);
  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  return (
    <Segment className={css}>
      <header onClick={() => setIsExpanded(!isExpanded)}>
        <span>{header}</span>
        <Image
          src={SingleArrow}
          style={{
            display: "inline-block",
            marginLeft: "0.5em",
            transform: `rotate(${isExpanded ? 0 : 180}deg)`,
          }}
        />
      </header>
      <Content marginTop={"medium"} visible={isExpanded}>
        {children}
      </Content>
    </Segment>
  );
};

export default ExpandableContentPanel;
