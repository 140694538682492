import { Form, SidePanel } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import { ClearLabel } from "components/controls";
import { DepartmentsDropdown } from "components/forms";
import { CustomerTypesLookupDropdown } from "features/Customers/CustomerTypesLookup";
import { Grid } from "semantic-ui-react";
import { EnquiryStatusesLookupDropdown } from "../../Enquiries/EnquiryStatusesLookup";
import React from "react";

export const FieldNames = {
  Search: "search",
  Status: "status",
  CustomerTypes: "customerType",
  Department: "departmentId",
  DepartmentDisplay: "department",
  CreatedDateFrom: "createdDateFromUtc",
  CreatedDateTo: "createdDateToUtc",
};

const QuotesFilters = ({ onChangeFilters }) => {
  return (
    <SidePanel
      closeIcon
      trigger={<FilterButton />}
      className="filter-side-panel"
    >
      <div className="ui header filters-title">
        <div className="title-text">Filters</div>
        <ClearLabel onClick={() => onChangeFilters({})} />
      </div>
      <Grid columns={2}>
        <Grid.Column>
          <EnquiryStatusesLookupDropdown
            field={FieldNames.Status}
            fluid
            multiple
            label="Status"
            placeholder="Select status"
          />
        </Grid.Column>
        <Grid.Column>
          <CustomerTypesLookupDropdown
            field={FieldNames.CustomerTypes}
            fluid
            multiple
            label="Customer Types"
            placeholder="Select customer type"
          />
        </Grid.Column>
        <Grid.Column>
          <DepartmentsDropdown
            field={FieldNames.Department}
            fluid
            label="Department"
            placeholder="Select department"
            clearable
            textField={FieldNames.DepartmentDisplay}
          />
        </Grid.Column>
        <Grid.Column></Grid.Column>
        <Grid.Column>
          <Form.Date
            instant
            fluid
            field={FieldNames.CreatedDateFrom}
            label="Created Date From"
            placeholder="Select date"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Date
            instant
            fluid
            field={FieldNames.CreatedDateTo}
            label="Created Date To"
            placeholder="Select date"
          />
        </Grid.Column>
      </Grid>
    </SidePanel>
  );
};

export default QuotesFilters;
