import React from "react";
import { TriggerBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { uncompleteJobItems } from "./actions";

const UncompleteSelectedItems = ({
  as: As = Menu.Item,
  jobId,
  selectedIds,
  onSubmitted,
  ...props
}) => (
  <TriggerBuilder
    submitAction={uncompleteJobItems}
    submitParams={{ jobId, selectedIds }}
    onSubmitted={onSubmitted}
    renderTrigger={(fireTrigger) => <As onClick={fireTrigger} {...props} />}
  />
);

export default UncompleteSelectedItems;
