import { TabMenu } from "@redriver/cinnamon";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

const TabbedContainer = ({
  tabs,
  className,
  minTabWidth,
  prefixContent,
  onRefresh,
  initialTabIndex=0,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState(tabs[initialTabIndex].key);
  const css = classNames("tabbed-container", className);

  return (
    <section className={css}>
      {prefixContent}
      <TabMenu minTabWidth={minTabWidth} showNav={false}>
        {tabs.map((x) => (
          <TabMenu.Item
            key={x.key}
            active={activeTab == x.key}
            onClick={() => setActiveTab(x.key)}
          >
            {x.title}
          </TabMenu.Item>
        ))}
      </TabMenu>
      {tabs.map((t) => {
        const isActive = t.key == activeTab;

        if (!t.keepAlive && !isActive) return null;

        const tabCss = classNames(
          "tabbed-content",
          !!t.keepAlive && !isActive && "hidden"
        );

        return (
          <div key={t.key} className={tabCss}>
            {t.renderContent({ onRefresh }, { ...props })}
          </div>
        );
      })}
    </section>
  );
};

TabbedContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      renderContent: PropTypes.func.isRequired,
    })
  ),
};

export default TabbedContainer;
