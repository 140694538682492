import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { AddressTypesDropdown, PostcodeSearch } from "components/forms";
import { AddressTypes } from "constants/enums";

const CustomerAddressForm = ({ initWithManualMode = false }) => {
  const [manual, setManual] = useState(!!initWithManualMode);
  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <PostcodeSearch onSelected={() => setManual(true)} />
          {!manual && (
            <a
              className="action-link"
              onClick={() => setManual(true)}
              style={{ marginTop: "1rem" }}
            >
              Or, Enter address manually
            </a>
          )}
        </Grid.Column>
      </Grid.Row>

      {manual && (
        <React.Fragment>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input
                field="addressLine1"
                label="Address Line 1"
                fluid
                required
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input field="addressLine2" label="Address Line 2" fluid />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input field="addressLine3" label="Address Line 3" fluid />
            </Grid.Column>
            <Grid.Column>
              <Form.Input field="townOrCity" label="Town Or City" fluid />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Input field="countyOrState" label="County" fluid />
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input field="postcode" label="Postcode" fluid />
                  </Grid.Column>
                  <Grid.Column>
                    <AddressTypesDropdown
                      field="type"
                      label="Type"
                      fluid
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Form.If condition={({ fields }) => fields.type == AddressTypes.Site}>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Input field="contactName" label="Contact Name" fluid />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  field="contactDetail"
                  label="Contact Detail"
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </Form.If>
        </React.Fragment>
      )}
      <Grid.Row columns={2}>
        <Grid.Column>
          <div className="primaryAddressCheckbox">
            <Form.Checkbox
              field="isPrimaryAddress"
              label="Set as Primary Address"
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CustomerAddressForm;
