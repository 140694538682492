import React from "react";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { getRoles } from "./actions";
import FilterRoles from "./FilterRoles";
import AddRole from "./AddRole";
import RoleActions from "./RoleActions";
import { TablePanel, HeaderBar } from "components/containers";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const ListRoles = () => (
  <ListBuilder
    withTable
    loadAction={getRoles}
    dataTransform={(data) => data.results}
    totalTransform={(data) => data.totalResults}
    renderList={(tableProps, state, events) => (
      <React.Fragment>
        <TablePanel
          pageSizePosition="footer"
          pageSizeOptions={[10, 25, 50, 100]}
          pagination={state.pagination}
          onPaginationChange={events.onChangePagination}
          totalResults={state.total}
          customHeader={
            <HeaderBar
              name="Roles"
              rightContent={
                <PermissionCheck target={Targets.Role} action={Actions.Create}>
                  <AddRole content="Add Role" onAdded={events.onRefresh} />
                </PermissionCheck>
              }
            >
              <FilterRoles
                value={state.filters}
                onChange={events.onChangeFilters}
              />
            </HeaderBar>
          }
          {...tableProps}
          dataKey="id"
          emptyMessage="No roles found"
          selectable
        >
          <Table.Column field="name" title="Name" width="25%" />
          <Table.Column field="description" title="Description" width="45%" />
          <PermissionCheck
            target={Targets.Role}
            action={[Actions.Edit, Actions.Delete]}
            any
          >
            <Table.Column
              hoverable
              width="10%"
              align="right"
              render={(role) => (
                <RoleActions role={role} onRefresh={events.onRefresh} />
              )}
            />
          </PermissionCheck>
        </TablePanel>
      </React.Fragment>
    )}
  />
);

export default ListRoles;
