import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteProduct } from "./actions";

const DeleteProduct = ({ product, onDeleted, onCancel }) => (
  <ModalBuilder
    submitAction={deleteProduct}
    submitParams={product.id}
    onSubmitted={onDeleted}
    onCancel={onCancel}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete Product Category">
        Are you sure you wish to delete <b>{product.name}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteProduct;
