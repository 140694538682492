import React from "react";
import classNames from "classnames";
import { Popup } from "semantic-ui-react";
import { Format } from "@redriver/cinnamon";

const ResourceJobDetails = ({ job, className, ...otherProps }) => {
  const items = job.items || [];
  const assigned = job.assigned || [];

  return (
    <Popup
      flowing
      hoverable
      position="bottom center"
      className="job-popup"
      popperModifiers={[
        {
          options: {
            preventOverflow: {
              boundariesElement: "offsetParent",
            },
          },
        },
      ]}
      on="hover"
      trigger={
        <section
          className={classNames("resource-job", className)}
          key={job.jobSheetId}
          {...otherProps}
        >
          <header>
            <span>{job.jobRef}</span>
            <span className="subtext">
              {job.address
                ? job.address.townOrCity
                  ? job.address.townOrCity
                  : job.address.postcode
                : ""}
            </span>
            <span className="subtext">
              <Format.Number value={job.price} format="GBP" />
            </span>
          </header>
        </section>
      }
    >
      <section className="scope-of-work" style={{ maxWidth: "250px" }}>
        <header className="title">
          <span>
            {job.address
              ? [job.customerName, job.address.townOrCity].join(" - ")
              : job.customerName}
          </span>
          <span>{job.department}</span>
        </header>
        <div>
          <span className="title">Scope of work</span>

          <span>
            <Format.Number value={job.price} format="GBP" />
          </span>
        </div>
        {items.map((x) => (
          <div className="job-item" key={x.id}>
            <div>
              {x.quantity} x {x.productName}
            </div>
            <div className="description">{x.description}</div>
          </div>
        ))}
        {items.length == 0 && <span className="no-items">No items</span>}
        {assigned.length != 0 && (
          <footer>Assigned: {assigned.join(", ")}</footer>
        )}
      </section>
    </Popup>
  );
};

export default ResourceJobDetails;
