import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationStack, NotFound } from "@redriver/cinnamon";
import {
  AppRoutes,
  SystemRoutes,
  RoleRoutes,
  UserRoutes,
  CustomerRoutes,
  EnquiryRoutes,
  DepartmentRoutes,
  ProductCategoryRoutes,
  ProductRoutes,
  JobRoutes,
  VatRateRoutes,
  QuoteRoutes,
  SchedulingRoutes,
  TemplateDocumentRoutes,
  SystemSettingsRoutes,
  ContractRoutes,
  MobileUserRoutes,
} from "constants/routes";
import EdenMenu from "./EdenMenu";
import DashboardPage from "./DashboardPage";
import AccountPage from "./system/AccountPage";
import RoleRouter from "./admin/roles/RoleRouter";
import UserRouter from "./admin/users/UserRouter";
import CustomerRouter from "./customers/CustomerRouter";
import { EnquiryRouter } from "pages/enquiries";
import DepartmentRouter from "./admin/departments/DepartmentRouter";
import ProductCategoriesRouter from "./admin/product-categories/ProductCategoriesRouter";
import ProductsRouter from "./admin/products/ProductsRouter";
import JobRouter from "./jobs/JobRouter";
import SchedulingRouter from "./scheduling/SchedulingRouter";
import VatRateRouter from "./admin/vat-rates/VatRateRouter";
import QuoteRouter from "./quotes/QuoteRouter";
import InvoiceRouter from "./finance/FinanceRouter";
import SystemAdminRouter from "./admin/system/SystemRouter";
import TemplateDocumentRouter from "./admin/template-documents/TemplateDocumentRouter";
import ContractRouter from "./contracts/ContractRouter";
import MobileUserRouter from "./admin/mobile-users/MobileUserRouter";

const EdenApp = () => (
  <React.Fragment>
    <EdenMenu />
    <Switch>
      <Route exact path={AppRoutes.Root} component={DashboardPage} />
      <Route exact path={SystemRoutes.Account} component={AccountPage} />
      <Route path={RoleRoutes.Roles} component={RoleRouter} />
      <Route path={UserRoutes.Users} component={UserRouter} />
      <Route path={CustomerRoutes.Customers} component={CustomerRouter} />
      <Route path={EnquiryRoutes.Enquiries} component={EnquiryRouter} />
      <Route path={ProductRoutes.Products} component={ProductsRouter} />
      <Route path={DepartmentRoutes.Departments} component={DepartmentRouter} />
      <Route path={JobRoutes.Jobs} component={JobRouter} />
      <Route path={SchedulingRoutes.Scheduling} component={SchedulingRouter} />
      <Route path={AppRoutes.Finance} component={InvoiceRouter} />
      <Route path={VatRateRoutes.VatRates} component={VatRateRouter} />
      <Route path={QuoteRoutes.Quotes} component={QuoteRouter} />
      <Route
        path={SystemSettingsRoutes.SystemSettings}
        component={SystemAdminRouter}
      />
      <Route
        path={ProductCategoryRoutes.ProductCategories}
        component={ProductCategoriesRouter}
      />
      <Route
        path={TemplateDocumentRoutes.TemplateDocuments}
        component={TemplateDocumentRouter}
      />
      <Route path={ContractRoutes.Contracts} component={ContractRouter} />
      <Route path={MobileUserRoutes.MobileUsers} component={MobileUserRouter} />
      <Route component={NotFound} />
    </Switch>
    <NotificationStack />
  </React.Fragment>
);

export default EdenApp;
