import { Form, Format, Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { createCombinedInvoice } from "./actions";
import { DepartmentsTypeAhead, VATDropdown } from "components/forms";
import React, { Fragment, useState } from "react";
import { CustomerTypes, InvoiceTypeFields } from "constants/enums";
import { DateTime } from "luxon";
import { InvoiceInformationModal } from "components/modals";

const CreateCombinedInvoice = ({
  as: As = Button,
  selectedJobs,
  onSubmitted,
  ...props
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  return (
    <ModalBuilder
      withForm
      initialData={{
        ...selectedJobs[0],
        reference: selectedJobs[0]?.customerReference,
        lines: selectedJobs.map((j) => ({
          ...j,
          quantity: 1,
          description: j.items,
          value: j.totalNetPrice,
          vatRateId: j.isUniqueVatRate ? j.vatRateId : null,
          ...(j.isUniqueVatRate && {
            vatRate: { vatValue: j.vatRate },
          }),
          grossPrice:
            j.totalNetPrice * (j.vatRate != null ? 1 + j.vatRate : 1.2),
        })),
        invoiceType: InvoiceTypeFields.Full,
        dueDate: DateTime.local().plus({ days: 30 }).toISODate(),
      }}
      submitAction={createCombinedInvoice}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps, state, events) => {
        const formData = formProps.value;
        return (
          <Fragment>
            <Modal.Add
              {...modalProps}
              submitLabel="Create"
              header="Create Invoice"
              size="fullscreen"
              onSubmit={() =>
                hasChanged ? setHasSubmitted(true) : events.onSubmit()
              }
            >
              <Form
                {...formProps}
                onChange={(field, data, applyChanges) => {
                  formProps.onChange(field, data, applyChanges);
                  if (field === "lines") {
                    setHasChanged(true);
                  }
                }}
              >
                <Form.Group className="invoice-header">
                  <div className="customer-reference">
                    <label>{formData.customer}</label>
                    <label>{formData.reference}</label>
                  </div>
                  <div className="department">
                    <DepartmentsTypeAhead
                      label="Department"
                      field="departmentId"
                      clearable
                      unknownValueOptions={
                        formData.departmentId
                          ? [
                              {
                                text: formData.department,
                                value: formData.departmentId,
                              },
                            ]
                          : null
                      }
                      fluid
                    />
                  </div>
                  {formData.customerType != CustomerTypes.Residential && (
                    <div className="order-number">
                      <Form.Input
                        label="Order Number"
                        field="orderNumber"
                        fluid
                      />
                    </div>
                  )}
                  <div className="due-date">
                    <Form.Date label="Due Date" field="dueDate" local fluid />
                  </div>
                </Form.Group>
                <div className="invoice-line-list">
                  <Form.Array field="lines" arrayKey="id">
                    <Form.Group>
                      <Form.Numeric
                        field="quantity"
                        label="QTY"
                        width={5}
                        required
                      />
                      <div className="job-field">
                        <Form.Input
                          field="reference"
                          label="Job No"
                          width={40}
                          required
                          readOnly
                        />
                      </div>
                      <div className="description">
                        <Form.TextArea
                          field="description"
                          label="Description"
                          rows={1}
                          fluid
                        />
                      </div>
                      <Form.Numeric
                        field="value"
                        label="Net Price"
                        decimalPlaces={2}
                        formatValue
                        width={10}
                        readOnly
                      />
                      <VATDropdown
                        field="vatRateId"
                        label="Vat Rate"
                        optionField="vatRate"
                        width={22}
                        required
                      />
                      <Form.Numeric
                        field="grossPrice"
                        label="Total"
                        decimalPlaces={2}
                        formatValue
                        subLabel="£"
                        subLabelPosition="left"
                        readOnly
                      />
                      <Form.Trigger
                        field="vatRateId"
                        event="onChange"
                        action="change"
                        value={(formState) => {
                          return formState.fields.value
                            ? formState.fields.value *
                                (1 + formState.fields.vatRate?.vatValue)
                            : null;
                        }}
                        target="grossPrice"
                      />
                      <Form.ArrayRemover
                        type="button"
                        className="remove-button"
                        icon="trash"
                      />
                    </Form.Group>
                  </Form.Array>
                </div>
                <Form.Group className="invoice-total full-width">
                  <span>Total Invoice</span>
                  <Format.Number
                    className="net-price"
                    value={(formData.lines || []).reduce(
                      (a, b) => a + parseFloat(b.value ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                  <Format.Number
                    className="vat"
                    value={(formData.lines || []).reduce(
                      (a, b) =>
                        a +
                        parseFloat(b.value ?? 0) *
                          parseFloat(b.vatRate?.vatValue ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                  <Format.Number
                    className="total"
                    value={(formData.lines || []).reduce(
                      (a, b) => a + parseFloat(b.grossPrice ?? 0),
                      0
                    )}
                    format="GBP"
                  />
                </Form.Group>
              </Form>
            </Modal.Add>
            <InvoiceInformationModal
              visible={hasChanged && hasSubmitted}
              onCancel={() => {
                events.onCancel();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
              onSubmitted={() => {
                events.onSubmit();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default CreateCombinedInvoice;
