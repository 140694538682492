import { useEffect, useRef, useState } from "react";
import { refreshJwt } from "@redriver/cinnamon";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useDispatch } from "react-redux";

const loggingEnabled = process.env.SIGNALR_LOGGING_ENABLED ?? false;

const useSignalRHub = (hubRoute) => {
  const hubConnection = useRef(null);
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const createHubConnection = async () => {
      loggingEnabled && console.log("[cinnamon-signalr] Connecting");
      const connection = new HubConnectionBuilder()
        .withUrl(`${process.env.API_URL}${hubRoute}`, {
          accessTokenFactory: () => {
            return dispatch(refreshJwt());
          },
        })
        .withAutomaticReconnect()
        .build();

      connection.onclose((err) => {
        loggingEnabled &&
          console.log("[cinnamon-signalr] Connection closed", err);
        connection.stop();
        if (err) {
          // If no error we don't want to do this as the only way we can be
          // disconnecting is if the component is unmounting
          setConnected(false);
        }
      });

      try {
        await connection.start();
        hubConnection.current = connection;
        loggingEnabled && console.log("[cinnamon-signalr] Connected");
        setConnected(true);
      } catch (err) {
        loggingEnabled &&
          console.log(
            "[cinnamon-signalr] Error establishing notification connection",
            err
          );
        if (connection.connectionId) {
          connection.stop();
          setConnected(false);
        }
      }
    };

    createHubConnection();

    return () => {
      if (hubConnection.current && hubConnection.current.connectionId) {
        hubConnection.current.stop();
      }
    };
  }, [dispatch, hubRoute]);

  const invokeMethod = (methodName, ...args) => {
    if (hubConnection.current) {
      hubConnection.current.invoke(methodName, ...args);
    }
  };

  if (hubConnection.current) {
    hubConnection.current.cinnamon_connected = connected;
  }

  return [hubConnection.current, invokeMethod];
};

export default useSignalRHub;
