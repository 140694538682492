import React, { useState } from "react";
import { FormBuilder, Form, usePreferenceValue } from "@redriver/cinnamon";
import { HeaderBar, LinePanel } from "components/containers";
import EnquiriesTable from "./EnquiriesTable";
import { SplitViewButton } from "components/buttons";
import EnquirySwimlanes from "./EnquirySwimlanes";
import { EnquiryStatus, getEnumAsOptionsList } from "constants/enums";
import EnquiriesFilters, { FieldNames } from "./EnquiriesFilters";
import EnquiriesFilterDisplay from "./EnquiriesFilterDisplay";
import { CreateEnquiry } from "features/Enquiries/CreateEnquiry";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { PreferenceKeys } from "constants/preferences";

const ListEnquiries = () => {
  const defaultFilters = {};
  const [filters, setFilters] = usePreferenceValue(
    PreferenceKeys.ListEnquiriesFilters
  );
  const [view, setView] = useState("tile");

  const resetFilters = () => setFilters(defaultFilters);

  const filtersToSearch = {
    ...filters,
    status:
      !filters?.status || filters?.status.length == 0
        ? getEnumAsOptionsList(EnquiryStatus)
            .filter(
              (x) =>
                x.value != EnquiryStatus.Closed && x.value != EnquiryStatus.Lost
            )
            .map((x) => x.value)
        : filters?.status,
  };

  return (
    <React.Fragment>
      <LinePanel className="header">
        <FormBuilder
          value={filters}
          onChange={(x) => setFilters(x)}
          renderForm={(formProps) => (
            <HeaderBar
              name="Enquiries"
              isSearchBar
              rightContent={
                <div className="right-content">
                  <PermissionCheck
                    target={Targets.Enquiry}
                    action={Actions.Create}
                  >
                    <CreateEnquiry style={{ marginRight: "1rem" }} />
                  </PermissionCheck>
                  <EnquiriesFilters
                    formProps={formProps}
                    resetFilters={resetFilters}
                  />
                  <SplitViewButton
                    useColumns
                    value={view}
                    onChange={(v) => setView(v)}
                  />
                </div>
              }
            >
              <Form {...formProps}>
                <Form.Input
                  field={FieldNames.Search}
                  placeholder="Search"
                  icon="search"
                />
              </Form>
              <EnquiriesFilterDisplay
                filters={filters}
                resetFilters={resetFilters}
              />
            </HeaderBar>
          )}
        />
      </LinePanel>
      {view == "row" ? (
        <EnquiriesTable filters={filtersToSearch} />
      ) : (
        <EnquirySwimlanes filters={filtersToSearch} />
      )}
    </React.Fragment>
  );
};

export default ListEnquiries;
