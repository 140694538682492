import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "FORMS/LOOKUPS";

const ActionTypes = {
  GetAddressTypes: `${NAMESPACE}/GET_ADDRESS_TYPES`,
  GetDepartments: `${NAMESPACE}/GET_DEPARTMENTS`,
  GetProductCategories: `${NAMESPACE}/GET_PRODUCT_CATEGORIES`,
  GetProducts: `${NAMESPACE}/GET_PRODUCTS`,
  GetTitles: `${NAMESPACE}/GET_TITLES`,
  GetUsers: `${NAMESPACE}/GET_USERS`,
  GetEnquirySources: `${NAMESPACE}/GET_ENQUIRY_SOURCES`,
  GetVatRates: `${NAMESPACE}/GET_VAT_RATES`,
  PostcodeSearch: `${NAMESPACE}/POSTCODE_SEARCH`,
  GetCustomerTypes: `${NAMESPACE}/CUSTOMER_TYPES`,
  GetTemplateDocumentTypes: `${NAMESPACE}/GET_TEMPLATE_DOCUMENT_TYPES`,
  GetCustomers: `${NAMESPACE}/GET_CUSTOMERS`,
  GetCustomerAddresses: `${NAMESPACE}/GET_CUSTOMER_ADDRESSES`,
  GetContractJobTypes: `${NAMESPACE}/GET_CONTRACT_JOB_TYPES`,
  GetContractStatuses: `${NAMESPACE}/GET_CONTRACT_STATUSES`,
  GetContractDocumentTypes: `${NAMESPACE}/GET_CONTRACT_DOCUMENT_TYPES`,
  GetEnquiryDocumentTypes: `${NAMESPACE}/GET_ENQUIRY_DOCUMENT_TYPES`,
  GetInvoiceTypes: `${NAMESPACE}/GET_INVOICE_TYPES`,
  GetJobDocumentTypes: `${NAMESPACE}/GET_JOB_DOCUMENT_TYPES`,
  GetContractPricingTypes: `${NAMESPACE}/GET_CONTRACT_PRICING_TYPES`,
  GetContractPlots: `${NAMESPACE}/GET_CONTRACT_PLOTS`,
};

export const getAddressTypes = () =>
  apiGet(ActionTypes.GetAddressTypes, `lookups/address-types`);

export const getDepartments = ({ search }) =>
  apiGet(ActionTypes.GetDepartments, `lookups/departments`, { search });

export const getProductCategories = ({ search, departmentId }) =>
  apiGet(ActionTypes.GetProductCategories, `lookups/product-categories`, {
    search,
    departmentId,
  });

export const getProducts = ({ search, categoryId }) =>
  apiGet(ActionTypes.GetProducts, `lookups/products`, {
    categoryId,
    search,
  });

export const getTitles = () => apiGet(ActionTypes.GetTitles, `lookups/titles`);

export const getUsers = ({ search }) =>
  apiGet(ActionTypes.GetUsers, `lookups/users`, { search });

export const getEnquirySourcesLookup = () =>
  apiGet(ActionTypes.GetEnquirySources, `lookups/enquiry-sources`);

export const getVatRates = () =>
  apiGet(ActionTypes.GetVatRates, `lookups/vat-rates`);

export const searchPostcode = (postcode) =>
  apiGet(ActionTypes.PostcodeSearch, `lookups/postcodes/${postcode}`);

export const getCustomerTypes = () =>
  apiGet(ActionTypes.GetCustomerTypes, "lookups/customer-types");

export const getTemplateDocumentTypes = () =>
  apiGet(
    ActionTypes.GetTemplateDocumentTypes,
    `lookups/template-document-types`
  );

export const getCustomers = ({ search }) =>
  apiGet(ActionTypes.GetCustomers, `lookups/customers`, {
    search,
    pageSize: 10,
  });

export const getCustomerAddresses = ({ customerId }) => {
  return apiGet(
    ActionTypes.GetCustomerAddresses,
    `lookups/customer-addresses`,
    { customerId }
  );
};

export const getContractJobTypes = () =>
  apiGet(ActionTypes.GetContractJobTypes, "lookups/contract-job-types");

export const getContractStatuses = () =>
  apiGet(ActionTypes.GetContractStatuses, "lookups/contract-statuses");

export const getContractDocumentTypes = () =>
  apiGet(
    ActionTypes.GetContractDocumentTypes,
    "lookups/contract-document-types"
  );

export const getEnquiryDocumentTypes = () =>
  apiGet(ActionTypes.GetEnquiryDocumentTypes, `lookups/enquiry-document-types`);

export const getInvoiceTypes = () =>
  apiGet(ActionTypes.GetInvoiceTypes, `lookups/invoice-types`);

export const getJobDocumentTypes = () =>
  apiGet(ActionTypes.GetJobDocumentTypes, `lookups/job-document-types`);

export const getContractPricingTypes = () =>
  apiGet(ActionTypes.GetContractPricingTypes, `lookups/contract-pricing-types`);

export const getContractPlots = ({ search, contractId, jobId }) =>
  apiGet(ActionTypes.GetContractPlots, `lookups/contract-plots`, {
    search,
    contractId,
    jobId,
  });
