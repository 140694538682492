import React from "react";
import { Actions, Targets } from "constants/Permissions";
import { addJobComment, getJobComments } from "./actions";
import { CommentPanel } from "components/comments";
import { ContentBuilder } from "@redriver/cinnamon";

const JobCommentPanel = ({ jobId, lastUpdatedUtc }) => (
  <ContentBuilder
    loadAction={getJobComments}
    loadParams={{ jobId, lastUpdatedUtc }}
    renderContent={(data, state, events) => (
      <CommentPanel
        header="History &amp; Comments"
        addPermissionTarget={Targets.Job}
        addPermissionAction={Actions.Edit}
        submitAction={addJobComment}
        submitParams={{ jobId }}
        placeholder="Add comment"
        noItemsFoundText="No comments found."
        items={data}
        onSubmitted={events.onRefresh}
        loading={state.loading}
      />
    )}
  />
);

export default JobCommentPanel;
