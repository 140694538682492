import React, { useCallback, useEffect, useRef } from "react";
import {
  enquiryStatusColours,
  quoteStatusColours,
  jobStatusColours,
  colours,
} from "./colours";
import {
  getEnquiryData,
  getQuotesData,
  getJobsData,
  getJobsByDepartmentData,
} from "./actions";
import DonutWidget from "./DonutWidget";
import DashboardSharedFilters from "./DashboardSharedFilters";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { DateTime } from "luxon";
import { DepartmentsDropdown } from "components/forms";
import ContractDashboard from "./ContractDashboard";
import classNames from "classnames";
import BarWidget from "./BarWidget";
import DashboardWidgetClear from "./DashboardWidgetClear";
import { produce } from "immer";
import { Form, usePreferenceValue } from "@redriver/cinnamon";
import { getEnumAsOptionsList, ContractJobType } from "constants/enums";
import { DashboardContext } from "./DashboardContext";
import { DashboardWidgetKeys } from "constants/index.js";

const contractJobTypeOptions = getEnumAsOptionsList(ContractJobType);
const contractText = "Contract";
const jobsByDepartmentColours = [
  colours.red,
  colours.yellow,
  colours.green,
  colours.blue,
  colours.purple,
];

const Dashboard = () => {
  const [savedFilters, setFilters] = usePreferenceValue("dashboardFilters");
  const filters = savedFilters || {};
  const reloads = useRef({});

  const triggerReloads = useCallback(() => {
    for (let k of Object.keys(reloads.current)) {
      if (typeof reloads.current[k] == "function") {
        reloads.current[k]();
      }
    }
  }, [reloads]);

  // specifically only want the filters updated after mount.
  useEffect(() => {
    if (!savedFilters) {
      setFilters({
        customerType: null,
        from: DateTime.local().plus({ days: -30 }).toISODate(),
        to: DateTime.local().toISODate(),
      });
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const updateFilter = ({ name, value, displayValue }) => {
    setFilters({
      ...filters,
      [name]: value,
      [`_${name}Display`]: displayValue, // underscore used to represent fields which are not normal filters. Each value is used as a display name for a filter which can then be used in several widgets as needed (e.g. within the widget's header)
    });
  };

  const isContract = filters.customerType == contractText;
  const { from, to, customerType } = filters;
  const commonFilters = {
    customerType,
    from,
    to,
  };
  const activeContractFilterKeys = Object.keys(filters).filter(
    (k) =>
      ["customerType", "from", "to"].every((x) => k != x) &&
      !k.startsWith("_") &&
      !!filters[k]
  );
  return (
    <DashboardContext.Provider
      value={{
        registerReload: (id, func) => {
          reloads.current[id] = func;
        },
        unregisterReload: (id) => {
          delete reloads.current[id];
        },
      }}
    >
      <section
        className={classNames(
          "dashboard",
          isContract ? "contract-dashboard" : null
        )}
      >
        <div className="dashboard-filters-wrapper">
          <DashboardSharedFilters
            value={filters}
            onChange={setFilters}
            extraFormContent={
              isContract && (
                <Form.Dropdown
                  field="contractJobType"
                  options={contractJobTypeOptions}
                  label="Contract Job Type"
                  width="180px"
                  clearable
                />
              )
            }
            onReload={triggerReloads}
          >
            {isContract && (
              <div style={{ display: "flex" }}>
                <p style={{ marginRight: "2em", marginBottom: 0 }}>
                  Click graph segments to filter all graphs below
                </p>
                {activeContractFilterKeys.length > 0 && (
                  <DashboardWidgetClear
                    left
                    onClear={() => {
                      const next = produce(filters, (draft) => {
                        for (let k of activeContractFilterKeys) {
                          draft[k] = null;
                        }
                        const displayKeys = Object.keys(filters).filter((k) =>
                          k.startsWith("_")
                        );
                        for (let k of displayKeys) {
                          draft[k] = null;
                        }
                      });
                      setFilters(next);
                    }}
                  />
                )}
              </div>
            )}
          </DashboardSharedFilters>
        </div>

        {isContract ? (
          <ContractDashboard filters={filters} updateFilter={updateFilter} />
        ) : (
          <React.Fragment>
            <PermissionCheck target={Targets.Enquiry} action={Actions.View}>
              <DonutWidget
                id={DashboardWidgetKeys.Enquiries}
                title="Enquiries"
                filterContent={
                  <DepartmentsDropdown
                    field="departmentId"
                    placeholder="All departments"
                    clearable
                    width="180px"
                  />
                }
                action={getEnquiryData}
                filterParams={commonFilters}
                transform={(data) =>
                  data.map((x) => ({
                    ...x,
                    colour: enquiryStatusColours[x.name],
                  }))
                }
              />
            </PermissionCheck>
            <PermissionCheck target={Targets.Quote} action={Actions.View}>
              <DonutWidget
                id={DashboardWidgetKeys.Quotes}
                title="Quotes"
                filterContent={
                  <DepartmentsDropdown
                    field="departmentId"
                    placeholder="All departments"
                    clearable
                    width="180px"
                  />
                }
                action={getQuotesData}
                filterParams={commonFilters}
                transform={(data) =>
                  data.map((x) => ({
                    ...x,
                    colour: quoteStatusColours[x.name],
                  }))
                }
              />
            </PermissionCheck>
            <PermissionCheck target={Targets.Job} action={Actions.View}>
              <DonutWidget
                id={DashboardWidgetKeys.Jobs}
                title="Jobs"
                filterContent={
                  <DepartmentsDropdown
                    field="departmentId"
                    placeholder="All departments"
                    clearable
                    width="180px"
                  />
                }
                action={getJobsData}
                filterParams={commonFilters}
                transform={(data) =>
                  data.map((x) => ({
                    ...x,
                    colour: jobStatusColours[x.name],
                  }))
                }
              />
            </PermissionCheck>
            <BarWidget
              id={DashboardWidgetKeys.JobsByDepartment}
              title="Jobs By Department"
              action={getJobsByDepartmentData}
              filterParams={commonFilters}
              colours={jobsByDepartmentColours}
            />
          </React.Fragment>
        )}
      </section>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
