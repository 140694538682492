import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import {
  ModalBuilder,
  Modal,
  Form,
  ListBuilder,
  ActionButton,
} from "@redriver/cinnamon";
import { uploadSignature, getSignature, deleteSignature } from "./actions";
import { LightboxImage } from "components/containers";

const UploadSignature = ({ as: As = Button, onAdded, userId, ...props }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [deleting, setDeleting] = useState(null);
  return (
    <ModalBuilder
      withForm
      submitAction={uploadSignature}
      submitParams={userId}
      onSubmitted={onAdded}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      onCancel={() => setImagePreview(null)}
      renderModal={(modalProps, formProps) => (
        <React.Fragment>
          <Modal.Add {...modalProps} header="Upload Signature">
            <ListBuilder
              loadAction={getSignature}
              loadParams={userId}
              renderList={(data, state, events) => {
                return (
                  <React.Fragment>
                    <p style={{ marginBottom: "1rem" }}>
                      {formProps.value.file
                        ? formProps.value.file.name
                        : data.blobId != null
                        ? data.fileName
                        : "No signature has been added"}
                    </p>
                    {data.blobId != null && !formProps.value.file && (
                      <ActionButton
                        className="signature-remove-button"
                        type="delete"
                        onClick={() => setDeleting(true)}
                      />
                    )}
                    {(imagePreview || data.blobId) && (
                      <div className="signature-image">
                        <LightboxImage
                          src={imagePreview ?? data.url}
                          key={data.blobId}
                        />
                      </div>
                    )}
                    {deleting && (
                      <ModalBuilder
                        submitAction={deleteSignature}
                        submitParams={userId}
                        onSubmitted={() => {
                          setDeleting(false);
                          events.onRefresh();
                        }}
                        onCancel={() => setDeleting(false)}
                        renderModal={(modalProps) => (
                          <Modal.Confirmation
                            {...modalProps}
                            header="Delete Signature"
                          >
                            Are you sure you wish to delete{" "}
                            <b>{data.fileName}</b>?
                          </Modal.Confirmation>
                        )}
                      />
                    )}
                  </React.Fragment>
                );
              }}
            />
            <Form {...formProps}>
              <Form.FileUpload
                field="file"
                label="Upload a file - please note this must be of type png or jpg"
                fileExtensionTypes={["jpg", "jpeg", "png"]}
                fileTypesError="File must be of type png or jpg"
                required
                fluid
              ></Form.FileUpload>
              <Form.Trigger
                field="file"
                action={(formState) => {
                  if (formState.file && formState.file.size > 0) {
                    const dataUrl = URL.createObjectURL(formState.file);
                    setImagePreview(dataUrl);
                  } else if (
                    !formState.file ||
                    !formState.file.fileNotModified
                  ) {
                    setImagePreview(null);
                  }
                }}
              />
            </Form>
          </Modal.Add>
        </React.Fragment>
      )}
    />
  );
};

export default UploadSignature;
