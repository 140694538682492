import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/PLOTS";

const ActionTypes = {
  GetContractPlots: `${NAMESPACE}/GET_CONTRACT_PLOTS`,
  AddContractPlot: `${NAMESPACE}/ADD_CONTRACT_PLOT`,
  EditContractPlot: `${NAMESPACE}/EDIT_CONTRACT_PLOT`,
  DeleteContractPlot: `${NAMESPACE}/DELETE_CONTRACT_PLOT`,
  UploadContractPlots: `${NAMESPACE}/UPLOAD_CONTRACT_PLOTS`,
  CreateJobFromContractPlots: `${NAMESPACE}/CREATE_JOB_FROM_CONTRACT_PLOTS`,
  DeleteContractPlots: `${NAMESPACE}/DELETE_CONTRACT_PLOTS`,
};

export const getContractPlots = (
  { filters, pagination },
  { contractId, pricingType }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetContractPlots, `contracts/${contractId}/plots`, {
    pageSize,
    pageNumber,
    ...filters,
    pricingType,
  });
};

export const addContractPlot = (data, { contractId, pricingType }) =>
  apiPost(ActionTypes.AddContractPlot, `contracts/${contractId}/plots`, {
    ...data,
    pricingType,
  });

export const editContractPlot = (data, { contractId, plotId }) =>
  apiPut(
    ActionTypes.EditContractPlot,
    `contracts/${contractId}/plots/${plotId}`,
    { ...data }
  );

export const deleteContractPlot = ({ contractId, plotId }) =>
  apiDelete(
    ActionTypes.DeleteContractPlot,
    `contracts/${contractId}/plots/${plotId}`
  );

export const uploadContractPlots = ({ csv }, { contractId, pricingType }) => {
  const formData = new FormData();
  formData.append("pricingType", pricingType);
  formData.append("csv", csv);

  return apiPost(
    ActionTypes.UploadContractPlots,
    `contracts/${contractId}/plots/import`,
    formData,
    {
      contentType: null,
    }
  );
};

export const createJobFromContractPlots = (
  formData,
  { contractId, selected }
) =>
  apiPost(
    ActionTypes.CreateJobFromContractPlots,
    `contracts/${contractId}/plots/jobs`,
    {
      contractPlotIds: selected,
      productName: formData.productName,
    }
  );

export const deleteContractPlots = ({ contractId, contractPlotIds }) =>
  apiDelete(ActionTypes.DeleteContractPlots, `contracts/${contractId}/plots`, {
    contractPlotIds,
  });
