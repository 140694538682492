import React from "react";
import { PageSize, TablePagination } from "components/pagination";
import { Table } from "@redriver/cinnamon";
import { Segment } from "semantic-ui-react";

const TablePanel = ({
  titleContent,
  children,
  pagination,
  onPaginationChange,
  pageSizeOptions,
  totalResults,
  customFooter,
  customHeader,
  renderTable,
  pageSizePosition,
  customTable,
  ...tableProps
}) => {
  const { pageSize, pageNumber } = pagination;
  const isLoading = tableProps ? tableProps.loading : false;

  return (
    <Segment className="table-panel">
      <header>
        {customHeader ? (
          customHeader
        ) : (
          <React.Fragment>
            <div className="title">{titleContent}</div>
            {pageSizePosition == "header" && (
              <PageSize
                options={pageSizeOptions}
                value={pageSize}
                onChange={(x) => {
                  onPaginationChange({
                    ...pagination,
                    pageSize: x,
                    pageNumber: 1,
                  });
                }}
              />
            )}
          </React.Fragment>
        )}
      </header>
      {customTable ? children : renderTable({ tableProps, children })}
      <footer>
        {customFooter ? (
          customFooter
        ) : (
          <React.Fragment>
            {!isLoading && totalResults > 0 && (
              <TablePagination
                pageNumber={pageNumber}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                onChange={(changes) => {
                  onPaginationChange({
                    ...pagination,
                    ...changes,
                  });
                }}
                totalItems={totalResults}
                withPageSize={pageSizePosition == "footer"}
              />
            )}
          </React.Fragment>
        )}
      </footer>
    </Segment>
  );
};

TablePanel.defaultProps = {
  pagination: {},
  pageSizePosition: "header",
  renderTable: ({ tableProps, children }) => (
    <Table {...tableProps}>{children}</Table>
  ),
};

export default TablePanel;
