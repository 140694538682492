import React from "react";
import { apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/CUSTOMER_TYPES_LOOKUP";

const ActionTypes = {
  GetTypes: `${NAMESPACE}/GET_TYPES`,
};

const getCustomerTypes = () =>
  apiGet(ActionTypes.GetTypes, `lookups/customer-types`);

export const CustomerTypesLookupDropdown = ({ ...props }) => (
  <Form.Dropdown {...props} lookupAction={getCustomerTypes} />
);
