import React from "react";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { Actions, Targets } from "constants/Permissions";
import { PermissionCheck } from "components/auth";
import { Image } from "semantic-ui-react";
import { getCustomers } from "./actions";
import { TablePanel } from "components/containers";
import { CustomerRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import CustomerActions from "./CustomerActions";
import LockIcon from "assets/icons/lock.svg";
import classNames from "classnames";

const TableCustomers = ({ filters }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getCustomers}
      loadParams={filters}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: classNames(
            `highlight-${item.type.toLowerCase()}`,
            item.isDeleted ? "archived" : ""
          ),
        })),
      })}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            {...tableProps}
            dataKey="id"
            emptyMessage="No customers found"
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            totalResults={state.total}
            onPaginationChange={events.onChangePagination}
            selectable
            className="customers-table"
            onRowClick={(item) => {
              if (!item.isDeleted) {
                history.push(CustomerRoutes.Customer(item.id));
              }
            }}
          >
            <Table.Column
              render={(customer) => {
                {
                  if (customer.isDeleted) {
                    return <Image src={LockIcon} />;
                  }
                }
              }}
            />
            <Table.Column field="type" title="Type" />
            <Table.Column field="displayName" title="Name" />
            <Table.Column
              render={(customer) =>
                customer.address ? customer.address.shortAddress : ""
              }
              title="Main Address"
            />
            <Table.Column field="mainContact" title="Main Contact" />
            <Table.Column field="phoneNumber" title="Phone" />
            <Table.Column field="emailAddress" title="Email" />
            <PermissionCheck target={Targets.Customer} action={Actions.Delete}>
              <Table.Column
                hoverable
                width="5%"
                align="right"
                render={(customer) => (
                  <CustomerActions
                    customer={customer}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default TableCustomers;
