import React from "react";
import { Segment, Divider } from "semantic-ui-react";
import { HeaderBar } from "components/containers";
import CalendarConfiguration from "./CalendarConfiguration";
import XeroConfiguration from "./XeroConfiguration";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const SystemSettings = () => (
  <Segment className="system-settings" padded>
    <HeaderBar name="System Settings"></HeaderBar>
    <Divider />
    <PermissionCheck
      target={Targets.CalendarConfiguration}
      action={Actions.Edit}
    >
      <div className="settings-section">
        <CalendarConfiguration />
      </div>
      <Divider />
    </PermissionCheck>
    <PermissionCheck target={Targets.XeroConfiguration} action={Actions.Edit}>
      <div className="settings-section">
        <XeroConfiguration />
      </div>
    </PermissionCheck>
  </Segment>
);

export default SystemSettings;
