import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "SCHEDULING/RESOURCES";

export const ActionTypes = {
  GetDepartments: `${NAMESPACE}/GET_DEPARTMENTS`,
  SetDepartmentFilter: `${NAMESPACE}/SET_DEPARTMENT_FILTER`,
  GetResourcesForDay: `${NAMESPACE}/GET_RESOURCES`,
  AssignResource: `${NAMESPACE}/ASSIGN`,
  UnassignResource: `${NAMESPACE}/UNASSIGN`,
  SetResourceNote: `${NAMESPACE}/SET_RESOURCE_NOTE`,
  SetJobSheetScheduleNote: `${NAMESPACE}/SET_JOB_SHEET_SCHEDULE_NOTE`,
};

export const getDepartments = () =>
  apiGet(ActionTypes.GetDepartments, `lookups/departments`, {
    filterForSchedulableDepartments: true,
  });

export const setDepartmentFilter = (filter) => {
  return {
    type: ActionTypes.SetDepartmentFilter,
    filter,
  };
};

export const assignResource = ({ jobSheetId, resourceId }) => {
  return apiPost(ActionTypes.AssignResource, `scheduling/resources/assign`, {
    jobSheetId,
    resourceId,
  });
};

export const unassignResource = ({ jobSheetId, resourceId }) => {
  return apiDelete(
    ActionTypes.UnassignResource,
    `scheduling/resources/unassign`,
    {
      jobSheetId,
      resourceId,
    }
  );
};

export const getAvailableResourcesForDay = (
  date,
  departmentsFilter,
  filters
) => {
  return apiGet(ActionTypes.GetResourcesForDay, `scheduling/resources`, {
    date,
    departments:
      departmentsFilter && departmentsFilter.length > 0
        ? departmentsFilter
        : [],
    filterType: filters.filterType,
    filter: filters.search,
    showOnly: filters.showOnly != "All" ? filters.showOnly : undefined,
    customerType: filters.customerType,
  });
};

export const setResourceNote = (resourceId, date, note) => {
  return apiPut(
    ActionTypes.SetResourceNote,
    `scheduling/resources/${resourceId}/notes/${date}`,
    {
      note,
    }
  );
};

export const setJobSheetScheduleNote = (jobId, jobSheetId, date, note) => {
  return apiPut(
    ActionTypes.SetJobSheetScheduleNote,
    `scheduling/jobs/${jobId}/sheets/${jobSheetId}/schedule-note`,
    {
      note,
      date,
    }
  );
};
