import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button } from "semantic-ui-react";
import { deleteContractPlot } from "./actions";

const DeleteContractPlot = ({
  as: As = Button,
  plot,
  contractId,
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteContractPlot}
    submitParams={{ contractId, plotId: plot.id }}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Delete {...modalProps} header="Delete Plot">
        Are you sure you wish to delete plot {plot.number}?
      </Modal.Delete>
    )}
  />
);

export default DeleteContractPlot;
