export const AppRoutes = {
  Root: "/",
  Admin: "/admin",
  Contracts: "/contracts",
  Customers: "/customers",
  Departments: "/departments",
  Enquiries: "/enquiries",
  Finance: "/finance",
  Jobs: "/jobs",
  MobileUsers: "/mobile-users",
  Products: "/products",
  ProductCategories: "/product-categories",
  Quotes: "/quotes",
  Roles: "/roles",
  Scheduling: "/scheduling",
  TemplateDocuments: "/template-documents",
  SystemSettings: "/system-settings",
  Users: "/users",
  VatRates: "/vat-rates",
  Xero: "/xero",
};

export const SystemRoutes = {
  Login: "/login",
  Logout: "/logout",
  Account: "/account",
  AzureAdSignIn: "/azure-ad-signin",
};

export const UserRoutes = {
  Users: AppRoutes.Admin + AppRoutes.Users,
  User: (id = ":id") => `${AppRoutes.Admin}${AppRoutes.Users}/${id}`,
};

export const RoleRoutes = {
  Roles: AppRoutes.Admin + AppRoutes.Roles,
};

export const ProductRoutes = {
  Products: AppRoutes.Admin + AppRoutes.Products,
};

export const DepartmentRoutes = {
  Departments: AppRoutes.Admin + AppRoutes.Departments,
};

export const CustomerRoutes = {
  Customers: AppRoutes.Customers,
  Customer: (id = ":id") => `${AppRoutes.Customers}/${id}`,
};

export const EnquiryRoutes = {
  Enquiries: AppRoutes.Enquiries,
  Enquiry: (id = ":id") => `${AppRoutes.Enquiries}/${id}`,
  EnquiryQuote: (id = ":id", tab) => `${AppRoutes.Enquiries}/${id}?tab=${tab}`,
};

export const ProductCategoryRoutes = {
  ProductCategories: AppRoutes.Admin + AppRoutes.ProductCategories,
};

export const JobRoutes = {
  Jobs: AppRoutes.Jobs,
  Job: (id = ":id") => `${AppRoutes.Jobs}/${id}`,
};

export const SchedulingRoutes = {
  Scheduling: AppRoutes.Scheduling,
  Job: `${AppRoutes.Scheduling}/jobs`,
  Resources: `${AppRoutes.Scheduling}/resources`,
};

export const VatRateRoutes = {
  VatRates: AppRoutes.Admin + AppRoutes.VatRates,
};

export const XeroRoutes = {
  Xero: AppRoutes.Admin + AppRoutes.Xero,
};

export const QuoteRoutes = {
  Quotes: AppRoutes.Quotes,
};

export const FinanceRoutes = {
  ReadyToInvoice: AppRoutes.Finance + "/ready-to-invoice",
  Invoices: AppRoutes.Finance + "/invoices",
};

export const SignalRHubs = {
  Scheduling: "scheduling-hub",
};

export const TemplateDocumentRoutes = {
  TemplateDocuments: AppRoutes.Admin + AppRoutes.TemplateDocuments,
};

export const SystemSettingsRoutes = {
  SystemSettings: AppRoutes.Admin + AppRoutes.SystemSettings,
};

export const ContractRoutes = {
  Contracts: AppRoutes.Contracts,
  Contract: (id = ":id") => `${AppRoutes.Contracts}/${id}`,
};

export const MobileUserRoutes = {
  MobileUsers: AppRoutes.Admin + AppRoutes.MobileUsers,
};
