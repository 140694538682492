import React, { useState } from "react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { Targets, Actions } from "constants/Permissions";
import { PermissionCheck } from "components/auth";
import { TablePanel, HeaderBar } from "components/containers";
import AddVatRate from "./AddVatRate";
import EditVatRate from "./EditVatRate";
import DeleteVatRate from "./DeleteVatRate";
import VatRateActions from "./VatRateActions";
import { getVatRates } from "./actions";

const ListVatRates = () => {
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);

  return (
    <ListBuilder
      withTable
      loadAction={getVatRates}
      loadTransform={(rates) =>
        rates.map((r) => ({ ...r, value: r.value * 100 }))
      }
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            customHeader={
              <HeaderBar
                name="VAT Rates"
                rightContent={
                  <AddVatRate
                    content="Add VAT Rate"
                    onAdded={events.onRefresh}
                  />
                }
              ></HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No VAT Rates found"
            selectable
          >
            <Table.Column field="name" title="Name" width="35%" />
            <Table.Column
              field="value"
              title="Rate"
              width="35%"
              render={(rate) => (
                <Format.Number
                  value={rate.value}
                  format="percentage"
                  decimalPlaces={2}
                />
              )}
            />
            <Table.Column field="xeroName" title="Xero Name" width="20%" />
            <PermissionCheck
              target={Targets.VatRate}
              action={[Actions.Edit, Actions.Delete]}
              any
            >
              <Table.Column
                hoverable
                width="10%"
                align="right"
                render={(rate) => (
                  <VatRateActions
                    rate={rate}
                    onEdit={setEditing}
                    onDelete={setDeleting}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
          {editing && (
            <EditVatRate
              rate={editing}
              onCancel={() => setEditing(null)}
              onUpdated={() => {
                setEditing(null);
                events.onRefresh();
              }}
              visible={editing}
            />
          )}
          {deleting && (
            <DeleteVatRate
              rate={deleting}
              onCancel={() => setDeleting(null)}
              onDeleted={() => {
                setDeleting(null);
                events.onRefresh();
              }}
              visible={deleting}
            />
          )}
        </React.Fragment>
      )}
    />
  );
};

export default ListVatRates;
