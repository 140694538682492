import React from "react";
import { Divider, Grid, Header } from "semantic-ui-react";
import { ContractContact, ContractPricing } from "../ContractForm";
import { ContractJobTypesDropdown } from "components/forms";
import { Form } from "@redriver/cinnamon";

const EditContractForm = ({ formProps }) => (
  <Form className="contract-form" {...formProps}>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <ContractJobTypesDropdown
            field="jobType"
            textField="jobTypeDescription"
            label="Job Type"
            placeholder="Select Job Type"
            required
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <ContractContact
        field="siteManager"
        label="Site Manager"
        placeholder="Enter Site Manager Name"
      />
      <ContractContact
        field="quantitySurveyor"
        label="Quantity Surveyor"
        placeholder="Enter Quantity Surveyor Name"
      />
      <ContractContact
        field="accountsContact"
        label="Accounts Contact"
        placeholder="Enter Accounts Contact Name"
      />
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input
            field="contractNumber"
            label="KPS Contract Number"
            placeholder="Enter KPS Contract Number"
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Input
            field="customerContractNumber"
            label="Customer Contract Number"
            placeholder="Enter Customer Contract Number"
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Date
            local
            field="plannedStartDate"
            label="Planned Start Date"
            placeholder="Select date"
            width="65%"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Date
            local
            field="estimatedCompletionDate"
            label="Estimated Completion Date"
            placeholder="Select date"
            width="65%"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.TextArea field="description" label="Description" fluid />
        </Grid.Column>
      </Grid.Row>
      <Divider />
      <Grid.Row columns={2}>
        <Grid.Column style={{ alignSelf: "center" }}>
          <Header style={{ fontWeight: "normal" }}>Contract Pricing</Header>
        </Grid.Column>
      </Grid.Row>
      <ContractPricing />
    </Grid>
  </Form>
);

export default EditContractForm;
