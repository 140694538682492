import { Form, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { LightPanel } from "components/containers";
import { Actions, Targets } from "constants/Permissions";
import React, { Fragment, useState } from "react";
import { Button } from "semantic-ui-react";
import {
  editJobFulfilmentOfWorksNotes,
  getJobFulfilmentOfWorksNotes,
} from "./actions";

const JobFulfilmentOfWorksNotesPanel = ({ jobId }) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <FormBuilder
      loadAction={getJobFulfilmentOfWorksNotes}
      loadParams={jobId}
      submitAction={editJobFulfilmentOfWorksNotes}
      submitParams={jobId}
      onSubmitted={() => setIsEditing(false)}
      renderForm={(formProps, state, events) => (
        <LightPanel
          headerText="Notes"
          rightHeaderContent={
            <PermissionCheck
              target={Targets.JobFulfilmentOfWorks}
              action={Actions.Edit}
            >
              {isEditing ? (
                <Fragment>
                  <Button
                    className="cancel-button"
                    compact
                    content="Cancel"
                    onClick={() => {
                      setIsEditing(false);
                      events.onRefresh();
                    }}
                  />
                  <Button
                    compact
                    content="Save"
                    onClick={() =>
                      events.onSubmit({ reload: true, reset: ["data"] })
                    }
                    loading={state.submitting}
                  />
                </Fragment>
              ) : (
                <Button
                  compact
                  icon="pencil"
                  onClick={() => setIsEditing(true)}
                />
              )}
            </PermissionCheck>
          }
        >
          {!isEditing ? (
            <div className="notes-display">
              {formProps.value.notes || "No notes have been added"}
            </div>
          ) : (
            <Form {...formProps}>
              <Form.TextArea field="notes" rows={5} fluid />
            </Form>
          )}
        </LightPanel>
      )}
    />
  );
};

export default JobFulfilmentOfWorksNotesPanel;
