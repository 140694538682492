import { Form } from "@redriver/cinnamon";
import { ContractPricingTypeFields } from "constants/enums";
import React from "react";
import { Grid } from "semantic-ui-react";

const ContractPlotForm = ({ formProps, pricingType }) => (
  <Form {...formProps}>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input
            field="number"
            label={
              pricingType === ContractPricingTypeFields.InfrastructureWorks ||
              pricingType === ContractPricingTypeFields.MaintenanceWorks
                ? "Claim"
                : "Plot Number"
            }
            required
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Input field="reference" label="Reference" fluid />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.TextArea field="description" label="Description" fluid />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Form.TextArea
            field="additionalComments"
            label="Additional Comments"
            fluid
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

export default ContractPlotForm;
