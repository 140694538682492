const { EnquiryStatus, JobStatus, ContractStatus } = require("constants/enums");

export const colours = {
  red: "#EB5A46",
  yellow: "#F7A500",
  green: "#61BD4F",
  blue: "#6485FF",
  purple: "#8058a5",
  grey: "#ababab",
  darkBlue: "#003F72",
  midBlue: "#0077D7",
  lightBlue: "#21CBDE",
  slate: "#256D79",
  black: "#020202",
  darkGreen: "#256D79",
};

export const enquiryStatusColours = {
  [EnquiryStatus.Open]: colours.red,
  [EnquiryStatus.InProgress]: colours.yellow,
  [EnquiryStatus.Quoted]: colours.green,
};

export const quoteStatusColours = {
  [EnquiryStatus.Closed]: colours.red,
  [EnquiryStatus.Converted]: colours.green,
  [EnquiryStatus.Quoted]: colours.yellow,
  [EnquiryStatus.Lost]: colours.grey,
};

export const jobStatusColours = {
  [JobStatus.ToBeScheduled]: colours.red,
  [JobStatus.Pending]: colours.yellow,
  [JobStatus.Scheduled]: colours.green,
  [JobStatus.Completed]: colours.blue,
  [JobStatus.Invoiced]: colours.purple,
};

export const contractColours = {
  [ContractStatus.New]: colours.darkBlue,
  [ContractStatus.Quoted]: colours.lightBlue,
  [ContractStatus.InProgress.replace(" ", "")]: colours.green,
  [ContractStatus.Completed]: colours.slate,
  [ContractStatus.Cancelled]: colours.black,
  [ContractStatus.OnHold.replace(" ", "")]: colours.midBlue,
};

export const contractJobStatusColours = {
  [JobStatus.Draft]: colours.darkBlue,
  [JobStatus.ToBeScheduled]: colours.midBlue,
  [JobStatus.Scheduled]: colours.lightBlue,
  [JobStatus.Completed]: colours.slate,
  [JobStatus.Invoiced]: colours.green,
};

export class ColourList {
  constructor(colours) {
    this.colours = Array.isArray(colours) ? [...colours] : [];
  }
  getNextColour() {
    if (this.colours.length == 0) {
      return null;
    }
    const next = this.colours.shift();
    this.colours.push(next);
    return next;
  }
}
