import contentDisposition from "content-disposition";

const ContentDispositionHeaderName = "Content-Disposition";

const convertToBlob = async (data) => {
  if (data instanceof Blob) {
    return data;
  } else if (data instanceof Response) {
    return await data.blob();
  } else if (typeof data === "string") {
    return new Blob([data]);
  }
  return null;
};

export const saveFile = async (result, filename) => {
  const blob = await convertToBlob(result.response);
  if (!blob) {
    console.warn(`Unable to download file (file type not supported)`);
    return null;
  }

  const header = result.response.headers.get(ContentDispositionHeaderName);
  const { parameters } = contentDisposition.parse(header);

  if (!filename) {
    filename = parameters.filename;
  }

  saveAs(blob, filename);
};

// From https://github.com/eligrey/FileSaver.js/issues/774#issue-1393525742
const saveAs = (blob, name) => {
  // Namespace is used to prevent conflict w/ Chrome PoperBlocker extension (Issue https://github.com/eligrey/FileSaver.js/issues/561)
  const a = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
  a.download = name;
  a.rel = "noopener";
  a.href = URL.createObjectURL(blob);

  setTimeout(() => URL.revokeObjectURL(a.href), 40 /* sec */ * 1000);
  setTimeout(() => a.click(), 0);
};
