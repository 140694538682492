import React from "react";
import { Icon } from "semantic-ui-react";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { getUsers } from "./actions";
import FilterUsers from "./FilterUsers";
import AddUser from "./AddUser";
import UserActions from "./UserActions";
import { TablePanel, HeaderBar } from "components/containers";

const ListUsers = () => (
  <ListBuilder
    withTable
    loadAction={getUsers}
    dataTransform={(data) => data.results}
    totalTransform={(data) => data.totalResults}
    initialPagination={{ pageSize: 25, pageNumber: 1 }}
    renderList={(tableProps, state, events) => (
      <React.Fragment>
        <TablePanel
          {...tableProps}
          dataKey="id"
          emptyMessage="No users found"
          pageSizePosition="footer"
          pageSizeOptions={[10, 25, 50, 100]}
          pagination={state.pagination}
          totalResults={state.total}
          onPaginationChange={events.onChangePagination}
          selectable
          customHeader={
            <HeaderBar
              name="Users"
              rightContent={
                <PermissionCheck target={Targets.User} action={Actions.Create}>
                  <AddUser content="Add User" onAdded={events.onRefresh} />
                </PermissionCheck>
              }
            >
              <FilterUsers
                value={state.filters}
                onChange={events.onChangeFilters}
              />
            </HeaderBar>
          }
        >
          <Table.Column
            width="5%"
            render={(user) => (
              <Icon
                circular
                inverted
                color="grey"
                name="user"
                size="small"
                disabled={user.isDisabled}
              />
            )}
          />
          <Table.Column
            render={(user) => user.firstName + " " + user.lastName}
            title="Name"
            width="22%"
          />
          <Table.Column field="email" title="Email" width="25%" />
          <Table.Column
            render={(user) => {
              return Array.isArray(user.roles) && user.roles.length
                ? user.roles.join(", ")
                : "-";
            }}
            title="Role"
            width="28%"
          />
          <Table.Column
            title="Status"
            width="15%"
            render={(user) =>
              user.isDisabled
                ? "Inactive"
                : !user.isVerified
                ? "Not Verified"
                : "Active"
            }
          />
          <PermissionCheck
            target={Targets.User}
            action={[Actions.Edit, Actions.Delete]}
            any
          >
            <Table.Column
              hoverable
              width="5%"
              align="right"
              render={(user) => (
                <UserActions user={user} onRefresh={events.onRefresh} />
              )}
            />
          </PermissionCheck>
        </TablePanel>
      </React.Fragment>
    )}
  />
);

export default ListUsers;
