import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { SlideSelector } from "components/controls";

const DepartmentSelector = ({
  getDepartmentsAction,
  setDepartmentFilter,
  departmentsSelector,
  useDisplayColour = false,
  showHeader = true,
}) => {
  const { departments, departmentsFilter: selectedDepartmentIds } =
    useSelector(departmentsSelector);
  const [fromIndex, setFromIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentsAction());
  }, [dispatch, getDepartmentsAction]);

  const toggleAllDepartments = (allSelected) => {
    if (allSelected) {
      dispatch(setDepartmentFilter([]));
    } else {
      dispatch(setDepartmentFilter(departments?.map((d) => d.id) ?? []));
    }
  };

  const toggleDepartmentFilter = (id) => {
    const currentIndex = selectedDepartmentIds.indexOf(id);
    let newFilters = [...selectedDepartmentIds];
    if (currentIndex > -1) {
      newFilters = newFilters.filter((value, i) => i != currentIndex);
    } else {
      newFilters.push(id);
    }
    dispatch(setDepartmentFilter(newFilters));
  };

  const departmentsToRender =
    Array.isArray(departments) && departments.length
      ? departments.filter((_, i) => i >= fromIndex)
      : [];

  return (
    <SlideSelector
      title="Department"
      showHeader={showHeader}
      headerContent={
        <div className="department-selector-header">
          <div
            className="toggle-all"
            onClick={() => toggleAllDepartments(false)}
          >
            Select All
          </div>
          <div
            className="toggle-all"
            onClick={() => toggleAllDepartments(true)}
            style={{
              display: selectedDepartmentIds.length > 0 ? "block" : "none",
            }}
          >
            Clear Selection
          </div>
        </div>
      }
      className="department-selector"
      items={departmentsToRender || []}
      moveNext={(_, hasOverflow) => {
        if (departments && fromIndex < departments.length - 1 && hasOverflow) {
          setFromIndex(fromIndex + 1);
        }
      }}
      movePrevious={() => {
        if (fromIndex > 0) {
          setFromIndex(fromIndex - 1);
        }
      }}
      renderItem={(d) => {
        const isSelected = (selectedDepartmentIds || []).some((x) => x == d.id);
        return (
          <div
            key={d.id}
            className={classNames(
              "department",
              isSelected ? "selected" : null,
              useDisplayColour && !d.displayColour ? "default" : null
            )}
            style={{
              backgroundColor: useDisplayColour ? d.displayColour : null,
            }}
            onClick={() => toggleDepartmentFilter(d.id)}
          >
            {d.name}
          </div>
        );
      }}
    />
  );
};

export default DepartmentSelector;
