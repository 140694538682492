import React from "react";
import { ListDepartments } from "features/Admin";
import { KpsPageContent } from "components/containers";

const DepartmentListPage = () => (
  <KpsPageContent>
    <ListDepartments />
  </KpsPageContent>
);

export default DepartmentListPage;
