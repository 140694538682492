import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteCustomer } from "./actions";

const DeleteCustomer = ({ as: As = Button, customer, onDeleted, ...props }) => (
  <ModalBuilder
    submitAction={deleteCustomer}
    submitParams={customer.id}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Archive Customer">
        Are you sure you wish to archive <b>{customer.displayName}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteCustomer;
