import React from "react";
import { Image } from "semantic-ui-react";
import { Format, useAuthClaims } from "@redriver/cinnamon";
import LocationIcon from "assets/icons/location.svg";
import UserIcon from "assets/icons/user.svg";
import ResidentialBlue from "assets/icons/residential-blue.svg";
import ResidentialRed from "assets/icons/residential-red.svg";
import ResidentialYellow from "assets/icons/residential-yellow.svg";
import ResidentialGreen from "assets/icons/residential-green.svg";
import ResidentialGrey from "assets/icons/residential-grey.svg";
import CommercialBlue from "assets/icons/commercial-blue.svg";
import CommercialRed from "assets/icons/commercial-red.svg";
import CommercialYellow from "assets/icons/commercial-yellow.svg";
import CommercialGreen from "assets/icons/commercial-green.svg";
import CommercialGrey from "assets/icons/commercial-grey.svg";
import EmailRed from "assets/icons/email-red.svg";
import { CustomerTypes, EnquiryStatus } from "constants/enums";
import { Link } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";
import { LinePanel } from "components/containers";
import { Targets, Actions } from "constants/Permissions";
import { claimExists } from "components/auth/utils";
import classNames from "classnames";

const Card = ({ item, background, colour, className }) => (
  <LinePanel
    className={classNames("enquiry-card", className)}
    highlight={colour}
  >
    <div
      style={{
        backgroundImage: background ? `url('${background}')` : "none",
      }}
      className="enquiry-background"
      title={EnquiryStatus[item.status]}
    >
      <div className="title">
        <h3 className="enquiry-title">
          {item.customerType == CustomerTypes.Unknown
            ? "New enquiry from email"
            : item.name}
        </h3>
        <div>{item.ref && <span>{item.ref}</span>}</div>
      </div>
      <div className="enquiry-body">
        <p title={item.address?.shortAddress ?? null}>
          <Image inline src={LocationIcon} />
          <span>{item.address?.shortAddress ?? "No address selected"}</span>
        </p>
        <p>
          <Image inline src={UserIcon} />{" "}
          <span>{item.ownerName ?? "No contract manager"}</span>
        </p>
      </div>
    </div>
    <div className="enquiry-footer">
      <div>{item.department}</div>
      <div className="enquiry-date">
        <Format.Date value={item.createdUtc} format="DD MMM YYYY" />
      </div>
    </div>
  </LinePanel>
);

const EnquiryCard = ({ item, useStatusColour = false, className }) => {
  const { permissions } = useAuthClaims();

  let colour = null;
  let background = null;
  if (useStatusColour) {
    switch (item.status) {
      case EnquiryStatus.Open.replace(" ", ""):
        colour = "red";
        background =
          item.customerType == CustomerTypes.Unknown
            ? EmailRed
            : item.customerType == CustomerTypes.Residential
            ? ResidentialRed
            : CommercialRed;
        break;
      case EnquiryStatus.InProgress.replace(" ", ""):
        colour = "orange";
        background =
          item.customerType == CustomerTypes.Residential
            ? ResidentialYellow
            : CommercialYellow;
        break;
      case EnquiryStatus.Quoted.replace(" ", ""):
        colour = "green";
        background =
          item.customerType == CustomerTypes.Residential
            ? ResidentialGreen
            : CommercialGreen;
        break;
      case EnquiryStatus.Converted.replace(" ", ""):
        colour = "blue";
        background =
          item.customerType == CustomerTypes.Residential
            ? ResidentialBlue
            : CommercialBlue;
        break;
      case EnquiryStatus.Closed.replace(" ", ""):
      case EnquiryStatus.Lost.replace(" ", ""):
        colour = "grey";
        background =
          item.customerType == CustomerTypes.Residential
            ? ResidentialGrey
            : CommercialGrey;
        break;
    }
  }

  return (
    <React.Fragment>
      {claimExists(permissions, Targets.Enquiry, Actions.View) ? (
        <Link to={EnquiryRoutes.Enquiry(item.id)} className="basic">
          <Card
            item={item}
            colour={colour}
            background={background}
            className={className}
          />
        </Link>
      ) : (
        <div className="inline">
          <Card
            item={item}
            colour={colour}
            background={background}
            className={className}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default EnquiryCard;
