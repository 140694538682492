import React from "react";
import {
  Form,
  Format,
  ListBuilder,
  Table,
  useApiAction,
} from "@redriver/cinnamon";
import { HeaderBar, TablePanel } from "components/containers";
import { addJobDocument, getJobDocuments, getJobSheets } from "./actions";
import JobDocumentTableActions from "./JobDocumentTableActions";
import { Actions, Targets } from "constants/Permissions";
import { PermissionCheck } from "components/auth";
import { JobDocumentTypesDropdown } from "components/forms";
import { AddDocumentsModal } from "components/modals";
import { DateTime } from "luxon";
import { JobDocumentType } from "constants/enums";

const CreatedAt = ({ item }) => {
  return <Format.DateTime value={item.createdUtc} format="DD/MM/YY HH:mm" />;
};

const JobDocumentsPanel = ({ jobId }) => {
  const [jobSheetsRequest] = useApiAction(getJobSheets, {
    actionParams: jobId,
    lazy: false,
  });

  const jobSheetOptions = Array.isArray(jobSheetsRequest.response)
    ? jobSheetsRequest.response.map((x) => ({
        value: x.id,
        text: DateTime.fromISO(x.date).toFormat("DDDD"),
      }))
    : [];

  return (
    <ListBuilder
      withTable
      loadAction={getJobDocuments}
      loadParams={{ jobId }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name="Documents"
                rightContent={
                  <PermissionCheck
                    target={Targets.ContractJob}
                    action={Actions.Edit}
                  >
                    <AddDocumentsModal
                      header="Add Job Document"
                      submitAction={addJobDocument}
                      submitParams={{ jobId }}
                      onAdded={events.onRefresh}
                      documentTypeDropdown={
                        <JobDocumentTypesDropdown
                          field="type"
                          label="Type"
                          placeholder="Select document type"
                          required
                          fluid
                        />
                      }
                    >
                      <Form.If
                        condition={({ fields }) =>
                          fields["type"] == JobDocumentType.RiskAssessment
                        }
                      >
                        <Form.Group widths={2}>
                          <Form.Dropdown
                            field="jobSheetId"
                            label="Related Visit"
                            placeholder="Optionally select"
                            options={jobSheetOptions}
                            fluid
                            clearable
                          />
                        </Form.Group>
                      </Form.If>
                    </AddDocumentsModal>
                  </PermissionCheck>
                }
              ></HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No documents found"
          >
            <Table.Column field="fileName" title="File Name" />
            <Table.Column field="description" title="Description" width="40%" />
            <Table.Column field="typeDescription" title="Type" />
            <Table.Column field="createdBy" title="Created By" />
            <Table.Column title="Created At" component={CreatedAt} />
            <Table.Column
              title="Related Visit"
              render={(item) =>
                item.jobSheetDate
                  ? DateTime.fromISO(item.jobSheetDate).toFormat("DDDD")
                  : null
              }
            />
            <Table.Column
              hoverable
              align="right"
              render={(item) => (
                <JobDocumentTableActions
                  document={{ ...item, jobSheetId: item.jobSheetId }}
                  jobId={jobId}
                  onRefresh={events.onRefresh}
                  jobSheetOptions={jobSheetOptions}
                />
              )}
            />
          </TablePanel>
        );
      }}
    />
  );
};

export default JobDocumentsPanel;
