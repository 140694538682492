import React from "react";
import { ListUsers } from "features/Admin";
import { KpsPageContent } from "components/containers";

const UserListPage = () => (
  <KpsPageContent>
    <ListUsers />
  </KpsPageContent>
);

export default UserListPage;
