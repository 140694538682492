import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/CONTRACT_FORM";

export const ActionTypes = {
  GetContractContactPointTypeFields: `${NAMESPACE}/GET_CONTRACT_CONTACT_POINT_TYPES`,
  GetContractPricingInvoicingTypes: `${NAMESPACE}/GET_CONTRACT_PRICING_INVOICING_TYPES`,
};

export const getContractContactPointTypeFields = () =>
  apiGet(
    ActionTypes.GetContractContactPointTypeFields,
    `lookups/contract-contact-point-types`
  );

export const getContractPricingInvoicingTypes = () =>
  apiGet(
    ActionTypes.GetContractPricingInvoicingTypes,
    `lookups/contract-pricing-invoicing-types`
  );
