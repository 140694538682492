import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateJobDetails } from "./actions";
import { DepartmentsTypeAhead, UsersTypeAhead } from "components/forms";
import { CustomerTypes } from "constants/enums";

const EditJobDetailsModal = ({ job, visible, closeModal }) => {
  return (
    <ModalBuilder
      withForm
      visible={visible}
      initialData={job}
      submitAction={updateJobDetails}
      submitParams={job.id}
      onCancel={closeModal}
      onSubmitted={() => closeModal(true)}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit {...modalProps} header="Edit Job Details">
            <Form {...formProps}>
              <Form.Group widths={2}>
                <DepartmentsTypeAhead
                  label="Department"
                  field="departmentId"
                  unknownValueOptions={
                    job.departmentId
                      ? [{ text: job.department, value: job.departmentId }]
                      : null
                  }
                  clearable
                  fluid
                />
                <UsersTypeAhead
                  label="Contract Manager"
                  field="ownerId"
                  placeholder="Select contract manager"
                  unknownValueOptions={
                    job.ownerId
                      ? [{ text: job.ownerName, value: job.ownerId }]
                      : null
                  }
                  clearable
                  fluid
                />
              </Form.Group>
              {job.customerType === CustomerTypes.Commercial && (
                <Form.Group widths={2}>
                  <Form.Input label="Order Number" field="orderNumber" fluid />
                </Form.Group>
              )}
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EditJobDetailsModal;
