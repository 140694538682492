import React, { useState } from "react";
import { ListBuilder, TileGrid } from "@redriver/cinnamon";
import { getCustomerEnquiries } from "./actions";
import { LinePanel, HeaderBar } from "components/containers";
import { EnquiryCard } from "components/enquiries";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { CreateEnquiry } from "features/Enquiries/CreateEnquiry";
import FilterEnquiries from "./FilterEnquiries";

const CustomerEnquiries = ({ customer }) => {
  const [filters, setFilters] = useState({});
  return (
    <ListBuilder
      loadAction={getCustomerEnquiries}
      loadParams={{ customerId: customer.id, filters }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tileProps) => (
        <React.Fragment>
          <LinePanel className="header">
            <HeaderBar
              name="Enquiries"
              rightContent={
                <div className="right-content">
                  <React.Fragment>
                    <PermissionCheck
                      target={Targets.Enquiry}
                      action={Actions.Create}
                    >
                      <CreateEnquiry
                        customerId={customer.id}
                        customerName={customer.displayName}
                      />
                    </PermissionCheck>
                  </React.Fragment>
                </div>
              }
            >
              <FilterEnquiries value={filters} onChange={setFilters} />
            </HeaderBar>
          </LinePanel>
          <TileGrid
            {...tileProps}
            dataKey="id"
            breakpoints={[500, 750, 1000]}
            render={(item) => (
              <EnquiryCard
                item={item}
                useStatusColour
                className="customer-enquiry-card"
              />
            )}
            className="card-grid-breakpoints-fill"
          />
        </React.Fragment>
      )}
      withTiles
    />
  );
};

export default CustomerEnquiries;
