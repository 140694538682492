import { apiGet, apiPut, apiDelete, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_USERS";

const ActionTypes = {
  GetUsers: `${NAMESPACE}/GET_USERS`,
  AddUser: `${NAMESPACE}/ADD_USER`,
  ResendVerification: `${NAMESPACE}/RESEND_VERIFICATION`,
  SendPasswordReset: `${NAMESPACE}/SEND_PASSWORD_RESET`,
  LockOutUser: `${NAMESPACE}/LOCK_OUT_USER`,
  ActivateUser: `${NAMESPACE}/ACTIVATE_USER`,
  DeleteUser: `${NAMESPACE}/DELETE_USER`,
  GetSignature: `${NAMESPACE}/GET_USER_SIGNATURE`,
  UploadSignature: `${NAMESPACE}/UPLOAD_USER_SIGNATURE`,
  DeleteSignature: `${NAMESPACE}/DELETE_USER_SIGNATURE`,
};

const mapUserData = (data) => {
  return {
    ...data,
  };
};

export const addUser = (data) =>
  apiPost(ActionTypes.AddUser, `users`, mapUserData(data));

export const getUsers = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;
  return apiGet(ActionTypes.GetUsers, "users", {
    pageSize,
    pageNumber,
    search,
  });
};

export const lockOutUser = (userId) =>
  apiPut(ActionTypes.LockOutUser, `users/${userId}/disable`);

export const activateUser = (userId) =>
  apiPut(ActionTypes.ActivateUser, `users/${userId}/activate`);

export const deleteUser = (userId) =>
  apiDelete(ActionTypes.DeleteUser, `users/${userId}`);

export const getSignature = (_, userId) => {
  return apiGet(ActionTypes.GetSignature, `users/${userId}/signature`);
};

export const uploadSignature = (data, userId) => {
  const formData = new FormData();
  formData.append("file", data.file);
  return apiPost(
    ActionTypes.UploadSignature,
    `users/${userId}/signature`,
    formData,
    {
      contentType: null,
    }
  );
};

export const deleteSignature = (userId) => {
  return apiDelete(ActionTypes.DeleteSignature, `users/${userId}/signature`);
};
