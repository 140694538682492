import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import EditTemplateDocument from "./EditTemplateDocument";
import DeleteTemplateDocument from "./DeleteTemplateDocument";
import DownloadTemplateDocument from "./DownloadTemplateDocument";

const TemplateDocumentActions = ({ document, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.TemplateDocument} action={Actions.Edit}>
      <EditTemplateDocument
        as={Table.MenuItem}
        onUpdated={onRefresh}
        id={document.id}
      >
        Edit Document
      </EditTemplateDocument>
    </PermissionCheck>
    <DownloadTemplateDocument as={Table.MenuItem} id={document.id}>
      Download Document
    </DownloadTemplateDocument>
    <PermissionCheck target={Targets.TemplateDocument} action={Actions.Delete}>
      <DeleteTemplateDocument
        as={Table.MenuItem}
        onDeleted={onRefresh}
        document={document}
      >
        Delete Document
      </DeleteTemplateDocument>
    </PermissionCheck>
  </Table.Menu>
);

export default TemplateDocumentActions;
