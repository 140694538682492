import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { deleteContractJob } from "./actions";

const DeleteContractJob = ({
  as: As = Button,
  job,
  contractId,
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteContractJob}
    submitParams={{ contractId, jobId: job.jobId }}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Delete {...modalProps} header="Delete Job">
        Are you sure you wish to delete job {job.reference}?
      </Modal.Delete>
    )}
  />
);

export default DeleteContractJob;
