import { ContentBuilder, Form } from "@redriver/cinnamon";
import { ColourPicker, UsersTypeAhead } from "components/forms";
import { DepartmentColourNames, DepartmentColourOptions } from "constants";
import { propName } from "modules/helpers";
import { Grid } from "semantic-ui-react";
import { getUsedDepartmentColours } from "./actions";
import React from "react";

const DepartmentForm = ({ formProps }) => (
  <Form {...formProps}>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input label="Name" field="name" fluid required />
        </Grid.Column>
        <Grid.Column>
          <Form.Input label="Description" field="description" fluid />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <UsersTypeAhead label="Team Lead" field="leadId" fluid clearable />
        </Grid.Column>
        <Grid.Column>
          <ContentBuilder
            loadAction={getUsedDepartmentColours}
            initialData={[]}
            renderContent={(data) => {
              return (
                <ColourPicker
                  field="displayColour"
                  label="Assign Colour"
                  colours={Object.values(DepartmentColourOptions).filter(
                    (colourOption) => {
                      return !data?.some(
                        (usedColour) => usedColour == colourOption
                      );
                    }
                  )}
                  onColourHover={(color, event) => {
                    event.target.title =
                      DepartmentColourNames[
                        propName(DepartmentColourOptions, color.hex)
                      ];
                  }}
                />
              );
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Input
            label="Commercial Nominal Code"
            field="commercialNominalCode"
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Input
            label="Residential Nominal Code"
            field="residentialNominalCode"
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Form.Checkbox
            label="Exclude from scheduling"
            field="excludeFromScheduling"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Checkbox label="Include TPO/CA" field="includeTpo" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

export default DepartmentForm;
