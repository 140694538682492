import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateTpoStatus } from "./actions";
import { JobTpoStatusesLookupDropdown } from "../JobTpoStatusesLookupDropdown";

const JobChangeTpoStatusModal = ({ job, visible, closeModal }) => {
  return (
    <ModalBuilder
      initialData={job}
      submitAction={updateTpoStatus}
      onSubmitted={() => closeModal(true)}
      onCancel={closeModal}
      submitParams={{ jobId: job.id }}
      withForm
      visible={visible}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit header="Change Job TPO Status" {...modalProps}>
            <Form {...formProps}>
              <Form.Group widths="equal">
                <JobTpoStatusesLookupDropdown
                  label="TPO Status"
                  field="tpoStatus"
                  fluid
                />
              </Form.Group>
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default JobChangeTpoStatusModal;
