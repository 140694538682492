import React from "react";
import { Button } from "semantic-ui-react";
import { ListBuilder } from "@redriver/cinnamon";
import { getSwimlaneEnquiries } from "./actions";
import { EnquiryCard } from "components/enquiries";

const EnquiryLane = ({ status, filters, flexBasis = "100%" }) => {
  return (
    <div style={{ flexBasis: flexBasis }}>
      <ListBuilder
        loadAction={getSwimlaneEnquiries}
        loadParams={{ status, ...filters }}
        dataTransform={(data) => data.results}
        totalTransform={(data) => data.totalResults}
        initialPagination={{ pageSize: 6, pageNumber: 1 }}
        renderList={(data, state, events) => (
          <div>
            {data && data.length ? (
              <React.Fragment>
                {data.map((x) => (
                  <EnquiryCard key={x.id} item={x} useStatusColour />
                ))}
                {state.hasMore && (
                  <div className="load-more">
                    <Button
                      loading={state.loadingMore}
                      onClick={events.onLoadMore}
                      size="tiny"
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <p>No enquiries</p>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default EnquiryLane;
