import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateQuoteNotes } from "./actions";

const UpdateQuoteNotesModal = ({ onSubmitted, enquiryId, quoteNotes }) => {
  return (
    <ModalBuilder
      initialData={{
        notes: quoteNotes,
      }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => {
        return (
          <Button compact onClick={showModal}>
            {quoteNotes && quoteNotes != "" ? "Edit" : "Add"} Note
          </Button>
        );
      }}
      submitAction={updateQuoteNotes}
      submitParams={{ enquiryId }}
      withForm
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit {...modalProps} header="Update Quote Notes" size="small">
            <Form {...formProps}>
              <Form.TextArea field="notes" rows={8} fluid />
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default UpdateQuoteNotesModal;
