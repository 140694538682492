import React from "react";
import { JobScheduling } from "features/Scheduling";
import { KpsPageContent } from "components/containers";

const JobSchedulingPage = () => {
  return (
    <KpsPageContent className="schedule-page">
      <JobScheduling />
    </KpsPageContent>
  );
};

export default JobSchedulingPage;
