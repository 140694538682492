import React from "react";
import { apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/ENQUIRY_STATUS_LOOKUP";

const ActionTypes = {
  GetStatuses: `${NAMESPACE}/GET_STATUSES`,
};

const getEnquiryStatuses = () =>
  apiGet(ActionTypes.GetStatuses, `lookups/enquiry-statuses`);

export const EnquiryStatusesLookupDropdown = ({ ...props }) => (
  <Form.Dropdown {...props} lookupAction={getEnquiryStatuses} />
);
