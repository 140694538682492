import React from "react";
import { Form, Lookup, registerLookup, apiGet } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LOOKUPS";

const ActionTypes = {
  Roles: `${NAMESPACE}/ROLES`,
  Permissions: `${NAMESPACE}/PERMISSIONS`,
};

registerLookup(
  "rolesLookup",
  () =>
    apiGet(ActionTypes.Roles, "roles", { pageSize: 100 }, { debounce: 400 }),
  {
    transform: (data) =>
      data.results.map((x) => ({ value: x.id, text: x.name })),
  }
);

export const RoleDropdown = (props) => (
  <Form.TypeAhead multiple {...props} lookup="rolesLookup" />
);

function mapNode(node) {
  const subNodes = Array.isArray(node.categories)
    ? node.categories.map(mapNode)
    : [];
  const permissions = Array.isArray(node.permissions)
    ? node.permissions.map((p) => ({ text: p.description, value: p.uniqueRef }))
    : [];

  return {
    text: node.name,
    value: node.name,
    children: [...subNodes, ...permissions],
  };
}

registerLookup(
  "permissionsLookup",
  () => apiGet(ActionTypes.Permissions, "roles/permissions"),
  {
    transform: (data) => {
      const transformedData = mapNode(data);
      return transformedData.children;
    },
  }
);

export const PermissionsTree = (props) => (
  <Lookup
    lookup="permissionsLookup"
    render={({ loading, response }) =>
      !loading && response && <Form.CheckboxTree {...props} nodes={response} />
    }
  />
);
