import React from "react";
import { getProductCategories } from "./actions";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { TablePanel, HeaderBar } from "components/containers";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import AddProductCategory from "./AddProductCategory";
import FilterProductCategories from "./FilterProductCategories";
import ProductCategoryActions from "./ProductCategoryActions";

const ListProductCategories = () => {
  return (
    <ListBuilder
      withTable
      loadAction={getProductCategories}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name="Product Categories"
                rightContent={
                  <AddProductCategory
                    content="Add Product Category"
                    onAdded={events.onRefresh}
                  />
                }
              >
                <FilterProductCategories
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No product categories found"
            selectable
          >
            <Table.Column field="code" title="Code" />
            <Table.Column field="name" title="Name" />
            <Table.Column field="description" title="Description" />
            <Table.Column field="departmentName" title="Department" />
            <PermissionCheck
              target={Targets.ProductCategory}
              action={[Actions.Edit, Actions.Delete]}
              any
            >
              <Table.Column
                hoverable
                width="10%"
                align="right"
                render={(productCategory) => (
                  <ProductCategoryActions
                    productCategory={productCategory}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default ListProductCategories;
