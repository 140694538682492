import React from "react";
import PropTypes from "prop-types";
import {
  connectForm,
  FormUtils,
  FormValidators,
  Field,
} from "@redriver/cinnamon";
import { ReactMultiEmail } from "react-multi-email";

/**
 * Field for inputting multiple email addresses
 */
class FormMultiEmailSelect extends React.Component {
  static propTypes = {
    // -------------------
    // field props
    // -------------------

    /**
     * Label content to display alongside the field
     */
    label: PropTypes.node,
    /**
     * Additional content to appear alongside the field for custom behaviour
     */
    actions: PropTypes.node,
    /**
     * Whether the label text should be displayed to the side of the field rather than above it
     */
    inline: PropTypes.bool,
    /**
     * Width of the field in approximate number of characters, or a valid CSS width
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * Whether the width of the field should automatically fill all available space
     */
    fluid: PropTypes.bool,

    // -------------------
    // validator props
    // -------------------

    /**
     * Whether this field should be mandatory to completing the form
     */
    required: PropTypes.bool,
    /**
     * Override the default error message for required fields
     */
    requiredError: PropTypes.string,

    // -------------------
    // connectForm props
    // -------------------

    /**
     * Name of this field, and the form data key against which the value will be stored
     */
    field: PropTypes.string.isRequired,
    /**
     * Whether any errors on the field should be displayed, if not specified then inherits from the parent form or fields
     */
    showErrors: PropTypes.bool,
    /**
     * Whether to display all errors for this field or just show one error at a time, if not specified then inherits from the parent form or fields
     */
    allErrors: PropTypes.bool,
    /**
     * Time in milliseconds for field error animation transitions or false to disable, if not specified then inherits from the parent form or fields
     */
    animateErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    /**
     * Whether to disable the field, if not specified then inherits from the parent form or fields
     */
    disabled: PropTypes.bool,
    /**
     * Additional error messages that should be displayed before validator error messages
     */
    customErrors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Function that will be run every time the field changes to perform additional validation
     * Resulting errors can be passed to customErrors
     */
    customValidator: PropTypes.func,
    /**
     * List of other field names that should be re-validated when this field changes
     */
    notifiedFields: PropTypes.arrayOf(PropTypes.string),
    /**
     * Current value of the field (supplied by the form connection)
     * @ignore
     */
    value: PropTypes.array,
    /**
     * Callback when the field has changed (supplied by the form connection)
     * @ignore
     */
    onChange: PropTypes.func.isRequired,
    /**
     * Array of error messages to display on the field (supplied by the form connection)
     * @ignore
     */
    errors: PropTypes.arrayOf(PropTypes.string),
    /**
     * Props excluding those from the form connection (supplied by the form connection)
     * @ignore
     */
    passThruProps: PropTypes.object,
    /**
     * The current state of the form relative to this field (supplied by the form connection)
     * @ignore
     */
    formState: PropTypes.object,
  };

  static defaultProps = {
    value: [],
    onChange: () => {}, //eslint-disable-line no-empty-function
    label: "",
    inline: false,
  };

  render() {
    const {
      errors,
      showErrors,
      allErrors,
      animateErrors,
      disabled,
      label,
      actions,
      inline,
      width,
      fluid,
      required,
      placeholder,
      onChange,
      value,
    } = this.props;

    return (
      <Field
        inline={inline}
        required={required}
        disabled={disabled}
        width={width}
        fluid={fluid}
        label={label}
        actions={actions}
        errors={FormUtils.fieldErrors(errors, showErrors, allErrors)}
        animateErrors={animateErrors}
      >
        <ReactMultiEmail
          placeholder={placeholder}
          emails={value}
          onChange={(_emails) => {
            onChange(_emails);
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
      </Field>
    );
  }
}

export default connectForm({
  displayName: (props) =>
    props.label && typeof props.label === "string"
      ? props.label
      : FormUtils.prettifyField(props.field),
  validators: [FormValidators.requiredField(false)],
})(FormMultiEmailSelect);
