import CheckIcon from "assets/icons/check.svg";
import LocationIcon from "assets/icons/location.svg";
import EmailIcon from "assets/icons/email.svg";
import PhoneIcon from "assets/icons/phone.svg";
import MobileIcon from "assets/icons/mobile.svg";
import CalendarIcon from "assets/icons/calendar.svg";
import SourceIcon from "assets/icons/magnifying-glass.svg";
import HeartbeatIcon from "assets/icons/heartbeat.svg";
import UserIcon from "assets/icons/user.svg";
import UsersIcon from "assets/icons/users.svg";
import { BannerPanel } from "components/containers";
import { FormatAddress } from "components/display";
import React, { useState } from "react";
import EnquiryActions from "./EnquiryActions";
import LinkEnquiryToCustomer from "./LinkEnquiryToCustomer";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import EnquiryEditDetailsModal from "./EnquiryEditDetailsModal";
import { CustomerTypes } from "constants/enums";
import { Format } from "@redriver/cinnamon";
import { Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CustomerRoutes } from "constants/routes";
import ResidentialGreen from "assets/icons/residential-solid-green.svg";
import CommercialBlue from "assets/icons/commercial-solid-blue.svg";
import { SelectAddressModal } from "components/modals";
import { updateSelectedAddress } from "./actions";

const EnquiryBanner = ({ enquiry, onRefresh }) => {
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);
  const [enquiryDetailsOpen, setEnquiryDetailsOpen] = useState(false);

  const customerIcon =
    enquiry.customerType == CustomerTypes.Commercial
      ? CommercialBlue
      : ResidentialGreen;

  const orderNumberDisplay = ` - Order Number: ${
    enquiry.orderNumber != null ? enquiry.orderNumber : ""
  }`;
  return (
    <BannerPanel
      title={
        enquiry && enquiry.customerType != CustomerTypes.Unknown ? (
          <div>
            <Image
              src={customerIcon}
              style={{ width: 20 }}
              spaced="right"
              verticalAlign="middle"
            />
            <span style={{ verticalAlign: "middle" }}>
              Enquiry {enquiry.ref} for{" "}
              {!enquiry.customerArchived ? (
                <PermissionCheck
                  target={Targets.Customer}
                  action={Actions.View}
                  renderFailure={() => enquiry.customer || ""}
                >
                  <span>
                    <Link
                      to={CustomerRoutes.Customer(enquiry.customerId)}
                      className="main customer-link"
                    >
                      {enquiry.customer}
                    </Link>
                    {enquiry.customerType != CustomerTypes.Residential
                      ? orderNumberDisplay
                      : ""}
                  </span>
                </PermissionCheck>
              ) : (
                enquiry.customer +
                (enquiry.customerType != CustomerTypes.Residential
                  ? orderNumberDisplay
                  : "")
              )}
            </span>
          </div>
        ) : (
          `New enquiry`
        )
      }
      infoItemList={[
        {
          icon: LocationIcon,
          text: enquiry.address
            ? FormatAddress(enquiry.address.address, null, true)
            : "Please select address",
          tooltip: "Address",
          maxWidth: "25%",
          onClick: enquiry.address
            ? null
            : () => {
                setSelectAddressOpen(true);
              },
          hidden: enquiry.customerType == CustomerTypes.Unknown,
        },
        {
          icon: EmailIcon,
          text: enquiry.emailAddress,
          tooltip: "Email",
        },
        {
          icon: PhoneIcon,
          text: enquiry.phoneNumber,
          tooltip: "Phone Number",
        },
        {
          icon: MobileIcon,
          text: enquiry.mobileNumber,
          tooltip: "Mobile Number",
        },
        {
          renderItem: () => (
            <span title="Date">
              <Image src={CalendarIcon} className="banner-icon" />
              <Format.Date value={enquiry.enquiryDate} format="DD MMM YYYY" />
            </span>
          ),
        },
        {
          icon: HeartbeatIcon,
          text: enquiry.statusText,
          tooltip: "Status",
        },
        {
          icon: SourceIcon,
          text: enquiry.source,
          tooltip: "Source",
        },
        {
          icon: CheckIcon,
          text: "TPO/CA Check In Progress",
          tooltip: "TPO/CA Check In Progress",
          hidden: !enquiry.tpoCheckInProgress,
        },
        {
          icon: CheckIcon,
          text: "TPO/CA Application In Progress",
          tooltip: "TPO/CA Application In Progress",
          hidden: !enquiry.tpoApplicationInProgress,
        },
      ]}
      footerContent={
        (enquiry.owner || enquiry.department) && (
          <p>
            {enquiry.ownerId && (
              <span title={"Contract Manager"}>
                <Image src={UserIcon} className="banner-icon" />
                <span>{enquiry.owner}</span>
              </span>
            )}
            {enquiry.departmentId && (
              <span title={"Department"}>
                <Image src={UsersIcon} className="banner-icon" />
                <span>{enquiry.department}</span>
              </span>
            )}
          </p>
        )
      }
      rightContent={
        <PermissionCheck target={Targets.Enquiry} action={Actions.Edit}>
          {enquiry.customer ? (
            <React.Fragment>
              <EnquiryActions
                onSelectAddress={() => setSelectAddressOpen(true)}
                onEditDetails={() => setEnquiryDetailsOpen(true)}
              />
              <SelectAddressModal
                visible={selectAddressOpen}
                entity={enquiry}
                address={enquiry.address}
                header="Enquiry Address"
                action={updateSelectedAddress}
                allowAdditions
                closeModal={(changed) => {
                  setSelectAddressOpen(false);
                  if (changed) {
                    onRefresh();
                  }
                }}
              />
              <EnquiryEditDetailsModal
                enquiry={enquiry}
                visible={enquiryDetailsOpen}
                closeModal={(changed) => {
                  setEnquiryDetailsOpen(false);
                  if (changed) {
                    onRefresh();
                  }
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <LinkEnquiryToCustomer
                enquiryId={enquiry.id}
                enquiryEmail={enquiry.emailAddress}
              />
            </React.Fragment>
          )}
        </PermissionCheck>
      }
    />
  );
};
export default EnquiryBanner;
