import { ActionMenu } from "@redriver/cinnamon";
import InvoiceSelectedJobs from "./InvoiceSelectedJobs";
import UncompleteSelectedJobs from "./UncompleteSelectedJobs";
import React from "react";
import CompleteSelectedJobs from "./CompleteSelectedJobs";
import { JobStatus } from "constants/enums";
import ConvertContractJobs from "./ConvertContractJobs";

const ContractJobsPanelActions = ({
  contractId,
  data,
  selectedItems,
  onSubmitted,
}) => {
  const selected = data.filter(
    (x) =>
      (selectedItems.selected.includes(x.jobId) || selectedItems.allSelected) &&
      !selectedItems.deselected.includes(x.jobId)
  );
  const selectedIds = selected.map((x) => x.jobId);
  const canUncomplete =
    selectedIds.length > 0 &&
    selected.every((x) => x.isComplete && x.status !== JobStatus.Invoiced);
  const canComplete =
    selectedIds.length > 0 &&
    selected.every((x) => x.status === JobStatus.Scheduled);
  const canInvoice =
    selectedIds.length > 0 &&
    selected.every(
      (x) =>
        x.jobType === selected[0].jobType &&
        x.status !== JobStatus.Invoiced
    ) &&
    selected.some((x) => !!x.isComplete);
  const canConvert =
    selectedIds.length > 0 &&
    selected.every((x) => x.status === JobStatus.Draft);

  return (
    <ActionMenu>
      <CompleteSelectedJobs
        content="Complete Selected Jobs"
        disabled={!canComplete}
        contractId={contractId}
        selectedIds={selectedIds}
        onSubmitted={onSubmitted}
      />
      <UncompleteSelectedJobs
        content="Uncomplete Selected Jobs"
        disabled={!canUncomplete}
        contractId={contractId}
        selectedIds={selectedIds}
        onSubmitted={onSubmitted}
      />
      <InvoiceSelectedJobs
        content="Invoice Selected Jobs"
        disabled={!canInvoice}
        contractId={contractId}
        selectedJobs={selectedIds}
        onSubmitted={onSubmitted}
      />
      <ConvertContractJobs
        content="Ready to Schedule"
        disabled={!canConvert}
        contractId={contractId}
        selectedIds={selectedIds}
        onSubmitted={onSubmitted}
      />
    </ActionMenu>
  );
};

export default ContractJobsPanelActions;
