import React, { useState } from "react";
import {
  ListBuilder,
  Table,
  Format,
  FormBuilder,
  Form,
} from "@redriver/cinnamon";
import { TablePanel, HeaderBar } from "components/containers";
import { PermissionCheck, MultiPermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { addContractDocument, getContractDocuments } from "./actions";
import { ContractDocumentCategory } from "constants/enums";
import ContractDocumentTableActions from "./ContractDocumentTableActions";
import { Button, ButtonGroup } from "semantic-ui-react";
import { ContractDocumentTypesDropdown } from "components/forms";
import { AddDocumentsModal } from "components/modals";

const CreatedAt = ({ item }) => {
  return <Format.DateTime value={item.createdUtc} format="DD/MM/YY HH:mm" />;
};

const ContractDocumentsPanel = ({ contractId }) => {
  const [documentCategory, setDocumentCategory] = useState(
    ContractDocumentCategory.Contract
  );

  return (
    <ListBuilder
      withTable
      loadAction={getContractDocuments}
      loadParams={{ contractId, category: documentCategory }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                isSearchBar
                name={
                  <ButtonGroup className="header-button-group">
                    <Button
                      className={
                        documentCategory === ContractDocumentCategory.Contract
                          ? "selected"
                          : ""
                      }
                      onClick={() => {
                        setDocumentCategory(ContractDocumentCategory.Contract);
                        events.onRefresh();
                      }}
                    >
                      Contract
                    </Button>
                    <Button
                      className={
                        documentCategory === ContractDocumentCategory.Sales
                          ? "selected"
                          : ""
                      }
                      onClick={() => {
                        setDocumentCategory(ContractDocumentCategory.Sales);
                        events.onRefresh();
                      }}
                    >
                      Sales
                    </Button>
                  </ButtonGroup>
                }
                rightContent={
                  <PermissionCheck
                    target={Targets.EnquiryDocument}
                    action={Actions.Create}
                  >
                    <AddDocumentsModal
                      header="Add Contract Document"
                      submitAction={addContractDocument}
                      submitParams={{ contractId, category: documentCategory }}
                      onAdded={events.onRefresh}
                      documentTypeDropdown={
                        <ContractDocumentTypesDropdown
                          field="type"
                          label="Type"
                          placeholder="Select document type"
                          required
                          fluid
                        />
                      }
                    />
                  </PermissionCheck>
                }
              >
                <FormBuilder
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  renderForm={(formProps) => (
                    <Form className="list-filters" {...formProps}>
                      <Form.Group widths="equal">
                        <Form.Input
                          field="search"
                          placeholder="Search"
                          icon="search"
                          fluid
                        />
                        <ContractDocumentTypesDropdown
                          className="type"
                          field="type"
                          placeholder="Type"
                          clearable
                        />
                      </Form.Group>
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No documents found"
          >
            <Table.Column field="fileName" title="File Name" />
            <Table.Column field="description" title="Description" width="40%" />
            <Table.Column field="typeDescription" title="Type" />
            <Table.Column field="createdBy" title="Created By" />
            <Table.Column title="Created At" component={CreatedAt} />
            <MultiPermissionCheck
              checks={[
                { target: Targets.ContractDocument, action: Actions.View },
                {
                  target: Targets.ContractDocument,
                  action: Actions.Delete,
                },
              ]}
            >
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <ContractDocumentTableActions
                    document={item}
                    contractId={contractId}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </MultiPermissionCheck>
          </TablePanel>
        );
      }}
    />
  );
};

export default ContractDocumentsPanel;
