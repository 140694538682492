import React from "react";
import { NavMenu, useAuthClaims } from "@redriver/cinnamon";
import { Link, useRouteMatch } from "react-router-dom";
import { Image, Popup, Icon } from "semantic-ui-react";
import {
  AppRoutes,
  ProductRoutes,
  UserRoutes,
  RoleRoutes,
  DepartmentRoutes,
  ProductCategoryRoutes,
  VatRateRoutes,
  FinanceRoutes,
  SchedulingRoutes,
  TemplateDocumentRoutes,
  SystemSettingsRoutes,
  MobileUserRoutes,
} from "constants/routes";
import Logo from "assets/logo.png";
import ToolBoxIcon from "assets/icons/toolbox.svg";
import PoundSterling from "assets/icons/pound-sterling.svg";
import DocumentsIcon from "assets/icons/documents.svg";
import ToolsIcon from "assets/icons/tools.svg";
import MobileIcon from "assets/icons/mobile.svg";
import AccountMenu from "./AccountMenu";
import { PermissionCheck, MultiPermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";
import { Actions, Targets } from "constants/Permissions";

const EdenMenu = () => {
  const { permissions } = useAuthClaims();

  const adminMatch = useRouteMatch(AppRoutes.Admin);
  const financeMatch = useRouteMatch(AppRoutes.Finance);
  const schedulingMatch = useRouteMatch(AppRoutes.Scheduling);
  return (
    <NavMenu vertical={false} className="main-nav">
      <NavMenu.Item link={AppRoutes.Root} className="main-logo">
        <Image src={Logo} alt="KPS" />
      </NavMenu.Item>
      <PermissionCheck action={Actions.View} target={Targets.Customer}>
        <NavMenu.Item link={AppRoutes.Customers}>
          <span className="nav-underline">Customers</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Enquiry}>
        <NavMenu.Item link={AppRoutes.Enquiries}>
          <span className="nav-underline">Enquiries</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Quote}>
        <NavMenu.Item link={AppRoutes.Quotes}>
          <span className="nav-underline">Quotes</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Job}>
        <NavMenu.Item link={AppRoutes.Jobs}>
          <span className="nav-underline">Jobs</span>
        </NavMenu.Item>
      </PermissionCheck>
      <PermissionCheck action={Actions.View} target={Targets.Contract}>
        <NavMenu.Item link={AppRoutes.Contracts}>
          <span className="nav-underline">Contracts</span>
        </NavMenu.Item>
      </PermissionCheck>
      {(claimExists(permissions, Targets.JobScheduling, Actions.View) ||
        claimExists(permissions, Targets.ResourceScheduling, Actions.View)) && (
        <Popup
          flowing
          hoverable
          trigger={
            <NavMenu.Item className={schedulingMatch ? "active" : null}>
              <span className="nav-underline">Scheduling</span>
            </NavMenu.Item>
          }
          position="bottom center"
        >
          <div className="nav-popup-links">
            <PermissionCheck
              action={Actions.View}
              target={Targets.JobScheduling}
            >
              <Link to={SchedulingRoutes.Job} className="dark">
                Jobs
              </Link>
            </PermissionCheck>
            <PermissionCheck
              action={Actions.View}
              target={Targets.ResourceScheduling}
            >
              <Link to={SchedulingRoutes.Resources} className="dark">
                Resources
              </Link>
            </PermissionCheck>
          </div>
        </Popup>
      )}
      {(claimExists(permissions, Targets.Jobs, Actions.View) ||
        claimExists(permissions, Targets.Invoice, Actions.View)) && (
        <Popup
          flowing
          hoverable
          trigger={
            <NavMenu.Item className={financeMatch ? "active" : null}>
              <span className="nav-underline">Finance</span>
            </NavMenu.Item>
          }
          position="bottom center"
        >
          <div className="nav-popup-links">
            {(claimExists(permissions, Targets.Job, Actions.View) ||
              claimExists(permissions, Targets.Invoice, Actions.View)) && (
              <Link to={FinanceRoutes.ReadyToInvoice} className="dark">
                Ready to Invoice
              </Link>
            )}
            <PermissionCheck action={Actions.View} target={Targets.Invoice}>
              <Link to={FinanceRoutes.Invoices} className="dark">
                Invoices
              </Link>
            </PermissionCheck>
          </div>
        </Popup>
      )}
      {(claimExists(permissions, Targets.User, Actions.View) ||
        claimExists(permissions, Targets.Role, Actions.View) ||
        claimExists(permissions, Targets.Department, Actions.View) ||
        claimExists(permissions, Targets.ProductCategory, Actions.View) ||
        claimExists(permissions, Targets.Product, Actions.View) ||
        claimExists(permissions, Targets.VatRate, Actions.View)) && (
        <Popup
          flowing
          hoverable
          trigger={
            <NavMenu.Item className={adminMatch ? "active" : null}>
              <span className="nav-underline">Admin</span>
            </NavMenu.Item>
          }
          position="bottom center"
          className="admin-popup"
        >
          <div className="nav-popup-links">
            <PermissionCheck action={Actions.View} target={Targets.User}>
              <Link to={UserRoutes.Users} className="dark">
                <Icon name="user" /> Users
              </Link>
            </PermissionCheck>
            <PermissionCheck action={Actions.View} target={Targets.Role}>
              <Link to={RoleRoutes.Roles} className="dark">
                <Icon name="cog" /> Roles
              </Link>
            </PermissionCheck>
            <PermissionCheck action={Actions.View} target={Targets.Department}>
              <Link to={DepartmentRoutes.Departments} className="dark">
                <Icon name="users" /> Departments
              </Link>
            </PermissionCheck>
            <PermissionCheck
              action={Actions.View}
              target={Targets.ProductCategory}
            >
              <Link
                to={ProductCategoryRoutes.ProductCategories}
                className="dark"
              >
                <Icon name="cubes" /> Product Categories
              </Link>
            </PermissionCheck>
            <PermissionCheck action={Actions.View} target={Targets.Product}>
              <Link to={ProductRoutes.Products} className="dark">
                <span style={{ marginRight: "0.5rem" }}>
                  <Image inline src={ToolBoxIcon} />
                </span>
                Products
              </Link>
            </PermissionCheck>
            <PermissionCheck action={Actions.View} target={Targets.VatRate}>
              <Link to={VatRateRoutes.VatRates} className="dark">
                <span style={{ marginRight: "0.5rem" }}>
                  <Image
                    inline
                    src={PoundSterling}
                    style={{ height: "0.85rem", width: "1rem" }}
                  />
                </span>
                Vat Rates
              </Link>
            </PermissionCheck>
            <PermissionCheck
              action={Actions.View}
              target={Targets.TemplateDocument}
            >
              <Link
                to={TemplateDocumentRoutes.TemplateDocuments}
                className="dark"
              >
                <span style={{ marginRight: "0.5rem" }}>
                  <Image inline src={DocumentsIcon} />
                </span>
                Template Documents
              </Link>
            </PermissionCheck>
            <MultiPermissionCheck
              checks={[
                { target: Targets.CalendarConfiguration, action: Actions.Edit },
                { target: Targets.XeroConfiguration, action: Actions.Edit },
              ]}
            >
              <Link to={SystemSettingsRoutes.SystemSettings} className="dark">
                <span style={{ marginRight: "0.5rem" }}>
                  <Image inline src={ToolsIcon} />
                </span>
                System Settings
              </Link>
            </MultiPermissionCheck>
            <PermissionCheck action={Actions.View} target={Targets.MobileUsers}>
              <Link to={MobileUserRoutes.MobileUsers} className="dark">
                <span style={{ marginRight: "0.5rem" }}>
                  <Image inline src={MobileIcon} />
                </span>
                Mobile Users
              </Link>
            </PermissionCheck>
          </div>
        </Popup>
      )}
      <AccountMenu />
    </NavMenu>
  );
};

export default EdenMenu;
