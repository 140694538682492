import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { updateProductCategory } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { DepartmentsTypeAhead } from "components/forms";

const EditProductCategory = ({
  as: As = Button,
  onEdited,
  productCategory,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={productCategory}
    submitAction={updateProductCategory}
    onSubmitted={onEdited}
    submitParams={productCategory.id}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.ProductCategory} action={Actions.Edit}>
        <As onClick={showModal} {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Product Category">
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.Input label="Code" field="code" fluid required />
            <Form.Input label="Name" field="name" fluid required />
          </Form.Group>
          <Form.Group widths="equal">
            <DepartmentsTypeAhead
              label="Department"
              field="departmentId"
              fluid
              clearable
              required
              unknownValueOptions={
                productCategory.departmentId
                  ? [
                      {
                        text: productCategory.departmentName,
                        value: productCategory.departmentId,
                      },
                    ]
                  : null
              }
            />
            <Form.Input label="Description" field="description" fluid />
          </Form.Group>
        </Form>
      </Modal.Edit>
    )}
  />
);

export default EditProductCategory;
