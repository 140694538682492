import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "JOBS/DETAILS/DOCUMENTS";

export const ActionTypes = {
  GetJobDocuments: `${NAMESPACE}/GET_JOB_DOCUMENTS`,
  GetJobDocument: `${NAMESPACE}/GET_JOB_DOCUMENT`,
  AddJobDocument: `${NAMESPACE}/ADD_JOB_DOCUMENT`,
  DeleteJobDocument: `${NAMESPACE}/DELETE_JOB_DOCUMENT`,
  UpdateJobDocument: `${NAMESPACE}/UPDATE_JOB_DOCUMENT`,
  GetJobSheetDates: `${NAMESPACE}/GET_JOB_SHEET_DATES`,
};

export const getJobDocuments = ({ pagination }, { jobId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetJobDocuments, `jobs/${jobId}/documents`, {
    pageSize,
    pageNumber,
  });
};

export const getJobDocument = ({ jobId, docId }) => {
  return apiGet(
    ActionTypes.GetJobDocument,
    `jobs/${jobId}/documents/${docId}`,
    {},
    { preventResponseHandling: true }
  );
};

export const addJobDocument = (data, { jobId }) => {
  const formData = new FormData();
  formData.append("name", data.name ?? "");
  formData.append("description", data.description ?? "");
  formData.append("type", data.type);
  if(data.jobSheetId){
    formData.append("jobSheetId", data.jobSheetId);
  }
  (data.files || []).forEach((f) => {
    formData.append(`files`, f);
  });

  return apiPost(
    ActionTypes.AddJobDocument,
    `jobs/${jobId}/documents`,
    formData,
    { contentType: null }
  );
};

export const deleteJobDocument = ({ jobId, docId }) => {
  return apiDelete(
    ActionTypes.DeleteJobDocument,
    `jobs/${jobId}/documents/${docId}`
  );
};

export const updateJobDocument = (formData, { jobId, documentId }) => {
  const { type, description, jobSheetId } = formData;
  return apiPut(
    ActionTypes.UpdateJobDocument,
    `jobs/${jobId}/documents/${documentId}`,
    {
      type,
      description,
      jobSheetId,
    }
  );
};

export const getJobSheets = (jobId) => {
  return apiGet(ActionTypes.GetJobSheetDates, `jobs/${jobId}/sheets`);
};
