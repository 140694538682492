import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/OVERVIEW";

export const ActionTypes = {
  GetOpenCustomerEnquiries: `${NAMESPACE}/GET_OPEN_CUSTOMER_ENQUIRIES`,
  GetOpenCustomerQuotes: `${NAMESPACE}/GET_OPEN_CUSTOMER_QUOTES`,
  GetOpenCustomerJobs: `${NAMESPACE}/GET_OPEN_CUSTOMER_JOBS`,
  GetCustomerComments: `${NAMESPACE}/GET_CUSTOMER_COMMENTS`,
  CreateCustomerComment: `${NAMESPACE}/CREATE_CUSTOMER_COMMENT`,
};

export const getOpenCustomerEnquiries = (_, { customerId }) => {
  return apiGet(
    ActionTypes.GetOpenCustomerEnquiries,
    `customers/${customerId}/enquiries-overview`
  );
};

export const getOpenCustomerQuotes = (_, { customerId }) => {
  return apiGet(
    ActionTypes.GetOpenCustomerQuotes,
    `customers/${customerId}/quotes-overview`
  );
};

export const getOpenCustomerJobs = (_, { customerId }) => {
  return apiGet(
    ActionTypes.GetOpenCustomerJobs,
    `customers/${customerId}/jobs-overview`
  );
};

export const getCustomerComments = ({ customerId }) =>
  apiGet(ActionTypes.GetCustomerComments, `customers/${customerId}/comments`);

export const addCustomerComment = ({ comment }, { customerId }) => {
  return apiPost(
    ActionTypes.CreateCustomerComment,
    `customers/${customerId}/comments`,
    {
      text: comment,
    }
  );
};
