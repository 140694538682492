import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import {
  ContractJobTypesDropdown,
  CustomerAddressesDropdown,
  CustomersTypeAhead,
  DepartmentsTypeAhead,
  UsersTypeAhead,
} from "components/forms";
import { getDepartments } from "components/forms/actions";
import { CustomerRoutes } from "constants/routes";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Grid } from "semantic-ui-react";
import { ContractContact, ContractPricing } from "../ContractForm";
import { addContract } from "./actions";

const AddContract = ({ as: As = Button, onAdded, ...props }) => {
  const [showPricing, setShowPricing] = useState(false);

  return (
    <ModalBuilder
      withForm
      loadAction={getDepartments}
      loadParams={{ search: "Landscaping & Fencing" }}
      loadTransform={(data) => ({
        departmentId: data[0]?.value,
        departmentName: data[0]?.text,
      })}
      submitAction={addContract}
      onSubmitted={onAdded}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        const formData = formProps.value;
        return (
          <Modal.Add {...modalProps} header="Add Contract">
            <Form className="contract-form" {...formProps}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <DepartmentsTypeAhead
                      label="Department"
                      field="departmentId"
                      textField="departmentName"
                      placeholder="Select department"
                      clearable
                      required
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <UsersTypeAhead
                      field="contractManagerId"
                      label="Contract Manager"
                      placeholder="Select contract manager"
                      clearable
                      required
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <ContractJobTypesDropdown
                      field="jobType"
                      label="Job Type"
                      placeholder="Select Job Type"
                      required
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <CustomersTypeAhead
                      field="customerId"
                      label="Select Existing Customer"
                      placeholder="Search for customer"
                      clearable
                      required
                      fluid
                    />
                    <Form.Trigger
                      field="customerId"
                      action="clear"
                      target="customerAddressId"
                    />
                    <Form.Trigger
                      field="customerId"
                      action="clear"
                      target="siteAddressId"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      to={CustomerRoutes.Customers}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="ui small button"
                    >
                      Create new customer
                    </Link>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <CustomerAddressesDropdown
                      field="customerAddressId"
                      label="Customer Address"
                      placeholder="Select Customer Address"
                      lookupParams={
                        formData.customerId
                          ? { customerId: formData.customerId }
                          : {}
                      }
                      clearable
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomerAddressesDropdown
                      field="siteAddressId"
                      label="Site Address"
                      placeholder="Select Site Address"
                      lookupParams={
                        formData.customerId
                          ? { customerId: formData.customerId }
                          : {}
                      }
                      clearable
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input
                      field="siteProjectName"
                      label="Site Project Name"
                      placeholder="Enter Site Project Name"
                      required
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      className={
                        formData.customerId
                          ? "ui small button"
                          : "ui small disabled button"
                      }
                      to={`${CustomerRoutes.Customer(
                        formData.customerId
                      )}?newCustomerAddress=true`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Create new address
                    </Link>
                  </Grid.Column>
                </Grid.Row>
                <ContractContact
                  field="siteManager"
                  label="Site Manager"
                  placeholder="Enter Site Manager Name"
                />
                <ContractContact
                  field="quantitySurveyor"
                  label="Quantity Surveyor"
                  placeholder="Enter Quantity Surveyor Name"
                />
                <ContractContact
                  field="accountsContact"
                  label="Accounts Contact"
                  placeholder="Enter Accounts Contact Name"
                />
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input
                      field="contractNumber"
                      label="KPS Contract Number"
                      placeholder="Enter KPS Contract Number"
                      fluid
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      field="customerContractNumber"
                      label="Customer Contract Number"
                      placeholder="Enter Customer Contract Number"
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Date
                      local
                      field="plannedStartDate"
                      label="Planned Start Date"
                      placeholder="Select date"
                      width="65%"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Date
                      local
                      field="estimatedCompletionDate"
                      label="Estimated Completion Date"
                      placeholder="Select date"
                      width="65%"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form.TextArea
                      field="description"
                      label="Description"
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      content="Contract Pricing"
                      icon={showPricing ? "chevron up" : "chevron down"}
                      labelPosition="right"
                      onClick={() => setShowPricing(!showPricing)}
                      className="contract-pricing-btn"
                    />
                  </Grid.Column>
                </Grid.Row>
                {showPricing && <ContractPricing />}
              </Grid>
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default AddContract;
