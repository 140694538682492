import React from "react";
import { Image } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";
import { StandardVatRateId } from "constants/vatRates";
import {
  DepartmentsTypeAhead,
  ProductCategoriesTypeAhead,
  ProductsTypeAhead,
  VATDropdown,
} from "components/forms";
import LandscapeIcon from "assets/icons/landscape.svg";
import { produce } from "immer";

const QuoteItemForm = ({
  value: formData,
  financialsRequired,
  showJobModifiers,
  onChange,
  ...formProps
}) => {
  const productOrMiscError = "Select Product or Miscellaneous Item";

  const modifiedOnFormChange = (field, data, applyChanges) => {
    const newApplyChanges = (formState) => {
      const nextState = applyChanges(formState);
      const modifiedNextState = produce(nextState, (draft) => {
        if (field == "productId" && draft.miscItem) {
          draft.miscItem = null;
        }
        if (field == "miscItem" && draft.productId) {
          draft.productId = null;
        }
      });
      return modifiedNextState;
    };
    onChange(field, data, newApplyChanges);
  };

  return (
    <Form {...formProps} value={formData} onChange={modifiedOnFormChange}>
      <Form.Group style={{ marginLeft: 0 }}>
        <Image src={LandscapeIcon} />
        <div
          className="quote-item-product-filters"
          style={{ flexGrow: 1, marginLeft: "0.5rem" }}
        >
          <DepartmentsTypeAhead
            field="departmentId"
            inline
            label="Department"
            placeholder="Select department"
            clearable
            width={28}
            textField="departmentName"
          />
          <ProductCategoriesTypeAhead
            field="productCategoryId"
            label="Category"
            departmentId={formData?.departmentId}
            inline
            width={28}
            clearable
            textField="productCategoryName"
          />
        </div>
      </Form.Group>
      <hr />
      <div className="product-options">
        <div style={{ marginRight: "2rem" }}>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            Select a Product
          </p>
          <ProductsTypeAhead
            field="productId"
            optionField="productItem"
            label="Product"
            inline={true}
            required={!formData?.miscItem}
            requiredError={productOrMiscError}
            categoryId={formData?.productCategoryId}
            textField="productName"
            clearable
          />
        </div>
        <div>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            Or Enter a Miscellaneous Item
          </p>
          <Form.Input
            field="miscItem"
            label="Miscellaneous Item"
            inline
            required={!formData?.productId}
            requiredError={productOrMiscError}
          />
        </div>
      </div>
      <Form.Trigger
        field="departmentId"
        action="clear"
        target="productCategoryId"
      />
      <Form.Trigger
        field="productCategoryId"
        action="clear"
        target="productId"
      />
      <Form.Trigger
        field="productId"
        target="unitCost"
        action="change"
        value={({ fields }) =>
          fields.productId ? fields.productItem?.cost : null
        }
      />
      <Form.Trigger
        field="productId"
        target="unitPrice"
        action="change"
        value={({ fields }) =>
          fields.productId ? fields.productItem?.price : null
        }
      />
      <Form.Trigger
        field="productId"
        target="vatRateId"
        action="change"
        value={({ fields }) =>
          fields.productId
            ? fields.productItem?.vatRateId ?? StandardVatRateId
            : fields.miscItem
            ? StandardVatRateId
            : null
        }
      />
      <Form.Trigger
        field="miscItem"
        event="onSet"
        action="set"
        target="vatRateId"
        value={StandardVatRateId}
      />
      <hr />
      <Form.Group className="item-details">
        <Form.Numeric
          field="quantity"
          label="Quantity"
          width={12}
          required={financialsRequired}
        />
        <Form.Numeric
          field="unitPrice"
          label="Price"
          decimalPlaces={2}
          formatValue={formData?.unitPrice != null}
          subLabel="£"
          subLabelPosition="left"
          width={15}
          required={financialsRequired}
        />
        <Form.Numeric
          field="unitCost"
          label="Cost"
          decimalPlaces={2}
          formatValue={formData?.unitCost != null}
          subLabel="£"
          subLabelPosition="left"
          width={15}
        />
        <VATDropdown
          field="vatRateId"
          unknownValueOptions={[
            { text: "Standard Rate", value: StandardVatRateId },
          ]}
          label="VAT Rate"
          clearable
          width={16}
          required={financialsRequired}
        />
      </Form.Group>
      <Form.TextArea
        field="description"
        label="Description"
        rows={1}
        fluid
        className="description"
      />
      {showJobModifiers && (
        <React.Fragment>
          <hr />
          <p className="job-modifiers-label">
            Check any of the following that apply
          </p>
          <Form.Group className="job-modifiers">
            <Form.Checkbox field="breakingGround" label="Breaking Ground" />
            <Form.Checkbox
              field="tpoCheckInProgress"
              label="TPO/CA Check In Progress"
            />
            <Form.Checkbox
              field="tpoApplicationInProgress"
              label="TPO/CA Application In Progress"
            />
          </Form.Group>
        </React.Fragment>
      )}
    </Form>
  );
};

export default QuoteItemForm;
