import React, { Fragment } from "react";
import { ActionMenu } from "@redriver/cinnamon";
import { JobStatus } from "constants/enums";
import AddJobItemModal from "./AddJobItemModal";
import CompleteSelectedItems from "./CompleteSelectedItems";
import ScheduleItemsModal from "./ScheduleItemsModal";
import UncompleteSelectedItems from "./UncompleteSelectedItems";
import InvoiceSelectedItems from "./InvoiceSelectedItems";
import { withPermissions } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import GenerateContractJobQuote from "./GenerateContractJobQuote";
import EmailContractJobQuoteModal from "./EmailContractJobQuoteModal";
import { InvoiceSelectedJobs } from "features/Contracts";

const JobScopeOfWorkPanelActions = ({
  job,
  data,
  selectedItems,
  onSubmitted,
  hasPermission
}) => {
  const selected = data.filter(
    (x) =>
      (selectedItems.selected.includes(x.id) || selectedItems.allSelected) &&
      !selectedItems.deselected.includes(x.id) &&
      !x.jobItemId
  );
  const selectedIds = selected.map((x) => x.id);

  const access = {
    canComplete: selectedIds.length > 0 &&
      (!job.contractId ? job.status !== JobStatus.Pending && job.status !== JobStatus.Draft : selected.every((x) => x.isScheduled)) &&
      selected.every((x) => !x.isComplete),
    canUncomplete: selectedIds.length > 0 &&
      selected.every((x) => x.isComplete && !x.isInvoiced) &&
      hasPermission(Targets.Job, Actions.Uncomplete),
    canInvoiceEnquiryItems: !job.contractId && selectedIds.length > 0 &&
      selected.every((x) => x.isComplete) &&
      hasPermission(Targets.Job, Actions.MarkInvoiceable),
    canInvoiceContractItems: !!job.contractId && selectedIds.length > 0 && selected.every((x) => x.isComplete && !x.isInvoiced),
    canSchedule: selectedIds.length > 0 && job.status !== JobStatus.Draft && selected.every((x) => !x.isComplete),
    canGenerateQuote: !!job.canGenerateQuote,
    canEmailQuote: !!job.canEmailQuote
  };

  return (
    <span className="right-content">
      <AddJobItemModal
        content="Add Item"
        disabled={
          job.status === JobStatus.Completed ||
          job.status === JobStatus.Invoiced
        }
        job={job}
        onSubmitted={onSubmitted}
      />
      <ActionMenu>
        <CompleteSelectedItems
          content="Complete Selected Items"
          disabled={!access.canComplete}
          jobId={job.id}
          selectedIds={selectedIds}
          onSubmitted={onSubmitted}
        />
        <UncompleteSelectedItems
          content="Uncomplete Selected Items"
          disabled={!access.canUncomplete}
          jobId={job.id}
          selectedIds={selectedIds}
          onSubmitted={onSubmitted}
        />
        {access.canInvoiceEnquiryItems ? (
          <InvoiceSelectedItems
            content="Invoice Selected Items"
            jobId={job.id}
            selectedIds={selectedIds}
            onSubmitted={onSubmitted}
          />
        ) : access.canInvoiceContractItems && (
          <InvoiceSelectedJobs
            content="Invoice Selected Items"
            contractId={job.contractId}
            selectedJobs={[job.id]}
            selectedItems={selectedIds}
            onSubmitted={onSubmitted}
          />
        )}
        <ScheduleItemsModal
          content="Schedule Items"
          disabled={!access.canSchedule}
          job={job}
          selectedIds={selectedIds}
          data={data}
          onSubmitted={onSubmitted}
        />
        {access.canGenerateQuote && (
          <Fragment>
            <GenerateContractJobQuote
              content="Generate Quote (PDF)"
              contractId={job.contractId}
              jobId={job.id}
              onSubmitted={onSubmitted}
            />
            <GenerateContractJobQuote
              asDocx
              content="Generate Quote (WORD)"
              contractId={job.contractId}
              jobId={job.id}
              onSubmitted={onSubmitted}
            />
          </Fragment>
        )}
        {access.canEmailQuote && (
          <EmailContractJobQuoteModal
            content="Email Quote"
            contractId={job.contractId}
            jobId={job.id}
            onSubmitted={onSubmitted}
          />
        )}
      </ActionMenu>
    </span>
  );
};

export default withPermissions(JobScopeOfWorkPanelActions);
