import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { updateProduct } from "./actions";
import { ProductCategoriesTypeAhead, VATDropdown } from "components/forms";

const EditProduct = ({ onEdited, product, onCancel }) => (
  <ModalBuilder
    withForm
    initialData={product}
    submitAction={updateProduct}
    onSubmitted={onEdited}
    submitParams={product.id}
    onCancel={onCancel}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Product">
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.Input label="Name" field="name" fluid required />
            <ProductCategoriesTypeAhead
              label="Category"
              field="categoryId"
              fluid
              clearable
              unknownValueOptions={
                product.categoryId
                  ? [
                      {
                        text: product.categoryName,
                        value: product.categoryId,
                      },
                    ]
                  : null
              }
            />
          </Form.Group>
          <Form.Input label="Description" field="description" fluid />
          <Form.Group widths="equal">
            <Form.Numeric
              label="Cost"
              field="cost"
              decimalPlaces={2}
              formatValue={product.cost != null}
              subLabel="£"
              subLabelPosition="left"
              fluid
            />
            <Form.Numeric
              label="Price"
              field="price"
              decimalPlaces={2}
              formatValue={product.price != null}
              subLabel="£"
              subLabelPosition="left"
              fluid
            />
            <VATDropdown
              unknownValueText={product.vatRate}
              field="vatRateId"
              label="Vat Rate"
              fluid
              clearable
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Unit" field="unit" fluid />
            <div className="field ui" />
            <div className="field ui" />
          </Form.Group>
        </Form>
      </Modal.Edit>
    )}
  />
);

export default EditProduct;
