import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ProductRoutes } from "constants/routes";
import ProductsListPage from "./ProductsListPage";

const ProductsRouter = () => (
  <Switch>
    <Route exact path={ProductRoutes.Products} component={ProductsListPage} />
    <Route render={() => <Redirect to={ProductRoutes.Products} />} />
  </Switch>
);

export default ProductsRouter;
