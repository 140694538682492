import React from "react";
import { ListRoles } from "features/Admin";
import { KpsPageContent } from "components/containers";

const RoleListPage = () => (
  <KpsPageContent>
    <ListRoles />
  </KpsPageContent>
);

export default RoleListPage;
