import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { changeMobileUserStatus } from "./actions";
import { Button } from "semantic-ui-react";

const ChangeMobileUserStatus = ({
  mobileUser,
  onRefresh,
  as: As = Button,
  ...props
}) => {
  const isActive = mobileUser.isActive;
  return (
    <ModalBuilder
      renderTrigger={(open) => (
        <As {...props} onClick={open}>
          {isActive ? "Lock Out" : "Activate"}
        </As>
      )}
      submitAction={changeMobileUserStatus}
      submitParams={{ id: mobileUser.id, isActive: !isActive }}
      onSubmitted={onRefresh}
      renderModal={(modalProps) => (
        <Modal.Confirmation
          {...modalProps}
          header={`${isActive ? "Lock Out" : "Activate"} Mobile User`}
        >
          <p>
            Are you sure that you wish to {isActive ? "lock out" : "activate"}{" "}
            {mobileUser.firstName} {mobileUser.lastName}?
          </p>
        </Modal.Confirmation>
      )}
    />
  );
};

export default ChangeMobileUserStatus;
