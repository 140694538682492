import { notifyError, TriggerBuilder } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";
import { Button } from "semantic-ui-react";
import React from "react";

const DownloadDocumentModal = ({
  as: As = Button,
  document,
  action,
  actionParams,
}) => {
  return (
    <TriggerBuilder
      submitAction={action}
      submitParams={{ ...actionParams, docId: document.id }}
      onSubmitted={(submitResponse) => {
        submitResponse
          ? saveFile({ response: submitResponse }, document.fileName)
          : notifyError("Failed to download");
      }}
      renderTrigger={(fireTrigger) => (
        <As content="Download Document" onClick={fireTrigger} />
      )}
    />
  );
};

export default DownloadDocumentModal;
