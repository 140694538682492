import { requestResponse } from "@redriver/cinnamon";
import { produce } from "immer";
import { ActionTypes } from "./actions";

const initialState = {
  departments: [],
  departmentsFilter: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetDepartments: {
      const response = requestResponse(action);
      return produce(state, (draftState) => {
        draftState.departments = response?.map((d) => ({
          id: d.value,
          name: d.text,
          displayColour: d.displayColour,
        }));
      });
    }

    case ActionTypes.SetDepartmentFilter: {
      return produce(state, (draftState) => {
        draftState.departmentsFilter = action.filter;
      });
    }

    default:
      return state;
  }
};
