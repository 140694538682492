import React, { useCallback, useState } from "react";

import { DragDropContext } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";

import { useSignalRHub, useSignalRCallback } from "modules/signalr";
import { SignalRHubs } from "constants/routes";
import { Scheduling } from "constants/signalRMethods";
import DepartmentSelector from "../DepartmentSelector";
import JobsToBeScheduled from "./JobsToBeScheduled";
import ScheduledJobs from "./ScheduledJobs";
import WeekSelector from "./WeekSelector";

import {
  scheduleJob,
  getDepartments,
  setDepartmentFilter,
  jobScheduled,
  jobUnscheduled,
  resourceAssigned,
  resourceUnassigned,
  markItemsAsCompleted,
  mergeJobSheets,
  jobSheetsMerged,
  jobStatusUpdated,
  markItemsAsUncompleted,
  markItemsAsInvoiceable,
} from "./actions";
import { UNSCHEDULED_JOBS_KEY } from "./constants";
import { getJobSchedulingState } from "./selectors";

const JobScheduling = () => {
  const dispatch = useDispatch();
  const [isDraggingFromSchedule, setIsDraggingFromSchedule] = useState(false);
  const [jobCardWidth, setJobCardWidth] = useState(null);
  const schedule = useSelector(getJobSchedulingState).schedule;


  const onDragEnd = useCallback(
    (result) => {
      setIsDraggingFromSchedule(false);
      if (result.combine) {
        const sourceJob = schedule[result.source.droppableId].find((j) => j.id == result.draggableId);
        const targetJob = schedule[result.combine.droppableId].find((j) => j.id == result.combine.draggableId);
  
        if(!sourceJob?.contractId && !targetJob?.contractId) {
          dispatch(
            mergeJobSheets(
              result.draggableId,
              result.source.droppableId,
              result.combine.draggableId,
              result.combine.droppableId
            )
          );
        }

        return;
      }
      if (
        result.destination &&
        result.destination.droppableId != result.source.droppableId
      ) {
        dispatch(
          scheduleJob(
            result.draggableId,
            result.destination.droppableId,
            result.source.droppableId
          )
        );
      }
    },
    [dispatch, schedule]
  );

  const onDragStart = useCallback((start) => {
    if (start.source.droppableId != UNSCHEDULED_JOBS_KEY) {
      setIsDraggingFromSchedule(true);
    }
  }, []);

  const [hubConnection] = useSignalRHub(SignalRHubs.Scheduling);

  const jobHasBeenScheduled = useCallback(
    (job) => {
      dispatch(jobScheduled(job));
    },
    [dispatch]
  );

  const jobHasBeenUnscheduled = useCallback(
    (job) => {
      dispatch(jobUnscheduled(job));
    },
    [dispatch]
  );

  const resourceHasBeenAssigned = useCallback(
    (assignment) => {
      dispatch(resourceAssigned(assignment));
    },
    [dispatch]
  );

  const resourceHasBeenUnassigned = useCallback(
    (assignment) => {
      dispatch(resourceUnassigned(assignment));
    },
    [dispatch]
  );

  const jobItemHasBeenCompleted = useCallback(
    (jobId, completedItemIds) => {
      dispatch(markItemsAsCompleted(jobId, completedItemIds));
    },
    [dispatch]
  );

  const jobStatusHasBeenUpdated = useCallback(
    (jobId, jobStatus) => {
      dispatch(jobStatusUpdated(jobId, jobStatus));
    },
    [dispatch]
  );

  const jobSheetsHaveBeenMerged = useCallback(
    (data) => {
      const {
        sourceSheetDate: sourceDate,
        sourceSheetId,
        targetSheetDate: targetDate,
        targetSheetId,
        targetJobRef,
        sourceJobId,
        sourceSheetPrice,
      } = data;
      dispatch(
        jobSheetsMerged({
          sourceDate,
          sourceSheetId,
          targetDate,
          targetSheetId,
          targetJobRef,
          sourceJobId,
          sourceSheetPrice,
        })
      );
    },
    [dispatch]
  );

  const jobItemHasBeenUncompleted = useCallback(
    (jobId, uncompletedItemIds) => {
      dispatch(markItemsAsUncompleted(jobId, uncompletedItemIds));
    },
    [dispatch]
  );

  const jobItemHasBeenInvoiced = useCallback(
    (jobId, invoiceableItemIds) => {
      dispatch(markItemsAsInvoiceable(jobId, invoiceableItemIds));
    },
    [dispatch]
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.ScheduleJob,
    jobHasBeenScheduled
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.UnscheduleJob,
    jobHasBeenUnscheduled
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.AssignResource,
    resourceHasBeenAssigned
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.UnassignResource,
    resourceHasBeenUnassigned
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.MarkJobItemsAsCompleted,
    jobItemHasBeenCompleted
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.MergeJobSheets,
    jobSheetsHaveBeenMerged
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.JobStatusUpdated,
    jobStatusHasBeenUpdated
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.MarkJobItemsAsUncompleted,
    jobItemHasBeenUncompleted
  );

  useSignalRCallback(
    hubConnection,
    Scheduling.MarkJobItemsAsInvoiceable,
    jobItemHasBeenInvoiced
  );

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <section className="job-scheduling">
        <DepartmentSelector
          getDepartmentsAction={getDepartments}
          setDepartmentFilter={setDepartmentFilter}
          departmentsSelector={getJobSchedulingState}
        />
        <JobsToBeScheduled
          isDragging={isDraggingFromSchedule}
          jobCardWidth={jobCardWidth}
        />
        <WeekSelector />
        <ScheduledJobs setJobCardWidth={setJobCardWidth} />
      </section>
    </DragDropContext>
  );
};

export default JobScheduling;
