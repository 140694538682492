import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { createJobFromContractPlots } from "./actions";
import { Menu } from "semantic-ui-react";

const AddJobFromContractPlots = ({
  as: As = Menu.Item,
  contractId,
  selected,
  onAdded,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createJobFromContractPlots}
      submitParams={{ contractId, selected }}
      onSubmitted={onAdded}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="Create Job">
          <Form {...formProps}>
            <div>
              <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                Add a Product
              </p>
              <Form.Input field="productName" label="Product" inline required />
            </div>
            <p style={{ marginTop: "3em", marginBottom: 0 }}>
              Please confirm that you would like to create a job for the
              selected plots
            </p>
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddJobFromContractPlots;
