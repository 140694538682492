import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { editCustomerAddress } from "./actions";
import CustomerAddressForm from "./CustomerAddressForm";

const EditCustomerAddress = ({
  modalOpen,
  closeModal,
  onRefresh,
  customerAddress,
  customerId,
}) => {
  return (
    <ModalBuilder
      withForm
      visible={modalOpen}
      submitAction={editCustomerAddress}
      submitParams={{
        customerAddressId: customerAddress.id,
        customerId: customerId,
      }}
      onSubmitted={() => {
        closeModal();
        onRefresh();
      }}
      onCancel={closeModal}
      initialData={{ ...customerAddress, ...customerAddress.address }}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Address">
          <Form {...formProps}>
            <CustomerAddressForm initWithManualMode />
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditCustomerAddress;
