import React from "react";
import { Grid } from "semantic-ui-react";
import { Form } from "@redriver/cinnamon";

const VatForm = (props) => (
  <Form {...props}>
    <Grid>
      <Grid.Column width={8}>
        <Form.Input field="name" label="Name" fluid required />
      </Grid.Column>
      <Grid.Column width={8}>
        <Form.Numeric
          field="value"
          label="Rate"
          subLabel="%"
          subLabelPosition="right"
          decimalPlaces={2}
          formatValue
          fluid
          required
        />
      </Grid.Column>
      <Grid.Column width={8}>
        <Form.Input field="xeroName" label="Xero Name" fluid required />
      </Grid.Column>
    </Grid>
  </Form>
);

export default VatForm;
