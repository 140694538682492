import React from "react";
import { LightPanel } from "components/containers";
import { Format, useAuthClaims } from "@redriver/cinnamon";
import { Link } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";
import { EnquiryTabKeys } from "constants/index";
import classNames from "classnames";
import { claimExists } from "components/auth/utils";
import { Actions, Targets } from "constants/Permissions";

const JobDetailsLinkPanel = ({ job, className }) => {
  const { permissions } = useAuthClaims();
  return (
    <LightPanel
      headerText="Links"
      className={classNames("job-side-panel", className)}
    >
      <div className="job-link">
        {claimExists(permissions, Targets.Enquiry, Actions.View) ? (
          <Link to={EnquiryRoutes.Enquiry(job.enquiryId)} className="basic">
            <EnquiryLabel job={job} />
          </Link>
        ) : (
          <EnquiryLabel job={job} />
        )}
      </div>
      <div className="job-link">
        {claimExists(permissions, Targets.Quote, Actions.View) ? (
          <Link
            to={EnquiryRoutes.EnquiryQuote(job.enquiryId, EnquiryTabKeys.Quote)}
            className="basic"
          >
            <QuoteLabel job={job} />
          </Link>
        ) : (
          <QuoteLabel job={job} />
        )}
      </div>
      {job.plannedStartDate && (
        <div className="job-link">
          <p>
            Planned Visit:{" "}
            <Format.Date value={job.plannedStartDate} format="D MMMM YYYY" />
          </p>
        </div>
      )}
      <div className="job-value">
        <p>
          Value: <Format.Number value={job.grossPrice} format="GBP" />
        </p>
      </div>
    </LightPanel>
  );
};

const EnquiryLabel = ({ job }) => <p>Enquiry: {job.enquiryRef}</p>;

const QuoteLabel = ({ job }) => <p>Quote: {job.quoteRef}</p>;

export default JobDetailsLinkPanel;
