import React, { Fragment } from "react";
import {
  FormBuilder,
  TriggerBuilder,
  Form,
  useAuthClaims,
} from "@redriver/cinnamon";
import { LightPanel } from "components/containers";
import { Button, Segment } from "semantic-ui-react";
import { getEnquiryEquipment, updateEnquiryEquipment } from "./actions";
import classNames from "classnames";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { claimExists } from "components/auth/utils";

const EnquiryEquipmentPanel = ({ enquiryId }) => {
  const { permissions } = useAuthClaims();
  return (
    <FormBuilder
      loadAction={getEnquiryEquipment}
      loadParams={{ enquiryId }}
      renderForm={(formProps, state, events) => {
        const formData = formProps.value;
        const readOnly = !claimExists(
          permissions,
          Targets.Enquiry,
          Actions.Edit
        );
        return (
          <Form {...formProps} className="equipment-form">
            <header>
              <Segment>
                <div>Add Equipment</div>
                <PermissionCheck target={Targets.Enquiry} action={Actions.Edit}>
                  <TriggerBuilder
                    submitAction={updateEnquiryEquipment}
                    submitParams={{ data: formData, enquiryId }}
                    onSubmitted={events.onRefresh}
                    loading={state.loading}
                    renderTrigger={(fireTrigger, state) => (
                      <Button
                        compact
                        content="Save"
                        onClick={fireTrigger}
                        loading={state.submitting}
                      />
                    )}
                  />
                </PermissionCheck>
              </Segment>
            </header>
            <div className="equipment-panels">
              {!formData.hasSimpleEquipmentView && (
                <Fragment>
                  <IndividualEquipmentPanel
                    headerText="Vehicles"
                    field="vehicles"
                    readOnly={readOnly}
                  />
                  <IndividualEquipmentPanel
                    headerText="Chippers / Grinders"
                    field="chippers"
                    readOnly={readOnly}
                  />
                </Fragment>
              )}
              <IndividualEquipmentPanel
                className={formData.hasSimpleEquipmentView && "general-panel"}
                headerText={
                  formData.hasSimpleEquipmentView
                    ? "General Equipment"
                    : "Tools General / Ladders etc"
                }
                field="general"
                readOnly={readOnly}
              />
              {!formData.hasSimpleEquipmentView && (
                <Fragment>
                  <IndividualEquipmentPanel
                    headerText="Chainsaws / Hedge Cutters"
                    field="cutters"
                    readOnly={readOnly}
                  />
                  <IndividualEquipmentPanel
                    headerText="Specialist Kit"
                    field="specialist"
                    readOnly={readOnly}
                  />
                  <IndividualEquipmentPanel
                    headerText="Staff"
                    field="staff"
                    readOnly={readOnly}
                  />
                </Fragment>
              )}
              <IndividualEquipmentPanel
                className={classNames("notes-panel", {
                  simple: formData.hasSimpleEquipmentView,
                })}
                headerText="Specific Comments / Notes"
                field="notes"
                readOnly={readOnly}
              />
            </div>
          </Form>
        );
      }}
    />
  );
};

const IndividualEquipmentPanel = ({
  headerText,
  field,
  className,
  readOnly,
}) => (
  <LightPanel
    headerText={headerText}
    className={classNames("individual-panel", className)}
  >
    <Form.TextArea field={field} rows={5} fluid readOnly={readOnly} />
  </LightPanel>
);

export default EnquiryEquipmentPanel;
