import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "DASHBOARD";

const ActionTypes = {
  GetEnquiryData: `${NAMESPACE}/GET_ENQUIRY_DATA`,
  GetQuotesData: `${NAMESPACE}/GET_QUOTES_DATA`,
  GetJobsData: `${NAMESPACE}/GET_JOBS_DATA`,
  GetJobsByDepartmentData: `${NAMESPACE}/GET_JOBS_BY_DEPARTMENT_DATA`,
  GetContractStatusData: `${NAMESPACE}/GET_CONTRACT_STATUS_DATA`,
  GetContractCustomerData: `${NAMESPACE}/GET_CONTRACT_CUSTOMER_DATA`,
  GetContractJobsData: `${NAMESPACE}/GET_CONTRACT_JOBS_DATA`,
  GetContractsByValueData: `${NAMESPACE}/GET_CONTRACTS_BY_VALUE_DATA`,
  GetContractInvoiceValueData: `${NAMESPACE}/GET_CONTRACT_INVOICE_VALUE_DATA`,
};

export const getEnquiryData = (filters) => {
  return apiGet(ActionTypes.GetEnquiryData, "dashboard/enquiries", {
    ...filters,
  });
};

export const getQuotesData = (filters) => {
  return apiGet(ActionTypes.GetQuotesData, "dashboard/quotes", { ...filters });
};

export const getJobsData = (filters) => {
  return apiGet(ActionTypes.GetJobsData, "dashboard/jobs", { ...filters });
};

export const getJobsByDepartmentData = (filters) => {
  return apiGet(
    ActionTypes.GetJobsByDepartmentData,
    "dashboard/jobs-by-department",
    { ...filters }
  );
};

export const getContractStatusData = (filters) => {
  return apiGet(
    ActionTypes.GetContractStatusData,
    "dashboard/contract/status",
    { ...filters }
  );
};

export const getContractCustomerData = (filters) => {
  return apiGet(
    ActionTypes.GetContractCustomerData,
    "dashboard/contract/customer",
    { ...filters }
  );
};

export const getContractJobStatusData = (filters) => {
  return apiGet(
    ActionTypes.GetContractJobsData,
    "dashboard/contract/job-status",
    { ...filters }
  );
};

export const getContractsByValueData = (filters) => {
  return apiGet(
    ActionTypes.GetContractsByValueData,
    "dashboard/contract/by-value",
    { ...filters }
  );
};

export const getContractInvoiceValueData = (filters) => {
  return apiGet(
    ActionTypes.GetContractInvoiceValueData,
    "dashboard/contract/invoice-value",
    { ...filters }
  );
};
