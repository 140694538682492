import React from "react";
import { FormatAddress } from "components/display";
import { LinePanel } from "components/containers";
import { AddressTypes } from "constants/enums";
import classNames from "classnames";
import { Image } from "semantic-ui-react";
import StarIcon from "assets/icons/star.svg";

const AddressTile = ({
  address,
  onClick,
  highlight,
  tint,
  highlightFull,
  backgroundUrl,
  primaryAddress,
  canDelete,
  renderDeleteAddress,
}) => (
  <LinePanel
    onClick={onClick}
    className={classNames("address-tile", onClick ? "pointer" : null)}
    highlight={highlight}
    highlightFull={highlightFull}
    tint={tint}
    backgroundUrl={backgroundUrl}
  >
    {canDelete ? renderDeleteAddress(address) : null}
    <header>{address ? FormatAddress(address) : null}</header>
    <span>
      {primaryAddress ? <Image src={StarIcon} className="primary" /> : null}
      <p>{AddressTypes[address.type]}</p>
    </span>
  </LinePanel>
);

export default AddressTile;
