import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Message, Icon } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { addEnquiryVisit, getCalendarConflicts } from "./actions";
import { UsersTypeAhead } from "components/forms";

const AddVisit = ({ as: As = Button, onAdded, enquiry, ...props }) => {
  const dispatch = useDispatch();
  const [isConflicted, setIsConflicted] = useState(false);

  return (
    <ModalBuilder
      withForm
      submitAction={addEnquiryVisit}
      submitParams={{ enquiryId: enquiry.id }}
      onSubmitted={onAdded}
      initialData={{
        estimatorId: enquiry.ownerId,
        startTime: "09:00",
        duration: 1,
      }}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="Add Visit">
          <Form
            {...formProps}
            onChange={(field, data, applyChanges) => {
              formProps.onChange(field, data, applyChanges);
              const values = { ...formProps.value };
              values[field] = data;
              if (
                values.estimatorId &&
                values.startDate &&
                (values.isAllDay ||
                  (values.startTime &&
                    values.duration &&
                    !isNaN(parseFloat(values.duration))))
              ) {
                dispatch(getCalendarConflicts(values)).then(
                  ({ response, success }) => {
                    if (success) {
                      setIsConflicted(response.conflicts);
                    } else {
                      setIsConflicted(false);
                    }
                  }
                );
              } else {
                setIsConflicted(false);
              }
            }}
          >
            {isConflicted && (
              <Message size="small" info>
                <Message.Content>
                  <span className="enquiry-visit-message">
                    <Icon name="info" size="large" />
                    Conflicts with other appointments on this persons calendar
                  </span>
                </Message.Content>
              </Message>
            )}
            <Form.Group widths="equal">
              <UsersTypeAhead
                label="Contract Manager"
                placeholder="Select contract manager"
                field="estimatorId"
                fluid
                required
                unknownValueOptions={
                  enquiry.ownerId
                    ? [{ text: enquiry.owner, value: enquiry.ownerId }]
                    : null
                }
              />
              <Form.Checkbox
                className="aligned-checkbox"
                field="isAllDay"
                label="All Day"
              />
            </Form.Group>
            <Form.Group>
              <Form.Date
                required
                local
                field="startDate"
                label="Date"
                width={10}
              />
              {!formProps.value.isAllDay && (
                <Form.Time required field="startTime" label="Time" />
              )}
              {!formProps.value.isAllDay && (
                <Form.Numeric
                  required
                  field="duration"
                  label="Duration"
                  subLabel="Hours"
                  subLabelPosition="right"
                  decimalPlaces={2}
                  width={7}
                />
              )}
            </Form.Group>
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddVisit;
