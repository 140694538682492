import { requestIsBusy, requestResponse } from "@redriver/cinnamon";
import { produce } from "immer";
import { DateTime } from "luxon";
import { range } from "modules/helpers";

import { ActionTypes } from "./actions";
import { UNSCHEDULED_JOBS_KEY } from "./constants";

// eslint-disable-next-line no-unused-vars
const log = (item) => console.log(JSON.parse(JSON.stringify(item)));

export const initialState = {
  dailyOverview: {},
  dailyOverviewLoading: false,
  departments: [],
  departmentsFilter: [],
  searchFilter: "",
  contractManagerFilter: "",
  schedule: {},
  selectedWeek: DateTime.local().startOf("week").toISODate(),
  unScheduledByThisTab: [],
  mergedByThisTab: [],
};

const updateDailyOverviews = (
  draftState,
  jobPrice,
  previousDate,
  scheduledDate,
  options = {
    merging: false,
    addPrice: null,
    subtractPrice: null,
  }
) => {
  if (
    previousDate != UNSCHEDULED_JOBS_KEY &&
    previousDate in draftState.dailyOverview
  ) {
    draftState.dailyOverview[previousDate] = {
      price:
        draftState.dailyOverview[previousDate].price -
        (options.subtractPrice ?? jobPrice),
      jobs: draftState.dailyOverview[previousDate].jobs - 1,
    };
  }
  if (
    scheduledDate != UNSCHEDULED_JOBS_KEY &&
    scheduledDate in draftState.dailyOverview
  ) {
    draftState.dailyOverview[scheduledDate] = {
      price:
        draftState.dailyOverview[scheduledDate].price +
        (options.addPrice ?? jobPrice),
      jobs:
        draftState.dailyOverview[scheduledDate].jobs +
        (options.merging ? 0 : 1),
    };
  }
};

const scheduleJob = (draftState, job, previousDate, scheduledDate) => {
  const existingJobIndex = draftState.schedule[scheduledDate]?.findIndex(
    (x) => x.jobId == job.jobId
  );
  draftState.schedule[previousDate] = draftState.schedule[previousDate]?.filter(
    (j) => j.id != job.id
  );
  job.date = scheduledDate;
  if (existingJobIndex != -1 && existingJobIndex != undefined) {
    const itemsToMerge = job.items.filter((x) =>
      draftState.schedule[scheduledDate][existingJobIndex].items.every(
        (i) => i.id != x.id
      )
    );
    if (itemsToMerge.length > 0) {
      draftState.schedule[scheduledDate][existingJobIndex].items =
        draftState.schedule[scheduledDate][existingJobIndex].items.concat(
          itemsToMerge
        );
    } else if (
      job.items.filter((x) =>
        draftState.schedule[scheduledDate][existingJobIndex].items.every(
          (i) => i.id == x.id
        )
      ) &&
      scheduledDate == UNSCHEDULED_JOBS_KEY
    ) {
      // All items match and unscheduling so no need to create new sheet
    } else {
      addSheet(draftState, job, scheduledDate, true);
    }
  } else {
    if (!Array.isArray(draftState.schedule[scheduledDate])) {
      draftState.schedule[scheduledDate] = [];
    }
    draftState.schedule[scheduledDate].push(job);
    draftState.schedule[scheduledDate].sort((a, b) => {
      if (a.jobRef < b.jobRef) {
        return -1;
      }
      if (a.jobRef > b.jobRef) {
        return 1;
      }
      return 0;
    });
  }

  if (scheduledDate == UNSCHEDULED_JOBS_KEY) {
    draftState.unScheduledByThisTab.push(job.id);
  }

  updateDailyOverviews(draftState, job.price, previousDate, scheduledDate);
};

const addSheet = (
  draftState,
  job,
  scheduledDate,
  skipOverviewUpdate = false,
  previousDate = UNSCHEDULED_JOBS_KEY,
  mergeSheets = false
) => {
  const existingJobIndex = draftState.schedule[scheduledDate]?.findIndex(
    (x) => x.jobId == job.jobId
  );
  if (mergeSheets) {
    if (existingJobIndex != -1 && existingJobIndex != undefined) {
      const itemsToMerge = job.items.filter((x) =>
        draftState.schedule[scheduledDate][existingJobIndex].items.every(
          (i) => i.id != x.id
        )
      );

      if (itemsToMerge.length > 0) {
        draftState.schedule[scheduledDate][existingJobIndex].items =
          draftState.schedule[scheduledDate][existingJobIndex].items.concat(
            itemsToMerge
          );
        draftState.schedule[scheduledDate][existingJobIndex].price =
          draftState.schedule[scheduledDate][existingJobIndex].items.reduce(
            (acc, item) => (acc += item.netPrice ?? 0),
            0
          );
        if (!skipOverviewUpdate) {
          updateDailyOverviews(
            draftState,
            job.price,
            previousDate,
            scheduledDate
          );
        }
        return;
      } else if (
        job.items.filter((x) =>
          draftState.schedule[scheduledDate][existingJobIndex].items.every(
            (i) => i.id == x.id
          )
        )
      ) {
        // All items match no need to create new sheet
        if (!skipOverviewUpdate) {
          updateDailyOverviews(
            draftState,
            job.price,
            previousDate,
            scheduledDate
          );
        }
        return;
      }
      // We should never hit this case, either some of the items are different so they will be merged
      // or they all the same so they are merged.
      // If we do somehow end up here, adding a sheet should be the correct approach
      return addSheet(
        draftState,
        job,
        scheduledDate,
        skipOverviewUpdate,
        previousDate,
        false
      );
    }
  }

  const jobCard = {
    id: job.jobSheetId || job.id,
    jobId: job.jobId,
    date: scheduledDate,
    jobRef: job.jobRef,
    enquiryId: null,
    customer: job.customerName || job.customer,
    customerType: job.customerType,
    siteAddress: job.address || job.siteAddress,
    ownerName: "",
    price: job.price,
    assigned: [],
    items: job.items.map((x) => ({ ...x, isScheduled: true })),
    contractId: job.contractId,
    contractPlots: job.contractPlots,
  };

  if (!Array.isArray(draftState.schedule[scheduledDate])) {
    draftState.schedule[scheduledDate] = [];
  }

  draftState.schedule[scheduledDate].push(jobCard);
  draftState.schedule[scheduledDate].sort((a, b) => {
    if (a.jobRef < b.jobRef) {
      return -1;
    }
    if (a.jobRef > b.jobRef) {
      return 1;
    }
    return 0;
  });

  if (!skipOverviewUpdate) {
    updateDailyOverviews(draftState, job.price, previousDate, scheduledDate);
  }
};

const shouldScheduleJob = (
  departmentsFilter,
  contractManagerFilter,
  searchFilter,
  job
) => {
  let shouldScheduleJob = true;
  if (
    departmentsFilter.length > 0 &&
    !departmentsFilter.includes(job.departmentId)
  ) {
    shouldScheduleJob = false;
  } else if (
    contractManagerFilter &&
    contractManagerFilter.value != "" &&
    job.contractManagerId.toUpperCase() !=
      contractManagerFilter.value.toUpperCase()
  ) {
    shouldScheduleJob = false;
  } else if (
    !(
      job.customerName.toLowerCase().includes(searchFilter.toLowerCase()) ||
      job.jobRef.toLowerCase().includes(searchFilter.toLowerCase()) ||
      job.address.postcode.toLowerCase().includes(searchFilter.toLowerCase())
    )
  ) {
    shouldScheduleJob = false;
  }

  return shouldScheduleJob;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GetDailyOverview: {
      const startDate = DateTime.fromISO(action.customData.startDate);
      const endDate = DateTime.fromISO(action.customData.endDate);
      const numberOfDays = endDate.diff(startDate, ["days"]).days;

      const days = range(0, numberOfDays).map((i) =>
        startDate.plus({ days: i }).toISODate()
      );

      return produce(state, (draftState) => {
        draftState.dailyOverview = requestResponse(action) ?? {};
        draftState.dailyOverviewLoading = requestIsBusy(action) ?? false;

        days.forEach((day) => {
          if (!(day in draftState.dailyOverview)) {
            draftState.dailyOverview[day] = { price: 0, jobs: 0 };
          }
        });
      });
    }

    case ActionTypes.GetDepartments: {
      const response = requestResponse(action);
      return produce(state, (draftState) => {
        draftState.departments = response?.map((d) => ({
          id: d.value,
          name: d.text,
        }));
      });
    }

    case ActionTypes.GetScheduledJobs: {
      const response = requestResponse(action);
      const daysOfWeek = range(0, 6).map((i) =>
        DateTime.fromISO(action.customData.weekCommencing)
          .plus({ days: i })
          .toISODate()
      );
      return produce(state, (draftState) => {
        draftState.schedule = {
          [UNSCHEDULED_JOBS_KEY]: draftState.schedule[UNSCHEDULED_JOBS_KEY],
        };
        daysOfWeek.forEach((day) => {
          draftState.schedule[day] = [];
        });
        response?.forEach((job) => {
          const price =
            job.contractJobTotalPrice ??
            job.items.reduce((acc, item) => acc + item.netPrice, 0);
          job.price = price;
          if (job.date in draftState.schedule) {
            draftState.schedule[job.date].push(job);
          }
        });
      });
    }

    case ActionTypes.GetUnscheduledJobs: {
      return produce(state, (draftState) => {
        draftState.schedule[UNSCHEDULED_JOBS_KEY] = requestResponse(
          action
        )?.map((job) => {
          const price =
            job.contractJobTotalPrice ??
            job.items.reduce((acc, item) => acc + item.netPrice, 0);
          return { ...job, price };
        });
      });
    }

    case ActionTypes.JobScheduled: {
      return produce(state, (draftState) => {
        const shouldSchedule = shouldScheduleJob(
          draftState.departmentsFilter,
          draftState.contractManagerFilter,
          draftState.searchFilter,
          action.data.job
        );
        if (!shouldSchedule) {
          return;
        }

        const jobDate = DateTime.fromISO(action.data.date);

        const isInSelectedWeek = draftState.selectedWeek
          ? jobDate
              .diff(DateTime.fromISO(draftState.selectedWeek), "days")
              .toObject().days < 7
          : false;
        const previousKey = action.data.previousDate ?? UNSCHEDULED_JOBS_KEY;

        const itemIds = action.data.job.items.map((i) => i.id);

        const previousJobCard =
          previousKey in draftState.schedule
            ? draftState.schedule[previousKey].find(
                (j) =>
                  (j.id === action.data.job.jobId ||
                    j.id === action.data.job.jobSheetId) &&
                  j.items.every((i) => itemIds.includes(i.id))
              )
            : null;

        const nextJobCard = draftState.schedule[action.data.date]?.find(
          (j) =>
            (j.id === action.data.job.jobId ||
              j.id === action.data.job.jobSheetId) &&
            j.items.every((i) => itemIds.includes(i.id))
        );

        const currentKey = action.data.date;
        const currentJobCard =
          currentKey in draftState.schedule
            ? draftState.schedule[currentKey].find(
                (j) =>
                  (j.id === action.data.job.jobId ||
                    j.id === action.data.job.jobSheetId) &&
                  j.items.every((i) => itemIds.includes(i.id))
              )
            : null;

        if (previousJobCard && previousJobCard.date != action.data.date) {
          scheduleJob(
            draftState,
            previousJobCard,
            previousJobCard.date ?? UNSCHEDULED_JOBS_KEY,
            action.data.date
          );
        } else if (!currentJobCard && isInSelectedWeek) {
          // inserts new job sheet
          addSheet(
            draftState,
            action.data.job,
            action.data.date,
            false,
            previousKey
          );
        } else if (!nextJobCard) {
          updateDailyOverviews(
            draftState,
            action.data.job.price,
            action.data.previousDate ?? UNSCHEDULED_JOBS_KEY,
            action.data.date
          );
        }
        if (previousJobCard) {
          previousJobCard.id = action.data.job.jobSheetId;
        }
        if (currentJobCard) {
          currentJobCard.id = action.data.job.jobSheetId;
        }
      });
    }

    case ActionTypes.JobUnscheduled: {
      return produce(state, (draftState) => {
        const previousKey = action.data.date;
        const previousJobCard =
          previousKey in draftState.schedule
            ? draftState.schedule[previousKey].find(
                (j) => j.id === action.data.oldJobSheetId
              )
            : null;

        const job = Object.values(draftState.schedule)
          .reduce((acc, item) => acc.concat(item), [])
          .find((j) => j.id === action.data.oldJobSheetId);

        if (previousJobCard) {
          if (previousJobCard.date != UNSCHEDULED_JOBS_KEY) {
            scheduleJob(draftState, job, job.date, UNSCHEDULED_JOBS_KEY);
          }
        } else if (
          !draftState.unScheduledByThisTab.includes(action.data.oldJobSheetId)
        ) {
          addSheet(
            draftState,
            action.data.job,
            UNSCHEDULED_JOBS_KEY,
            false,
            action.data.date,
            true
          );
        }
        if (job) {
          job.id = action.data.jobId;
        }
      });
    }

    case ActionTypes.JobSheetsMerged: {
      return produce(state, (draftState) => {
        const {
          sourceSheetId,
          targetSheetId,
          targetJobRef,
          sourceJobId,
          sourceSheetPrice,
        } = action.data;

        let { sourceDate, targetDate } = action.data;
        if (sourceDate == null) {
          sourceDate = UNSCHEDULED_JOBS_KEY;
        }
        if (targetDate == null) {
          targetDate = UNSCHEDULED_JOBS_KEY;
        }

        if (
          draftState.mergedByThisTab.includes(
            `${sourceDate}${sourceSheetId}${targetDate}${targetSheetId}`
          )
        ) {
          return;
        }

        const sourceSheet = draftState.schedule[sourceDate]?.find(
          (j) => j.id == sourceSheetId
        );
        const destinationSheet = draftState.schedule[targetDate]?.find(
          (j) => j.id == targetSheetId
        );

        let dailyOverviewPriceAdjustment = sourceSheetPrice ?? 0;
        if (sourceSheet && destinationSheet) {
          const extraItems = sourceSheet.items.filter(
            (i) => !destinationSheet.items.some((si) => si.id === i.id)
          );

          destinationSheet.items = destinationSheet.items.concat(extraItems);

          dailyOverviewPriceAdjustment =
            extraItems?.reduce((acc, item) => (acc += item.netPrice), 0) ?? 0;
          destinationSheet.price += dailyOverviewPriceAdjustment;

          draftState.schedule[sourceDate].splice(
            draftState.schedule[sourceDate].indexOf(sourceSheet),
            1
          );
        }
        updateDailyOverviews(
          draftState,
          sourceSheetPrice ?? 0,
          sourceDate,
          targetDate,
          { merging: true, addPrice: dailyOverviewPriceAdjustment }
        );
        const mergedJobs = Object.keys(draftState.schedule).reduce(
          (acc, key) => {
            const item = draftState.schedule[key];
            acc = acc.concat(item.filter((j) => j.jobId === sourceJobId));
            return acc;
          },
          []
        );
        mergedJobs.forEach((j) => {
          j.jobRef = targetJobRef;
        });

        draftState.mergedByThisTab.push(
          `${sourceDate}${sourceSheetId}${targetDate}${targetSheetId}`
        );
      });
    }

    case ActionTypes.JobStatusUpdated: {
      return produce(state, (draftState) => {
        const cardsToUpdate = Object.values(draftState.schedule).reduce(
          (acc, val) =>
            (acc = [...acc, ...val.filter((c) => c.jobId == action.jobId)]),
          []
        );

        cardsToUpdate.forEach((card) => {
          card.jobStatusText = action.jobStatus;
        });
      });
    }

    case ActionTypes.MarkItemsAsCompleted: {
      return produce(state, (draftState) => {
        const cardsToUpdate = Object.values(draftState.schedule).reduce(
          (acc, val) =>
            (acc = [...acc, ...val.filter((c) => c.jobId == action.jobId)]),
          []
        );

        cardsToUpdate.forEach((card) => {
          card.items
            .filter((item) => action.completedIds.includes(item.id))
            .forEach((item) => {
              item.isComplete = true;
            });
        });
      });
    }

    case ActionTypes.ResourceAssigned: {
      return produce(state, (draftState) => {
        const job = draftState.schedule[action.data.date].find(
          (j) => j.id === action.data.jobSheetId
        );
        if (
          job.assigned == null ||
          !job.assigned.includes(action.data.resourceName)
        ) {
          if (job.assigned == null) {
            job.assigned = [];
          }
          job.assigned.push(action.data.resourceName);
        }
      });
    }

    case ActionTypes.ResourceUnassigned: {
      return produce(state, (draftState) => {
        const job = draftState.schedule[action.data.date].find(
          (j) => j.id === action.data.jobSheetId
        );
        if (
          job.assigned != null &&
          job.assigned.includes(action.data.resourceName)
        ) {
          job.assigned = job.assigned.filter(
            (name) => name != action.data.resourceName
          );
        }
      });
    }

    case ActionTypes.ScheduleJob: {
      return produce(state, (draftState) => {
        const job = draftState.schedule[action.previousDate].find(
          (j) => j.id === action.jobId
        );

        scheduleJob(draftState, job, action.previousDate, action.scheduledDate);
      });
    }

    case ActionTypes.SelectWeek: {
      return produce(state, (draftState) => {
        draftState.selectedWeek = action.weekCommencing;
      });
    }

    case ActionTypes.SetDepartmentFilter: {
      return produce(state, (draftState) => {
        draftState.departmentsFilter = action.filter;
      });
    }

    case ActionTypes.SetSearchFilter: {
      return produce(state, (draftState) => {
        draftState.searchFilter = action.filter;
      });
    }

    case ActionTypes.SetContractManagerFilter: {
      return produce(state, (draftState) => {
        draftState.contractManagerFilter = action.filter;
      });
    }

    case ActionTypes.MarkItemsAsUncompleted: {
      return produce(state, (draftState) => {
        const cardsToUpdate = Object.values(draftState.schedule).reduce(
          (acc, val) =>
            (acc = [...acc, ...val.filter((c) => c.jobId == action.jobId)]),
          []
        );

        cardsToUpdate.forEach((card) => {
          card.items
            .filter((item) => action.uncompletedIds.includes(item.id))
            .forEach((item) => {
              item.isComplete = false;
            });
        });
      });
    }

    case ActionTypes.MarkItemsAsInvoiceable: {
      return produce(state, (draftState) => {
        const cardsToUpdate = Object.values(draftState.schedule).reduce(
          (acc, val) =>
            (acc = [...acc, ...val.filter((c) => c.jobId == action.jobId)]),
          []
        );

        cardsToUpdate.forEach((card) => {
          card.items
            .filter((item) => action.invoiceableItemIds.includes(item.id))
            .forEach((item) => {
              item.isInvoiceable = true;
            });
        });
      });
    }

    default:
      return state;
  }
};
