import React from "react";
import { Actions, Targets } from "constants/Permissions";
import { Button } from "semantic-ui-react";
import { updateEnquiryDescription, getEnquiryDescription } from "./actions";
import { LightPanel } from "components/containers";
import {
  FormBuilder,
  Form,
  TriggerBuilder,
  useAuthClaims,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";

const EnquiryDescriptionPanel = ({ enquiryId }) => {
  const { permissions } = useAuthClaims();
  return (
    <FormBuilder
      loadAction={getEnquiryDescription}
      loadParams={{ enquiryId }}
      renderForm={(formProps, state, events) => {
        return (
          <LightPanel
            headerText="Enquiry Description"
            className="enquiry-description"
            rightHeaderContent={
              <PermissionCheck target={Targets.Enquiry} action={Actions.Edit}>
                <TriggerBuilder
                  submitAction={updateEnquiryDescription}
                  submitParams={{ data: formProps.value, enquiryId }}
                  onSubmitted={events.onRefresh}
                  loading={state.loading}
                  renderTrigger={(fireTrigger, state) => (
                    <Button
                      compact
                      content="Save"
                      onClick={fireTrigger}
                      loading={state.submitting}
                    />
                  )}
                />
              </PermissionCheck>
            }
          >
            {!claimExists(permissions, Targets.Enquiry, Actions.Edit) ? (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {formProps.value.description}
              </div>
            ) : (
              <Form {...formProps}>
                <Form.TextArea
                  field="description"
                  fluid
                  style={{ height: "100%", marginBottom: "1rem" }}
                />
              </Form>
            )}
          </LightPanel>
        );
      }}
    />
  );
};

export default EnquiryDescriptionPanel;
