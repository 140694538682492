import React, { useState } from "react";
import { getCustomerJobs } from "./actions";
import {
  ListBuilder,
  FormBuilder,
  Format,
  Form,
  useAuthClaims,
} from "@redriver/cinnamon";
import { Image, Table } from "semantic-ui-react";
import { TablePanel, HeaderBar } from "components/containers";
import { CustomerTypes } from "constants/enums";
import { useHistory } from "react-router-dom";
import { JobRoutes } from "constants/routes";
import classNames from "classnames";
import { Targets, Actions } from "constants/Permissions";
import { claimExists } from "components/auth/utils";
import { JobStatusTintMapping, JobStatusIconMapping } from "constants/mappings";

const CustomerJobs = ({ customer }) => {
  const defaultFilters = {};
  const [searchFilters, setFilters] = useState(defaultFilters);

  const history = useHistory();

  const { permissions } = useAuthClaims();

  return (
    <ListBuilder
      withTable
      loadAction={getCustomerJobs}
      loadParams={{ customerId: customer.id, searchFilters }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            onRowClick={(item) => {
              history.push(JobRoutes.Job(item.id));
            }}
            customHeader={
              <HeaderBar name="Jobs" isSearchBar>
                <FormBuilder
                  value={searchFilters}
                  onChange={setFilters}
                  renderForm={(formProps) => (
                    <Form {...formProps}>
                      <Form.Input
                        field="search"
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No jobs found"
            selectable
            customTable
          >
            <Table className="jobs-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Planned Start Date</Table.HeaderCell>
                  <Table.HeaderCell>Department</Table.HeaderCell>
                  <Table.HeaderCell>Assigned To</Table.HeaderCell>
                  <Table.HeaderCell>Completed Date</Table.HeaderCell>
                  <Table.HeaderCell>Invoiced Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tableProps.data.map((job) => {
                  let icon = JobStatusIconMapping[job.status];
                  let tint = JobStatusTintMapping[job.status];

                  const highlight =
                    job.customerType == CustomerTypes.Residential
                      ? "residential-green"
                      : "commercial-blue";
                  return (
                    <Table.Row
                      key={job.id}
                      onClick={() => {
                        if (
                          claimExists(permissions, Targets.Quote, Actions.View)
                        ) {
                          history.push(JobRoutes.Job(job.id));
                        }
                      }}
                      className={classNames(
                        highlight ? `highlight-${highlight}` : null,
                        tint ? `tint-${tint}` : null,
                        claimExists(permissions, Targets.Job, Actions.View)
                          ? "clickable"
                          : null
                      )}
                    >
                      <Table.Cell>{job.ref}</Table.Cell>
                      <Table.Cell>
                        <span className="icon-text-column">
                          <Image inline src={icon} /> {job.status}
                        </span>
                      </Table.Cell>
                      <Table.Cell>
                        <Format.Date
                          value={job.plannedStartDate}
                          format="DD MMM YYYY"
                        />
                      </Table.Cell>
                      <Table.Cell>{job.department}</Table.Cell>
                      <Table.Cell>
                        {job.contractId ? job.contractManager : job.ownerName}
                      </Table.Cell>
                      <Table.Cell>
                        <Format.Date
                          value={job.completedDate}
                          format="DD MMM YYYY"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Format.Date
                          value={job.invoicedDate}
                          format="DD MMM YYYY"
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default CustomerJobs;
