import React from "react";
import { connect } from "react-redux";
import { getAuthClaim, NavMenu } from "@redriver/cinnamon";
import { Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { SystemRoutes } from "constants/routes";
import { getInitials } from "modules/helpers";

const AccountMenu = ({ name }) => {
  return (
    <Popup
      flowing
      hoverable
      trigger={
        <NavMenu.Item position="right" className="account-item">
          <div style={{ padding: "0.25rem" }}>
            <div className="avatar-circle">
              <span className="initials">{getInitials(name)}</span>
            </div>
          </div>
        </NavMenu.Item>
      }
      position="bottom center"
    >
      <NavMenu>
        <NavMenu.Item>{name}</NavMenu.Item>
        <NavMenu.Item>
          <Link to={SystemRoutes.Account} className="dark">
            My Account
          </Link>
        </NavMenu.Item>
        <NavMenu.Item>
          <Link to={SystemRoutes.Logout} className="dark">
            Sign Out
          </Link>
        </NavMenu.Item>
      </NavMenu>
    </Popup>
  );
};

const mapStateToProps = (state) => ({
  name: getAuthClaim(state, "userName"),
});

export default connect(mapStateToProps)(AccountMenu);
