import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { EmailQuoteForm } from "components/forms";
import "react-multi-email/dist/style.css";
import {
  emailContractJobQuote,
  getContractJobQuoteEmailTemplate,
} from "./actions";
import { Menu } from "semantic-ui-react";

const EmailContractJobQuoteModal = ({
  as: As = Menu.Item,
  contractId,
  jobId,
  onSubmitted,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getContractJobQuoteEmailTemplate}
      loadParams={{ contractId, jobId }}
      loadTransform={(data) => ({
        ...data,
        toAddress: data.recipient,
      })}
      submitAction={emailContractJobQuote}
      submitParams={{ contractId, jobId }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal {...modalProps} header="Send Quote Email" submitLabel="Send">
            <EmailQuoteForm formProps={formProps} />
          </Modal>
        );
      }}
    />
  );
};

export default EmailContractJobQuoteModal;
