import { ActionMenu } from "@redriver/cinnamon";
import { ContractContactPointTypeFields } from "constants/enums";
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import EmailIcon from "assets/icons/email.svg";
import PhoneIcon from "assets/icons/phone.svg";
import classNames from "classnames";

const ContractContactPointsDropdown = ({
  contactPoints = [],
  direction = "left",
}) => (
  <ActionMenu
    trigger={
      <React.Fragment>
        <Image
          src={
            contactPoints[0].type === ContractContactPointTypeFields.Email
              ? EmailIcon
              : PhoneIcon
          }
        />
        {contactPoints[0].value}
        {contactPoints.length > 1 && <Icon name="caret down" />}
      </React.Fragment>
    }
    openOnHover={contactPoints.length > 1}
    direction={direction}
    className={classNames("compact", "contract-action-menu")}
  >
    {contactPoints.slice(1).map((item, i) => (
      <ActionMenu.Item key={i}>
        <Image
          src={
            item.type === ContractContactPointTypeFields.Email
              ? EmailIcon
              : PhoneIcon
          }
        />
        {item.value}
      </ActionMenu.Item>
    ))}
  </ActionMenu>
);

export default ContractContactPointsDropdown;
