import { Form } from "@redriver/cinnamon";
import React from "react";
import { Grid } from "semantic-ui-react";

const ContractPricingHeader = () => (
  <Grid.Row className="contract-pricing-header">
    <Grid.Column className="job-type">
      <Form.Field label="Job Type" fluid />
    </Grid.Column>
    <Grid.Column className="number-of-plots">
      <Form.Field label="No. of Plots" fluid />
    </Grid.Column>
    <Grid.Column className="invoicing">
      <Form.Field label="Invoicing" fluid />
    </Grid.Column>
  </Grid.Row>
);

export default ContractPricingHeader;
