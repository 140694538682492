import React from "react";
import { Actions, Targets } from "constants/Permissions";
import { addCustomerComment, getCustomerComments } from "./actions";
import { CommentPanel } from "components/comments";
import { ContentBuilder } from "@redriver/cinnamon";

const CustomerCommentPanel = ({ customerId }) => (
  <ContentBuilder
    loadAction={getCustomerComments}
    loadParams={{ customerId }}
    renderContent={(response, state, events) => (
      <CommentPanel
        header="Comments"
        submitAction={addCustomerComment}
        submitParams={{ customerId }}
        addPermissionTarget={Targets.CustomerComment}
        addPermissionAction={Actions.Create}
        placeholder="Add comment"
        noItemsFoundText="No comments found."
        items={response}
        onSubmitted={events.onRefresh}
        loading={state.loading}
        className={"overview-comments"}
      />
    )}
    renderLoading={false}
  />
);

export default CustomerCommentPanel;
