import React from "react";
import PropTypes from "prop-types";

class Reveal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    duration: PropTypes.number.isRequired,
  };

  static defaultProps = {
    visible: true,
    duration: 400,
  };

  constructor(props) {
    super(props);
    this.state = {
      wrapperStyle: {
        height: 0,
        opacity: 0,
      },
    };
    this.content = React.createRef();
  }

  componentDidMount() {
    if (this.props.visible) {
      this.show();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.show();
    } else if (!this.props.visible && prevProps.visible) {
      this.hide();
    }
  }

  getContentHeight() {
    const el = this.content.current;
    if (!el) return 0;
    return (
      el.offsetHeight +
      parseInt(
        window.getComputedStyle(el, null).getPropertyValue("margin-top"),
        10
      ) +
      parseInt(
        window.getComputedStyle(el, null).getPropertyValue("margin-bottom"),
        10
      )
    );
  }

  show() {
    const { duration } = this.props;
    const height = this.getContentHeight();
    this.setState({
      wrapperStyle: {
        //Changed from Cinnamon
        height: height ? height : "auto",
        opacity: 1,
        transition: `height ${
          duration * 0.5
        }ms ease 0ms, opacity ${duration}ms ease ${duration * 0.1}ms`,
      },
    });
  }

  hide() {
    const { duration } = this.props;
    this.setState({
      wrapperStyle: {
        height: 0,
        opacity: 0,
        transition: `height ${duration}ms ease ${
          duration * 0.4
        }ms, opacity ${duration}ms ease 0ms`,
      },
    });
  }

  render() {
    const { children } = this.props;
    const { wrapperStyle } = this.state;
    return (
      <div style={wrapperStyle}>
        <div ref={this.content}>{children}</div>
      </div>
    );
  }
}

export default Reveal;
