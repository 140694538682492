import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { lockOutUser } from "./actions";

const LockOutUser = ({ as: As = Button, user, onLockOut, ...props }) => (
  <ModalBuilder
    submitAction={lockOutUser}
    submitParams={user.id}
    onSubmitted={onLockOut}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Lock Out">
        Are you sure you wish to lock out {user.firstName} {user.lastName}?
      </Modal.Confirmation>
    )}
  />
);

export default LockOutUser;
