import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { JobRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { createContractJob } from "./actions";

const AddJobFromContract = ({ as: As = Button, contractId, ...props }) => {
  const history = useHistory();
  return (
    <ModalBuilder
      submitAction={createContractJob}
      submitParams={contractId}
      onSubmitted={(response) =>
        history.push(`${JobRoutes.Job(response)}?contractId=${contractId}`)
      }
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps) => (
        <Modal.Confirmation {...modalProps} header="Create Job">
          Please confirm that you would like to create a job for this contract
        </Modal.Confirmation>
      )}
    />
  );
};

export default AddJobFromContract;
