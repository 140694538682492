import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { addProductCategory } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { DepartmentsTypeAhead } from "components/forms";

const AddProductCategory = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addProductCategory}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.ProductCategory} action={Actions.Create}>
        <As onClick={showModal} compact {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Product Category">
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.Input label="Code" field="code" fluid required />
            <Form.Input label="Name" field="name" fluid required />
          </Form.Group>
          <Form.Group widths="equal">
            <DepartmentsTypeAhead
              label="Department"
              field="departmentId"
              required
              fluid
              clearable
            />
            <Form.Input label="Description" field="description" fluid />
          </Form.Group>
        </Form>
      </Modal.Add>
    )}
  />
);

export default AddProductCategory;
