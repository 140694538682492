import React, { useState } from "react";
import { ModalBuilder, Modal, Form, Format } from "@redriver/cinnamon";
import { getRepeatJobFrequencies, repeatJob } from "./actions";
import { DateTime } from "luxon";
import { RepeatJobFrequency } from "constants/enums";

const getFrequencyCalculationUnit = (frequency) => {
  switch (frequency) {
    case RepeatJobFrequency.Daily:
    case RepeatJobFrequency.Weekly:
      return "days";
    case RepeatJobFrequency.Monthly:
      return "months";
    default:
      return "days";
  }
};

const RepeatJob = ({ jobSheet, visible, onClose }) => {
  const [formData, setFormData] = useState({
    visits: [
      {
        key: 1,
        from: jobSheet.date,
        to: null,
        frequency: null,
        frequencyType: RepeatJobFrequency.Weekly,
      },
    ],
  });
  const [formValid, setFormValid] = useState(false);
  const items = jobSheet.items || [];
  const [summaryMessage, setSummaryMessage] = useState("");

  const buildSummaryMessage = (visits) => {
    let totalJobs = 0;
    visits.forEach((item) => {
      const frequencyUnit = getFrequencyCalculationUnit(item.frequencyType);
      const timespan = DateTime.fromISO(item.to).diff(
        DateTime.fromISO(item.from),
        frequencyUnit
      )[frequencyUnit];
      const visitJobs = Math.floor(
        timespan /
          (item.frequency *
            (item.frequencyType === RepeatJobFrequency.Weekly ? 7 : 1))
      );
      // include initial job on start date
      totalJobs += visitJobs + 1;
    });
    const startDate = DateTime.fromISO(visits[0].from);
    const endDate = DateTime.fromISO(visits[visits.length - 1].to);

    setSummaryMessage(
      `You are scheduling ${totalJobs} jobs from ${startDate.toFormat(
        "EEE dd LLL"
      )} to ${endDate.toFormat("EEE dd LLL")}`
    );
  };

  return (
    <ModalBuilder
      visible={visible}
      submitAction={repeatJob}
      submitParams={{
        jobId: jobSheet.jobId,
        jobSheetId: jobSheet.id,
        jobItemIds: jobSheet.items.map((x) => x.id),
        visits: formData.visits,
      }}
      onCancel={() => {
        onClose();
      }}
      onSubmitted={() => {
        onClose();
      }}
      renderModal={(modalProps, formProps) => (
        <Modal.Add
          {...modalProps}
          header="Repeat Job"
          submitLabel="Confirm"
          submitDisabled={!formValid}
        >
          <Form
            {...formProps}
            value={formData}
            onValidated={setFormValid}
            onChange={(field, data, applyChanges) => {
              setFormData(applyChanges);
              buildSummaryMessage(applyChanges(formData).visits);
            }}
            className="repeat-job-form"
          >
            <section className="spread-scope-of-work">
              <header>
                <span>Scope of work</span>
                <Format.Number value={jobSheet.price} format="GBP" />
              </header>
              {items.map((x) => (
                <div className="job-item" key={x.id}>
                  <span>
                    {x.quantity} x {x.productName}
                  </span>
                  {x.description && (
                    <span className="description"> - {x.description}</span>
                  )}
                </div>
              ))}
              {items.length == 0 && <span className="no-items">No items</span>}
            </section>

            <div className="visits">
              <Form.Array field="visits" minLength={1}>
                {({ fields, arrayIndex }) => (
                  <Form.Group widths="equal">
                    <Form.Date
                      field="from"
                      label="Start Date"
                      local
                      required
                      fluid
                      minDate={
                        arrayIndex == 0
                          ? DateTime.fromISO(jobSheet.date).toMoment()
                          : null
                      }
                      maxDate={
                        fields.to
                          ? DateTime.fromISO(fields.to).toMoment()
                          : null
                      }
                    />
                    <Form.Date
                      field="to"
                      label="Finish Date"
                      local
                      required
                      fluid
                      minDate={
                        fields.from
                          ? DateTime.fromISO(fields.from).toMoment()
                          : null
                      }
                    />
                    <Form.Numeric
                      field="frequency"
                      label="Frequency"
                      required
                      fluid
                    />
                    <Form.Dropdown
                      label="​"
                      field="frequencyType"
                      lookupAction={getRepeatJobFrequencies}
                      fluid
                    />
                    <Form.ArrayRemover icon="times" className="remove-button" />
                  </Form.Group>
                )}
              </Form.Array>
            </div>
            <Form.ArrayAdder field="visits">Add Visit</Form.ArrayAdder>
            {formValid && (
              <div className="summary-message">
                <p>{summaryMessage}</p>
              </div>
            )}
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default RepeatJob;
