import React from "react";
import { JobDetails } from "features/Jobs";
import { KpsPageContent } from "components/containers";
import { useLocation, useParams } from "react-router-dom";
import { ContractRoutes, JobRoutes } from "constants/routes";
import { Breadcrumb } from "@redriver/cinnamon";
import queryString from "query-string";
import { ContractTabKeys } from "constants";

const JobDetailsPage = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { contractId } = queryString.parse(search);

  return (
    <KpsPageContent
      renderHeader={() => (
        <Breadcrumb>
          <Breadcrumb.Item
            link={
              contractId
                ? `${ContractRoutes.Contract(contractId)}?initialTab=${
                    ContractTabKeys.JobManagement
                  }`
                : JobRoutes.Jobs
            }
            icon="chevron left"
          >
            {contractId ? "Return to Job Management" : "Return to Jobs"}
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
      className="job-details-wrapper"
      contentClassName="job-details-content-wrapper"
    >
      <JobDetails jobId={id} />
    </KpsPageContent>
  );
};

export default JobDetailsPage;
