import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/ENQUIRIES";

export const ActionTypes = {
  GetCustomerEnquiries: `${NAMESPACE}/GET_CUSTOMER_ENQUIRIES`,
};

export const getCustomerEnquiries = (
  { pagination },
  { customerId, filters: { search } }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetCustomerEnquiries,
    `customers/${customerId}/enquiries`,
    {
      search,
      pageSize,
      pageNumber,
    }
  );
};
