import React from "react";
import { Form } from "@redriver/cinnamon";
import { Button, Segment } from "semantic-ui-react";
import { CustomerTypesDropdown } from "components/forms";
import { DateTime } from "luxon";

const DashboardSharedFilters = ({
  value,
  onChange,
  children,
  extraFormContent,
  onReload,
}) => {
  const updateFilters = (field, data, applyChanges) => {
    const next = applyChanges(value);
    onChange(next);
  };

  return (
    <Segment className="dashboard-filters">
      <React.Fragment>
        <Form value={value} onChange={updateFilters}>
          <CustomerTypesDropdown
            field="customerType"
            label="Customer Type"
            width="180px"
            placeholder="Any customer type"
            clearable
          />
          <Form.Date
            field="from"
            label="From"
            local
            width="180px"
            maxDate={DateTime.fromISO(value.to).toMoment()}
          />
          <Form.Date
            field="to"
            label="To"
            local
            width="180px"
            minDate={DateTime.fromISO(value.from).toMoment()}
          />
          {extraFormContent}
          {onReload && (
            <div style={{ paddingTop: "1.5em" }}>
              <Button icon="refresh" onClick={onReload} />
            </div>
          )}
          <Form.Trigger
            action="change"
            field="customerType"
            target="from"
            value={({ fields }) => {
              const to = fields.to
                ? DateTime.fromISO(fields.to)
                : DateTime.local();
              return fields.customerType == "Contract"
                ? to.plus({ years: -1 }).toISODate()
                : fields.from;
            }}
          />
        </Form>
        {children}
      </React.Fragment>
    </Segment>
  );
};

export default DashboardSharedFilters;
