import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getAuthState } from "@redriver/cinnamon";
import { Login } from "features/System";
import { AppRoutes } from "constants/routes";
import { SplitPage } from "components/pages";

const LoginPage = ({ loggedIn, location }) => {
  if (loggedIn) {
    const nextRoute =
      location.state && location.state.nextPath
        ? location.state.nextPath
        : AppRoutes.Root;
    return <Redirect to={nextRoute} />;
  }
  return (
    <SplitPage
      className="login"
      left={null}
      right={<Login nextPath={location?.state?.nextPath?.pathName} />}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  return {
    loggedIn,
  };
};

export default connect(mapStateToProps)(LoginPage);
