import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Menu } from "semantic-ui-react";
import { deleteContractPlots } from "./actions";

const DeleteContractPlots = ({
  as: As = Menu.Item,
  contractPlotIds,
  contractId,
  onDeleted,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteContractPlots}
    submitParams={{ contractId, contractPlotIds }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Delete {...modalProps} header="Delete Plots">
        Are you sure you wish to delete the selected plots?
      </Modal.Delete>
    )}
  />
);

export default DeleteContractPlots;
