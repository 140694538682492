import React from "react";
import { getDepartments } from "./actions";
import FilterDepartments from "./FilterDepartments";
import { ListBuilder, Table } from "@redriver/cinnamon";
import { TablePanel, HeaderBar } from "components/containers";
import AddDepartment from "./AddDepartment";
import DepartmentActions from "./DepartmentActions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const ListDepartments = () => {
  return (
    <ListBuilder
      withTable
      loadAction={getDepartments}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            className="departments-table"
            customHeader={
              <HeaderBar
                name="Departments"
                rightContent={
                  <AddDepartment
                    content="Add Department"
                    onAdded={events.onRefresh}
                  />
                }
              >
                <FilterDepartments
                  value={state.filters}
                  onChange={events.onChangeFilters}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No departments found"
            selectable
          >
            <Table.Column
              width="2%"
              render={(department) => (
                <div style={{ background: department.displayColour }}> </div>
              )}
            />
            <Table.Column field="name" title="Name" />
            <Table.Column field="description" title="Description" />
            <Table.Column field="leadName" title="Lead" />
            <PermissionCheck
              target={Targets.Department}
              action={[Actions.Edit, Actions.Delete]}
              any
            >
              <Table.Column
                hoverable
                width="10%"
                align="right"
                render={(department) => (
                  <DepartmentActions
                    department={department}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default ListDepartments;
