import React from "react";
import { connect } from "react-redux";
import { getAuthState } from "@redriver/cinnamon";
import { PublicContentWrapper } from "components/containers";
import { Image } from "semantic-ui-react";
import AzureSignInImage from "assets/ms_signin_dark.svg";
import { SystemRoutes } from "constants/routes";
import queryString from "query-string";

const clientId = process.env.AZURE_AD_CLIENT_ID;
const codeChallenge = process.env.AZURE_AD_CODE_CHALLENGE;
const baseUrl = process.env.BASE_URL;

const Login = ({ loggedIn, nextPath }) => {
  const state = nextPath ? encodeURIComponent(nextPath) : null;
  let content = null;
  if (loggedIn) {
    content = (
      <p style={{ marginTop: "4rem" }}>You have been successfully signed in</p>
    );
  } else {
    const query = {
      client_id: clientId,
      response_type: "code",
      redirect_uri: baseUrl.slice(0, -1) + SystemRoutes.AzureAdSignIn,
      response_mode: "query",
      scope: "openid email offline_access",
      code_challenge: codeChallenge,
      code_challenge_method: "S256",
      state,
    };
    content = (
      <div className="login-content">
        <header className="login-header">Welcome, please sign in below</header>
        <a
          href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${queryString.stringify(
            query
          )}`}
        >
          <Image src={AzureSignInImage} />
        </a>
      </div>
    );
  }

  return <PublicContentWrapper>{content}</PublicContentWrapper>;
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  return {
    loggedIn,
  };
};

export default connect(mapStateToProps)(Login);
