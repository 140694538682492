import React from "react";
import { FormBuilder, Form, useLookupAction } from "@redriver/cinnamon";
import { Header, Button, Image } from "semantic-ui-react";
import {
  getCalendarOptions,
  getCalendarDetails,
  updateCalendarDetails,
} from "./actions";
import InfoIcon from "assets/icons/info.svg";

const CalendarConfiguration = () => {
  const [
    { loading: calendarOptionsLoading, response: calendarOptionsResponse },
  ] = useLookupAction(getCalendarOptions);

  return (
    <React.Fragment>
      <Header as="h4">Configure Visits Calendar</Header>
      <FormBuilder
        loadAction={getCalendarDetails}
        submitAction={updateCalendarDetails}
        renderForm={(formProps, state, events) => (
          <Form {...formProps} className="system-calendar-form">
            <Form.Group className="form-input">
              {!calendarOptionsLoading && calendarOptionsResponse ? (
                <Form.RadioGroup
                  label="Available Calendars"
                  field="calendarId"
                  requiredError="A calendar is required"
                  options={calendarOptionsResponse}
                  required
                />
              ) : (
                <div>
                  <p>No calendars found</p>
                </div>
              )}
              <div className="calendar-url">
                <Form.Input
                  field="calendarEmbedUrl"
                  label={
                    <label>
                      Calendar Embed URL
                      <Image
                        src={InfoIcon}
                        inline
                        title="Use HTML link from published calendar"
                      />
                    </label>
                  }
                  fluid
                  required
                />
              </div>
            </Form.Group>
            <Button
              content="Submit"
              disabled={calendarOptionsLoading}
              onClick={events.onSubmit}
              loading={state.submitting}
              type="button"
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default CalendarConfiguration;
