import React from "react";
import { KpsPageContent } from "components/containers";
import ListProducts from "features/Admin/ListProducts";

const ProductsListPage = () => (
  <KpsPageContent>
    <ListProducts />
  </KpsPageContent>
);

export default ProductsListPage;
