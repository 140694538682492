import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/LIST";

const ActionTypes = {
  GetEnquiries: `${NAMESPACE}/GET_ENQUIRIES`,
  GetSwimlaneEnquiries: (status) =>
    `${NAMESPACE}/GET_SWIMLANE_ENQUIRIES/${status}`,
};

export const getEnquiries = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetEnquiries, "enquiries", {
    pageSize,
    pageNumber,
    ...filters,
  });
};

export const getSwimlaneEnquiries = ({ pagination }, { status, ...filters }) =>
  apiGet(ActionTypes.GetSwimlaneEnquiries(status), "enquiries", {
    status,
    ...pagination,
    ...filters,
  });
