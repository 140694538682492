import { ActionButton, Format, ListBuilder } from "@redriver/cinnamon";
import CheckIcon from "assets/icons/check.svg";
import classNames from "classnames";
import { TablePanel } from "components/containers";
import { FilterBar } from "components/filters";
import { CustomerTypes } from "constants/enums";
import { EnquiryTabKeys } from "constants/index";
import { EnquiryStatusTintMapping } from "constants/mappings";
import { PreferenceKeys } from "constants/preferences";
import { EnquiryRoutes } from "constants/routes";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Image, Table } from "semantic-ui-react";
import { downloadQuote, getQuotes } from "./actions";
import QuotesFilterDisplay from "./QuotesFilterDisplay";
import QuotesFilters from "./QuotesFilters";

const ListQuotes = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <ListBuilder
      withTable
      loadAction={getQuotes}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      persistFilters={PreferenceKeys.ListQuotesFilters}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            {...tableProps}
            dataKey="id"
            emptyMessage="No quotes found"
            selectable
            customTable
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <FilterBar
                name="Quotes"
                filters={state.filters}
                onChange={events.onChangeFilters}
                display={
                  <QuotesFilterDisplay
                    filters={state.filters}
                    resetFilters={() => events.onChangeFilters({})}
                  />
                }
              >
                <QuotesFilters onChangeFilters={events.onChangeFilters} />
              </FilterBar>
            }
          >
            <Table className="quotes-table">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ref</Table.HeaderCell>
                  <Table.HeaderCell>Customer</Table.HeaderCell>
                  <Table.HeaderCell>Site Address</Table.HeaderCell>
                  <Table.HeaderCell>Latest</Table.HeaderCell>
                  <Table.HeaderCell>Department</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Created</Table.HeaderCell>
                  <Table.HeaderCell>PDF</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {tableProps.data.map((quote) => {
                  let className = null;
                  let onClick = null;
                  let tickImage = null;
                  if (quote.isCurrentQuote) {
                    className = "current-quote";
                    onClick = () => {
                      history.push(
                        EnquiryRoutes.EnquiryQuote(
                          quote.enquiryId,
                          EnquiryTabKeys.Quote
                        )
                      );
                    };
                    tickImage = <Image src={CheckIcon} />;
                  }
                  let tint = EnquiryStatusTintMapping[quote.status];
                  const highlight =
                    quote.customerType == CustomerTypes.Residential
                      ? "residential-green"
                      : "commercial-blue";
                  return (
                    <Table.Row
                      key={quote.id}
                      className={classNames(
                        highlight ? `highlight-${highlight}` : null,
                        tint ? `tint-${tint}` : null
                      )}
                    >
                      <Table.Cell onClick={onClick} className={className}>
                        {quote.ref}
                      </Table.Cell>
                      <Table.Cell>{quote.customer}</Table.Cell>
                      <Table.Cell>
                        {quote.siteAddress
                          ? quote.siteAddress.shortAddress
                          : ""}
                      </Table.Cell>
                      <Table.Cell>{tickImage}</Table.Cell>
                      <Table.Cell>{quote.department}</Table.Cell>
                      <Table.Cell>{quote.status}</Table.Cell>
                      <Table.Cell>
                        <Format.DateTime
                          value={quote.createdUtc}
                          format="DD MMM YYYY HH:mm"
                        />
                      </Table.Cell>
                      <Table.Cell className="document-download">
                        {quote.hasDocument && (
                          <ActionButton
                            type="download"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                downloadQuote({
                                  quoteId: quote.id,
                                })
                              );
                            }}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default ListQuotes;
