import React from "react";
import { Button } from "semantic-ui-react";
import { Form, ModalBuilder, Modal } from "@redriver/cinnamon";
import {
  getTemplateDocumentDetails,
  saveTemplateDocumentDetails,
} from "./actions";
import {
  DepartmentsTypeAhead,
  TemplateDocumentTypeDropdown,
} from "components/forms";

const EditTemplateDocument = ({ id, as: As = Button, onUpdated, ...props }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getTemplateDocumentDetails}
      loadParams={id}
      loadTransform={(data) => ({
        ...data,
        defaultDepartments: data.departmentIds.map((r, i) => ({
          value: r,
          text: data.departments[i],
        })),
        departmentId: data.departmentIds != null ? data.departmentIds[0] : null,
        department: data.departments != null ? data.departments[0] : null,
      })}
      submitAction={saveTemplateDocumentDetails}
      submitParams={id}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit {...modalProps} header="Edit Template Document">
            <Form {...formProps}>
              <Form.Group widths="equal">
                <TemplateDocumentTypeDropdown
                  field="type"
                  required
                  fluid
                  label="Type"
                  placeholder="Select document type"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input label="Description" field="description" fluid />
              </Form.Group>
              <Form.Group widths="equal">
                <DepartmentsTypeAhead
                  label="Department"
                  field="departmentIds"
                  fluid
                  required
                  multiple
                  clearable
                  unknownValueOptions={formProps.value.defaultDepartments}
                />
              </Form.Group>
            </Form>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EditTemplateDocument;
