import React from "react";
import { Segment } from "semantic-ui-react";
import { EnquiryStatus, getEnumAsOptionsList } from "constants/enums";
import EnquiryLane from "./EnquiryLane";

const EnquirySwimlanes = ({ filters }) => {
  const { status, ...laneFilters } = filters;
  const statuses =
    status != null && status.length > 0
      ? status
      : getEnumAsOptionsList(EnquiryStatus).map((x) => x.value);
  const flexBasis = `${100 / (statuses.length || 1)}%`;

  const statusLanes = getEnumAsOptionsList(EnquiryStatus).filter((x) =>
    statuses.includes(x.value)
  );

  return (
    <section className="swimlanes">
      <Segment className="swimlanes-headers">
        {statusLanes.map((s) => (
          <div
            key={s.value}
            style={{ flexBasis: flexBasis, paddingLeft: "0.5rem" }}
          >
            <span>{s.text}</span>
          </div>
        ))}
      </Segment>
      <div className="lanes-wrapper">
        {statusLanes.map((s) => (
          <EnquiryLane
            key={s.value}
            status={s.value}
            filters={laneFilters}
            flexBasis={flexBasis}
          />
        ))}
      </div>
    </section>
  );
};

export default EnquirySwimlanes;
