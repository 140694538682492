import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS";

export const ActionTypes = {
  GetCustomer: `${NAMESPACE}/GET_CUSTOMER`,
  EditCustomer: `${NAMESPACE}/EDIT_CUSTOMER`,
};

export const getCustomer = ({ customerId }) =>
  apiGet(ActionTypes.GetCustomer, `customers/${customerId}`);

export const editCustomer = (customer, { customerId }) => {
  return apiPut(ActionTypes.EditCustomer, `customers/${customerId}`, {
    ...customer,
  });
};
