import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ModalBuilder, Modal, Form, lookupReset } from "@redriver/cinnamon";
import { Button, Grid } from "semantic-ui-react";
import { addEnquiry } from "./actions";
import {
  TitlesDropdown,
  DepartmentsTypeAhead,
  UsersTypeAhead,
  CustomersTypeAhead,
  customersLookup,
} from "components/forms";
import { CustomerTypesLookupDropdown } from "features/Customers/CustomerTypesLookup";
import { CustomerTypes } from "constants/enums";
import { useHistory } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";

const CreateEnquiry = ({
  customerId = null,
  customerName = null,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showCreate, setShowCreate] = useState(false);
  const history = useHistory();
  const phoneOrEmailError = "Phone or Email is required";

  return (
    <ModalBuilder
      withForm
      initialData={{ customerId: customerId }}
      submitAction={addEnquiry}
      onCancel={() => {
        setShowCreate(false);
        dispatch(lookupReset(customersLookup));
      }}
      onSubmitted={(id) => {
        setShowCreate(false);
        dispatch(lookupReset(customersLookup));
        history.push(EnquiryRoutes.Enquiry(id));
      }}
      renderTrigger={(showModal) => (
        <Button compact content="Add Enquiry" onClick={showModal} {...props} />
      )}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add
            {...modalProps}
            header="Add Enquiry"
            submitLabel="Add Enquiry"
          >
            <Form {...formProps}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <CustomersTypeAhead
                      field="customerId"
                      label="Select Existing Customer"
                      required={!showCreate}
                      searchSuggestions={false}
                      placeholder="Search for customer"
                      clearable
                      disabled={showCreate || !!customerId}
                      fluid
                      unknownValueOptions={
                        customerId
                          ? [{ text: customerName, value: customerId }]
                          : []
                      }
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <DepartmentsTypeAhead
                      field="departmentId"
                      label="Department"
                      fluid
                      clearable
                      required
                      placeholder="Select department"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    {!customerId && (
                      <Button
                        size="tiny"
                        onClick={() => setShowCreate(!showCreate)}
                        style={{ position: "absolute", bottom: 0 }}
                      >
                        {showCreate
                          ? "Use existing customer"
                          : "Add new customer"}
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <UsersTypeAhead
                      field="ownerId"
                      label="Contract Manager"
                      fluid
                      clearable
                      placeholder="Select contract manager"
                    />
                  </Grid.Column>
                </Grid.Row>
                {showCreate && (
                  <React.Fragment>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <CustomerTypesLookupDropdown
                          field="customerType"
                          label="Type"
                          fluid
                          required
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.If
                          condition={({ fields }) =>
                            fields.customerType != null &&
                            fields.customerType != CustomerTypes.Residential
                          }
                        >
                          <Form.Input
                            field="customerName"
                            label="Business Name"
                            fluid
                            required
                          />
                        </Form.If>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column width={4}>
                        <TitlesDropdown
                          field="contactTitleId"
                          label="Title"
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Form.Input
                          field="contactFirstName"
                          label="First Name"
                          required={
                            formProps.value.customerType ==
                            CustomerTypes.Residential
                          }
                          fluid
                        />
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <Form.Input
                          field="contactLastName"
                          label="Last Name"
                          required={
                            formProps.value.customerType ==
                            CustomerTypes.Residential
                          }
                          fluid
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <Form.Email
                          field="contactEmail"
                          label="Email"
                          fluid
                          emailValidation={!!formProps.value.contactEmail}
                          required={!formProps.value.contactPhone}
                          requiredError={phoneOrEmailError}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Input
                          field="contactPhone"
                          label="Phone"
                          fluid
                          required={!formProps.value.contactEmail}
                          requiredError={phoneOrEmailError}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </React.Fragment>
                )}
              </Grid>
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default CreateEnquiry;
