import React from "react";
import { Grid, Button } from "semantic-ui-react";
import { Form, ModalBuilder, Modal } from "@redriver/cinnamon";
import { getUser, saveUser } from "./actions";
import { RoleDropdown } from "../lookups";

const EditUser = ({ userId, as: As = Button, onUpdated, ...props }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getUser}
      loadParams={userId}
      loadTransform={(data) => ({
        ...data,
        defaultRoles: data.roleIds.map((r, i) => ({
          value: r,
          text: data.roles[i],
        })),
        roleId: data.roleIds != null ? data.roleIds[0] : null,
        role: data.roles != null ? data.roles[0] : null,
        status: data.isDisabled || !data.isVerified ? "Inactive" : "Active",
      })}
      submitAction={saveUser}
      submitParams={userId}
      onSubmitted={onUpdated}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal {...modalProps} header="Edit User" submitLabel="Save">
            <Form {...formProps}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input label="Email" field="email" fluid readOnly />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input label="Status" field="status" fluid readOnly />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input label="First Name" field="firstName" fluid />
                    <RoleDropdown
                      field="roleIds"
                      label="Roles"
                      placeholder="Type to search roles"
                      fluid
                      required
                      unknownValueOptions={formProps.value.defaultRoles}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input label="Last Name" field="lastName" fluid />
                    <Form.Input label="People HR ID" field="peopleHrId" fluid />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Modal>
        );
      }}
    />
  );
};

export default EditUser;
