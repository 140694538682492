import React, { Fragment, useState } from "react";
import { Form, ModalBuilder, Modal, Format } from "@redriver/cinnamon";
import { DateTime } from "luxon";
import { CustomerTypes } from "constants/enums";
import {
  VATDropdown,
  DepartmentsTypeAhead,
  InvoiceTypesDropdown,
} from "components/forms";
import { InvoiceInformationModal } from "components/modals";

const InvoiceDetailsModal = ({
  header,
  row,
  loadAction,
  submitAction,
  onSubmitted,
  onCancel,
  renderTrigger,
  isEditing = false,
  jobId,
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  return (
    <ModalBuilder
      withForm
      loadAction={loadAction}
      loadParams={row.id}
      loadTransform={(data) => ({
        ...data,
        lines: data.items ?? data.lines,
        dueDate:
          data.dueDate ?? DateTime.local().plus({ days: 30 }).toISODate(),
        jobId: jobId ?? data.jobId,
      })}
      submitAction={submitAction}
      onSubmitted={onSubmitted}
      onCancel={onCancel}
      renderTrigger={renderTrigger}
      renderModal={(modalProps, formProps, state, events) => {
        const formData = formProps.value;
        return (
          <Fragment>
            <Modal
              {...modalProps}
              header={header}
              submitLabel="Save"
              size="fullscreen"
              onSubmit={() =>
                hasChanged ? setHasSubmitted(true) : events.onSubmit()
              }
              submitDisabled={formData.lines?.length === 0}
            >
              <Form
                {...formProps}
                onChange={(field, data, applyChanges) => {
                  formProps.onChange(field, data, applyChanges);
                  if (field === "lines") {
                    setHasChanged(true);
                  }
                }}
              >
                <Form.Group className="invoice-header">
                  <div className="customer-reference">
                    {isEditing ? (
                      <Fragment>
                        <label>Invoice No. {formData.reference}</label>
                        <label>{formData.customer}</label>
                      </Fragment>
                    ) : (
                      <label>
                        {formData.reference} for {formData.customer}
                      </label>
                    )}
                  </div>
                  {!formData.contractId && (
                    <div className="department">
                      <DepartmentsTypeAhead
                        label="Department"
                        field="departmentId"
                        clearable
                        unknownValueOptions={
                          formData.departmentId
                            ? [
                              {
                                text: formData.department,
                                value: formData.departmentId,
                              },
                            ]
                            : null
                        }
                        fluid
                        readOnly={formData.contractId != null}
                        renderReadOnly={({ formState }) =>
                          formState.fields.department
                        }
                      />
                    </div>
                  )}
                  {formData.customerType != CustomerTypes.Residential && (
                    <div className="order-number">
                      <Form.Input
                        label={formData.contractId ? "Site ID" : "Order Number"}
                        field="orderNumber"
                        fluid
                      />
                    </div>
                  )}
                  {formData.contractId && (
                    <Fragment>
                      <div className="same-width">
                        <Form.Input
                          label="KPS Contract Number"
                          field="kpsContractNumber"
                          fluid
                        />
                      </div>
                      <div className="same-width">
                        <Form.Input
                          label="Customer Contract Number"
                          field="customerContractNumber"
                          fluid
                        />
                      </div>
                      <div className="same-width">
                        <Form.Input
                          label="Quantity Surveyor"
                          field="quantitySurveyor"
                          fluid
                        />
                      </div>
                    </Fragment>
                  )}
                  <div className="due-date">
                    <Form.Date label="Due Date" field="dueDate" local fluid />
                  </div>
                  <div className="invoice-type">
                    <InvoiceTypesDropdown
                      field="invoiceType"
                      label="Invoice Type"
                      readOnly={!formData.jobId}
                      required
                      fluid
                    />
                  </div>
                </Form.Group>
                <div className="invoice-line-list">
                  <Form.Array field="lines" arrayKey="id">
                    {({ fields }) => (
                      <Form.Group>
                        <Form.Numeric
                          field="quantity"
                          label="QTY"
                          width={5}
                          required
                        />
                        <div className="job-field">
                          <Form.Input
                            field="productName"
                            label={
                              formData.contractId
                                ? "Job Reference"
                                : !formData.jobId
                                  ? "Job No"
                                  : "Product Name"
                            }
                            width={20}
                            required
                            readOnly={
                              fields.jobItemId != null || fields.jobId != null
                            }
                            fluid
                          />
                        </div>
                        {formData.contractId && (
                          <div className="job-field">
                            <Form.Input
                              field="jobType"
                              label="Job Type"
                              readOnly
                              renderReadOnly={({ formState }) =>
                                formState.fields.jobTypeDescription
                              }
                              fluid
                            />
                          </div>
                        )}
                        <div className="description">
                          <Form.TextArea
                            field="description"
                            label="Description"
                            rows={1}
                            fluid
                          />
                        </div>
                        <Form.Numeric
                          field="netPrice"
                          label="Net Price"
                          decimalPlaces={2}
                          formatValue
                          width={10}
                          required
                        />
                        <VATDropdown
                          field="vatRateId"
                          label="Vat Rate"
                          optionField="vatRate"
                          width={22}
                          required
                        />
                        <Form.Numeric
                          field="grossPrice"
                          label="Total"
                          decimalPlaces={2}
                          formatValue
                          readOnly
                        />
                        <Form.Trigger
                          field="netPrice"
                          event="onChange"
                          action="change"
                          value={(formState) => {
                            return formState.fields.vatRate
                              ? formState.fields.netPrice *
                              (1 + formState.fields.vatRate?.vatValue)
                              : null;
                          }}
                          target="grossPrice"
                        />
                        <Form.Trigger
                          field="vatRateId"
                          event="onChange"
                          action="change"
                          value={(formState) => {
                            return formState.fields.netPrice
                              ? formState.fields.netPrice *
                              (1 + formState.fields.vatRate?.vatValue)
                              : null;
                          }}
                          target="grossPrice"
                        />
                        <Form.ArrayRemover
                          type="button"
                          className="remove-button"
                          icon="trash"
                        />
                      </Form.Group>
                    )}
                  </Form.Array>
                  <Form.Group>
                    {formData.jobId && (
                      <Form.ArrayAdder
                        field="lines"
                        arrayKey="id"
                        onAdded={(item) => (item.quantity = 1)}
                      >
                        Add Invoice Line
                      </Form.ArrayAdder>
                    )}
                    <Form.Group className="invoice-total">
                      <span>Total Invoice</span>
                      <Format.Number
                        className="net-price"
                        value={(formData.lines || []).reduce(
                          (a, b) => a + parseFloat(b.netPrice ?? 0),
                          0
                        )}
                        format="GBP"
                      />
                      <Format.Number
                        className="vat"
                        value={(formData.lines || []).reduce(
                          (a, b) =>
                            a +
                            parseFloat(b.netPrice ?? 0) *
                            parseFloat(b.vatRate?.vatValue ?? 0),
                          0
                        )}
                        format="GBP"
                      />
                      <Format.Number
                        className="total"
                        value={(formData.lines || []).reduce(
                          (a, b) => a + parseFloat(b.grossPrice ?? 0),
                          0
                        )}
                        format="GBP"
                      />
                    </Form.Group>
                  </Form.Group>
                </div>
              </Form>
            </Modal>
            <InvoiceInformationModal
              visible={hasChanged && hasSubmitted}
              onCancel={() => {
                events.onCancel();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
              onSubmitted={() => {
                events.onSubmit();
                setHasChanged(false);
                setHasSubmitted(false);
              }}
            />
          </Fragment>
        );
      }}
    />
  );
};

export default InvoiceDetailsModal;
