import React from "react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { getTemplateDocuments } from "./actions";
import AddTemplateDocument from "./AddTemplateDocument";
import TemplateDocumentActions from "./TemplateDocumentActions";
import { TablePanel, HeaderBar } from "components/containers";
const ListTemplateDocuments = () => (
  <ListBuilder
    withTable
    loadAction={getTemplateDocuments}
    dataTransform={(data) => data.results}
    totalTransform={(data) => data.totalResults}
    initialPagination={{ pageSize: 25, pageNumber: 1 }}
    renderList={(tableProps, state, events) => (
      <React.Fragment>
        <TablePanel
          {...tableProps}
          dataKey="id"
          emptyMessage="No template documents found"
          pageSizePosition="footer"
          pageSizeOptions={[10, 25, 50, 100]}
          pagination={state.pagination}
          totalResults={state.total}
          onPaginationChange={events.onChangePagination}
          selectable
          customHeader={
            <HeaderBar
              name="Template Documents"
              rightContent={
                <PermissionCheck
                  target={Targets.TemplateDocument}
                  action={Actions.Create}
                >
                  <AddTemplateDocument
                    content="Add Template Document"
                    onAdded={events.onRefresh}
                  />
                </PermissionCheck>
              }
            ></HeaderBar>
          }
        >
          <Table.Column field="fileName" title="File Name" />
          <Table.Column field="description" title="Description" width="25%" />
          <Table.Column
            render={(document) => {
              return Array.isArray(document.departments) &&
                document.departments.length
                ? document.departments.join(", ")
                : "-";
            }}
            title="Departments"
            width="28%"
          />
          <Table.Column field="type" title="Type" width="15%" />
          <PermissionCheck
            target={Targets.TemplateDocument}
            action={[Actions.Edit, Actions.Delete]}
            any
          >
            <Table.Column component={CreatedDate} title="Created" />
            <Table.Column
              hoverable
              width="5%"
              align="right"
              render={(document) => (
                <TemplateDocumentActions
                  document={document}
                  onRefresh={events.onRefresh}
                />
              )}
            />
          </PermissionCheck>
        </TablePanel>
      </React.Fragment>
    )}
  />
);

const CreatedDate = ({ item }) => {
  return <Format.Date value={item.createdUtc} format="DD MMM YYYY" />;
};

export default ListTemplateDocuments;
