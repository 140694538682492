import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_PRODUCT_CATEGORIES";

const ActionTypes = {
  GetProductCategories: `${NAMESPACE}/GET_PRODUCT_CATEGORIES`,
  AddProductCategory: `${NAMESPACE}/ADD_PRODUCT_CATEGORY`,
  DeleteProductCategory: `${NAMESPACE}/DELETE_PRODUCT_CATEGORY`,
  UpdateProductCategory: `${NAMESPACE}/UPDATE_PRODUCT_CATEGORY`,
};

export const getProductCategories = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;

  return apiGet(ActionTypes.GetProductCategories, `product-categories`, {
    pageNumber,
    pageSize,
    search,
  });
};

export const addProductCategory = (data) =>
  apiPost(ActionTypes.AddProductCategory, `product-categories`, data);

export const deleteProductCategory = (productCategoryId) =>
  apiDelete(
    ActionTypes.DeleteProductCategory,
    `product-categories/${productCategoryId}`
  );

export const updateProductCategory = (data, productCategoryId) =>
  apiPut(
    ActionTypes.UpdateProductCategory,
    `product-categories/${productCategoryId}`,
    data
  );
