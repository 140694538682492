import React from "react";
import { Format, ListBuilder, Table } from "@redriver/cinnamon";
import { MultiPermissionCheck, PermissionCheck } from "components/auth";
import { HeaderBar, TablePanel } from "components/containers";
import { EnquiryDocumentTypesDropdown } from "components/forms";
import { Actions, Targets } from "constants/Permissions";
import { addEnquiryDocument, getEnquiryDocuments } from "./actions";
import EnquiryDocumentTableActions from "./EnquiryDocumentTableActions";
import { AddDocumentsModal } from "components/modals";

const CreatedAt = ({ item }) => {
  return <Format.DateTime value={item.createdUtc} format="DD/MM/YY HH:mm" />;
};

const EnquiryDocumentsPanel = ({ enquiryId }) => {
  return (
    <ListBuilder
      withTable
      loadAction={getEnquiryDocuments}
      loadParams={{ enquiryId }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name="Documents"
                rightContent={
                  <PermissionCheck
                    target={Targets.EnquiryDocument}
                    action={Actions.Create}
                  >
                    <AddDocumentsModal
                      submitAction={addEnquiryDocument}
                      submitParams={{ enquiryId }}
                      onAdded={events.onRefresh}
                      documentTypeDropdown={
                        <EnquiryDocumentTypesDropdown
                          field="type"
                          label="Type"
                          placeholder="Select document type"
                          required
                          fluid
                        />
                      }
                    />
                  </PermissionCheck>
                }
              ></HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No documents found"
          >
            <Table.Column field="fileName" title="File Name" />
            <Table.Column field="description" title="Description" width="40%" />
            <Table.Column field="typeDescription" title="Type" />
            <Table.Column field="createdBy" title="Created By" />
            <Table.Column title="Created At" component={CreatedAt} />
            <MultiPermissionCheck
              checks={[
                { target: Targets.EnquiryDocument, action: Actions.View },
                { target: Targets.EnquiryDocument, action: Actions.Delete },
              ]}
            >
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <EnquiryDocumentTableActions
                    document={item}
                    enquiryId={enquiryId}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </MultiPermissionCheck>
          </TablePanel>
        );
      }}
    />
  );
};

export default EnquiryDocumentsPanel;
