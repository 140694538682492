import React, { useState } from "react";
import { getProducts } from "./actions";
import {
  ListBuilder,
  Table,
  Format,
  FormBuilder,
  Form,
} from "@redriver/cinnamon";
import { TablePanel, HeaderBar } from "components/containers";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import AddProduct from "./AddProduct";
import ProductActions from "./ProductActions";
import ProductsFilters, { FieldNames } from "./ProductFilters";
import ProductsFilterDisplay from "./ProductsFilterDisplay";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";

const ListProducts = () => {
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [filters, setFilters] = useState({});
  const resetFilters = () => setFilters({});
  return (
    <ListBuilder
      withTable
      loadAction={getProducts}
      loadParams={filters}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <FormBuilder
                value={filters}
                onChange={(x) => setFilters(x)}
                renderForm={(formProps) => (
                  <HeaderBar
                    isSearchBar
                    name="Products"
                    rightContent={
                      <div className="header-filter-right">
                        <AddProduct
                          content="Add Product"
                          onAdded={events.onRefresh}
                        />
                        <ProductsFilters
                          filters={filters}
                          formProps={formProps}
                          resetFilters={resetFilters}
                        />
                      </div>
                    }
                  >
                    <Form {...formProps}>
                      <Form.Input
                        field={FieldNames.Search}
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                    <ProductsFilterDisplay
                      filters={filters}
                      resetFilters={resetFilters}
                    />
                  </HeaderBar>
                )}
              />
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No products found"
            selectable
          >
            <Table.Column field="name" title="Name" width="20%" />
            <Table.Column field="description" title="Description" width="30%" />
            <Table.Column field="categoryName" title="Category" />
            <Table.Column field="departmentName" title="Department" />
            <Table.Column
              field="cost"
              title="Cost"
              render={(product) => (
                <Format.Number value={product.cost} format="GBP" />
              )}
            />
            <Table.Column
              field="price"
              title="Price"
              render={(product) => (
                <Format.Number value={product.price} format="GBP" />
              )}
            />
            <Table.Column field="unit" title="Unit" />
            <PermissionCheck
              target={Targets.Product}
              action={[Actions.Edit, Actions.Delete]}
              any
            >
              <Table.Column
                hoverable
                width="10%"
                align="right"
                render={(product) => (
                  <ProductActions
                    product={product}
                    onEdit={setEditing}
                    onDelete={setDeleting}
                  />
                )}
              />
            </PermissionCheck>
          </TablePanel>
          {editing && (
            <EditProduct
              product={editing}
              onCancel={() => setEditing(null)}
              onEdited={() => {
                setEditing(null);
                events.onRefresh();
              }}
              visible={editing}
            />
          )}
          {deleting && (
            <DeleteProduct
              product={deleting}
              onCancel={() => setDeleting(null)}
              onDeleted={() => {
                setDeleting(null);
                events.onRefresh();
              }}
              visible={deleting}
            />
          )}
        </React.Fragment>
      )}
    />
  );
};

export default ListProducts;
