import React from "react";
import { KpsPageContent } from "components/containers";
import { ListEnquiries } from "features/Enquiries";

const ListEnquiriesPage = () => (
  <KpsPageContent>
    <ListEnquiries />
  </KpsPageContent>
);

export default ListEnquiriesPage;
