import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { QuoteItemForm } from "components/enquiries";
import { addJobItem } from "./actions";
import { ZeroRateVatId } from "constants/vatRates";
import ContractJobItemForm from "./ContractJobItemForm";

const AddJobItemModal = ({ as: As = Button, job, onSubmitted, ...props }) => {
  return (
    <ModalBuilder
      withForm
      initialData={{
        quantity: 1,
        ...(job.hasContractPlot && { vatRateId: ZeroRateVatId }),
      }}
      submitAction={addJobItem}
      submitParams={{ jobId: job.id }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add {...modalProps} header="Add Job Item" size="large">
            <div className={"sow-item-modal"}>
              {job.enquiryId ? (
                <QuoteItemForm {...formProps} financialsRequired />
              ) : (
                <ContractJobItemForm {...formProps} job={job} />
              )}
            </div>
          </Modal.Add>
        );
      }}
    />
  );
};

export default AddJobItemModal;
