import React from "react";
import { ContentPanel, LightPanel } from "components/containers";
import { ListBuilder } from "@redriver/cinnamon";
import {
  getOpenCustomerEnquiries,
  getOpenCustomerQuotes,
  getOpenCustomerJobs,
} from "./actions";
import classNames from "classnames";
import CustomerCommentPanel from "./CustomerCommentPanel";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { JobStatus, EnquiryStatus } from "constants/enums";

const getEnquiryClassNamesFromStatus = (status) => {
  switch (status) {
    case EnquiryStatus.Open:
      return ["highlight-red", "tint-red"];
    case EnquiryStatus.InProgress.replace(" ", ""):
      return ["highlight-orange", "tint-orange"];
    case EnquiryStatus.Quoted:
      return ["highlight-green", "tint-green"];
    case EnquiryStatus.Converted:
      return ["highlight-blue", "tint-blue"];
    default:
      return [];
  }
};

const getJobClassNamesFromStatus = (status) => {
  switch (status) {
    case JobStatus.ToBeScheduled:
      return ["highlight-red", "tint-red"];
    case JobStatus.Pending:
      return ["highlight-blue", "tint-blue"];
    case JobStatus.Scheduled:
      return ["highlight-orange", "tint-orange"];
    case JobStatus.Invoiced:
      return ["highlight-light-blue", "tint-light-blue"];
    default:
      return [];
  }
};

const getQuoteClassNamesFromStatus = (status) => {
  switch (status) {
    case false:
      return ["highlight-red", "tint-red"];
    case true:
      return ["highlight-green", "tint-green"];
    default:
      return [];
  }
};

const CustomerDashboard = ({ customer }) => {
  const customerId = customer ? customer.id : null;
  return (
    <section style={{ display: "flex" }}>
      <div style={{ flexGrow: 1, marginRight: "0.5rem" }}>
        <PermissionCheck target={Targets.Customer} action={Actions.View}>
          <LightPanel className="overview" headerText="Open Enquiries">
            <ListBuilder
              loadAction={getOpenCustomerEnquiries}
              loadParams={{ customerId }}
              renderList={(data) =>
                data.map((enquiry) => {
                  const computedClassNames = getEnquiryClassNamesFromStatus(
                    enquiry.status
                  );
                  return (
                    <ContentPanel
                      key={enquiry.ref}
                      className={classNames(
                        "tight",
                        "overview-record",
                        ...computedClassNames
                      )}
                    >
                      {enquiry.ref}
                    </ContentPanel>
                  );
                })
              }
            />
          </LightPanel>
        </PermissionCheck>
        <PermissionCheck target={Targets.Customer} action={Actions.View}>
          <LightPanel className="overview" headerText="Open Quotes">
            <ListBuilder
              loadAction={getOpenCustomerQuotes}
              loadParams={{ customerId }}
              renderList={(data) =>
                data.map((quote) => {
                  const computedClassNames = getQuoteClassNamesFromStatus(
                    quote.isCurrentQuote
                  );
                  return (
                    <ContentPanel
                      key={quote.reference}
                      className={classNames(
                        "tight",
                        "overview-record",
                        ...computedClassNames
                      )}
                    >
                      {quote.reference}
                    </ContentPanel>
                  );
                })
              }
            />
          </LightPanel>
        </PermissionCheck>
      </div>
      <div style={{ flexGrow: 1, marginLeft: "0.5rem" }}>
        <PermissionCheck target={Targets.Customer} action={Actions.View}>
          <LightPanel className="overview" headerText="Open Jobs">
            <ListBuilder
              loadAction={getOpenCustomerJobs}
              loadParams={{ customerId }}
              renderList={(data) =>
                data.map((job) => {
                  const computedClassNames = getJobClassNamesFromStatus(
                    job.status
                  );
                  return (
                    <ContentPanel
                      key={job.ref}
                      className={classNames(
                        "tight",
                        "overview-record",
                        ...computedClassNames
                      )}
                    >
                      {job.ref}
                    </ContentPanel>
                  );
                })
              }
            />
          </LightPanel>
        </PermissionCheck>

        <PermissionCheck target={Targets.CustomerComment} action={Actions.View}>
          <CustomerCommentPanel customerId={customerId} />
        </PermissionCheck>
      </div>
    </section>
  );
};

export default CustomerDashboard;
