import { useCallback, useEffect } from "react";

const useSignalRCallback = (
  hubConnection,
  methodName,
  callback,
  dependencies
) => {
  const cb = useCallback(callback, [callback, dependencies]);

  useEffect(() => {
    const wrappedCallback = (...args) => {
      try {
        cb(...args);
      } catch (error) {
        console.error(error);
      }
    };

    if (hubConnection) {
      hubConnection.on(methodName, wrappedCallback);
    }
    return () => {
      if (hubConnection) {
        hubConnection.off(methodName, wrappedCallback);
      }
    };
  }, [hubConnection, methodName, cb]);
};

export default useSignalRCallback;
