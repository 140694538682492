import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { editMobileUser } from "./actions";

const EditMobileUser = ({
  mobileUser,
  onRefresh,
  as: As = Button,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      initialData={{ username: mobileUser.username, notes: mobileUser.notes }}
      renderTrigger={(open) => (
        <As {...props} onClick={open}>
          Edit User
        </As>
      )}
      submitAction={editMobileUser}
      submitParams={mobileUser.id}
      onSubmitted={onRefresh}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Mobile User">
          <Form {...formProps}>
            <Form.Group widths={2}>
              <Form.Input field="username" label="Username" required fluid />
            </Form.Group>
            <Form.TextArea field="notes" label="Assigned Device" fluid />
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditMobileUser;
