import LocationIcon from "assets/icons/location.svg";
import EmailIcon from "assets/icons/email.svg";
import PhoneIcon from "assets/icons/phone.svg";
import MobileIcon from "assets/icons/mobile.svg";
import { BannerPanel } from "components/containers";
import { FormatAddress } from "components/display";
import React from "react";
import { Button, Image } from "semantic-ui-react";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import EditCustomer from "./EditCustomer";
import { CustomerTypes } from "constants/enums";
import { Loadable } from "@redriver/cinnamon";
import ResidentialGreen from "assets/icons/residential-solid-green.svg";
import CommercialBlue from "assets/icons/commercial-solid-blue.svg";
import ContractRed from "assets/icons/contract-solid-red.svg";

const CustomerBanner = ({ customer, onRefresh, loading }) => {
  const customerIcon =
    customer.type == CustomerTypes.Commercial
      ? CommercialBlue
      : customer.type === CustomerTypes.Contract
      ? ContractRed
      : ResidentialGreen;

  return (
    <Loadable loading={loading}>
      <BannerPanel
        title={
          customer && (
            <React.Fragment>
              <Image
                src={customerIcon}
                style={{ width: 20 }}
                spaced="right"
                verticalAlign="middle"
              />
              <span style={{ verticalAlign: "middle" }}>
                {customer.displayName}{" "}
                {<span style={{ marginLeft: "1rem" }}>({customer.ref})</span>}{" "}
              </span>
            </React.Fragment>
          )
        }
        infoItemList={[
          {
            icon: LocationIcon,
            text: customer.address ? FormatAddress(customer.address) : null,
            tooltip: "Address",
          },
          {
            icon: EmailIcon,
            text: customer.emailAddress,
            tooltip: "Email",
          },
          {
            icon: PhoneIcon,
            text: customer.phoneNumber,
            tooltip: "Phone Number",
          },
          {
            icon: MobileIcon,
            text: customer.mobileNumber,
            tooltip: "Mobile Number",
          },
        ]}
        rightContent={
          <PermissionCheck target={Targets.Customer} action={Actions.Edit}>
            <EditCustomer
              customerId={customer.id}
              customer={customer}
              onRefresh={onRefresh}
              renderTrigger={(showModal) => (
                <Button
                  primary
                  onClick={showModal}
                  className="compact"
                  icon="pencil"
                />
              )}
            />
          </PermissionCheck>
        }
      />
    </Loadable>
  );
};

export default CustomerBanner;
