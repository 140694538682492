import React from "react";
import { ActionBar, Form, FormBuilder } from "@redriver/cinnamon";

const FilterBar = ({
  filters,
  onChange,
  name,
  display,
  rightContent,
  children,
}) => {
  const rightItems = Array.isArray(rightContent)
    ? rightContent
    : rightContent != null
    ? [rightContent]
    : [];

  return (
    <FormBuilder
      value={filters}
      onChange={onChange}
      renderForm={(formProps) => (
        <Form {...formProps}>
          <ActionBar className="filter-bar">
            <ActionBar.Item className="filter-bar-title">{name}</ActionBar.Item>
            <ActionBar.Item className="search-filter-display">
              <Form.Input field="search" placeholder="Search" icon="search" />
              {display}
            </ActionBar.Item>
            <ActionBar.Space />
            <ActionBar.Item className="right-content" align="right" fluid>
              {rightItems.map((item, i) => (
                <React.Fragment key={i}>{item}</React.Fragment>
              ))}
              <ActionBar.Item>{children}</ActionBar.Item>
            </ActionBar.Item>
          </ActionBar>
        </Form>
      )}
    />
  );
};

export default FilterBar;
