import React, { useEffect, useState, useMemo } from "react";
import { Input } from "semantic-ui-react";

import { Format, LookupTypeAhead } from "@redriver/cinnamon";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, Tooltip, XAxis, Rectangle, Label } from "recharts";
import classNames from "classnames";
import { range } from "modules/helpers";

import { getJobSchedulingState } from "./selectors";
import { WeekOverviewFilterTypes } from "./constants";
import {
  getDailyOverview,
  selectWeek,
  setSearchFilter,
  setContractManagerFilter,
} from "./actions";
import { SlideSelector } from "components/controls";
import { Droppable } from "@hello-pangea/dnd";
import { getUsers } from "components/forms/actions";

const weekWidth = 200;
const numberOfWeeksInThePastToDisplay = 12; // Show three months in the past
const numberOfWeeksInTheFutureToDisplay = 52; // Show next twelve months

const WeekSelector = () => {
  const [viewOffest, setViewOffest] = useState(numberOfWeeksInThePastToDisplay);
  const [filterType, setFilterType] = useState(WeekOverviewFilterTypes.Jobs);
  const dispatch = useDispatch();

  const departmentsFilter = useSelector(
    getJobSchedulingState
  ).departmentsFilter;
  const searchFilter = useSelector(getJobSchedulingState).searchFilter;
  const contractManagerFilter = useSelector(
    getJobSchedulingState
  ).contractManagerFilter;
  const dailyOverviewLoading = useSelector(
    getJobSchedulingState
  ).dailyOverviewLoading;
  const selectedWeek = useSelector(getJobSchedulingState).selectedWeek;
  const [numberOfWeeksToDisplay, setNumberOfWeeksToDisplay] = useState(5);
  const weeks = useMemo(
    () =>
      range(
        -numberOfWeeksInThePastToDisplay,
        numberOfWeeksInTheFutureToDisplay
      ).map((weekOffest) =>
        DateTime.local()
          .startOf("week")
          .plus({ weeks: weekOffest })
          .startOf("week")
      ),
    []
  );

  useEffect(() => {
    dispatch(
      getDailyOverview(
        weeks[0].toISODate(),
        weeks[weeks.length - 1].endOf("week").toISODate(),
        departmentsFilter,
        searchFilter,
        contractManagerFilter.value
      )
    );
  }, [
    dispatch,
    departmentsFilter,
    searchFilter,
    contractManagerFilter.value,
    weeks,
  ]);

  const increaseViewOffset = () => {
    if (viewOffest === weeks.length - numberOfWeeksToDisplay) {
      // Can't show anymore
      return;
    }
    setViewOffest(viewOffest + 1);
  };

  const decreaseViewOffset = () => {
    if (viewOffest === 0) {
      // Can't show any more
      return;
    }
    setViewOffest(viewOffest - 1);
  };

  return (
    <SlideSelector
      title="Schedule"
      className="week-selector"
      items={weeks.slice(viewOffest, viewOffest + numberOfWeeksToDisplay)}
      movePrevious={() => decreaseViewOffset()}
      moveNext={() => increaseViewOffset()}
      onWidthChanged={(w) => {
        const weeks = Math.floor(w / weekWidth);
        setNumberOfWeeksToDisplay(weeks);
      }}
      renderItem={(w, i) => {
        const isSelected = selectedWeek == w.toISODate();
        const weekDisplay = (
          <Week
            weekStart={w}
            dataType={filterType}
            key={"week-" + w.toISODate()}
            onClick={() => dispatch(selectWeek(w.toISODate()))}
            weekPosition={i}
            isSelected={isSelected}
          />
        );
        return isSelected ? (
          weekDisplay
        ) : (
          <Droppable droppableId={w.toISODate()} key={"week-" + w.toISODate()}>
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {weekDisplay}
              </div>
            )}
          </Droppable>
        );
      }}
      loading={dailyOverviewLoading}
      headerContent={
        <React.Fragment>
          <Input
            defaultValue={searchFilter}
            placeholder="Search jobs..."
            onChange={(_, { value }) => dispatch(setSearchFilter(value))}
            className="job-search"
          />
          <LookupTypeAhead
            placeholder="Select contract manager"
            onChange={(e, { value }) => {
              dispatch(
                setContractManagerFilter({
                  value: value,
                  text: e.target.textContent,
                })
              );
            }}
            value={contractManagerFilter.value}
            unknownValueOptions={[contractManagerFilter]}
            clearable
            lookupAction={getUsers}
            selection
          />
          <WeekOverviewFilter
            setFilterType={setFilterType}
            currentFilterType={filterType}
          />
        </React.Fragment>
      }
    />
  );
};

const WeekOverviewFilter = ({ setFilterType, currentFilterType }) => (
  <div className="overview-type-filter">
    <label>Display</label>
    {Object.keys(WeekOverviewFilterTypes).map((type) => (
      <div
        className={
          currentFilterType == WeekOverviewFilterTypes[type] ? "active" : null
        }
        onClick={() => setFilterType(WeekOverviewFilterTypes[type])}
        key={type}
      >
        {type}
      </div>
    ))}
  </div>
);

const getDailyOverviewWithCutOff = (overview) => {
  const MAX_DAILY_JOBS = 3;
  const MAX_DAILY_PRICE = 500;

  return {
    jobs: overview.jobs > MAX_DAILY_JOBS ? MAX_DAILY_JOBS : overview.jobs,
    price: overview.price > MAX_DAILY_PRICE ? MAX_DAILY_PRICE : overview.price,
    jobsExcess:
      overview.jobs > MAX_DAILY_JOBS ? overview.jobs - MAX_DAILY_JOBS : 0,
    priceExcess:
      overview.price > MAX_DAILY_PRICE ? overview.price - MAX_DAILY_PRICE : 0,
  };
};

const Week = ({ weekStart, dataType, onClick, weekPosition, isSelected }) => {
  const daysOfWeek = range(0, 6).map((i) => weekStart.plus({ days: i }));

  const dailyOverview = useSelector(getJobSchedulingState).dailyOverview;
  const data = useMemo(
    () =>
      daysOfWeek.map((date) => {
        const isoDate = date.toISODate();
        return {
          name: date.toFormat("dd"),
          ...(dailyOverview && dailyOverview[isoDate]
            ? {
                ...getDailyOverviewWithCutOff(dailyOverview[isoDate]),
                date: isoDate,
              }
            : {
                isoDate,
                price: 0,
                jobs: 0,
                priceExcess: 0,
                jobsExcess: 0,
              }),
        };
      }),
    [dailyOverview, daysOfWeek]
  );

  const showMonthLabel = weekPosition == 0 || daysOfWeek[0].day <= 7;

  return (
    <BarChart
      width={weekWidth}
      height={120}
      data={data}
      margin={{
        top: 20,
        right: 20,
        left: 20,
        bottom: 5,
      }}
      onClick={onClick}
      className={classNames("week", isSelected ? "is-selected" : null)}
      style={{ cursor: "pointer" }}
    >
      <XAxis dataKey="name" tickLine={false} axisLine={false} tickCount={7}>
        {showMonthLabel && (
          <Label
            value={daysOfWeek[0].toFormat("LLL")}
            offset={0}
            position="insideBottomLeft"
            className="month-label"
          />
        )}
      </XAxis>
      <Bar
        dataKey={dataType}
        name={Object.keys(WeekOverviewFilterTypes).find(
          (key) => WeekOverviewFilterTypes[key] == dataType
        )}
        stackId="a"
        fill="#4ECDCD"
        isAnimationActive={false}
        background={(item) =>
          item[dataType] == 0 ? (
            <Rectangle {...item} radius={7} fill="#e2f1f4" />
          ) : null
        }
        shape={(item) => (
          <Rectangle
            {...item}
            radius={item[`${dataType}Excess`] > 0 ? [0, 0, 7, 7] : 7}
          />
        )}
      />
      <Bar
        dataKey={`${dataType}Excess`}
        name={Object.keys(WeekOverviewFilterTypes).find(
          (key) => WeekOverviewFilterTypes[key] == dataType
        )}
        stackId="a"
        fill="#EB5A46"
        isAnimationActive={false}
        shape={<Rectangle radius={[7, 7, 0, 0]} />}
      />
      <Tooltip
        content={<DailyOverviewTooltip />}
        isAnimationActive={false}
        allowEscapeViewBox={{ x: false, y: true }}
        cursor={{ fill: "transparent" }}
      />
    </BarChart>
  );
};

const DailyOverviewTooltip = ({ active, payload }) => {
  if (active && payload) {
    const jobs = payload[0].payload.jobs + payload[0].payload.jobsExcess;
    const price = payload[0].payload.price + payload[0].payload.priceExcess;
    return (
      <div className="daily-overview-tooltip">
        <h4>
          {DateTime.fromISO(payload[0].payload.date).toFormat("EEE dd LLL")}
        </h4>
        <p>
          <span className={payload[0].payload.jobsExcess > 0 ? "excess" : null}>
            {jobs} Jobs
          </span>{" "}
          •{" "}
          <span
            className={payload[0].payload.priceExcess > 0 ? "excess" : null}
          >
            <Format.Number value={price} format="GBP" />
          </span>
        </p>
      </div>
    );
  }

  return null;
};

export default WeekSelector;
