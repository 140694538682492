import React from "react";
import { KpsPageContent } from "components/containers";
import { ListProductCategories } from "features/Admin";

const ProductCategoriesListPage = () => (
  <KpsPageContent>
    <ListProductCategories />
  </KpsPageContent>
);

export default ProductCategoriesListPage;
