import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { MobileUserRoutes } from "constants/routes";
import MobileUserListPage from "./MobileUserListPage";

const MobileUserRouter = () => (
  <Switch>
    <Route
      exact
      path={MobileUserRoutes.MobileUsers}
      component={MobileUserListPage}
    />
    <Route render={() => <Redirect to={MobileUserRoutes.MobileUsers} />} />
  </Switch>
);

export default MobileUserRouter;
