import React from "react";
import { TriggerBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { completeContractJobs } from "./actions";

const CompleteSelectedJobs = ({
  as: As = Menu.Item,
  contractId,
  selectedIds,
  onSubmitted,
  ...props
}) => (
  <TriggerBuilder
    submitAction={completeContractJobs}
    submitParams={{ contractId, selectedIds }}
    onSubmitted={onSubmitted}
    renderTrigger={(fireTrigger) => <As onClick={fireTrigger} {...props} />}
  />
);

export default CompleteSelectedJobs;
