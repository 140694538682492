import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";

const InvoiceInformationModal = ({ visible, onCancel, onSubmitted }) => (
  <ModalBuilder
    visible={visible}
    onCancel={onCancel}
    onSubmitted={onSubmitted}
    renderModal={(modalProps) => (
      <Modal.Confirmation
        {...modalProps}
        header="Invoice Information"
        submitLabel="Save"
        cancelLabel="Cancel"
      >
        Please be aware that making changes to the invoice will also update the
        job.
      </Modal.Confirmation>
    )}
  />
);

export default InvoiceInformationModal;
