import React from "react";
import { FormBuilder, Form } from "@redriver/cinnamon";
import { CustomerTypesDropdown } from "components/forms";

const FilterCustomers = ({ value, onChange }) => (
  <FormBuilder
    value={value}
    onChange={onChange}
    renderForm={(formProps) => (
      <Form {...formProps} className="customer-filters">
        <Form.Input field="search" placeholder="Search" icon="search" inline />
        <CustomerTypesDropdown
          field="customerType"
          placeholder="Any customer type"
          width="180px"
          clearable
          inline
        />
      </Form>
    )}
  />
);

export default FilterCustomers;
