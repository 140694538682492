import { Form, Lookup, useFormField } from "@redriver/cinnamon";
import React, { Fragment } from "react";
import { Button, Grid } from "semantic-ui-react";
import { getContractPricingInvoicingTypes } from "./actions";
import { ContractPricingInvoicingTypeFields } from "constants/enums";

const ContractPricingItem = ({
  field,
  label,
  hiddenInvoiceTypes = [],
  isClaimBased,
}) => {
  const [, setPricingItem] = useFormField(field);
  const averagePrice = (fields) => {
    return fields.value && fields.numberOfPlots
      ? parseFloat(fields.value) / parseFloat(fields.numberOfPlots)
      : 0;
  };
  const percentageOfPrice = (fields) => {
    return fields.percentageOfPrice && fields.value
      ? (parseFloat(fields.percentageOfPrice) / 100) * parseFloat(fields.value)
      : 0;
  };

  return (
    <Form.Object field={field}>
      {({ fields }) => {
        const hasData = Object.keys(fields).some((k) => !!fields[k]);
        return (
          <Fragment>
            <Grid.Row className="contract-pricing">
              <Grid.Column className="value">
                <Form.Numeric
                  field="value"
                  label={label}
                  decimalPlaces={2}
                  formatValue
                  subLabel="£"
                  subLabelPosition="left"
                  required={hasData}
                  fluid
                />
              </Grid.Column>
              <Grid.Column className="number-of-plots">
                <Form.Numeric
                  field="numberOfPlots"
                  required={
                    fields.invoicingType ===
                    ContractPricingInvoicingTypeFields.AveragePrice
                  }
                  inline
                  fluid
                  label={isClaimBased ? "No of Claims" : undefined}
                />
              </Grid.Column>
              <Grid.Column className="invoicing-radio-group">
                <Lookup
                  lookupAction={getContractPricingInvoicingTypes}
                  render={({ loading, response }) =>
                    !loading &&
                    response && (
                      <Form.RadioGroup
                        name={field}
                        field="invoicingType"
                        options={response.filter(
                          (x) =>
                            !Array.isArray(hiddenInvoiceTypes) ||
                            hiddenInvoiceTypes.every((y) => y != x.value)
                        )}
                        required={hasData}
                        fluid
                      />
                    )
                  }
                />
              </Grid.Column>
              <Grid.Column className="clear-btn">
                <Button
                  basic
                  color="blue"
                  onClick={() => setPricingItem(null)}
                  className="clear-contract-pricing"
                >
                  Clear
                </Button>
              </Grid.Column>
            </Grid.Row>
            {(fields.invoicingType ===
              ContractPricingInvoicingTypeFields.PricePerPlot ||
              fields.invoicingType ===
                ContractPricingInvoicingTypeFields.AveragePrice) && (
              <Grid.Row className="extra-pricing">
                <Grid.Column className="price-per-plot">
                  <Form.Numeric
                    field="pricePerPlot"
                    label={`Price per ${isClaimBased ? "Claim" : "Plot"}`}
                    decimalPlaces={2}
                    formatValue
                    subLabel="£"
                    subLabelPosition="left"
                    fluid
                    required={
                      fields.invoicingType ===
                      ContractPricingInvoicingTypeFields.AveragePrice
                    }
                  />
                  {fields.invoicingType ===
                    ContractPricingInvoicingTypeFields.AveragePrice &&
                    ["numberOfPlots", "value"].map((field) => (
                      <Form.Trigger
                        key={field}
                        field={field}
                        target="pricePerPlot"
                        action="change"
                        value={({ fields }) => averagePrice(fields)}
                      />
                    ))}
                </Grid.Column>
              </Grid.Row>
            )}
            {fields.invoicingType ===
              ContractPricingInvoicingTypeFields.PercentageOfPrice && (
              <Grid.Row className="extra-pricing">
                <Grid.Column className="price-per-plot">
                  <Form.Numeric
                    field="pricePerPlot"
                    label={`Price per ${isClaimBased ? "Claim" : "Plot"}`}
                    decimalPlaces={2}
                    formatValue
                    subLabel="£"
                    subLabelPosition="left"
                    onBlur={() =>
                      (fields.percentageOfPrice =
                        fields.pricePerPlot && fields.value
                          ? (parseFloat(fields.pricePerPlot) /
                              parseFloat(fields.value)) *
                            100
                          : 0)
                    }
                    fluid
                    required
                  />
                </Grid.Column>
                <Grid.Column className="percentage-of-price">
                  <Form.Numeric
                    field="percentageOfPrice"
                    label="% of Price"
                    decimalPlaces={2}
                    maxValue={100}
                    minValue={0}
                    formatValue
                    fluid
                    required
                  />
                </Grid.Column>
                {["percentageOfPrice", "value"].map((field) => (
                  <Form.Trigger
                    key={field}
                    field={field}
                    target="pricePerPlot"
                    action="change"
                    value={({ fields }) => percentageOfPrice(fields)}
                  />
                ))}
              </Grid.Row>
            )}
            <Form.Trigger
              field="invoicingType"
              target="pricePerPlot"
              action="change"
              event="onMatch"
              match={({ fields }) =>
                fields.invoicingType ===
                ContractPricingInvoicingTypeFields.AveragePrice
              }
              value={({ fields }) => averagePrice(fields)}
            />
            <Form.Trigger
              field="invoicingType"
              target="pricePerPlot"
              action="change"
              event="onMatch"
              match={({ fields }) =>
                fields.invoicingType ===
                ContractPricingInvoicingTypeFields.PercentageOfPrice
              }
              value={({ fields }) => percentageOfPrice(fields)}
            />
          </Fragment>
        );
      }}
    </Form.Object>
  );
};

export default ContractPricingItem;
