import React from "react";
import { Button, Image } from "semantic-ui-react";
import TileLight from "assets/icons/tile-view-white.svg";
import TileDark from "assets/icons/tile-view.svg";
import RowLight from "assets/icons/list-view-white.svg";
import RowDark from "assets/icons/list-view.svg";
import ColumnLight from "assets/icons/column-view-white.svg";
import ColumnDark from "assets/icons/column-view.svg";
import PropTypes from "prop-types";

const SplitViewButton = ({ onChange, value, useColumns = false }) => {
  return (
    <React.Fragment>
      <Button.Group className="split-view-button">
        <Button
          icon
          disabled={value == "tile"}
          title="Tile View"
          onClick={() => {
            onChange("tile");
          }}
        >
          <Image
            src={
              value == "tile"
                ? useColumns
                  ? ColumnLight
                  : TileLight
                : useColumns
                ? ColumnDark
                : TileDark
            }
          />
        </Button>
        <Button
          icon
          disabled={value == "row"}
          title="List View"
          onClick={() => {
            onChange("row");
          }}
        >
          <Image src={value == "row" ? RowLight : RowDark} />
        </Button>
      </Button.Group>
    </React.Fragment>
  );
};

SplitViewButton.propTypes = {
  value: PropTypes.oneOf(["row", "tile"]).isRequired,
  onChange: PropTypes.func.isRequired,
  useColumns: PropTypes.bool,
};

export default SplitViewButton;
