import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import React from "react";
import DeleteContractPlot from "./DeleteContractPlot";
import EditContractPlot from "./EditContractPlot";

const ContractPlotTableActions = ({
  plot,
  contractId,
  pricingType,
  onRefresh,
}) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.ContractPlot} action={Actions.View}>
      <EditContractPlot
        as={Table.MenuItem}
        content="Edit Plot"
        plot={plot}
        contractId={contractId}
        pricingType={pricingType}
        onUpdated={onRefresh}
      />
    </PermissionCheck>
    {!plot.isCompleted && !plot.isInvoiced && (
      <PermissionCheck target={Targets.ContractPlot} action={Actions.Delete}>
        <DeleteContractPlot
          as={Table.MenuItem}
          content="Delete Plot"
          disabled={!!plot.hasJob}
          plot={plot}
          contractId={contractId}
          onUpdated={onRefresh}
        />
      </PermissionCheck>
    )}
  </Table.Menu>
);

export default ContractPlotTableActions;
