import React from "react";
import { Form, Table } from "@redriver/cinnamon";
import {
  DeleteDocumentModal,
  DownloadDocumentModal,
  EditDocumentModal,
} from "components/modals";
import {
  deleteJobDocument,
  getJobDocument,
  updateJobDocument,
} from "./actions";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import { JobDocumentTypesDropdown } from "components/forms";
import { JobDocumentType } from "constants/enums";

const JobDocumentTableActions = ({
  document,
  jobId,
  onRefresh,
  jobSheetOptions,
}) => (
  <Table.Menu openOnFocus>
    <DownloadDocumentModal
      document={document}
      action={getJobDocument}
      actionParams={{ jobId }}
      as={Table.MenuItem}
    >
      Download Document
    </DownloadDocumentModal>
    {document.type !== JobDocumentType.JobSheet && (
      <PermissionCheck target={Targets.ContractJob} action={Actions.Edit}>
        <EditDocumentModal
          document={document}
          submitAction={updateJobDocument}
          submitParams={{ jobId }}
          onUpdated={onRefresh}
          documentTypeDropdown={
            <JobDocumentTypesDropdown
              field="type"
              label="Type"
              placeholder="Select document type"
              required
              fluid
            />
          }
          as={Table.MenuItem}
          extraContent={
            <Form.Group widths={2}>
              <Form.Dropdown
                field="jobSheetId"
                label="Related Visit"
                placeholder="Optionally select"
                options={jobSheetOptions}
                fluid
                clearable
              />
            </Form.Group>
          }
        >
          Edit Document
        </EditDocumentModal>
      </PermissionCheck>
    )}

    <PermissionCheck target={Targets.ContractJob} action={Actions.Edit}>
      <DeleteDocumentModal
        document={document}
        action={deleteJobDocument}
        actionParams={{ jobId }}
        onDeleted={onRefresh}
        as={Table.MenuItem}
      >
        Delete Document
      </DeleteDocumentModal>
    </PermissionCheck>
  </Table.Menu>
);

export default JobDocumentTableActions;
