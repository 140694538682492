import React, { useState } from "react";
import {
  ListBuilder,
  ContentBuilder,
  Table,
  Modal,
  ModalBuilder,
  TriggerBuilder,
  Form,
} from "@redriver/cinnamon";
import { Button, Checkbox, Segment } from "semantic-ui-react";
import classNames from "classnames";
import { DateTime } from "luxon";
import {
  getJobSheets,
  getJobSheet,
  updateJobSheetNotes,
  rescheduleVisit,
  unscheduleVisit,
  generateJobSheet,
} from "./actions";
import { SignalRHubs } from "constants/routes";
import { useSignalRCallback, useSignalRHub } from "modules/signalr";
import { Scheduling } from "constants/signalRMethods";
import { JobStatus } from "constants/enums";
import { PermissionCheck, MultiPermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const JobSchedulePanel = ({ job, onRefresh }) => {
  const [selectedSheetId, setSelectedSheetId] = useState(null);
  const [updatedJobItems, setUpdatedJobItems] = useState({
    includes: {},
    excludes: {},
  });
  const [hubConnection, invokeMethod] = useSignalRHub(SignalRHubs.Scheduling);

  const jobItemAssigned = (jobItem) => {
    setUpdatedJobItems({
      includes: {
        ...updatedJobItems.includes,
        [jobItem.jobItemId]: [
          ...(updatedJobItems.includes[jobItem.jobItemId] || []),
          jobItem.jobSheetId,
        ],
      },
      excludes: {
        ...updatedJobItems.excludes,
        [jobItem.jobItemId]: (
          updatedJobItems.excludes[jobItem.jobItemId] || []
        ).filter((sheetId) => sheetId != jobItem.jobSheetId),
      },
    });
  };

  const jobItemUnassigned = (jobItem) => {
    setUpdatedJobItems({
      excludes: {
        ...updatedJobItems.excludes,
        [jobItem.jobItemId]: [
          ...(updatedJobItems.excludes[jobItem.jobItemId] || []),
          jobItem.jobSheetId,
        ],
      },
      includes: {
        ...updatedJobItems.includes,
        [jobItem.jobItemId]: (
          updatedJobItems.includes[jobItem.jobItemId] || []
        ).filter((sheetId) => sheetId != jobItem.jobSheetId),
      },
    });
  };

  useSignalRCallback(
    hubConnection,
    Scheduling.AssignJobItemToJobSheet,
    jobItemAssigned
  );
  useSignalRCallback(
    hubConnection,
    Scheduling.UnassignJobItemToJobSheet,
    jobItemUnassigned
  );

  const editDisabled =
    job.status == JobStatus.Completed || job.status == JobStatus.Invoiced;

  return (
    <React.Fragment>
      <div className="job-sheet-visits">
        <header>
          <Segment>Visits</Segment>
        </header>
        <ListBuilder
          loadAction={getJobSheets}
          loadParams={{ jobId: job.id }}
          onLoaded={(data) => setSelectedSheetId(data[0]?.id)}
          renderList={(data, state, events) =>
            data && data.length > 0 ? (
              data.map((sheet, i) => (
                <Segment
                  key={i}
                  className={classNames(
                    "visit",
                    sheet.id == selectedSheetId ? "selected-visit" : null
                  )}
                  onClick={() => setSelectedSheetId(sheet.id)}
                >
                  <div>
                    {DateTime.fromISO(sheet.date).toLocaleString(
                      DateTime.DATE_HUGE
                    )}
                  </div>
                  <div className="visit-actions">
                    <PermissionCheck target={Targets.Job} action={Actions.Edit}>
                      <React.Fragment>
                        <ModalBuilder
                          submitParams={{
                            jobSheetId: sheet.id,
                          }}
                          onSubmitted={() => {
                            events.onRefresh();
                            onRefresh();
                          }}
                          submitAction={rescheduleVisit}
                          withForm
                          renderTrigger={(showModal) => (
                            <Button
                              compact
                              icon="pencil"
                              onClick={showModal}
                              disabled={editDisabled}
                            />
                          )}
                          renderModal={(modalProps, formProps) => (
                            <Modal.Edit
                              header="Reschedule Visit"
                              submitLabel="Reschedule"
                              {...modalProps}
                            >
                              <Form {...formProps}>
                                <Form.Date
                                  local
                                  field="date"
                                  label="New Date"
                                />
                              </Form>
                            </Modal.Edit>
                          )}
                        />
                        <ModalBuilder
                          submitParams={{
                            jobSheetId: sheet.id,
                          }}
                          onSubmitted={() => {
                            events.onRefresh();
                            onRefresh();
                          }}
                          submitAction={unscheduleVisit}
                          renderTrigger={(showModal) => (
                            <Button
                              compact
                              className="grey"
                              icon="trash"
                              onClick={showModal}
                              disabled={editDisabled}
                            />
                          )}
                          renderModal={(modalProps) => (
                            <Modal.Delete
                              header="Unschedule Visit"
                              submitLabel="Unschedule"
                              {...modalProps}
                            >
                              <p>
                                Are you sure you want to unschedule this visit?
                              </p>
                            </Modal.Delete>
                          )}
                        />
                      </React.Fragment>
                    </PermissionCheck>
                  </div>
                </Segment>
              ))
            ) : (
              <Segment>No visits yet</Segment>
            )
          }
        />
      </div>
      {selectedSheetId && (
        <ContentBuilder
          loadAction={selectedSheetId ? getJobSheet : null}
          loadParams={{ jobId: job.id, jobSheetId: selectedSheetId }}
          renderContent={(data, state, events) => {
            const items = data.items?.map((item) => ({
              ...item,
              jobSheetIds:
                item.id in updatedJobItems.includes ||
                item.id in updatedJobItems.excludes
                  ? [
                      ...item.jobSheetIds,
                      ...updatedJobItems.includes[item.id],
                    ].filter(
                      (sheetId) =>
                        !updatedJobItems.excludes[item.id].includes(sheetId)
                    )
                  : item.jobSheetIds,
            }));

            return (
              <Segment className="job-sheet-panel table-panel">
                <div className="scope-of-work">
                  <header>
                    <div>Scope Of Work</div>
                    <MultiPermissionCheck
                      checks={[
                        { target: Targets.Job, action: Actions.Edit },
                        { target: Targets.JobScheduling, action: Actions.Edit },
                      ]}
                    >
                      <TriggerBuilder
                        submitAction={generateJobSheet}
                        submitParams={{
                          jobId: job.id,
                          jobSheetId: selectedSheetId,
                        }}
                        renderTrigger={(fireTrigger, state) => (
                          <Button
                            compact
                            disabled={
                              job.status == JobStatus.Pending || editDisabled
                            }
                            onClick={fireTrigger}
                            loading={state.submitting}
                          >
                            Generate Job Sheet
                          </Button>
                        )}
                      />
                    </MultiPermissionCheck>
                  </header>
                  <div>
                    <Table data={items} dataKey="id">
                      <Table.Column
                        render={(item) => {
                          const assigned =
                            item?.jobSheetIds.includes(selectedSheetId);
                          return (
                            <Checkbox
                              className={
                                item?.jobSheetIds &&
                                item?.jobSheetIds.length > 0 &&
                                !item?.jobSheetIds.includes(selectedSheetId)
                                  ? "job-item-on-different-sheet"
                                  : null
                              }
                              checked={assigned}
                              onClick={() => {
                                invokeMethod(
                                  assigned
                                    ? Scheduling.UnassignJobItem
                                    : Scheduling.AssignJobItem,
                                  {
                                    jobSheetId: selectedSheetId,
                                    jobItemId: item.id,
                                  }
                                );
                              }}
                            />
                          );
                        }}
                      />
                      <Table.Column field="productName" title="Product" />
                      <Table.Column field="description" title="Description" />
                      <Table.Column field="quantity" title="Quantity" />
                    </Table>
                  </div>
                </div>
                <div className="team">
                  <header>Team</header>
                  {data.teamMembers && data.teamMembers.length > 0 ? (
                    <ul>
                      {data.teamMembers?.map((teamMember) => (
                        <li key={teamMember.id}>{teamMember.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No team members assigned</p>
                  )}
                </div>
                <div className="notes">
                  <header>
                    <div>Notes</div>
                    <ModalBuilder
                      initialData={{ notes: data.notes }}
                      submitAction={updateJobSheetNotes}
                      submitParams={{
                        jobId: job.id,
                        jobSheetId: selectedSheetId,
                      }}
                      onSubmitted={events.onRefresh}
                      withForm
                      renderTrigger={(showModal) => (
                        <PermissionCheck
                          target={Targets.Job}
                          action={Actions.Edit}
                        >
                          <Button
                            compact
                            icon="pencil"
                            disabled={
                              job.status == JobStatus.Pending || editDisabled
                            }
                            onClick={showModal}
                          />
                        </PermissionCheck>
                      )}
                      renderModal={(modalProps, formProps) => (
                        <Modal.Edit {...modalProps} header="Update Visit Notes">
                          <Form {...formProps}>
                            <Form.TextArea
                              field="notes"
                              label="Notes"
                              fluid
                              width="100%"
                            />
                          </Form>
                        </Modal.Edit>
                      )}
                    />
                  </header>
                  <pre>{data.notes || "No Notes have been added"}</pre>
                </div>
              </Segment>
            );
          }}
        />
      )}
    </React.Fragment>
  );
};

export default JobSchedulePanel;
