import React from "react";
import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { JobStatus } from "constants/enums";
import { convertContractJobs } from "./actions";
import { Menu } from "semantic-ui-react";

const ConvertContractJobs = ({
  as: As = Menu.Item,
  contractId,
  selectedIds,
  onSubmitted,
  ...props
}) => {
  return (
    <ModalBuilder
      submitAction={convertContractJobs}
      submitParams={{ jobIds: selectedIds, contractId }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps) => {
        return (
          <Modal.Confirmation
            {...modalProps}
            header="Ready to Schedule"
            submitLabel="Confirm"
            cancelLabel="Cancel"
          >
            Please confirm that you would like to mark the job(s) as{" '"}
            {JobStatus.ToBeScheduled}
            {"'"}
          </Modal.Confirmation>
        );
      }}
    />
  );
};

export default ConvertContractJobs;
