import React from "react";
import { EnquiryDetails } from "features/Enquiries";
import { KpsPageContent } from "components/containers";
import { useParams, useLocation } from "react-router-dom";
import { EnquiryRoutes } from "constants/routes";
import { Breadcrumb } from "@redriver/cinnamon";
import queryString from "query-string";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const EnquiryDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const query = queryString.parse(location.search);

  return (
    <KpsPageContent
      renderHeader={() => (
        <PermissionCheck target={Targets.Enquiry} action={Actions.View}>
          <Breadcrumb>
            <Breadcrumb.Item link={EnquiryRoutes.Enquiries} icon="chevron left">
              Return to Enquiries
            </Breadcrumb.Item>
          </Breadcrumb>
        </PermissionCheck>
      )}
    >
      <EnquiryDetails enquiryId={id} tab={query.tab} />
    </KpsPageContent>
  );
};

export default EnquiryDetailsPage;
