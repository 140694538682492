import { Modal, ModalBuilder, Form } from "@redriver/cinnamon";
import { MobileUserStatus } from "constants/enums";
import React from "react";
import { Button } from "semantic-ui-react";
import { getNonRegisteredTeamMembers, createMobileUser } from "./actions";

const options = Object.keys(MobileUserStatus).map((k) => ({
  text: k,
  value: k,
}));

const AddMobileUser = ({ As = Button, onRefresh, ...props }) => {
  return (
    <ModalBuilder
      withForm
      submitAction={createMobileUser}
      onSubmitted={onRefresh}
      renderTrigger={(openModal) => <As onClick={openModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="Add Mobile User">
          <Form {...formProps}>
            <Form.Group widths={2}>
              <Form.TypeAhead
                field="teamMemberId"
                label="Team Member"
                placeholder="Search..."
                lookupAction={getNonRegisteredTeamMembers}
                required
                fluid
                clearable
              />
              <Form.Dropdown
                field="status"
                label="Status"
                options={options}
                placeholder="Please select"
                required
                fluid
              />
            </Form.Group>
            <Form.Group widths={2}>
              <Form.Password
                field="password"
                label="Password"
                required
                toggleVisibility
                fluid
                minLength={8}
                minLengthError="Password must be at least 8 characters"
              />
              <Form.Password
                field="confirmPassword"
                label="Confirm Password"
                confirmField="password"
                confirmFieldError="The password does not match"
                required
                requiredError="Please confirm your password"
                toggleVisibility
                fluid
              />
            </Form.Group>
            <Form.TextArea field="notes" label="Assigned Device" fluid />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddMobileUser;
