import React from "react";
import { Form, Modal, ModalBuilder } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { setMobileUserPassword } from "./actions";

const SetMobileUserPassword = ({
  mobileUser,
  onRefresh,
  as: As = Button,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      renderTrigger={(open) => (
        <As {...props} onClick={open}>
          Set Password
        </As>
      )}
      submitAction={setMobileUserPassword}
      submitParams={mobileUser.id}
      onSubmitted={onRefresh}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Set Password">
          <Form {...formProps}>
            <Form.Group widths={2}>
              <Form.Password
                field="password"
                label="Password"
                required
                toggleVisibility
                fluid
                minLength={8}
                minLengthError="Password must be at least 8 characters"
              />
              <Form.Password
                field="confirmPassword"
                label="Confirm Password"
                confirmField="password"
                confirmFieldError="The password does not match"
                required
                requiredError="Please confirm your password"
                toggleVisibility
                fluid
              />
            </Form.Group>
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default SetMobileUserPassword;
