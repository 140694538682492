import React, { useCallback } from "react";
import DonutWidget from "./DonutWidget";
import { getContractInvoiceValueData } from "./actions";
import { colours } from "./colours";
import { buildContractTitleSuffix, buildCurrencyString } from "./helpers";
import { DashboardWidgetKeys } from "constants/index.js";

const ContractInvoiceValueWidget = ({ filters }) => {
  const baseFilters = {
    from: filters.from,
    to: filters.to,
    contractStatus: filters.contractStatus,
    customerId: filters.contractCustomer,
    contractId: filters.contractId,
    contractJobType: filters.contractJobType,
  };

  const filterMessage = buildContractTitleSuffix({
    filters,
    includeStatus: true,
    includeCustomer: true,
    includeContract: true,
  });

  const transform = useCallback(
    (data) =>
      data.map((x) => ({
        ...x,
        colour:
          x.name == "Invoiced"
            ? colours.darkBlue
            : x.name == "Completed"
            ? colours.green
            : colours.lightBlue,
      })),
    []
  );

  return (
    <DonutWidget
      id={DashboardWidgetKeys.ContractInvoiceValue}
      headerTitle={`Contracts (${filterMessage})`}
      title="Jobs"
      action={getContractInvoiceValueData}
      filterParams={baseFilters}
      transform={transform}
      renderCustomLabel={({ total }) => {
        const currencyValue = buildCurrencyString(total);
        return currencyValue;
      }}
      renderCustomLegend={(value, entry) => {
        const currencyValue = buildCurrencyString(entry.payload.value);
        return (
          <span className="legend-text">
            {value} ({currencyValue})
          </span>
        );
      }}
    />
  );
};

export default ContractInvoiceValueWidget;
