import React from "react";
import { ListCustomers } from "features/Customers";
import { KpsPageContent } from "components/containers";

const CustomerListPage = () => (
  <KpsPageContent>
    <ListCustomers />
  </KpsPageContent>
);

export default CustomerListPage;
