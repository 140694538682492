import React from "react";
import { ListReadyToInvoice } from "features/Finance";
import { KpsPageContent } from "components/containers";

const ReadyToInvoicePage = () => (
  <KpsPageContent>
    <ListReadyToInvoice />
  </KpsPageContent>
);

export default ReadyToInvoicePage;
