import React from "react";
import { ResourceScheduling } from "features/Scheduling";
import { KpsPageContent } from "components/containers";

const ResourceSchedulingPage = () => {
  return (
    <KpsPageContent className="fullscreen-page fixed">
      <ResourceScheduling />
    </KpsPageContent>
  );
};

export default ResourceSchedulingPage;
