import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { QuoteRoutes } from "constants/routes";
import QuoteListPage from "./QuoteListPage";

const QuoteRouter = () => (
  <Switch>
    <Route exact path={QuoteRoutes.Quotes} component={QuoteListPage} />
    <Route render={() => <Redirect to={QuoteRoutes.Quotes} />} />
  </Switch>
);

export default QuoteRouter;
