import { apiGet, apiPost } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";
import { DateTime } from "luxon";

const NAMESPACE = "JOBS/LIST";

const ActionTypes = {
  GetJobs: `${NAMESPACE}/GET_JOBS`,
  GetJobSheets: `${NAMESPACE}/GET_JOB_SHEETS`,
};

export const getJobs = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { startDateFromUtc, startDateToUtc, ...otherFilters } = filters;

  const startDateFrom = startDateFromUtc
    ? DateTime.fromISO(startDateFromUtc).toISODate()
    : null;

  const startDateTo = startDateToUtc
    ? DateTime.fromISO(startDateToUtc).toISODate()
    : null;

  return apiGet(ActionTypes.GetJobs, "jobs", {
    pageSize,
    pageNumber,
    startDateFrom,
    startDateTo,
    ...otherFilters,
  });
};

export const downloadJobSheets = (jobIds) => async (dispatch) => {
  const result = await dispatch(
    apiPost(ActionTypes.GetJobSheets, "jobs/job-sheet-documents", jobIds, {
      preventResponseHandling: true,
    })
  );
  if (result.success) {
    saveFile(result);
  }
  return result;
};
