import React from "react";
import { Button } from "semantic-ui-react";
import { ListBuilder, Content, TileGrid } from "@redriver/cinnamon";
import { getCustomers } from "./actions";
import CustomerTile from "./CustomerTile";

const TileCustomers = ({ filters }) => (
  <ListBuilder
    withTiles
    loadAction={getCustomers}
    loadParams={filters}
    dataTransform={(data) => data.results.filter((x) => !x.isDeleted)}
    totalTransform={(data) => data.totalResults}
    initialPagination={{ pageSize: 25, pageNumber: 1 }}
    renderList={(tileProps, state, events) => (
      <Content>
        <TileGrid
          {...tileProps}
          className="card-grid-fill"
          dataKey="id"
          wide
          render={(item) => <CustomerTile item={item} />}
        />
        {state.hasMore && (
          <Button
            onClick={events.onLoadMore}
            loading={state.loadingMore}
            disabled={state.loadingMore}
            style={{ display: "block", margin: "1em auto" }}
          >
            Show More
          </Button>
        )}
      </Content>
    )}
  />
);

export default TileCustomers;
