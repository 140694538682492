import React from "react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { QuoteItemForm } from "components/enquiries";
import { editQuoteItem } from "./actions";

const EditQuoteItemModal = ({
  enquiryId,
  visible,
  initialData,
  showModifiers,
  onCancel,
  onSubmitted,
}) => {
  return (
    <ModalBuilder
      initialData={initialData}
      visible={visible}
      onSubmitted={onSubmitted}
      onCancel={onCancel}
      submitAction={editQuoteItem}
      submitParams={{ enquiryId }}
      withForm
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Edit {...modalProps} header="Edit Quote Item" size="large">
            <div className={"sow-item-modal"}>
              <QuoteItemForm {...formProps} showJobModifiers={showModifiers} />
            </div>
          </Modal.Edit>
        );
      }}
    />
  );
};

export default EditQuoteItemModal;
