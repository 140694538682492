import React from "react";
import { TriggerBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { uncompleteContractJobs } from "./actions";

const UncompleteSelectedJobs = ({
  as: As = Menu.Item,
  contractId,
  selectedIds,
  onSubmitted,
  ...props
}) => (
  <TriggerBuilder
    submitAction={uncompleteContractJobs}
    submitParams={{ contractId, selectedIds }}
    onSubmitted={onSubmitted}
    renderTrigger={(fireTrigger) => <As onClick={fireTrigger} {...props} />}
  />
);

export default UncompleteSelectedJobs;
