import { Form, FormBuilder } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { SplitViewButton } from "components/buttons";
import { HeaderBar, LinePanel } from "components/containers";
import { ContractStatus, getEnumAsOptionsList } from "constants/enums";
import { Actions, Targets } from "constants/Permissions";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import AddContract from "./AddContract";
import ContractsFilterDisplay from "./ContractsFilterDisplay";
import ContractsFilters, { FieldNames } from "./ContractsFilters";
import ContractsTable from "./ContractsTable";
import ContractSwimlanes from "./ContractSwimlanes";

const ListContracts = () => {
  const [filters, setFilters] = useState({});
  const [view, setView] = useState("tile");
  const [updated, setUpdated] = useState(null);

  const filtersToSearch = {
    ...filters,
    status:
      !filters.status || filters.status.length == 0
        ? getEnumAsOptionsList(ContractStatus)
            .filter((x) => x.text != ContractStatus.Cancelled)
            .map((x) => x.value)
        : filters.status,
  };

  return (
    <React.Fragment>
      <LinePanel className="header">
        <FormBuilder
          value={filters}
          onChange={(f) => setFilters(f)}
          renderForm={(formProps) => (
            <HeaderBar
              name="Contracts"
              isSearchBar
              rightContent={
                <div className="right-content">
                  <PermissionCheck
                    target={Targets.Contract}
                    action={Actions.Create}
                  >
                    <AddContract
                      as={Button}
                      content="Add Contract"
                      onAdded={() => setUpdated(Date.now())}
                      className="contract-header-button"
                    />
                  </PermissionCheck>
                  <ContractsFilters
                    formProps={formProps}
                    resetFilters={() => setFilters({})}
                  />
                  <SplitViewButton
                    useColumns
                    value={view}
                    onChange={(v) => setView(v)}
                  />
                </div>
              }
            >
              <Form {...formProps}>
                <Form.Input
                  field={FieldNames.Search}
                  placeholder="Search"
                  icon="search"
                />
              </Form>
              <ContractsFilterDisplay
                filters={filters}
                resetFilters={() => setFilters({})}
              />
            </HeaderBar>
          )}
        />
      </LinePanel>
      {view === "row" ? (
        <ContractsTable filters={filtersToSearch} updated={updated} />
      ) : (
        <ContractSwimlanes filters={filtersToSearch} updated={updated} />
      )}
    </React.Fragment>
  );
};

export default ListContracts;
