import { apiGet, apiPost, apiPut, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/ADDRESSES";

export const ActionTypes = {
  GetCustomerAddresses: `${NAMESPACE}/GET_CUSTOMER_ADDRESSES`,
  AddCustomerAddress: `${NAMESPACE}/ADD_CUSTOMER_ADDRESS`,
  EditCustomerAddress: `${NAMESPACE}/EDIT_CUSTOMER_ADDRESS`,
  DeleteCustomerAddress: `${NAMESPACE}/DELETE_CUSTOMER_ADDRESS`,
};

export const getCustomerAddresses = (_, { customerId }) =>
  apiGet(ActionTypes.GetCustomerAddresses, `customers/${customerId}/addresses`);

export const addCustomerAddress = (customerAddress, { customerId }) =>
  apiPost(
    ActionTypes.AddCustomerAddress,
    `customers/${customerId}/addresses`,
    customerAddress
  );

export const editCustomerAddress = (
  customerAddress,
  { customerId, customerAddressId }
) =>
  apiPut(
    ActionTypes.EditCustomerAddress,
    `customers/${customerId}/addresses/${customerAddressId}`,
    {
      ...customerAddress,
    }
  );

export const deleteCustomerAddress = ({ customerId, addressId }) =>
  apiDelete(
    ActionTypes.DeleteCustomerAddress,
    `customers/${customerId}/addresses/${addressId}`
  );
