import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/JOBS";

const ActionTypes = {
  GetCustomerJobs: `${NAMESPACE}/GET_CUSTOMER_JOBS`,
};

export const getCustomerJobs = (
  { pagination },
  { customerId, searchFilters: { search } }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetCustomerJobs, `customers/${customerId}/jobs`, {
    search,
    pageSize,
    pageNumber,
  });
};
