import React, { useCallback, useMemo } from "react";
import { getContractsByValueData } from "./actions";
import { colours } from "./colours";
import DashboardWidgetClear from "./DashboardWidgetClear";
import BarWidget from "./BarWidget";
import { Format } from "@redriver/cinnamon";
import { buildContractTitleSuffix } from "./helpers";
import { DashboardWidgetKeys } from "constants/index.js";

const ContractsByValueWidget = ({ filters, updateFilter }) => {
  const baseFilters = useMemo(() => {
    return {
      from: filters.from,
      to: filters.to,
      status: filters.contractStatus,
      customerId: filters.contractCustomer,
      contractJobType: filters.contractJobType,
    };
  }, [filters]);

  const filterInfo = buildContractTitleSuffix({
    filters,
    includeStatus: true,
    includeCustomer: true,
  });

  const setContractFilter = useCallback(
    (contractId, displayValue) => {
      if (updateFilter) {
        updateFilter({
          name: "contractId",
          value: contractId,
          displayValue,
        });
      }
    },
    [updateFilter]
  );

  return (
    <BarWidget
      id={DashboardWidgetKeys.ContractsByValue}
      title={`Contracts by Value (${filterInfo})`}
      action={getContractsByValueData}
      filterParams={baseFilters}
      transform={(data) =>
        data.map((x) => ({ ...x, value: (x.value || 0) / 1000 }))
      }
      valueField="value"
      label="Thousands (£)"
      colours={[
        colours.darkBlue,
        colours.midBlue,
        colours.lightBlue,
        colours.slate,
        colours.darkGreen,
      ]}
      categoryField="contractRef"
      headerContent={
        filters.contractId && (
          <DashboardWidgetClear onClear={() => setContractFilter(null)} />
        )
      }
      onBarClick={(item) => {
        if (filters.contractId == item.contractId) {
          setContractFilter(null);
        } else {
          setContractFilter(item.contractId, item.contractRef);
        }
      }}
      renderCustomTooltip={(item) => (
        <React.Fragment>
          <p>{item.contractRef}</p>
          {item.kpsContractRef && <p>{item.kpsContractRef}</p>}
          <p>
            <Format.Number format="GBP" value={item.value * 1000} />
          </p>
        </React.Fragment>
      )}
      highlightValue={filters.contractId}
      highlightFieldName="contractId"
      style={{ flexBasis: "100%" }}
    />
  );
};

export default ContractsByValueWidget;
