import React, { useCallback } from "react";
import DonutWidget from "./DonutWidget";
import { getContractJobStatusData } from "./actions";
import { contractJobStatusColours } from "./colours";
import { buildContractTitleSuffix } from "./helpers";
import { DashboardWidgetKeys } from "constants/index.js";

const ContractJobStatusWidget = ({ filters }) => {
  const baseFilters = {
    from: filters.from,
    to: filters.to,
    contractStatus: filters.contractStatus,
    customerId: filters.contractCustomer,
    contractId: filters.contractId,
    contractJobType: filters.contractJobType,
  };

  const filterMessage = buildContractTitleSuffix({
    filters,
    includeStatus: true,
    includeCustomer: true,
    includeContract: true,
  });

  const transform = useCallback(
    (data) =>
      data.map((x) => ({
        ...x,
        colour: contractJobStatusColours[x.name],
      })),
    []
  );

  return (
    <DonutWidget
      id={DashboardWidgetKeys.ContractJobStatus}
      headerTitle={`Jobs (${filterMessage})`}
      title="Jobs"
      action={getContractJobStatusData}
      filterParams={baseFilters}
      transform={transform}
    />
  );
};

export default ContractJobStatusWidget;
