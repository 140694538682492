import * as Sentry from "@sentry/browser";
import {
  setApiOptions,
  enableJwtAuth,
  setLoggingOptions,
  enableSentryLogger,
  enableSegmentLogger,
  enableHotjarLogger,
  setPreferenceDefaults,
  setResponsiveOptions,
  createDefaultReduxStore,
  ApiError,
} from "@redriver/cinnamon";
import reducers from "./reducers";
import { onRefreshJwt, onDeauthenticated } from "features/System";
import { DateTime } from "luxon";
import moment from "moment"; //eslint-disable-line no-restricted-imports
import { ContentTypes, ResponseHeaders } from "constants/http";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  configureResponsive();
  configureShims();
  const store = configureStore();
  return { store };
};

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL,
    onErrorResponse: (response) => {
      return new Promise((resolve) => {
        const responseType = response.headers.get(ResponseHeaders.ContentType);
        if (responseType && responseType.includes(ContentTypes.JSON)) {
          response
            .json()
            .then((data) => resolve(new ApiError(data, response.status)))
            .catch((error) => {
              console.error(response, error);
              Sentry.captureException(error, { extra: { response } });
              resolve(new ApiError(error.message, response.status));
            });
        } else {
          response
            .text()
            .then((data) => {
              Sentry.captureMessage(data, { extra: { response } });
              resolve(new ApiError(data, response.status));
            })
            .catch((error) => {
              console.error(response, error);
              Sentry.captureException(error, { extra: { response } });
              resolve(new ApiError(error.message, response.status));
            });
        }
      });
    },
  });

const configureAuth = () =>
  enableJwtAuth({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev",
      },
      userId: {
        type: "string",
        key: "sub",
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " ",
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email",
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)",
      },
    },
    onRefreshJwt,
    onDeauthenticated,
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE,
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN, {
      tracingEnabled: true,
      sampleRate: 0.2,
    });
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
  if (process.env.HOTJAR_SITEID) {
    enableHotjarLogger(process.env.HOTJAR_SITEID);
  }
};

// preferences module

const configurePreferences = () =>
  setPreferenceDefaults({
    timezone: "Europe/London",
  });

// responsive module

const configureResponsive = () =>
  setResponsiveOptions({
    screenSizes: {
      mobile: { max: 639, className: "mobile" },
      tablet: { min: 640, max: 1067, className: "tablet" },
      desktop: { min: 1068 },
    },
  });

// redux store module

const configureStore = () =>
  createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
  });

// shims

const configureShims = () => {
  DateTime.prototype.toMoment = function () {
    return moment(this.toJSDate());
  };
};
