import {
  apiGet,
  apiPost,
  apiDelete,
  apiPut,
  lookupReset,
  refreshJwt,
} from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_ROLES";

const ActionTypes = {
  GetRoles: `${NAMESPACE}/GET_ROLES`,
  GetRole: `${NAMESPACE}/GET_ROLE`,
  AddRole: `${NAMESPACE}/ADD_ROLE`,
  UpdateRole: `${NAMESPACE}/UPDATE_ROLE`,
  DeleteRole: `${NAMESPACE}/DELETE_ROLE`,
};

export const getRoles = ({ filters, pagination }) => {
  const { pageSize, pageNumber } = pagination;
  const { search } = filters;

  return apiGet(ActionTypes.GetRoles, `roles`, {
    pageNumber,
    pageSize,
    search,
  });
};

export const getRole = (roleId) => {
  return apiGet(ActionTypes.GetRole, `roles/${roleId}`);
};

// NOTE - refresh jwt when modifying role to ensure current user has up to date permissions

export const addRole = (data) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(apiPost(ActionTypes.AddRole, "roles", data)).then((res) => {
      dispatch(lookupReset("rolesLookup"));
      resolve(res);
    });
  });
};

export const updateRole = (data, roleId) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(apiPut(ActionTypes.UpdateRole, `roles/${roleId}`, data)).then(
      (res) => {
        dispatch(refreshJwt(true));
        resolve(res);
      }
    );
  });
};

export const deleteRole = (roleId) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch(apiDelete(ActionTypes.DeleteRole, `roles/${roleId}`)).then(
      (res) => {
        dispatch(refreshJwt(true));
        resolve(res);
      }
    );
  });
};
