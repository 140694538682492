import React from "react";
import ResultsCounter from "./ResultsCounter";
import PageNumber from "./PageNumber";
import PageSize from "./PageSize";

const TablePagination = ({
  pageNumber,
  onChange,
  pageSize,
  totalItems,
  withPageSize,
  pageSizeOptions
}) => (
  <div className="table-pagination">
    <ResultsCounter
      pageNumber={pageNumber}
      pageSize={pageSize}
      totalItems={totalItems}
    />
    <PageNumber
      activePage={pageNumber}
      onChange={x =>
        onChange({
          pageNumber: x
        })
      }
      pageSize={pageSize}
      totalItems={totalItems}
    />
    {withPageSize && (
      <PageSize
        options={pageSizeOptions}
        value={pageSize}
        onChange={x =>
          onChange({
            pageSize: x,
            pageNumber: 1
          })
        }
      />
    )}
  </div>
);

export default TablePagination;
