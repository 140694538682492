import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteUser } from "./actions";

const DeleteUser = ({ as: As = Button, user, onDeleted, ...props }) => (
  <ModalBuilder
    submitAction={deleteUser}
    submitParams={user.id}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete User">
        Are you sure you wish to delete{" "}
        <b>
          {user.firstName} {user.lastName}
        </b>
        ?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteUser;
