import React from "react";
import { Table } from "@redriver/cinnamon";
import DeleteMobileUser from "./DeleteMobileUser";
import SetMobileUserPassword from "./SetMobileUserPassword";
import ChangeMobileUserStatus from "./ChangeMobileUserStatus";
import EditMobileUser from "./EditMobileUser";
import { usePermission } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const MobileUserActions = ({ mobileUser, onRefresh }) => {
  const canEdit = usePermission(Targets.MobileUsers, Actions.Edit);
  const canDelete = usePermission(Targets.MobileUsers, Actions.Delete);

  if (!canEdit && !canDelete) {
    return null;
  }

  return (
    <Table.Menu openOnFocus>
      {canEdit && (
        <React.Fragment>
          <EditMobileUser
            as={Table.MenuItem}
            mobileUser={mobileUser}
            onRefresh={onRefresh}
          />
          <SetMobileUserPassword
            as={Table.MenuItem}
            mobileUser={mobileUser}
            onRefresh={onRefresh}
          />
          <ChangeMobileUserStatus
            as={Table.MenuItem}
            mobileUser={mobileUser}
            onRefresh={onRefresh}
          />
        </React.Fragment>
      )}
      {canDelete && (
        <DeleteMobileUser
          as={Table.MenuItem}
          mobileUser={mobileUser}
          onRefresh={onRefresh}
        />
      )}
    </Table.Menu>
  );
};

export default MobileUserActions;
