import CertificationIcon from "assets/icons/certification-blue.svg";
import EmailIcon from "assets/icons/email.svg";
import HeartbeatIcon from "assets/icons/heartbeat.svg";
import LocationIcon from "assets/icons/location.svg";
import MobileIcon from "assets/icons/mobile.svg";
import PhoneIcon from "assets/icons/phone.svg";
import RealEstateIcon from "assets/icons/real-estate-blue.svg";
import UserIcon from "assets/icons/user.svg";
import UsersIcon from "assets/icons/users.svg";
import { PermissionCheck } from "components/auth";
import { BannerPanel } from "components/containers";
import { FormatAddress } from "components/display";
import { Actions, Targets } from "constants/Permissions";
import { CustomerTypes, JobStatus } from "constants/enums";
import { CustomerRoutes } from "constants/routes";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import EditJobDetailsModal from "./EditJobDetailsModal";
import JobActions from "./JobActions";
import JobCancelModal from "./JobCancelModal";
import JobChangeStatusModal from "./JobChangeStatusModal";
import JobChangeTpoStatusModal from "./JobChangeTpoStatusModal";
import JobSelectAddressModal from "./JobSelectAddressModal";

const JobBanner = ({ job, onRefresh }) => {
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const [changeTpoStatusOpen, setChangeTpoStatusOpen] = useState(false);
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);
  const [editJobDetailsOpen, setEditJobDetailsOpen] = useState(false);
  const [cancelJobOpen, setCancelJobOpen] = useState(false);

  const customerIcon =
    job.customerType == CustomerTypes.Commercial
      ? CertificationIcon
      : RealEstateIcon;

  const display = job.enquiryId
    ? ` - Order Number: ${job.orderNumber != null ? job.orderNumber : ""}`
    : ` - Site ID: ${job.contractSiteProjectName}`;

  return (
    <BannerPanel
      title={
        job && (
          <React.Fragment>
            <Image
              src={customerIcon}
              style={{ width: 20 }}
              spaced="right"
              verticalAlign="middle"
            />
            <span style={{ verticalAlign: "middle" }}>
              Job {job.ref}
              {job.repeatScheduleTotal && (
                <span>
                  {" "}
                  (Job {job.repeatScheduleIndex} of {job.repeatScheduleTotal})
                </span>
              )}{" "}
              for{" "}
              {!job.customerArchived ? (
                <PermissionCheck
                  target={Targets.Customer}
                  action={Actions.View}
                  renderFailure={() => job.customer || ""}
                >
                  <Link
                    to={CustomerRoutes.Customer(job.customerId)}
                    className="main customer-link"
                  >
                    {job.customer}
                  </Link>
                  {job.customerType != CustomerTypes.Residential ? display : ""}
                </PermissionCheck>
              ) : (
                job.customer +
                (job.customerType != CustomerTypes.Residential ? display : "")
              )}
            </span>
          </React.Fragment>
        )
      }
      infoItemList={[
        {
          icon: LocationIcon,
          text: job.siteAddress
            ? FormatAddress(job.siteAddress.address, null, true)
            : "Address not set",
          tooltip: "Address",
          maxWidth: "25%",
        },
        {
          icon: EmailIcon,
          text: job.emailAddress,
          tooltip: "Email",
        },
        {
          icon: PhoneIcon,
          text: job.phoneNumber,
          tooltip: "Phone Number",
        },
        {
          icon: MobileIcon,
          text: job.mobileNumber,
          tooltip: "Mobile Number",
        },
        {
          icon: HeartbeatIcon,
          text:
            job.statusText == JobStatus.Cancelled && job.cancelledReason
              ? `${job.statusText} - ${job.cancelledReason}`
              : job.statusText,
          tooltip: "Status",
        },
        {
          icon: HeartbeatIcon,
          text: job.tpoStatusText,
          tooltip: "TPO Status",
          hidden: !job.departmentIncludeTpo,
        },
      ]}
      footerContent={
        (job.ownerName || job.department || job.contractManager) && (
          <p>
            {(job.ownerId || job.contractManager) && (
              <span title={"Contract Manager"}>
                <Image src={UserIcon} className="banner-icon" />
                <span>{job.ownerName || job.contractManager}</span>
              </span>
            )}
            {job.departmentId && (
              <span title={"Department"}>
                <Image src={UsersIcon} className="banner-icon" />
                <span>{job.department}</span>
              </span>
            )}
          </p>
        )
      }
      rightContent={
        <PermissionCheck target={Targets.Job} action={Actions.Edit}>
          <React.Fragment>
            <JobActions
              job={job}
              onSelectAddress={() => setSelectAddressOpen(true)}
              onChangeStatus={() => setChangeStatusOpen(true)}
              onChangeTpoStatus={() => setChangeTpoStatusOpen(true)}
              onEditJobDetails={() => setEditJobDetailsOpen(true)}
              onCancelJob={() => setCancelJobOpen(true)}
            />
            <JobSelectAddressModal
              visible={selectAddressOpen}
              job={job}
              closeModal={(changed) => {
                setSelectAddressOpen(false);
                if (changed) {
                  onRefresh();
                }
              }}
            />
            <JobChangeStatusModal
              job={job}
              visible={changeStatusOpen}
              closeModal={(changed) => {
                setChangeStatusOpen(false);
                if (changed) {
                  onRefresh();
                }
              }}
            />
            <JobChangeTpoStatusModal
              job={job}
              visible={changeTpoStatusOpen}
              closeModal={(changed) => {
                setChangeTpoStatusOpen(false);
                if (changed) {
                  onRefresh();
                }
              }}
            />
            <EditJobDetailsModal
              job={job}
              visible={editJobDetailsOpen}
              closeModal={(changed) => {
                setEditJobDetailsOpen(false);
                if (changed) {
                  onRefresh();
                }
              }}
            />
            <JobCancelModal
              job={job}
              visible={cancelJobOpen}
              closeModal={(changed) => {
                setCancelJobOpen(false);
                if (changed) {
                  onRefresh();
                }
              }}
            />
          </React.Fragment>
        </PermissionCheck>
      }
    />
  );
};

export default JobBanner;
