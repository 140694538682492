import {
  Form,
  FormBuilder,
  Format,
  ListBuilder,
  Table,
  useAuthClaims,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";
import { HeaderBar, TablePanel } from "components/containers";
import { Actions, Targets } from "constants/Permissions";
import { JobRoutes } from "constants/routes";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import InvoiceDetailsModal from "../InvoiceDetailsModal";
import JobsFilterDisplay from "./JobsFilterDisplay";
import JobFilters, { FieldNames } from "./JobsFilters";
import { createInvoice, getJobInvoice, getJobs } from "./actions";
import CreateCombinedInvoice from "./CreateCombinedInvoice";

const ListReadyToInvoice = () => {
  const { permissions } = useAuthClaims();

  const defaultFilters = {};
  const [filters, setFilters] = useState(defaultFilters);
  const resetFilters = () => setFilters(defaultFilters);

  const { completedDateFromUtc, completedDateToUtc, ...items } = filters;
  const searchFilters = {
    completedDateFrom: completedDateFromUtc
      ? DateTime.fromISO(completedDateFromUtc).toISODate()
      : null,
    completedDateTo: completedDateToUtc
      ? DateTime.fromISO(completedDateToUtc).toISODate()
      : null,
    ...items,
  };

  const [selection, setSelection] = useState({ selected: [], deselected: [] });

  return (
    <FormBuilder
      value={filters}
      onChange={setFilters}
      renderForm={(formProps) => (
        <ListBuilder
          withTable
          loadAction={getJobs}
          loadParams={searchFilters}
          dataTransform={(data) =>
            data.results.map((j) => {
              const highlight = j.customerType.toLowerCase();
              return {
                ...j,
                className: highlight ? `highlight-${highlight}` : null,
              };
            })
          }
          totalTransform={(data) => data.totalResults}
          renderList={(tableProps, state, events) => {
            const onReset = () =>
              events.onReset({ reload: true, reset: ["data"] });
            const firstJob =
              selection.selected.length > 0
                ? tableProps.data.find((j) => j.id === selection.selected[0])
                : null;
            const selected = tableProps.data.filter(
              (j) =>
                (selection.selected.includes(j.id) || selection.allSelected) &&
                !selection.deselected.includes(j.id)
            );
            return (
              <React.Fragment>
                <TablePanel
                  className="invoices-table"
                  pageSizePosition="footer"
                  pageSizeOptions={[10, 25, 50, 100]}
                  pagination={state.pagination}
                  onPaginationChange={events.onChangePagination}
                  totalResults={state.total}
                  customHeader={
                    <HeaderBar
                      name="Jobs to be Invoiced"
                      isSearchBar
                      rightContent={
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          <CreateCombinedInvoice
                            compact
                            content="Create Invoice"
                            disabled={
                              selection.selected.length <= 1 ||
                              selection.selected.some((s) => {
                                let job = tableProps.data.find(
                                  (j) => j.id == s
                                );
                                return (
                                  job &&
                                  (job?.customer != firstJob?.customer ||
                                    job?.department != firstJob?.department)
                                );
                              })
                            }
                            selectedJobs={selected}
                            onSubmitted={onReset}
                          />
                          <JobFilters
                            filters={filters}
                            formProps={formProps}
                            resetFilters={resetFilters}
                          />
                        </div>
                      }
                    >
                      <Form {...formProps}>
                        <Form.Input
                          field={FieldNames.Search}
                          placeholder="Search"
                          icon="search"
                        />
                      </Form>
                      <JobsFilterDisplay
                        filters={filters}
                        resetFilters={resetFilters}
                      />
                    </HeaderBar>
                  }
                  {...tableProps}
                  dataKey="id"
                  emptyMessage="No Invoices found"
                  selectable
                >
                  <Table.SelectableColumn
                    header="page"
                    value={selection}
                    onChange={setSelection}
                    totalItems={state.total}
                    preserveSelectedKeys
                    width="1%"
                  />
                  <Table.Column
                    className="job-link"
                    title="Job Reference"
                    render={(job) =>
                      claimExists(
                        permissions,
                        Targets.JobScheduling,
                        Actions.View
                      ) ? (
                        <Link
                          to={JobRoutes.Job(job.id)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.reference}
                        </Link>
                      ) : (
                        job.reference
                      )
                    }
                  />
                  <Table.Column field="customer" title="Customer" />
                  <Table.Column
                    title="Site Address"
                    render={(job) =>
                      job.siteAddress ? job.siteAddress.shortAddress : ""
                    }
                  />
                  <Table.Column field="department" title="Department" />
                  <Table.Column
                    title="Completed Date"
                    render={(job) => (
                      <Format.Date
                        value={job.completedDateUtc}
                        format="DD MMM YYYY"
                      />
                    )}
                  />
                  <Table.Column
                    title="Total"
                    render={(job) => (
                      <Format.Number value={job.total} format="GBP" />
                    )}
                  />
                  <PermissionCheck
                    target={Targets.Invoice}
                    action={Actions.Create}
                  >
                    <Table.Column
                      render={(job) => (
                        <InvoiceDetailsModal
                          header="Create Invoice"
                          row={job}
                          jobId={job.id}
                          loadAction={getJobInvoice}
                          submitAction={createInvoice}
                          onSubmitted={onReset}
                          renderTrigger={(showModal) => (
                            <Button
                              compact
                              onClick={showModal}
                              disabled={selection.selected.includes(job.id)}
                            >
                              Create Invoice
                            </Button>
                          )}
                        />
                      )}
                    />
                  </PermissionCheck>
                </TablePanel>
              </React.Fragment>
            );
          }}
        />
      )}
    />
  );
};

export default ListReadyToInvoice;
