import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { JobRoutes } from "constants/routes";
import JobListPage from "./JobListPage";
import JobDetailsPage from "./JobDetailsPage";

const JobRouter = () => (
  <Switch>
    <Route exact path={JobRoutes.Jobs} component={JobListPage} />
    <Route path={JobRoutes.Job()} component={JobDetailsPage} />
    <Route render={() => <Redirect to={JobRoutes.Jobs} />} />
  </Switch>
);

export default JobRouter;
