import React from "react";
import { Grid } from "semantic-ui-react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { editCustomer } from "./actions";
import { TitlesDropdown } from "components/forms";
import { CustomerTypes } from "constants/enums";
import { CustomerTypesLookupDropdown } from "../CustomerTypesLookup";

const EditCustomer = ({ renderTrigger, customer, onRefresh }) => {
  const phoneOrEmailError = "Phone or Email is required";
  return (
    <ModalBuilder
      withForm
      initialData={customer}
      submitAction={editCustomer}
      submitParams={{ customerId: customer.id }}
      onSubmitted={onRefresh}
      renderTrigger={renderTrigger}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Customer">
          <Form {...formProps}>
            <Grid>
              {formProps.value.type != CustomerTypes.Residential && (
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form.Input
                      field="businessName"
                      label="Business Name"
                      required
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={4}>
                  <TitlesDropdown
                    unknownValueText={customer.title}
                    field="titleId"
                    label="Title"
                    placeholder=""
                    clearable
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    field="firstName"
                    label="First Name"
                    required={formProps.value.type == CustomerTypes.Residential}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    field="lastName"
                    label="Last Name"
                    fluid
                    required={formProps.value.type == CustomerTypes.Residential}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Form.Email
                    required={
                      !formProps.value.phoneNumber &&
                      !formProps.value.mobileNumber
                    }
                    emailValidation={!!formProps.value.emailAddress}
                    requiredError={phoneOrEmailError}
                    field="emailAddress"
                    label="Email"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    field="phoneNumber"
                    label="Phone"
                    requiredError={phoneOrEmailError}
                    required={
                      !formProps.value.emailAddress &&
                      !formProps.value.mobileNumber
                    }
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    field="mobileNumber"
                    label="Mobile"
                    requiredError={phoneOrEmailError}
                    required={
                      !formProps.value.emailAddress &&
                      !formProps.value.phoneNumber
                    }
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <CustomerTypesLookupDropdown
                    unknownValueText={customer.type}
                    field="type"
                    label="Type"
                    required
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditCustomer;
