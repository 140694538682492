import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/USER_DETAILS";

export const ActionTypes = {
    GetUserRoles: `${NAMESPACE}/GET_USER_ROLES`
};

export const getUserRoles = () =>
apiGet(ActionTypes.GetUserRoles, `users/current/roles`);
