import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";

const AddDocumentsModal = ({
  as: As = Button,
  content = "Add Document",
  header = "Add Document",
  submitAction,
  submitParams,
  onAdded,
  documentTypeDropdown,
  children,
  ...props
}) => {
  return (
    <ModalBuilder
      withForm
      submitAction={submitAction}
      submitParams={submitParams}
      onSubmitted={onAdded}
      renderTrigger={(showModal) => (
        <As onClick={showModal} content={content} {...props} />
      )}
      renderModal={(modalProps, formProps) => {
        const formData = formProps.value;
        return (
          <Modal.Add {...modalProps} header={header}>
            <Form {...formProps}>
              <Form.FileUpload
                field="files"
                label="Upload a file"
                multiple
                required
                fluid
              />
              {!formData.files || formData.files?.length <= 1 ? (
                <Fragment>
                  <Form.Group widths="equal">
                    <Form.Input field="name" label="Name" fluid required />
                    {documentTypeDropdown}
                  </Form.Group>
                  <Form.Input field="description" label="Description" fluid />
                  <Form.Trigger
                    field="files"
                    event="onSet"
                    action="set"
                    target="name"
                    value={(formState) => {
                      return formState.fields.files[0].name;
                    }}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  {documentTypeDropdown}
                  <Form.Input
                    field="description"
                    label="Description"
                    placeholder="Select"
                    fluid
                  />
                </Fragment>
              )}

              {children}
            </Form>
          </Modal.Add>
        );
      }}
    />
  );
};

export default AddDocumentsModal;
