import React from "react";
import { Form, FormBuilder, Loadable } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Button, Icon } from "semantic-ui-react";
import { ScrollbarPanel, LightPanel } from "components/containers";
import classNames from "classnames";

const ScrollableListPanel = ({
  header,
  submitAction,
  submitParams,
  addPermissionTarget,
  addPermissionAction,
  placeholder,
  noItemsFoundText,
  renderItem,
  items,
  onSubmitted,
  loading,
  className,
}) => {
  return (
    <LightPanel
      headerText={header}
      className={classNames("scrollable-list-panel", className)}
    >
      <Loadable loading={loading}>
        <section className="scrollable-list-panel-list">
          <div className="scrollable-list-panel-timeline">
            <ScrollbarPanel>
              {!loading && Array.isArray(items) && items.length > 0
                ? items.map((x, i) => renderItem(x, i))
                : !loading && (
                    <p style={{ padding: "0 1rem 0 1rem" }}>
                      {noItemsFoundText}
                    </p>
                  )}
            </ScrollbarPanel>
          </div>
          <PermissionCheck
            target={addPermissionTarget}
            action={addPermissionAction}
          >
            <div className="scrollable-list-panel-add">
              <FormBuilder
                submitParams={submitParams}
                submitAction={submitAction}
                onSubmitted={onSubmitted}
                renderForm={(formProps, formState, formEvents) => {
                  return (
                    <Form {...formProps}>
                      <Form.TextArea
                        field="comment"
                        placeholder={placeholder}
                        fluid
                      />
                      <Button
                        compact
                        onClick={() =>
                          formEvents.onSubmit().then(() => {
                            formEvents.onReset();
                          })
                        }
                        disabled={
                          !formState.dirty ||
                          formState.submitting ||
                          formProps.value.comment == ""
                        }
                        loading={formState.submitting}
                      >
                        <Icon name="plus" />
                      </Button>
                    </Form>
                  );
                }}
              />
            </div>
          </PermissionCheck>
        </section>
      </Loadable>
    </LightPanel>
  );
};

export default ScrollableListPanel;
