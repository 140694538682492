import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { css, styled } from "styled-components";
import Reveal from "./Reveal";

const sizeMap = {
  none: "0",
  smallest: "0.25em",
  smaller: "0.5em",
  small: "0.75em",
  default: "1em",
  medium: "1em",
  large: "2em",
  larger: "3em",
  largest: "4em",
};

const buildDimensions = (all, top, bottom, left, right) => {
  const fallback = sizeMap[all] ? sizeMap[all] : 0;
  let topVal = sizeMap[top] ? sizeMap[top] : fallback;
  let bottomVal = sizeMap[bottom] ? sizeMap[bottom] : fallback;
  let leftVal = sizeMap[left] ? sizeMap[left] : fallback;
  let rightVal = sizeMap[right] ? sizeMap[right] : fallback;
  return `${topVal} ${rightVal} ${bottomVal} ${leftVal}`;
};

const StyledContent = styled.div`
  margin: ${(props) =>
    buildDimensions(
      props.margin,
      props.marginTop,
      props.marginBottom,
      props.marginLeft,
      props.marginRight,
    )};
  padding: ${(props) =>
    buildDimensions(
      props.padding,
      props.paddingTop,
      props.paddingBottom,
      props.paddingLeft,
      props.paddingRight,
    )};

  ${(props) =>
    sizeMap[props.top] &&
    css`
      top: ${sizeMap[props.top]};
    `};

  ${(props) =>
    sizeMap[props.bottom] &&
    css`
      bottom: ${sizeMap[props.bottom]};
    `};

  ${(props) =>
    sizeMap[props.left] &&
    css`
      left: ${sizeMap[props.left]};
    `};

  ${(props) =>
    sizeMap[props.right] &&
    css`
      right: ${sizeMap[props.right]};
    `};
`;

/**
 * A component for arranging and spacing content
 */
const Content = ({
  absolute,
  className,
  visible,
  animateVisible,
  children,
  ...props
}) => {
  const classes = ["cin content", { absolute }];

  if (
    visible !== undefined &&
    typeof animateVisible === "number" &&
    animateVisible > 0
  ) {
    return (
      <StyledContent {...props} className={classNames(...classes, className)}>
        <Reveal visible={visible} duration={animateVisible}>
          {children}
        </Reveal>
      </StyledContent>
    );
  }

  classes.push({ hidden: visible !== undefined && !visible });
  return (
    <StyledContent {...props} className={classNames(...classes, className)}>
      {children}
    </StyledContent>
  );
};

Content.propTypes = {
  /**
   * Content margin size for all sides
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  margin: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content margin size for the component top
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  marginTop: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content margin size for the component bottom
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  marginBottom: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content margin size for the component left
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  marginLeft: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content margin size for the component right
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  marginRight: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content padding size for all sides
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  padding: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content padding size for the component top
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  paddingTop: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content padding size for the component bottom
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  paddingBottom: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content padding size for the component left
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  paddingLeft: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content padding size for the component right
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  paddingRight: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Whether the content should be positioned absolutely
   */
  absolute: PropTypes.bool,
  /**
   * Content top spacing when using absolute positioning
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  top: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content bottom spacing when using absolute positioning
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  bottom: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content left spacing when using absolute positioning
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  left: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Content right spacing when using absolute positioning
   * @typedef sizeMap
   * @property {string} `none`
   * @property {string} `smallest`
   * @property {string} `smaller`
   * @property {string} `small`
   * @property {string} `medium`
   * @property {string} `large`
   * @property {string} `larger`
   * @property {string} `largest`
   */
  right: PropTypes.oneOf(Object.keys(sizeMap)),
  /**
   * Additional classes for styling
   */
  className: PropTypes.string,
  /**
   * Optional, toggles whether the content is shown (without unmounting)
   */
  visible: PropTypes.bool,
  /**
   * Time in milliseconds for the visible animation transition, or false to disable
   */
  animateVisible: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

Content.defaultProps = {
  animateVisible: 400,
};

export default Content;
