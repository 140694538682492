import React, { useRef, useEffect, useState } from "react";
import { Segment, Image } from "semantic-ui-react";
import SliderLeftArrow from "assets/icons/slider-left-arrow.svg";
import SliderRightArrow from "assets/icons/slider-right-arrow.svg";
import classNames from "classnames";

const totalArrowSize = 30;

const SlideSelector = ({
  title,
  headerContent,
  footerContent,
  movePrevious,
  moveNext,
  items,
  renderItem,
  className,
  onWidthChanged,
  showHeader = true,
  ...otherProps
}) => {
  const slideBodyRef = useRef();
  const wrapperRef = useRef();
  const [width, setWidth] = useState(0);
  const css = classNames("slide-selector", className);

  useEffect(() => {
    const handleResize = () => {
      const bodyWidth = slideBodyRef ? slideBodyRef.current.clientWidth : 0;
      setWidth(bodyWidth);
      // deduct arrow size from width to allow for left/right arrows and optionally report change to parent
      if (onWidthChanged) onWidthChanged(bodyWidth - totalArrowSize);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [onWidthChanged]);

  useEffect(() => {
    const bodyWidth = slideBodyRef ? slideBodyRef.current.clientWidth : 0;
    if (onWidthChanged && bodyWidth && bodyWidth != width) {
      setWidth(bodyWidth);
      // deduct arrow size from width to allow for left/right arrows and optionally report change to parent
      onWidthChanged(bodyWidth - totalArrowSize);
    }
  }, [slideBodyRef, onWidthChanged, wrapperRef, width]);

  return (
    <Segment className={css} {...otherProps}>
      {showHeader && (
        <header>
          {title && <h4>{title}</h4>}
          {headerContent}
        </header>
      )}
      <div className="slide-selector-body" ref={slideBodyRef}>
        <Image
          src={SliderLeftArrow}
          inline
          onClick={() => movePrevious(items[0])}
        />
        <div
          className="items-area"
          style={{
            width: `calc(100% - ${totalArrowSize}px)`,
          }}
        >
          <div className="item-wrapper" ref={wrapperRef}>
            {items.map((x, i) => renderItem(x, i))}
          </div>
        </div>
        <Image
          src={SliderRightArrow}
          inline
          onClick={() => {
            const hasOverflowContent =
              wrapperRef.current.scrollWidth > wrapperRef.current.clientWidth;
            moveNext(items[0], hasOverflowContent);
          }}
        />
      </div>
      {footerContent && <footer>{footerContent}</footer>}
    </Segment>
  );
};

export default SlideSelector;
