import {
  Form,
  Format,
  FormBuilder,
  ListBuilder,
  Table,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { HeaderBar, TablePanel } from "components/containers";
import { Actions, Targets } from "constants/Permissions";
import { ContractRoutes, CustomerRoutes, JobRoutes } from "constants/routes";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import InvoiceDetailsModal from "../InvoiceDetailsModal";
import {
  creditXeroInvoice,
  exportInvoiceToXero,
  getInvoice,
  getInvoices,
  updateInvoice,
} from "./actions";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import ExportInvoices from "./ExportInvoices";
import InvoiceActions from "./InvoiceActions";
import InvoiceFilters, { FieldNames } from "./InvoiceFilters";
import InvoicesFilterDisplay from "./InvoicesFilterDisplay";
import CheckIcon from "assets/icons/check.svg";
import { ContractTabKeys } from "constants";

const ListInvoices = () => {
  const defaultFilters = {};
  const [filters, setFilters] = useState(defaultFilters);
  const [editing, setEditing] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [busyInvoiceIds, setBusyInvoiceIds] = useState([]);
  const resetFilters = () => setFilters(defaultFilters);

  const dispatch = useDispatch();

  const {
    invoicedDateFromUtc,
    invoicedDateToUtc,
    exportedDateFromUtc,
    exportedDateToUtc,
    ...items
  } = filters;
  const searchFilters = {
    invoicedDateFrom: invoicedDateFromUtc
      ? DateTime.fromISO(invoicedDateFromUtc).toISODate()
      : null,
    invoicedDateTo: invoicedDateToUtc
      ? DateTime.fromISO(invoicedDateToUtc).toISODate()
      : null,
    exportedDateFrom: exportedDateFromUtc
      ? DateTime.fromISO(exportedDateFromUtc).toISODate()
      : null,
    exportedDateTo: exportedDateToUtc
      ? DateTime.fromISO(exportedDateToUtc).toISODate()
      : null,
    ...items,
  };

  const exportToXero = (invoiceId, onRefresh) => {
    setBusyInvoiceIds([...busyInvoiceIds, invoiceId]);
    dispatch(exportInvoiceToXero(invoiceId)).then(() => {
      setBusyInvoiceIds(busyInvoiceIds.filter((x) => x != invoiceId));
      onRefresh();
    });
  };
  const creditInvoice = (invoiceId, onRefresh) => {
    setBusyInvoiceIds([...busyInvoiceIds, invoiceId]);
    dispatch(creditXeroInvoice(invoiceId)).then(() => {
      setBusyInvoiceIds(busyInvoiceIds.filter((x) => x != invoiceId));
      onRefresh();
    });
  };

  return (
    <FormBuilder
      value={filters}
      onChange={setFilters}
      renderForm={(formProps) => (
        <ListBuilder
          withTable
          loadAction={getInvoices}
          loadParams={searchFilters}
          loadTransform={(data) => ({
            ...data,
            results: data.results.map((item) => ({
              ...item,
              className: `highlight-${
                item.contractJobType
                  ? item.contractJobType.toLowerCase()
                  : item.customerType.toLowerCase()
              }`,
            })),
          })}
          dataTransform={(data) => data.results}
          totalTransform={(data) => data.totalResults}
          renderList={(tableProps, state, events) => (
            <React.Fragment>
              <TablePanel
                pageSizePosition="footer"
                pageSizeOptions={[10, 25, 50, 100]}
                pagination={state.pagination}
                onPaginationChange={events.onChangePagination}
                totalResults={state.total}
                className="invoices-table"
                customHeader={
                  <HeaderBar
                    name="Invoices"
                    isSearchBar
                    rightContent={
                      <div
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <PermissionCheck
                          target={Targets.Invoice}
                          action={Actions.Download}
                        >
                          <ExportInvoices
                            filters={searchFilters}
                            loading={state.loading}
                            submitting={state.submitting}
                          />
                        </PermissionCheck>
                        <InvoiceFilters
                          filters={filters}
                          formProps={formProps}
                          resetFilters={resetFilters}
                        />
                      </div>
                    }
                  >
                    <Form {...formProps}>
                      <Form.Input
                        field={FieldNames.Search}
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                    <InvoicesFilterDisplay
                      filters={filters}
                      resetFilters={resetFilters}
                    />
                  </HeaderBar>
                }
                {...tableProps}
                dataKey="id"
                expandRows="onClick"
                maxExpandedRows={1}
                renderExpandedRow={(item) => (
                  <Table
                    data={item.invoiceLines}
                    dataKey="id"
                    className="invoice-lines-table"
                  >
                    <Table.Column
                      field="quantity"
                      title="Quantity"
                      width="7.5%"
                    />
                    <Table.Column
                      field="productName"
                      title={item.jobId ? "Product Name" : "Job No"}
                      width="30%"
                    />
                    <Table.Column
                      field="description"
                      title="Description"
                      width="30%"
                    />
                    <Table.Column
                      component={NetPrice}
                      title="Net Price"
                      width="7.5%"
                    />
                    <Table.Column component={Vat} title="VAT" width="7.5%" />
                    <Table.Column
                      component={GrossPrice}
                      title="Total"
                      width="7.5%"
                    />
                  </Table>
                )}
                emptyMessage="No Invoices found"
                selectable
              >
                <Table.Column
                  title="Job/Invoice Ref"
                  width="8%"
                  className="job-link"
                  render={(invoice) => (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <PermissionCheck
                        target={Targets.Job}
                        action={Actions.View}
                        renderFailure={() =>
                          invoice.jobReference || invoice.reference
                        }
                      >
                        <Link
                          to={
                            invoice.jobId
                              ? JobRoutes.Job(invoice.jobId)
                              : invoice.contractId
                              ? `${ContractRoutes.Contract(
                                  invoice.contractId
                                )}?initialTab=${ContractTabKeys.JobManagement}`
                              : CustomerRoutes.Customer(invoice.customerId)
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {invoice.jobReference || invoice.reference}
                        </Link>
                      </PermissionCheck>
                    </span>
                  )}
                />
                <Table.Column field="customer" title="Customer" width="15%" />
                <Table.Column
                  field="department"
                  title="Department"
                  width="15%"
                />
                <Table.Column
                  component={NetTotal}
                  title="Net Price"
                  width="8%"
                />
                <Table.Column component={VatTotal} title="VAT" width="8%" />
                <Table.Column component={GrossTotal} title="Total" width="8%" />
                <Table.Column component={DueDate} title="Due Date" width="8%" />
                <Table.Column
                  field="invoiceType"
                  title="Invoice Type"
                  width="7%"
                />
                <Table.Column
                  component={ExportedCheck}
                  title="Exported"
                  width="4%"
                />
                <Table.Column
                  component={ExportedDate}
                  title="Exported On"
                  width="8%"
                />
                <Table.Column
                  field="exportedBy"
                  title="Exported By"
                  width="8%"
                />
                <PermissionCheck
                  target={Targets.Invoice}
                  action={[Actions.Edit, Actions.Export, Actions.Credit]}
                  any
                >
                  <Table.Column
                    hoverable
                    align="right"
                    render={(invoice) => (
                      <InvoiceActions
                        disabled={busyInvoiceIds.includes(invoice.id)}
                        invoice={invoice}
                        onEdit={setEditing}
                        onDelete={setDeleting}
                        onExportToXero={() =>
                          exportToXero(invoice.id, events.onRefresh)
                        }
                        onCreditInvoice={() =>
                          creditInvoice(invoice.id, events.onRefresh)
                        }
                      />
                    )}
                  />
                </PermissionCheck>
              </TablePanel>
              {editing && (
                <InvoiceDetailsModal
                  header="Edit Invoice"
                  row={editing}
                  loadAction={getInvoice}
                  submitAction={updateInvoice}
                  onSubmitted={() => {
                    setEditing(null);
                    events.onRefresh();
                  }}
                  onCancel={() => setEditing(null)}
                  isEditing
                />
              )}
              {deleting && (
                <DeleteInvoiceModal
                  invoice={deleting}
                  onDeleted={() => {
                    setDeleting(null);
                    events.onRefresh();
                  }}
                  onCancel={() => setDeleting(null)}
                />
              )}
            </React.Fragment>
          )}
        />
      )}
    />
  );
};

const NetTotal = ({ item }) => {
  return <Format.Number value={item.netTotal} format="GBP" />;
};

const VatTotal = ({ item }) => {
  return <Format.Number value={item.vatTotal} format="GBP" />;
};

const GrossTotal = ({ item }) => {
  return <Format.Number value={item.grossTotal} format="GBP" />;
};

const DueDate = ({ item }) => {
  return <Format.Date value={item.dueDate} format="DD MMM YYYY" />;
};

const NetPrice = ({ item }) => {
  return <Format.Number value={item.netPrice} format="GBP" />;
};

const Vat = ({ item }) => {
  return <Format.Number value={item.vat} format="GBP" />;
};

const GrossPrice = ({ item }) => {
  return <Format.Number value={item.grossPrice} format="GBP" />;
};

const ExportedDate = ({ item }) => (
  <Format.DateTime value={item.exportedUtc} format="DD MMM YYYY" />
);

const ExportedCheck = ({ item }) =>
  item.exported && <Image src={CheckIcon} centered />;

export default ListInvoices;
