import React from "react";
import { ListBuilder, Table, Format } from "@redriver/cinnamon";
import { getEnquiries } from "./actions";
import { TablePanel } from "components/containers";
import { EnquiryStatus } from "constants/enums";
import { EnquiryRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { EnquiryStatusTintMapping } from "constants/mappings";

const EnquiriesTable = ({ filters }) => {
  const history = useHistory();
  return (
    <ListBuilder
      withTable
      loadAction={getEnquiries}
      loadParams={filters}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: classNames(
            `highlight-${item.customerType.toLowerCase()}`,
            `tint-${EnquiryStatusTintMapping[EnquiryStatus[item.status]]}`
          ),
        })),
      })}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            {...tableProps}
            dataKey="id"
            emptyMessage="No enquiries found"
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            totalResults={state.total}
            onPaginationChange={events.onChangePagination}
            selectable
            className="enquiries-table"
            onRowClick={(item) => {
              history.push(EnquiryRoutes.Enquiry(item.id));
            }}
          >
            <Table.Column
              title="Ref"
              render={(enq) => {
                if (enq.ref) {
                  let value = enq.ref.toString();
                  while (value.length < 6) {
                    value = "0" + value;
                  }
                  return value;
                }
                return "-";
              }}
            />
            <Table.Column field="name" title="Name" />
            <Table.Column
              title="Address"
              render={(enq) => (enq.address ? enq.address.shortAddress : null)}
            />
            <Table.Column field="department" title="Department" />
            <Table.Column
              width="10%"
              render={(enq) => EnquiryStatus[enq.status]}
              title="Status"
            />
            <Table.Column
              title="Created"
              width="12%"
              render={(enq) => (
                <Format.DateTime
                  value={enq.createdUtc}
                  format="DD MMM YYYY HH:mm"
                />
              )}
            />
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

export default EnquiriesTable;
