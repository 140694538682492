import React, { useState } from "react";
import { useSelector } from "react-redux";
import DepartmentSelector from "../DepartmentSelector";
import { getDepartments, setDepartmentFilter } from "./actions";
import { getResourceSchedulingState } from "./selectors";
import { SlideSelector } from "components/controls";
import { range } from "modules/helpers";
import { DateTime } from "luxon";
import classNames from "classnames";
import ResourceAllocation from "./ResourceAllocation";

const dateWidth = 120;

const ResourceScheduling = () => {
  const [firstDay, setFirstDay] = useState(DateTime.local());
  const [selectedDay, setSelectedDay] = useState(firstDay);
  const departmentsFilter = useSelector(
    getResourceSchedulingState
  ).departmentsFilter;

  const [days, setDays] = useState([firstDay]);

  return (
    <section className="resource-scheduling">
      <div className="filters">
        <SlideSelector
          className="date-selector"
          title="Days"
          showHeader={false}
          movePrevious={(x) => {
            const prev = x.plus({ day: -1 });
            setFirstDay(prev);
            setDays(
              range(0, days.length - 1).map((x) => prev.plus({ day: x }))
            );
          }}
          moveNext={(x) => {
            const next = x.plus({ day: 1 });
            setFirstDay(next);
            setDays(
              range(0, days.length - 1).map((x) => next.plus({ day: x }))
            );
          }}
          items={days}
          onWidthChanged={(w) => {
            const num = Math.floor(w / dateWidth);
            setDays(range(0, num).map((x) => firstDay.plus({ day: x })));
          }}
          renderItem={(d) => (
            <div
              key={d}
              className={classNames(
                "resource-date",
                d.valueOf() == selectedDay.valueOf() ? "selected" : null
              )}
              onClick={() => setSelectedDay(d)}
            >
              {d.toFormat("ccc dd MMM")}
            </div>
          )}
        />
        <DepartmentSelector
          getDepartmentsAction={getDepartments}
          setDepartmentFilter={setDepartmentFilter}
          departmentsSelector={getResourceSchedulingState}
          useDisplayColour
          showHeader={false}
        />
      </div>

      <ResourceAllocation
        date={selectedDay.toISODate()}
        departmentsFilter={departmentsFilter}
      />
    </section>
  );
};

export default ResourceScheduling;
