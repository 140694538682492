import React, { useMemo } from "react";
import { useSignalRHub } from "modules/signalr";
import SignalRHubContext from "./SignalRHubContext";

const SignalRHub = ({ route, children }) => {
  const [hubConnection, invokeMethod] = useSignalRHub(route);

  const contextValue = useMemo(
    () => ({
      hubConnection,
      invokeMethod,
    }),
    [hubConnection, invokeMethod]
  );

  return (
    <SignalRHubContext.Provider value={contextValue}>
      {children}
    </SignalRHubContext.Provider>
  );
};

export default SignalRHub;
