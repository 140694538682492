import React, { useCallback, useMemo } from "react";
import { getContractCustomerData } from "./actions";
import { colours } from "./colours";
import DashboardWidgetClear from "./DashboardWidgetClear";
import BarWidget from "./BarWidget";
import { Format } from "@redriver/cinnamon";
import { buildContractTitleSuffix } from "./helpers";
import { DashboardWidgetKeys } from "constants/index.js";

const ContractCustomerWidget = ({ filters, updateFilter }) => {
  const baseFilters = useMemo(() => {
    return {
      from: filters.from,
      to: filters.to,
      status: filters.contractStatus,
      contractJobType: filters.contractJobType,
    };
  }, [
    filters.to,
    filters.from,
    filters.contractStatus,
    filters.contractJobType,
  ]);

  const selectedCustomer = filters.contractCustomer;

  const setContractCustomerFilter = useCallback(
    (customerId, displayValue) => {
      if (updateFilter) {
        updateFilter({
          name: "contractCustomer",
          value: customerId,
          displayValue,
        });
      }
    },
    [updateFilter]
  );

  const filterInfo = buildContractTitleSuffix({ filters, includeStatus: true });

  return (
    <BarWidget
      id={DashboardWidgetKeys.ContractCustomer}
      title={`Contracts by Customer (${filterInfo})`}
      action={getContractCustomerData}
      filterParams={baseFilters}
      transform={(data) => {
        return data.map((x) => ({ ...x, value: (x.value || 0) / 1000 })); // convert the value in pounds to be the value in thousands of pounds instead
      }}
      valueField="value"
      label="Thousands (£)"
      colours={[
        colours.darkBlue,
        colours.midBlue,
        colours.lightBlue,
        colours.slate,
        colours.darkGreen,
      ]}
      headerContent={
        selectedCustomer && (
          <DashboardWidgetClear
            onClear={() => setContractCustomerFilter(null)}
          />
        )
      }
      onBarClick={(item) => {
        if (selectedCustomer == item.customerId) {
          setContractCustomerFilter(null);
        } else {
          setContractCustomerFilter(item.customerId, item.name);
        }
      }}
      highlightValue={selectedCustomer}
      highlightFieldName="customerId"
      renderCustomTooltip={(item) => (
        <React.Fragment>
          <p>{item.name}</p>
          <p>No. of contracts: {item.count}</p>
          <p>
            <Format.Number format="GBP" value={item.value * 1000} />
          </p>
        </React.Fragment>
      )}
      style={{ flexBasis: "100%" }}
    />
  );
};

export default ContractCustomerWidget;
