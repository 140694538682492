import React from "react";
import { Format } from "@redriver/cinnamon";

const QuoteFooter = ({ nameText, quantityText, tableItems }) => {
  return (
    <section>
      <div className="productName">{nameText}</div>
      <div className="productNumber">{quantityText}</div>
      <div className="productNumber">
        {tableItems != null ? (
          <Format.Number
            value={tableItems.reduce((acc, item) => {
              acc += item.unitPrice * item.quantity;
              return acc;
            }, 0)}
            format="GBP"
          />
        ) : (
          0
        )}
      </div>
      <div className="productNumber">
        {tableItems != null ? (
          <Format.Number
            value={tableItems.reduce((acc, item) => {
              acc += item.vat * item.quantity;
              return acc;
            }, 0)}
            format="GBP"
          />
        ) : (
          0
        )}
      </div>
      <div className="productNumber">
        {tableItems != null ? (
          <Format.Number
            value={tableItems.reduce((acc, item) => {
              acc += item.unitCost * item.quantity;
              return acc;
            }, 0)}
            format="GBP"
          />
        ) : (
          0
        )}
      </div>
      <div className="productNumber">
        {tableItems != null ? (
          <Format.Number
            value={tableItems.reduce((acc, item) => {
              acc += ((item.unitPrice ?? 0) + (item.vat ?? 0)) * item.quantity;
              return acc;
            }, 0)}
            format="GBP"
          />
        ) : (
          0
        )}
      </div>
    </section>
  );
};

export default QuoteFooter;
