import React from "react";
import { Form } from "@redriver/cinnamon";
import { ContractContactPointTypeFields } from "constants/enums";
import { Grid, Icon } from "semantic-ui-react";
import classNames from "classnames";
import { getContractContactPointTypeFields } from "./actions";

const ContractContact = ({ field, label, placeholder }) => (
  <Form.Object field={field}>
    {({ fields }) => (
      <React.Fragment>
        <Grid.Row columns={2} className="contract-contact-row">
          <Grid.Column>
            <Form.Input
              field="name"
              label={label}
              placeholder={placeholder}
              required={fields.contactPoints?.length > 0}
              fluid
            />
          </Grid.Column>
          <Grid.Column>
            <Form.ArrayAdder
              size="small"
              field="contactPoints"
              arrayKey="key"
              content="Add contact detail"
            />
          </Grid.Column>
        </Grid.Row>
        <Form.Array
          field="contactPoints"
          arrayKey="key"
          propagateUpdates="always"
        >
          {({ fields, arrayIndex }) => {
            return (
              <Grid.Row
                columns={2}
                className={classNames(
                  "contract-contact-detail-row",
                  arrayIndex === 0 ? "first" : ""
                )}
              >
                <Grid.Column>
                  <Form.Dropdown
                    field="type"
                    label="Type"
                    placeholder="Select contact type"
                    lookupAction={getContractContactPointTypeFields}
                    required
                    fluid
                  />
                  <Form.Trigger field="type" action="clear" target="id" />
                </Grid.Column>
                <Grid.Column style={{ display: "flex" }}>
                  {fields.type === ContractContactPointTypeFields.Email ? (
                    <Form.Email
                      field="value"
                      label="Contact Detail"
                      placeholder="Enter contact detail"
                      required
                    />
                  ) : (
                    <Form.Input
                      field="value"
                      label="Contact Detail"
                      placeholder="Enter contact detail"
                      required
                    />
                  )}
                  <Form.ArrayRemover
                    as={Icon}
                    name="trash alternate"
                    style={{ alignSelf: "center", marginLeft: "0.75em" }}
                  />
                </Grid.Column>
              </Grid.Row>
            );
          }}
        </Form.Array>
      </React.Fragment>
    )}
  </Form.Object>
);

export default ContractContact;
