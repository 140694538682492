import React from "react";
import { System } from "features/Admin";
import { KpsPageContent } from "components/containers";

const SystemPage = () => (
  <KpsPageContent>
    <System />
  </KpsPageContent>
);

export default SystemPage;
