import React from "react";
import { Table } from "@redriver/cinnamon";
import DeleteDepartment from "./DeleteDepartment";
import EditDepartment from "./EditDepartment";

const DepartmentActions = ({ department, onRefresh }) => (
  <Table.Menu openOnFocus>
    <EditDepartment
      department={department}
      onEdited={onRefresh}
      as={Table.MenuItem}
    >
      Edit Department
    </EditDepartment>
    <DeleteDepartment
      department={department}
      onDeleted={onRefresh}
      as={Table.MenuItem}
    >
      Delete Department
    </DeleteDepartment>
  </Table.Menu>
);

export default DepartmentActions;
