import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";

const NAMESPACE = "JOBS/DETAILS";

export const ActionTypes = {
  GetJob: `${NAMESPACE}/GET_JOB`,
  GetJobSummary: `${NAMESPACE}/GET_JOB_LINKS`,
  UpdateStatus: `${NAMESPACE}/UPDATE_JOB_STATUS`,
  GetJobItems: `${NAMESPACE}/GET_JOB_ITEMS`,
  CompleteJobItems: `${NAMESPACE}/COMPLETE_JOB_ITEMS`,
  UpdateSelectedAddress: `${NAMESPACE}/UPDATE_SELECTED_ADDRESS`,
  AddJobItem: `${NAMESPACE}/ADD_JOB_ITEM`,
  EditJobItem: `${NAMESPACE}/EDIT_JOB_ITEM`,
  DeleteJobItem: `${NAMESPACE}/DELETE_JOB_ITEM`,
  GetSheetsDates: `${NAMESPACE}/GET_SHEETS_DATES`,
  UpdateJobSheetNotes: `${NAMESPACE}/UPDATE_JOB_SHEET_NOTES`,
  ScheduleJobItems: `${NAMESPACE}/SCHEDULE_JOB_ITEMS`,
  RescheduleVisit: `${NAMESPACE}/RESCHEDULE_VISIT`,
  UnscheduleVisit: `${NAMESPACE}/UNSCHEDULE_VISIT`,
  GenerateJobSheet: `${NAMESPACE}/GENERATE_JOB_SHEET`,
  CancelJob: `${NAMESPACE}/CANCEL_JOB`,
  GetJobRepeatSchedule: `${NAMESPACE}/GET_REPEAT_SCHEDULE`,
  GetJobComments: `${NAMESPACE}/GET_JOB_COMMENTS`,
  AddJobComment: `${NAMESPACE}/ADD_JOB_COMMENT`,
  GetJobEquipment: `${NAMESPACE}/GET_JOB_EQUIPMENT`,
  UpdateJobEquipment: `${NAMESPACE}/UPDATE_JOB_EQUIPMENT`,
  RescheduleJob: `${NAMESPACE}/RESCHEDULE_JOB`,
  AddRepeatJob: `${NAMESPACE}/ADD_REPEAT_JOB`,
  UncompleteJobItems: `${NAMESPACE}/UNCOMPLETE_JOB_ITEMS`,
  InvoiceJobItems: `${NAMESPACE}/INVOICE_JOB_ITEMS`,
  UpdateJobDetails: `${NAMESPACE}/UPDATE_JOB_DETAILS`,
  GenerateContractJobQuote: `${NAMESPACE}/GENERATE_CONTRACT_JOB_QUOTE`,
  GetContractJobQuoteEmailTemplate: `${NAMESPACE}/GET_CONTRACT_JOB_QUOTE_EMAIL_TEMPLATE`,
  EmailContractJobQuote: `${NAMESPACE}/EMAIL_CONTRACT_JOB_QUOTE`,
};

export const getJob = ({ jobId }) =>
  apiGet(ActionTypes.GetJob, `jobs/${jobId}`);

export const updateStatus = ({ status }, { jobId }) => {
  return apiPut(ActionTypes.UpdateStatus, `jobs/${jobId}/status`, {
    status,
  });
};

export const updateTpoStatus = ({ tpoStatus }, { jobId }) => {
  return apiPut(ActionTypes.UpdateStatus, `jobs/${jobId}/tpo-status`, {
    tpoStatus,
  });
};

export const getJobItems = (_, { jobId }) =>
  apiGet(ActionTypes.GetJobItems, `jobs/${jobId}/items`);

export const completeJobItems = ({ jobId, selectedIds }) =>
  apiPut(ActionTypes.CompleteJobItems, `jobs/${jobId}/items/complete`, {
    selectedIds,
  });

export const updateSelectedAddress = (jobId, formData) =>
  apiPut(ActionTypes.UpdateSelectedAddress, `jobs/${jobId}/address`, {
    ...formData,
    customerAddressId: formData.selectedAddressId,
  });

export const addJobItem = (formData, { jobId }) =>
  apiPost(ActionTypes.AddJobItem, `jobs/${jobId}/items`, formData);

export const editJobItem = (formData, { jobId, jobItemId }) =>
  apiPut(ActionTypes.EditJobItem, `jobs/${jobId}/items/${jobItemId}`, formData);

export const deleteJobItem = ({ jobId, jobItemId }) =>
  apiDelete(ActionTypes.DeleteJobItem, `jobs/${jobId}/items/${jobItemId}`);

export const getJobSheets = (_, { jobId }) =>
  apiGet(ActionTypes.GetSheetsDates, `jobs/${jobId}/sheets`);

export const getJobSheet = ({ jobId, jobSheetId }) =>
  apiGet(ActionTypes.GetSheetsDates, `jobs/${jobId}/sheets/${jobSheetId}`);

export const updateJobSheetNotes = (formData, { jobId, jobSheetId }) =>
  apiPut(
    ActionTypes.UpdateJobSheetNotes,
    `jobs/${jobId}/sheets/${jobSheetId}/notes`,
    formData
  );

export const scheduleJobItems = ({ date }, { jobId, jobItemIds }) =>
  apiPost(ActionTypes.ScheduleJobItems, `scheduling/jobs`, {
    jobOrJobSheetId: jobId,
    date,
    jobItemIds,
  });

export const rescheduleVisit = ({ date }, { jobSheetId }) =>
  apiPost(ActionTypes.RescheduleVisit, `scheduling/jobs`, {
    jobOrJobSheetId: jobSheetId,
    date,
  });

export const unscheduleVisit = ({ jobSheetId }) =>
  apiPost(ActionTypes.UnscheduleVisit, `scheduling/jobs`, {
    jobOrJobSheetId: jobSheetId,
    date: null,
  });

export const generateJobSheet =
  ({ jobId, jobSheetId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiPost(
        ActionTypes.GenerateJobSheet,
        `jobs/${jobId}/sheets/${jobSheetId}/generate`,
        { jobId, jobSheetId },
        { preventResponseHandling: true }
      )
    );

    if (result) {
      saveFile(result);
    }
    return result;
  };

export const cancelJob = (data, { jobId }) => {
  return apiDelete(ActionTypes.CancelJob, `jobs/${jobId}/cancel`, data);
};

export const rescheduleJob = (data, { jobId }) =>
  apiPut(ActionTypes.RescheduleJob, `jobs/${jobId}/reschedule`, data);

export const addRepeatJob = (data, { repeatScheduleId }) =>
  apiPost(
    ActionTypes.AddRepeatJob,
    `scheduling/${repeatScheduleId}/repeat`,
    data
  );

export const getJobRepeatSchedule = ({ pagination }, { repeatScheduleId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetJobRepeatSchedule,
    `jobs/repeat-schedule/${repeatScheduleId}`,
    {
      pageSize,
      pageNumber,
    }
  );
};

export const getJobComments = ({ jobId }) =>
  apiGet(ActionTypes.GetJobComments, `jobs/${jobId}/comments`);

export const addJobComment = ({ comment }, { jobId }) => {
  return apiPost(ActionTypes.AddJobComment, `jobs/${jobId}/comments`, {
    text: comment,
  });
};

export const getJobEquipment = ({ jobId }) =>
  apiGet(ActionTypes.GetJobEquipment, `jobs/${jobId}/equipment`);

export const updateJobEquipment = ({ data, jobId }) =>
  apiPut(ActionTypes.UpdateJobEquipment, `jobs/${jobId}/equipment`, data);

export const uncompleteJobItems = ({ jobId, selectedIds }) =>
  apiPut(ActionTypes.UncompleteJobItems, `jobs/${jobId}/items/uncomplete`, {
    selectedIds,
  });

export const invoiceJobItems = ({ jobId, selectedIds }) =>
  apiPut(ActionTypes.InvoiceJobItems, `jobs/${jobId}/items/invoice`, {
    selectedIds,
  });

export const updateJobDetails = (formData, jobId) =>
  apiPut(ActionTypes.UpdateJobDetails, `jobs/${jobId}`, {
    ...formData,
  });

export const generateContractJobQuote =
  ({ contractId, jobId, asDocx }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiPost(
        ActionTypes.GenerateContractJobQuote,
        `contracts/${contractId}/jobs/${jobId}/generate-quote`,
        {
          asDocx,
        },
        { preventResponseHandling: true }
      )
    );
    if (result) {
      saveFile(result);
    }
    return result;
  };

export const getContractJobQuoteEmailTemplate = ({ contractId, jobId }) => {
  return apiGet(
    ActionTypes.GetContractJobQuoteEmailTemplate,
    `contracts/${contractId}/jobs/${jobId}/email-quote`
  );
};

export const emailContractJobQuote = (data, { contractId, jobId }) => {
  return apiPost(
    ActionTypes.EmailContractJobQuote,
    `contracts/${contractId}/jobs/${jobId}/email-quote`,
    data
  );
};
