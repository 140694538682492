import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";

const NAMESPACE = "INVOICES/LIST";

const ActionTypes = {
  GetInvoices: `${NAMESPACE}/GET_INVOICES`,
  GetInvoice: `${NAMESPACE}/GET_INVOICE`,
  UpdateInvoice: `${NAMESPACE}/UPDATE_INVOICE`,
  ExportInvoiceToXero: `${NAMESPACE}/EXPORT_INVOICE_TO_XERO`,
  CreditInvoice: `${NAMESPACE}/CREDIT_INVOICE`,
  DeleteInvoice: `${NAMESPACE}/DELETE_INVOICE`,
  ExportInvoices: `${NAMESPACE}/EXPORT_INVOICES`,
};

export const getInvoices = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetInvoices, "invoices", {
    pageSize,
    pageNumber,
    ...filters,
  });
};

export const getInvoice = (invoiceId) =>
  apiGet(ActionTypes.GetInvoice, `invoices/${invoiceId}`);

export const updateInvoice = (data) => {
  const lines = data.lines.map((line) => ({
    ...line,
    id: Number.isInteger(line.id) ? null : line.id,
  }));

  return apiPut(ActionTypes.UpdateInvoice, `invoices/${data.id}`, {
    ...data,
    lines,
  });
};

export const exportInvoiceToXero = (invoiceId) =>
  apiPost(ActionTypes.ExportInvoiceToXero, `invoices/${invoiceId}/xero`);

export const creditXeroInvoice = (invoiceId) =>
  apiDelete(ActionTypes.CreditInvoice, `invoices/${invoiceId}/xero`);

export const deleteInvoice = (invoiceId) =>
  apiDelete(ActionTypes.DeleteInvoice, `invoices/${invoiceId}`);

export const exportInvoices = (filters) => async (dispatch) => {
  const result = await dispatch(
    apiGet(
      ActionTypes.ExportInvoices,
      "invoices/export",
      { ...filters },
      { preventResponseHandling: true }
    )
  );

  if (result.success) {
    saveFile(result);
  }

  return result;
};
