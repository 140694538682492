import {
  Form,
  Format,
  FormBuilder,
  ListBuilder,
  Table,
} from "@redriver/cinnamon";
import { MultiPermissionCheck, PermissionCheck } from "components/auth";
import { HeaderBar, TablePanel } from "components/containers";
import {
  ContractPricingType,
  ContractPricingTypeFields,
} from "constants/enums";
import { Actions, Targets } from "constants/Permissions";
import React, { Fragment, useState } from "react";
import { Image } from "semantic-ui-react";
import { getContractPlots } from "./actions";
import ContractPlotTableActions from "./ContractPlotTableActions";
import UploadContractPlots from "./UploadContractPlots";
import CheckIcon from "assets/icons/check.svg";
import { SignalRCallback } from "components/signalr";
import { Scheduling } from "constants/signalRMethods";
import ContractPlotsPanelActions from "./ContractPlotsPanelActions";

const ContractPlotsPanel = ({ contractId, pricingType }) => {
  const [selection, setSelection] = useState({ selected: [], deselected: [] });

  return (
    <ListBuilder
      withTable
      loadAction={getContractPlots}
      loadParams={{ contractId, pricingType }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: item.hasJob ? "tint-green" : "",
        })),
      })}
      renderList={(tableProps, state, events) => {
        const onReset = () => events.onReset({ reload: true, reset: ["data"] });
        return (
          <TablePanel
            className="plots-table"
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name={ContractPricingType[pricingType]}
                isSearchBar
                rightContent={
                  <Fragment>
                    <PermissionCheck
                      target={Targets.ContractPlot}
                      action={Actions.Create}
                    >
                      <UploadContractPlots
                        content="Upload Plot List"
                        contractId={contractId}
                        pricingType={pricingType}
                        onUploaded={onReset}
                      />
                    </PermissionCheck>
                    <ContractPlotsPanelActions
                      contractId={contractId}
                      pricingType={pricingType}
                      data={tableProps.data}
                      selectedItems={selection}
                      onSubmitted={() => {
                        setSelection({ selected: [], deselected: [] });
                        onReset();
                      }}
                    />
                  </Fragment>
                }
              >
                <FormBuilder
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  renderForm={(formProps) => (
                    <Form {...formProps}>
                      <Form.Input
                        field="search"
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No plots found"
          >
            <SignalRCallback
              methodName={Scheduling.JobStatusUpdated}
              callback={() => {
                if (state.loading) return;
                events.onRefresh();
              }}
              dependencies={[state.loading, events.onRefresh]}
            />
            <Table.SelectableColumn
              header="page"
              value={selection}
              onChange={setSelection}
              totalItems={state.total}
              preserveSelectedKeys
              canSelect={(item) => !item.hasJob}
              width="1%"
            />
            <Table.Column
              field="number"
              title={
                pricingType === ContractPricingTypeFields.InfrastructureWorks ||
                pricingType === ContractPricingTypeFields.MaintenanceWorks
                  ? "Claim"
                  : "Plot Number"
              }
              width="8%"
            />
            <Table.Column field="reference" title="Reference" width="10%" />
            <Table.Column field="description" title="Description" width="17%" />
            <Table.Column
              field="additionalComments"
              title="Additional Comments"
              width="25%"
            />
            <Table.Column
              title="Value"
              width="8%"
              render={(item) => (
                <Format.Number value={item.value} format="GBP" />
              )}
            />
            <Table.Column
              title="Scheduled"
              width="5%"
              render={(item) =>
                item.isScheduled && <Image src={CheckIcon} centered />
              }
            />
            <Table.Column
              title="Completed"
              width="5%"
              render={(item) =>
                item.isCompleted && <Image src={CheckIcon} centered />
              }
            />
            <Table.Column
              title="Invoiced"
              width="5%"
              render={(item) =>
                item.isInvoiced && <Image src={CheckIcon} centered />
              }
            />
            <Table.Column
              title="Invoiced Date"
              width="10%"
              render={(item) => (
                <Format.Date value={item.invoicedDate} format="short" />
              )}
            />
            <MultiPermissionCheck
              checks={[
                { target: Targets.ContractPlot, action: Actions.View },
                { target: Targets.ContractPlot, action: Actions.Delete },
              ]}
            >
              <Table.Column
                hoverable
                align="right"
                width="5%"
                render={(item) => (
                  <ContractPlotTableActions
                    plot={item}
                    contractId={contractId}
                    pricingType={pricingType}
                    onRefresh={onReset}
                  />
                )}
              />
            </MultiPermissionCheck>
          </TablePanel>
        );
      }}
    />
  );
};

export default ContractPlotsPanel;
