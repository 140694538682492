export const EnquiryTabKeys = {
  Overview: "overview",
  Email: "email",
  Documents: "documents",
  Quote: "currentQuote",
  Visits: "visits",
  Equipment: "equipment",
};

export const JobTabKeys = {
  Overview: "overview",
  Documents: "documents",
  ScopeOfWork: "scopeOfWork",
  Schedule: "schedule",
  RepeatSchedule: "repeatSchedule",
  Equipment: "equipment",
  FulfilmentOfWorks: "fulfilmentOfWorks",
};

export const InvoicedStatus = {
  ReadyToInvoice: "ReadyToInvoice",
  Invoiced: "Invoiced",
};

export const DepartmentColourOptions = {
  DarkRed: "#b94f5f",
  Red: "#ff363e",
  Orange: "#ff7f27",
  Yellow: "#fff200",
  Green: "#22b14c",
  Turquoise: "#00a2e8",
  Indigo: "#737cff",
  Purple: "#a349a4",
  Brown: "#b97a57",
  Rose: "#ffaec9",
  Gold: "#ffc90e",
  LightYellow: "#efe4b0",
  Lime: "#b5e61d",
  LightTurquoise: "#99d9ea",
  BlueGrey: "#7092be",
  Lavender: "#c8bfe7",
};

export const DepartmentColourNames = {
  DarkRed: "Dark Red",
  Red: "Red",
  Orange: "Orange",
  Yellow: "Yellow",
  Green: "Green",
  Turquoise: "Turquoise",
  Indigo: "Indigo",
  Purple: "Purple",
  Brown: "Brown",
  Rose: "Rose",
  Gold: "Gold",
  LightYellow: "Light Yellow",
  Lime: "Lime",
  LightTurquoise: "Light Turquoise",
  BlueGrey: "Blue-Grey",
  Lavender: "Lavender",
};

export const ContractTabKeys = {
  Overview: "overview",
  Documents: "documents",
  PlotWorksFencing: "plotWorksFencing",
  PlotWorksLandscaping: "plotWorksLandscaping",
  PosWorksFencing: "posWorksFencing",
  PosWorksLandscaping: "posWorksLandscaping",
  InfrastructureWorks: "infrastructureWorks",
  MaintenanceWorks: "maintenanceWorks",
  JobManagement: "jobManagement",
  RiskAssessment: "riskAssessment",
};

export const DashboardWidgetKeys = {
  ContractInvoiceValue: "ContractInvoiceValue",
  ContractJobStatus: "ContractJobStatus",
  ContractStatus: "ContractStatus",
  ContractCustomer: "ContractCustomer",
  ContractsByValue: "ContractsByValue",
  JobsByDepartment: "JobsByDepartment",
  Enquiries: "Enquiries",
  Quotes: "Quotes",
  Jobs: "Jobs",
};
