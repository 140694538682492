import { useSignalRCallback } from "modules/signalr";
import SignalRHubContext from "./SignalRHubContext";
import React from "react";

const CallbackListener = ({
  hubConnection,
  methodName,
  callback,
  dependencies,
}) => {
  useSignalRCallback(hubConnection, methodName, callback, dependencies);
  return null;
};

const SignalRCallback = ({
  hubConnection,
  methodName,
  callback,
  dependencies,
}) => {
  if (hubConnection) {
    return (
      <CallbackListener
        hubConnection={hubConnection}
        methodName={methodName}
        callback={callback}
        dependencies={dependencies}
      />
    );
  }

  return (
    <SignalRHubContext.Consumer>
      {({ hubConnection }) => (
        <CallbackListener
          hubConnection={hubConnection}
          methodName={methodName}
          callback={callback}
          dependencies={dependencies}
        />
      )}
    </SignalRHubContext.Consumer>
  );
};

export default SignalRCallback;
