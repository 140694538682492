import { apiGet, apiPost, apiDelete, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/LIST_PRODUCTS";

const ActionTypes = {
  GetProducts: `${NAMESPACE}/GET_PRODUCTS`,
  AddProduct: `${NAMESPACE}/ADD_PRODUCTS`,
  DeleteProduct: `${NAMESPACE}/DELETE_PRODUCTS`,
  UpdateProduct: `${NAMESPACE}/UPDATE_PRODUCTS`,
};

export const getProducts = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;

  return apiGet(ActionTypes.GetProducts, `products`, {
    pageNumber,
    pageSize,
    ...filters,
  });
};

export const addProduct = (data) =>
  apiPost(ActionTypes.AddProduct, `products`, data);

export const deleteProduct = (productId) =>
  apiDelete(ActionTypes.DeleteProduct, `products/${productId}`);

export const updateProduct = (data, productId) =>
  apiPut(ActionTypes.UpdateProduct, `products/${productId}`, data);
