import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/QUOTES";

export const ActionTypes = {
  GetCustomerQuotes: `${NAMESPACE}/GET_CUSTOMER_QUOTES`,
};

export const getCustomerQuotes = (
  { pagination },
  { customerId, searchFilters: { search } }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetCustomerQuotes,
    `customers/${customerId}/quotes`,
    {
      search,
      pageSize,
      pageNumber,
    }
  );
};
