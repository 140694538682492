import ContractOrange from "assets/icons/contract-solid-orange.svg";
import ContractPurple from "assets/icons/contract-solid-purple.svg";
import ContractRed from "assets/icons/contract-solid-red.svg";
import FencingIcon from "assets/icons/fence.svg";
import HeartbeatIcon from "assets/icons/heartbeat.svg";
import LandscapingIcon from "assets/icons/landscaping.svg";
import LocationIcon from "assets/icons/location.svg";
import UserIcon from "assets/icons/user.svg";
import UsersIcon from "assets/icons/users.svg";
import { PermissionCheck } from "components/auth";
import { BannerPanel } from "components/containers";
import {
  ContractContactPointsDropdown,
  FormatAddress,
} from "components/display";
import { SelectAddressModal } from "components/modals";
import { ContractJobType } from "constants/enums";
import { Actions, Targets } from "constants/Permissions";
import { CustomerRoutes } from "constants/routes";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";
import { editContractSiteAddress } from "./actions";
import ContractActions from "./ContractActions";
import EditContract from "./EditContract";

const ContractBanner = ({ contract, onRefresh }) => {
  const [selectAddressOpen, setSelectAddressOpen] = useState(false);
  const [contractDetailsOpen, setContractDetailsOpen] = useState(false);

  const contractIcon =
    contract.jobType === ContractJobType.Fencing
      ? ContractRed
      : contract.jobType === ContractJobType.Landscaping
      ? ContractOrange
      : ContractPurple;

  const customerContractNumberDisplay = contract.customerContractNumber
    ? ` - Contract Number: ${contract.customerContractNumber}`
    : "";

  const contractNumberDisplay = contract.contractNumber
    ? `KPS Contract Number: ${contract.contractNumber}`
    : "";

  const contactDetailsDisplay = contract.siteManager
    ? {
        type: "Site Manager",
        name: contract.siteManager?.name,
        contactPoints: contract.siteManager?.contactPoints ?? [],
      }
    : contract.quantitySurveyor
    ? {
        type: "Quantity Surveyor",
        name: contract.quantitySurveyor?.name,
        contactPoints: contract.quantitySurveyor?.contactPoints ?? [],
      }
    : {
        type: "Site Manager",
        name: "-",
        contactPoints: [],
      };

  return (
    <BannerPanel
      title={
        <div>
          <Image
            src={contractIcon}
            style={{ width: 20 }}
            spaced="right"
            verticalAlign="middle"
          />
          <span style={{ verticalAlign: "middle" }}>
            Contract {contract.reference} for{" "}
            {!contract.customerArchived ? (
              <PermissionCheck
                target={Targets.Contract}
                action={Actions.View}
                renderFailure={() => contract.customerName || ""}
              >
                <span>
                  <Link
                    to={CustomerRoutes.Customer(contract.customerId)}
                    className="main customer-link"
                  >
                    {contract.customerName}
                  </Link>
                </span>
              </PermissionCheck>
            ) : (
              contract.customerName
            )}
            {customerContractNumberDisplay}
            {contractNumberDisplay && (
              <span style={{ marginLeft: "2rem" }}>
                {contractNumberDisplay}
              </span>
            )}
            <span title="Status" style={{ marginLeft: "2rem" }}>
              <Image src={HeartbeatIcon} className="banner-icon" />
              {contract.statusDescription}
            </span>
          </span>
        </div>
      }
      infoItemList={[
        {
          icon: LocationIcon,
          text: contract.siteAddress
            ? FormatAddress(contract.siteAddress.address, null, true)
            : "No site address selected",
          tooltip: "Site Address",
          onClick: contract.siteAddress
            ? null
            : () => {
                setSelectAddressOpen(true);
              },
        },
        {
          header: "Site ID",
          text: contract.siteProjectName,
          tooltip: "Site ID",
        },
      ]}
      additionalInfoItemList={
        contactDetailsDisplay
          ? [
              {
                header: contactDetailsDisplay.type,
                text: contactDetailsDisplay.name,
                tooltip: contactDetailsDisplay.type,
              },
              {
                renderItem:
                  contactDetailsDisplay.contactPoints.length > 0
                    ? () => (
                        <span title="Contact Points">
                          <ContractContactPointsDropdown
                            contactPoints={contactDetailsDisplay.contactPoints}
                          />
                        </span>
                      )
                    : null,
              },
            ]
          : []
      }
      footerContent={
        <p>
          <span title="Contract Manager">
            <Image src={UserIcon} className="banner-icon" />
            <span>{contract.contractManagerName}</span>
          </span>
          <span title="Department">
            <Image src={UsersIcon} className="banner-icon" />
            <span>{contract.departmentName}</span>
          </span>
          <span title="Job Type">
            <Image
              src={
                contract.jobType === ContractJobType.Fencing
                  ? FencingIcon
                  : LandscapingIcon
              }
              className="banner-icon"
            />
            <span>{contract.jobTypeDescription}</span>
          </span>
        </p>
      }
      rightContent={
        <PermissionCheck target={Targets.Contract} action={Actions.Edit}>
          <ContractActions
            onSelectAddress={() => setSelectAddressOpen(true)}
            onEditDetails={() => setContractDetailsOpen(true)}
          />
          <SelectAddressModal
            visible={selectAddressOpen}
            entity={contract}
            address={contract.siteAddress}
            header="Contract Site Address"
            action={editContractSiteAddress}
            closeModal={(changed) => {
              setSelectAddressOpen(false);
              if (changed) {
                onRefresh();
              }
            }}
          />
          <EditContract
            contract={contract}
            visible={contractDetailsOpen}
            closeModal={(changed) => {
              setContractDetailsOpen(false);
              if (changed) {
                onRefresh();
              }
            }}
          />
        </PermissionCheck>
      }
    />
  );
};

export default ContractBanner;
