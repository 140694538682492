export const buildCurrencyString = (value) => {
  const pence = Math.round(value * 100);
  return `£${pence / 100}`;
};

export const buildContractTitleSuffix = ({
  filters,
  includeStatus,
  includeCustomer,
  includeContract,
}) => {
  const items = [];
  if (includeStatus && filters._contractStatusDisplay) {
    items.push(filters._contractStatusDisplay);
  }
  if (includeCustomer && filters._contractCustomerDisplay) {
    items.push(filters._contractCustomerDisplay);
  }
  if (includeContract && filters._contractIdDisplay) {
    items.push(filters._contractIdDisplay);
  }
  return items.length > 0 ? items.join(", ") : "All";
};
