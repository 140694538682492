import React from "react";
import { Button, Image } from "semantic-ui-react";

const IconButton = ({ icon, content, iconRight, ...props }) => (
  <Button icon {...props}>
    {!iconRight && <Image src={icon} alt="" aria-hidden="true" />}
    <span>{content}</span>
    {iconRight && <Image src={icon} alt="" aria-hidden="true" />}
  </Button>
);

export default IconButton;
