import React from "react";
import { Format } from "@redriver/cinnamon";
import { ScrollableListPanel } from "components/containers";

const CommentPanel = ({ ...props }) => (
  <ScrollableListPanel
    {...props}
    renderItem={(x) => <CommentItem comment={x} key={x.id} />}
  />
);

const CommentItem = ({ comment }) => {
  const lines = comment.text.split("\n");

  return (
    <section className="comment-item comment-item-bullet">
      <div className="comment-item-left">
        <header>
          {lines.map((x, i) => (
            <React.Fragment key={i}>
              <span>{x}</span>
              {i < lines.length - 1 && <br />}
            </React.Fragment>
          ))}
          <div style={{ float: "right" }}></div>
        </header>
        <footer className="comment-footer">
          <Format.DateTime
            value={comment.createdUtc}
            format="HH:mm:ss - DD MMM YYYY"
          />
          <span className="bullet-point">&bull;</span>
          <span>{comment.createdBy}</span>
        </footer>
      </div>
    </section>
  );
};

export default CommentPanel;
