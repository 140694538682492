import React from "react";
import { DateTime } from "luxon";
import { CustomerTypes, JobStatus } from "constants/enums";
import { ClearLabel } from "components/controls";
import { FieldNames } from "./JobsFilters";

const FieldMappings = {
  status: "Status",
  customerType: "Customer Type",
  department: "Department",
  contractManager: "Contract Manager",
  startDateFromUtc: "Start Date From",
  startDateToUtc: "Start Date To",
};

const FormatDisplay = (item) => {
  let value = item.value;
  switch (item.label) {
    case FieldNames.Status:
      value = item.value.map((x) => JobStatus[x]).join(", ");
      break;
    case FieldNames.CustomerTypes:
      value = item.value.map((x) => CustomerTypes[x]).join(", ");
      break;
    case FieldNames.StartDateFrom:
    case FieldNames.StartDateTo:
      value = DateTime.fromISO(item.value).toFormat("dd MMM yyyy");
  }

  return `${FieldMappings[item.label] || item.label}: ${value}`;
};

const JobsFilterDisplay = ({ filters, resetFilters }) => {
  const items = filters
    ? Object.keys(filters)
        .filter(
          (f) =>
            f !== FieldNames.Search &&
            f !== FieldNames.ContractManager &&
            f !== FieldNames.Department &&
            filters[f] != undefined &&
            filters[f] != null &&
            filters[f] != ""
        )
        .map((k) => {
          return {
            label: k,
            value: filters[k],
          };
        })
        .sort((a, b) => {
          if (
            a.label == FieldNames.StartDateFrom &&
            b.label == FieldNames.StartDateTo
          ) {
            return -1;
          } else if (
            b.label == FieldNames.StartDateFrom &&
            a.label == FieldNames.StartDateTo
          ) {
            return 1;
          }
          return 0;
        })
    : [];

  if (!items.length) return null;

  return (
    <div className="filter-display">
      {items.map((item) => (
        <div
          className="filter-display-item"
          key={item.label}
          title={FormatDisplay(item)}
        >
          {FormatDisplay(item)}
        </div>
      ))}
      <ClearLabel onClick={resetFilters} />
    </div>
  );
};

export default JobsFilterDisplay;
