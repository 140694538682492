import React from "react";
import { Grid } from "semantic-ui-react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import {
  ContractStatusesDropdown,
  DepartmentsTypeAhead,
  UsersTypeAhead,
} from "components/forms";
import { editContract } from "./actions";

const EditContract = ({ contract, visible, closeModal }) => (
  <ModalBuilder
    withForm
    visible={visible}
    initialData={contract}
    submitAction={editContract}
    onSubmitted={() => closeModal(true)}
    onCancel={closeModal}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Contract Details">
        <Form {...formProps}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <ContractStatusesDropdown
                  field="status"
                  textField="statusDescription"
                  label="Status"
                  placeholder="Select status"
                  clearable
                  required
                  fluid
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  field="siteProjectName"
                  label="Site ID"
                  required
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <DepartmentsTypeAhead
                  field="departmentId"
                  textField="departmentName"
                  label="Department"
                  placeholder="Select department"
                  clearable
                  required
                  fluid
                />
              </Grid.Column>
              <Grid.Column>
                <UsersTypeAhead
                  field="contractManagerId"
                  textField="contractManagerName"
                  label="Contract Manager"
                  placeholder="Select contract manager"
                  clearable
                  required
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Edit>
    )}
  />
);

export default EditContract;
