import React from "react";
import {
  cancelJob,
  getJobRepeatSchedule,
  rescheduleJob,
  addRepeatJob,
} from "./actions";
import {
  ActionButton,
  ListBuilder,
  ModalBuilder,
  Modal,
  Table,
  Form,
  Format,
} from "@redriver/cinnamon";
import { Button, Image } from "semantic-ui-react";
import { TablePanel, HeaderBar } from "components/containers";
import { Link } from "react-router-dom";
import { JobRoutes } from "constants/routes";
import { CancelJobForm } from "./JobCancelModal";
import { CancelType, JobStatus } from "constants/enums";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import CheckIcon from "assets/icons/check.svg";

const JobRepeatSchedulePanel = ({ job, onRefresh }) => {
  return (
    <ListBuilder
      withTable
      loadAction={getJobRepeatSchedule}
      loadParams={{ repeatScheduleId: job.repeatJobScheduleId }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <React.Fragment>
            <TablePanel
              pageSizePosition="footer"
              pageSizeOptions={[10, 25, 50, 100]}
              pagination={state.pagination}
              onPaginationChange={events.onChangePagination}
              totalResults={state.total}
              className="jobs-table"
              customHeader={
                <HeaderBar
                  dataKey="id"
                  name="Scheduled Jobs"
                  emptyMessage="No jobs found"
                  selectable
                  customTable
                  rightContent={
                    <PermissionCheck
                      target={Targets.JobScheduling}
                      action={Actions.Edit}
                    >
                      <ModalBuilder
                        submitAction={addRepeatJob}
                        onSubmitted={() => {
                          events.onRefresh();
                          onRefresh();
                        }}
                        submitParams={{
                          repeatScheduleId: job.repeatJobScheduleId,
                        }}
                        withForm
                        renderTrigger={(showModal) => (
                          <Button compact onClick={showModal}>
                            Add Job
                          </Button>
                        )}
                        renderModal={(modalProps, formProps) => {
                          return (
                            <Modal.Confirmation
                              header="Add Repeat Job"
                              cancelLabel="Cancel"
                              submitLabel="Add"
                              {...modalProps}
                            >
                              <Form {...formProps}>
                                <Form.Date
                                  label="Date"
                                  field="date"
                                  local
                                  required
                                />
                              </Form>
                            </Modal.Confirmation>
                          );
                        }}
                      />
                    </PermissionCheck>
                  }
                />
              }
              {...tableProps}
            >
              <Table.Column
                title="Job"
                width="15%"
                component={({ item }) => (
                  <Link className="main" to={JobRoutes.Job(item.id)}>
                    {item.ref}
                  </Link>
                )}
              />
              <Table.Column
                field="scheduledDate"
                title="Scheduled Date"
                width="15%"
                component={({ item }) => (
                  <Format.Date
                    value={item.scheduledDate}
                    format="D MMMM YYYY"
                  />
                )}
              />
              <Table.Column field="product" title="Product" width="50%" />
              <Table.Column
                title="Completed"
                width="1%"
                render={(item) =>
                  item.isCompleted && <Image src={CheckIcon} centered />
                }
              />
              <Table.Column
                title="Invoiced"
                width="1%"
                render={(item) =>
                  item.status === JobStatus.Invoiced && (
                    <Image src={CheckIcon} centered />
                  )
                }
              />
              <Table.Column
                align="right"
                render={(item) => (
                  <Table.Actions>
                    <PermissionCheck
                      target={Targets.JobScheduling}
                      action={Actions.Edit}
                    >
                      <ModalBuilder
                        onSubmitted={() => {
                          events.onRefresh();
                          onRefresh();
                        }}
                        submitParams={{ jobId: item.id }}
                        submitAction={rescheduleJob}
                        renderTrigger={(showModal) => (
                          <ActionButton
                            type="edit"
                            className="squareish"
                            onClick={showModal}
                          />
                        )}
                        withForm
                        renderModal={(modalProps, formProps) => (
                          <Modal
                            {...modalProps}
                            header="Reschedule Job"
                            submitLabel="Reschedule"
                          >
                            <Form {...formProps}>
                              <Form.Date
                                field="newDate"
                                label="New Date"
                                required
                                inline
                                local
                              />
                            </Form>
                          </Modal>
                        )}
                      />
                    </PermissionCheck>
                    <PermissionCheck
                      target={Targets.Job}
                      action={Actions.Delete}
                    >
                      <ModalBuilder
                        initialData={{ cancelType: CancelType.Single }}
                        onSubmitted={() => {
                          events.onRefresh();
                          onRefresh();
                        }}
                        submitParams={{ jobId: item.id }}
                        submitAction={cancelJob}
                        renderTrigger={(showModal) => (
                          <ActionButton
                            type="delete"
                            className="squareish"
                            onClick={showModal}
                          />
                        )}
                        withForm
                        renderModal={(modalProps, formProps) => (
                          <Modal
                            {...modalProps}
                            header="Cancel Job"
                            submitLabel="Cancel"
                          >
                            <CancelJobForm
                              formProps={formProps}
                              showCancelType={false}
                            />
                          </Modal>
                        )}
                      />
                    </PermissionCheck>
                  </Table.Actions>
                )}
              />
            </TablePanel>
          </React.Fragment>
        );
      }}
    />
  );
};

export default JobRepeatSchedulePanel;
