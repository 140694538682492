import React from "react";
import { KpsPageContent } from "components/containers";
import { Dashboard } from "features/Dashboard";

/**
 * This is the default authenticated 'dashboard' page for Cinnamon apps
 */
const DashboardPage = () => {
  return (
    <KpsPageContent>
      <Dashboard />
    </KpsPageContent>
  );
};

export default DashboardPage;
