import React from "react";
import { FieldNames } from "./ProductFilters";
import { ClearLabel } from "components/controls";

const FieldMappings = {
  category: "Category",
  department: "Department",
};

const FormatDisplay = (item) => {
  let value = item.value;
  return `${FieldMappings[item.label] || item.label}: ${value}`;
};

const ProductsFilterDisplay = ({ filters, resetFilters }) => {
  const items = filters
    ? Object.keys(filters)
        .filter(
          (f) =>
            f !== FieldNames.Search &&
            f !== FieldNames.CategoryId &&
            f !== FieldNames.DepartmentId
        )
        .map((k) => {
          return {
            label: k,
            value: filters[k],
          };
        })
    : [];
  if (!items.filter((i) => i.value != null && i.value != "").length) {
    return null;
  }

  return (
    <div className="filter-display">
      {items
        .filter((i) => i.value != null && i.value != "")
        .map((item) => (
          <div
            className="filter-display-item"
            key={item.label}
            title={FormatDisplay(item)}
          >
            {FormatDisplay(item)}
          </div>
        ))}
      <ClearLabel onClick={resetFilters} />
    </div>
  );
};

export default ProductsFilterDisplay;
