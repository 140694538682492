import React from "react";
import { CustomerDetails } from "features/Customers";
import { KpsPageContent } from "components/containers";
import { useParams } from "react-router-dom";
import { CustomerRoutes } from "constants/routes";
import { Breadcrumb } from "@redriver/cinnamon";

const CustomerDetailsPage = () => {
  const { id } = useParams();
  return (
    <KpsPageContent
      renderHeader={() => (
        <Breadcrumb>
          <Breadcrumb.Item link={CustomerRoutes.Customers} icon="chevron left">
            Return to Customers
          </Breadcrumb.Item>
        </Breadcrumb>
      )}
    >
      <CustomerDetails customerId={id} />
    </KpsPageContent>
  );
};

export default CustomerDetailsPage;
