import React from "react";
import { TriggerBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { generateContractJobQuote } from "./actions";

const GenerateContractJobQuote = ({
  as: As = Menu.Item,
  asDocx = false,
  contractId,
  jobId,
  onSubmitted,
  ...props
}) => (
  <TriggerBuilder
    submitAction={generateContractJobQuote}
    submitParams={{ contractId, jobId, asDocx }}
    onSubmitted={onSubmitted}
    renderTrigger={(fireTrigger) => <As onClick={fireTrigger} {...props} />}
  />
);

export default GenerateContractJobQuote;
