export default {
  View: "v",
  Create: "c",
  Edit: "e",
  Delete: "d",
  Export: "ex",
  Credit: "cr",
  Download: "dw",
  Uncomplete: "uc",
  MarkInvoiceable: "mi",
};
