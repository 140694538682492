import { ContentBuilder, useAuthClaims } from "@redriver/cinnamon";
import { claimExists } from "components/auth/utils";
import { TabbedContainer } from "components/containers";
import { SignalRHub } from "components/signalr";
import { ContractTabKeys } from "constants";
import { ContractPricingType } from "constants/enums";
import { Actions, Targets } from "constants/Permissions";
import { SignalRHubs } from "constants/routes";
import queryString from "query-string";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { getContractBanner } from "./actions";
import ContractBanner from "./ContractBanner";
import ContractCommentPanel from "./ContractCommentPanel";
import ContractDetailsPanel from "./ContractDetailsPanel";
import { ContractDocumentsPanel } from "./Documents";
import { ContractJobsPanel } from "./Jobs";
import { ContractPlotsPanel } from "./Plots";
import { ContractRiskAssessment } from "./RiskAssessments";

const ContractDetails = ({ contractId }) => {
  const { permissions } = useAuthClaims();
  const { search } = useLocation();
  const { initialTab } = queryString.parse(search);

  const tabs = [
    {
      key: ContractTabKeys.Overview,
      title: "Overview",
      renderContent: ({ onRefresh }) => (
        <section style={{ display: "flex", marginTop: "-1rem" }}>
          <div className="contract-panel">
            <ContractDetailsPanel
              contractId={contractId}
              onRefresh={onRefresh}
            />
          </div>
          <div className="contract-panel">
            <ContractCommentPanel contractId={contractId} />
          </div>
        </section>
      ),
      target: Targets.Contract,
      action: Actions.View,
    },
    {
      key: ContractTabKeys.Documents,
      title: "Documents",
      renderContent: () => <ContractDocumentsPanel contractId={contractId} />,
      target: Targets.ContractDocument,
      action: Actions.View,
    },
    {
      key: ContractTabKeys.JobManagement,
      renderContent: () => <ContractJobsPanel contractId={contractId} />,
      title: "Job Management",
      target: Targets.ContractJob,
      action: Actions.View,
    },
    {
      key: ContractTabKeys.RiskAssessment,
      renderContent: () => <ContractRiskAssessment contractId={contractId} />,
      title: "Risk Assessment",
      target: Targets.Contract,
      action: Actions.View,
    },
  ];

  return (
    <SignalRHub route={SignalRHubs.Scheduling}>
      <ContentBuilder
        loadAction={getContractBanner}
        loadParams={contractId}
        renderContent={(contract, state, events) => {
          const filteredTabs = tabs.filter(
            (t) =>
              (!t.target && !t.action) ||
              claimExists(permissions, t.target, t.action)
          );

          const defaultTabIndex = initialTab
            ? tabs.findIndex((x) => x.key === initialTab)
            : 0;

          let pricingTabs = [];
          (contract.pricingTypes || []).map((t) =>
            pricingTabs.push({
              key: ContractTabKeys[t],
              renderContent: () => (
                <ContractPlotsPanel contractId={contractId} pricingType={t} />
              ),
              title: ContractPricingType[t],
              target: Targets.ContractPlot,
              action: Actions.View,
            })
          );
          const jobManagementTabIndex = filteredTabs.findIndex(
            (x) => x.key === ContractTabKeys.JobManagement
          );

          if (pricingTabs.length > 0) {
            filteredTabs.splice(jobManagementTabIndex, 0, ...pricingTabs);
          }

          return (
            <Fragment>
              <ContractBanner
                contract={contract}
                onRefresh={events.onRefresh}
              />
              <TabbedContainer
                tabs={filteredTabs}
                initialTabIndex={defaultTabIndex}
                onRefresh={events.onRefresh}
              />
            </Fragment>
          );
        }}
      />
    </SignalRHub>
  );
};

export default ContractDetails;
