import { Modal, ModalBuilder, Form } from "@redriver/cinnamon";
import { UsersTypeAhead } from "components/forms";
import { DateTime, Interval } from "luxon";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { getEnquiryVisits, updateEnquiryVisits } from "./actions";

const EditVisit = ({ as: As = Button, onEdited, enquiry, ...props }) => {
  return (
    <ModalBuilder
      withForm
      loadAction={getEnquiryVisits}
      loadParams={{ enquiryId: enquiry.id }}
      loadTransform={(data) => ({
        visits: data.visits.map((v) => ({
          ...v,
          duration: Interval.fromISO(
            `${v.startDateTimeLocal}/${v.endDateTimeLocal}`
          ).length("hours"),
          startDate: DateTime.fromISO(v.startDateTimeLocal).toISODate(),
          startTime: DateTime.fromISO(v.startDateTimeLocal).toISOTime({
            includeOffset: false,
            suppressMilliseconds: true,
          }),
        })),
      })}
      submitAction={updateEnquiryVisits}
      submitParams={{ enquiryId: enquiry.id }}
      onSubmitted={onEdited}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Edit {...modalProps} header="Edit Visits">
          <Form {...formProps}>
            <Form.If
              condition={({ fields }) =>
                !fields?.visits || fields?.visits?.length === 0
              }
            >
              <p style={{ marginTop: 0 }}>No visits for this enquiry</p>
            </Form.If>

            <Form.Array field="visits" arrayKey="id">
              {({ fields }) => (
                <Form.Group className="edit-visit-row">
                  <Form.Date
                    required
                    local
                    field="startDate"
                    label="Date"
                    width={10}
                  />
                  <UsersTypeAhead
                    label="Contract Manager"
                    placeholder="Select contract manager"
                    field="contractManagerId"
                    width={20}
                    required
                    unknownValueOptions={
                      fields.contractManagerId
                        ? [
                            {
                              text: fields.contractManager,
                              value: fields.contractManagerId,
                            },
                          ]
                        : null
                    }
                  />
                  {!fields.isAllDay && (
                    <Form.Time required field="startTime" label="Time" />
                  )}
                  {!fields.isAllDay && (
                    <Form.Numeric
                      required
                      field="duration"
                      label="Duration"
                      subLabel="Hours"
                      subLabelPosition="right"
                      decimalPlaces={2}
                      width={14}
                    />
                  )}
                  <Form.Checkbox
                    className="aligned-checkbox"
                    field="isAllDay"
                    label="All Day"
                  />
                  <div className="field">
                    <label aria-hidden>&nbsp;</label>
                    <Form.ArrayRemover
                      style={{
                        height: "fit-content",
                        alignSelf: "center",
                        padding: "0.5rem",
                      }}
                    >
                      <Icon
                        name="trash"
                        style={{ marginRight: 0, marginLeft: 0 }}
                      />
                    </Form.ArrayRemover>
                  </div>
                </Form.Group>
              )}
            </Form.Array>
          </Form>
        </Modal.Edit>
      )}
    />
  );
};

export default EditVisit;
