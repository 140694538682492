import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { reinstateCustomer } from "./actions";

const ReinstateCustomer = ({
  as: As = Button,
  customer,
  onSubmitted,
  ...props
}) => (
  <ModalBuilder
    submitAction={reinstateCustomer}
    submitParams={customer.id}
    onSubmitted={onSubmitted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Reinstate Customer">
        Are you sure you wish to reinstate <b>{customer.displayName}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default ReinstateCustomer;
