import React, { useState } from "react";
import JobBanner from "./JobBanner";
import { ContentBuilder, useAuthClaims } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";
import { Targets, Actions } from "constants/Permissions";
import { TabbedContainer } from "components/containers";
import { getJob } from "./actions";
import JobCommentPanel from "./JobCommentPanel";
import EnquiryDescriptionPanel from "features/Enquiries/EnquiryDetails/EnquiryDescriptionPanel";
import EnquiryDocumentsPanel from "features/Enquiries/EnquiryDetails/Documents/EnquiryDocumentsPanel";
import JobScopeOfWorkPanel from "./JobScopeOfWorkPanel";
import JobSchedulePanel from "./JobSchedulePanel";
import JobRepeatSchedulePanel from "./JobRepeatSchedulePanel";
import JobEquipmentPanel from "./JobEquipmentPanel";
import { JobTabKeys } from "constants/index";
import { useLocation } from "react-router-dom";
import { JobDocumentsPanel } from "./Documents";
import JobDetailsLinkPanel from "./JobDetailsLinkPanel";
import { JobFulfilmentOfWorksPanel } from "./FulfilmentOfWorks";

const JobDetails = ({ jobId }) => {
  const location = useLocation();
  const { permissions } = useAuthClaims();

  const [historyUpdatedUtc, setHistoryUpdatedUtc] = useState(null);

  const tabs = [
    {
      key: JobTabKeys.Overview,
      title: "Overview",
      renderContent: ({ onRefresh }, { job }) => (
        <JobDetailsTab job={job}>
          {job.enquiryId && (
            <EnquiryDescriptionPanel
              enquiryId={job.enquiryId}
              onRefresh={onRefresh}
            />
          )}
          <JobCommentPanel jobId={job.id} lastUpdatedUtc={historyUpdatedUtc} />
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.Documents,
      title: "Documents",
      renderContent: ({ onRefresh }, { job }) => (
        <JobDetailsTab job={job}>
          {job.enquiryId ? (
            <EnquiryDocumentsPanel
              enquiryId={job.enquiryId}
              jobId={job.id}
              onRefresh={onRefresh}
            />
          ) : (
            <JobDocumentsPanel jobId={job.id} onRefresh={onRefresh} />
          )}
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.ScopeOfWork,
      title: "Scope of Work",
      renderContent: ({ onRefresh }, { job }) => (
        <JobDetailsTab job={job}>
          <JobScopeOfWorkPanel
            job={job}
            permissionTarget={job.enquiryId ? Targets.Job : Targets.ContractJob}
            onRefresh={onRefresh}
          />
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.Schedule,
      title: "Schedule",
      renderContent: ({ onRefresh }, { job }) => (
        <JobDetailsTab job={job}>
          <JobSchedulePanel job={job} onRefresh={onRefresh} />
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.RepeatSchedule,
      title: "Repeat Schedule",
      hidden: (job) => !job.repeatJobScheduleId,
      renderContent: ({ onRefresh }, { job }) => (
        <JobDetailsTab job={job}>
          <JobRepeatSchedulePanel job={job} onRefresh={onRefresh} />
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.Equipment,
      title: "Equipment",
      renderContent: (_, { job }) => (
        <JobDetailsTab job={job}>
          <JobEquipmentPanel jobId={job.id} />
        </JobDetailsTab>
      ),
    },
    {
      key: JobTabKeys.FulfilmentOfWorks,
      title: "Fulfilment of Works",
      target: Targets.JobFulfilmentOfWorks,
      action: Actions.View,
      renderContent: (_, { job }) => (
        <JobDetailsTab job={job}>
          <JobFulfilmentOfWorksPanel job={job} />
        </JobDetailsTab>
      ),
    },
  ];

  const defaultTabIndex =
    location.state && !!location.state.tabName
      ? tabs.findIndex((x) => x.key == location.state.tabName)
      : 0;

  const filteredTabs = tabs.filter(
    (t) =>
      (!t.target && !t.action) || claimExists(permissions, t.target, t.action)
  );

  return (
    <PermissionCheck target={Targets.Job} action={Actions.View}>
      <ContentBuilder
        className="job-details-wrapper"
        loadAction={getJob}
        loadParams={{ jobId }}
        initialData={{ id: jobId }}
        renderContent={(job, state, events) => (
          <React.Fragment>
            <JobBanner
              job={job}
              onRefresh={() => {
                events.onRefresh();
                setHistoryUpdatedUtc(Date.now());
              }}
            />
            <TabbedContainer
              job={{ ...job, id: jobId }}
              onRefresh={events.onRefresh}
              tabs={filteredTabs.filter((t) => !t.hidden || !t.hidden(job))}
              initialTabIndex={defaultTabIndex}
            />
          </React.Fragment>
        )}
      />
    </PermissionCheck>
  );
};

const JobDetailsTab = ({ job, children }) => {
  return (
    <section style={{ display: "flex", height: "100%" }}>
      {job.enquiryId && (
        <div className="job-side-panel">
          <JobDetailsLinkPanel job={job} />
        </div>
      )}
      <div className="job-tab-content">{children}</div>
    </section>
  );
};

export default JobDetails;
