import React, { useState } from "react";
import { LinePanel, HeaderBar } from "components/containers";
import { ListBuilder, TileGrid, useAuthClaims } from "@redriver/cinnamon";
import { getCustomerAddresses } from "./actions";
import ResidentialGreen from "assets/icons/residential-green.svg";
import CommercialBlue from "assets/icons/commercial-blue.svg";
import { PermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";
import { Targets, Actions } from "constants/Permissions";
import { Button } from "semantic-ui-react";
import AddCustomerAddress from "./AddCustomerAddress";
import { AddressTile } from "components/addresses";
import EditCustomerAddress from "./EditCustomerAddress";
import { CustomerTypes } from "constants/enums";
import DeleteCustomerAddress from "./DeleteCustomerAddress";

const CustomerAddresses = ({ customer, onRefresh }) => {
  const { permissions } = useAuthClaims();
  const canEdit = claimExists(permissions, Targets.Customer, Actions.Edit);

  const refreshAddresses = (refresh) => {
    refresh();
    onRefresh();
  };

  const [editId, setEditId] = useState(null);
  return (
    <section style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        <ListBuilder
          withTiles
          loadAction={getCustomerAddresses}
          loadParams={{ customerId: customer.id }}
          renderList={(tileProps, state, events) => (
            <React.Fragment>
              <LinePanel className="header">
                <HeaderBar
                  name="Addresses"
                  rightContent={
                    <PermissionCheck
                      target={Targets.Customer}
                      action={Actions.Edit}
                    >
                      <AddCustomerAddress
                        customerId={customer.id}
                        customerType={customer.type}
                        firstAddress={state.total === 0}
                        onRefresh={() => refreshAddresses(events.onRefresh)}
                        renderTrigger={(showModal) => (
                          <Button
                            className="compact"
                            content="New Address"
                            onClick={showModal}
                          />
                        )}
                      />
                    </PermissionCheck>
                  }
                />
              </LinePanel>
              <EditCustomerAddress
                modalOpen={!!editId}
                customerAddress={
                  tileProps.data.find((x) => x.id == editId) || {}
                }
                customerId={customer.id}
                closeModal={() => setEditId(null)}
                onRefresh={() => refreshAddresses(events.onRefresh)}
              />
              <TileGrid
                {...tileProps}
                dataKey="id"
                wide
                className="card-grid-fill"
                render={(item) => (
                  <AddressTile
                    highlight={
                      customer.type == CustomerTypes.Residential
                        ? "faded-green"
                        : "faded-blue"
                    }
                    address={{ ...item.address, id: item.id }}
                    onClick={canEdit ? () => setEditId(item.id) : null}
                    primaryAddress={item.isPrimaryAddress}
                    backgroundUrl={
                      customer.type == CustomerTypes.Residential
                        ? ResidentialGreen
                        : CommercialBlue
                    }
                    canDelete={canEdit}
                    renderDeleteAddress={(address) => (
                      <DeleteCustomerAddress
                        address={address}
                        customerId={customer.id}
                        onDeleted={events.onRefresh}
                      />
                    )}
                  />
                )}
              />
            </React.Fragment>
          )}
        />
      </div>
    </section>
  );
};

export default CustomerAddresses;
