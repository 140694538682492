import React, { useState } from "react";
import { ContentBuilder, Format, ListBuilder, Table } from "@redriver/cinnamon";
import CheckIcon from "assets/icons/check.svg";
import { PermissionCheck } from "components/auth";
import { HeaderBar, LightPanel, TablePanel } from "components/containers";
import { JobStatus } from "constants/enums";
import { Actions } from "constants/Permissions";
import { Image } from "semantic-ui-react";
import { getJobItems } from "./actions";
import EditJobItemModal from "./EditJobItemModal";
import JobScopeOfWorkPanelActions from "./JobScopeOfWorkPanelActions";
import DeleteJobItemModal from "./DeleteJobItemModal";

const ScopeOfWorkPanel = ({ job, permissionTarget, onRefresh }) => {
  const [selectedItems, setSelectedItems] = useState({
    selected: [],
    deselected: [],
  });

  return (
    <ListBuilder
      loadAction={getJobItems}
      loadParams={{ jobId: job.id }}
      renderList={(data, state, events) => {
        return (
          <React.Fragment>
            <TablePanel
              data={data}
              dataKey="id"
              emptyMessage={`No ${job.enquiryId ? "quote " : ""}items found`}
              columns={9}
              customHeader={
                <HeaderBar
                  name="Scope of Work"
                  rightContent={
                    <PermissionCheck
                      target={permissionTarget}
                      action={Actions.Edit}
                    >
                      <JobScopeOfWorkPanelActions
                        job={job}
                        data={data}
                        selectedItems={selectedItems}
                        onSubmitted={() => {
                          setSelectedItems({ selected: [], deselected: [] });
                          events.onRefresh();
                          onRefresh();
                        }}
                      />
                    </PermissionCheck>
                  }
                />
              }
            >
              {(item) => (
                <React.Fragment>
                  <Table.SelectableColumn
                    width="2%"
                    className={
                      item?.isScheduled &&
                      !selectedItems.selected.includes(item.id) &&
                      !selectedItems.allSelected
                        ? "item-scheduled"
                        : null
                    }
                    value={selectedItems}
                    onChange={setSelectedItems}
                    totalItems={data ? data.length : 0}
                    header="all"
                    canSelect={(item) => !item.isInvoiceable}
                    canSelectAll={() =>
                      data.some((item) => !item.isInvoiceable)
                    }
                  />
                  {job.contractId && (
                    <Table.Column
                      field="contractPlotDescription"
                      title="Plot"
                      width="10%"
                    />
                  )}
                  <Table.Column
                    field="productName"
                    title="Product"
                    width="10%"
                  />
                  <Table.Column
                    field="description"
                    title="Description"
                    width="25%"
                  />
                  <Table.Column field="quantity" title="Quantity" width="4%" />
                  <Table.Column
                    title="Net Price"
                    component={NetPrice}
                    width="4%"
                  />
                  <Table.Column title="VAT" component={Vat} width="4%" />
                  {job.enquiryId && (
                    <React.Fragment>
                      <Table.Column
                        title="Cost"
                        component={UnitCost}
                        width="4%"
                      />
                      <Table.Column
                        title="Total"
                        component={GrossPrice}
                        width="4%"
                      />
                    </React.Fragment>
                  )}
                  <Table.Column
                    title="Completed"
                    width="4%"
                    render={(item) => {
                      if (item.isComplete) {
                        return <Image src={CheckIcon} centered />;
                      }
                    }}
                  />
                  <Table.Column
                    title="Invoiced"
                    width="4%"
                    render={(item) => {
                      if (
                        (item.contractId && item.isInvoiced) ||
                        item.isInvoiceable
                      ) {
                        return <Image src={CheckIcon} centered />;
                      }
                    }}
                  />
                  <PermissionCheck
                    target={permissionTarget}
                    action={Actions.Edit}
                  >
                    <Table.Column
                      width="0%"
                      render={(item) => (
                        <EditJobItemModal
                          initialData={item}
                          onSubmitted={() => {
                            events.onRefresh();
                            onRefresh();
                          }}
                          disabled={
                            job.status == JobStatus.Completed ||
                            job.status == JobStatus.Invoiced ||
                            item.isComplete
                          }
                          job={job}
                          jobItemId={item.id}
                        />
                      )}
                    />
                    <Table.Column
                      width="0%"
                      render={(item) => (
                        <DeleteJobItemModal
                          jobId={job.id}
                          jobItemId={item.id}
                          onSubmitted={() => {
                            events.onRefresh();
                            onRefresh();
                          }}
                        />
                      )}
                    />
                  </PermissionCheck>
                </React.Fragment>
              )}
            </TablePanel>
            {job.enquiryId && (
              <LightPanel
                headerText="Quote Notes"
                className="quote-notes-panel"
              >
                <ContentBuilder
                  initialData={job}
                  renderContent={(data) => {
                    return <pre>{data.notes}</pre>;
                  }}
                />
              </LightPanel>
            )}
          </React.Fragment>
        );
      }}
    />
  );
};

const UnitCost = ({ item }) => {
  return <Format.Number value={item.unitCost} format="GBP" />;
};

const GrossPrice = ({ item }) => {
  return <Format.Number value={item.grossPrice} format="GBP" />;
};

const Vat = ({ item }) => {
  return <Format.Number value={item.vat} format="GBP" />;
};

const NetPrice = ({ item }) => {
  return <Format.Number value={item.netPrice} format="GBP" />;
};

export default ScopeOfWorkPanel;
