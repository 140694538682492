import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { TemplateDocumentRoutes } from "constants/routes";
import TemplateDocumentListPage from "./TemplateDocumentListPage";

const TemplateDocumentRouter = () => (
  <Switch>
    <Route
      exact
      path={TemplateDocumentRoutes.TemplateDocuments}
      component={TemplateDocumentListPage}
    />
    <Route
      render={() => <Redirect to={TemplateDocumentRoutes.TemplateDocument} />}
    />
  </Switch>
);

export default TemplateDocumentRouter;
