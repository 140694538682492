import React, { useState } from "react";
import {
  Form,
  Format,
  FormBuilder,
  ListBuilder,
  Table,
} from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { HeaderBar, TablePanel } from "components/containers";
import { Actions, Targets } from "constants/Permissions";
import { JobRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import { FormGroup, Image } from "semantic-ui-react";
import { getContractJobs } from "./actions";
import AddJobFromContract from "./AddJobFromContract";
import CheckIcon from "assets/icons/check.svg";
import ContractJobTableActions from "./ContractJobTableActions";
import { JobStatus } from "constants/enums";
import { JobTabKeys } from "constants";
import { ContractPricingTypesDropdown } from "components/forms";
import ContractJobsPanelActions from "./ContractJobsPanelActions";
import { SignalRCallback } from "components/signalr";
import { Scheduling } from "constants/signalRMethods";

const ContractJobsPanel = ({ contractId }) => {
  const history = useHistory();
  const [selection, setSelection] = useState({ selected: [], deselected: [] });

  return (
    <ListBuilder
      loadAction={getContractJobs}
      loadParams={contractId}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(data, state, events) => {
        const onReset = () => events.onReset({ reload: true, reset: ["data"] });
        return (
          <TablePanel
            data={data}
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                name="Job Management"
                isSearchBar
                rightContent={
                  <PermissionCheck
                    target={Targets.ContractJob}
                    action={Actions.Create}
                  >
                    <AddJobFromContract contractId={contractId}>
                      Add Job
                    </AddJobFromContract>
                    <ContractJobsPanelActions
                      contractId={contractId}
                      data={data}
                      selectedItems={selection}
                      onSubmitted={() => {
                        setSelection({ selected: [], deselected: [] });
                        onReset();
                      }}
                    />
                  </PermissionCheck>
                }
              >
                <FormBuilder
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  renderForm={(formProps) => (
                    <Form className="list-filters" {...formProps}>
                      <FormGroup widths="equal">
                        <Form.Input
                          field="search"
                          placeholder="Search"
                          icon="search"
                        />
                        <ContractPricingTypesDropdown
                          className="type"
                          field="jobType"
                          placeholder="Job Type"
                          clearable
                        />
                      </FormGroup>
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            dataKey="jobId"
            emptyMessage="No jobs found"
            onRowClick={(item) =>
              history.push(
                `${JobRoutes.Job(item.jobId)}?contractId=${item.contractId}`,
                {
                  tabName: JobTabKeys.ScopeOfWork,
                }
              )
            }
          >
            <SignalRCallback
              methodName={Scheduling.JobStatusUpdated}
              callback={() => {
                if (state.loading) return;
                events.onRefresh();
              }}
              dependencies={[state.loading, events.onRefresh]}
            />
            <Table.SelectableColumn
              header="page"
              value={selection}
              onChange={setSelection}
              totalItems={state.total}
              preserveSelectedKeys
              width="1%"
            />
            <Table.Column field="reference" title="Job" width="7%" />
            <Table.Column
              field="jobTypeDescription"
              title="Job Type"
              width="8%"
            />
            <Table.Column field="contractPlots" title="Plots" width="5%" />
            <Table.Column field="product" title="Product" width="8%" />
            <Table.Column field="description" title="Description" width="10%" />
            <Table.Column
              title="Value"
              width="5%"
              render={(item) =>
                item.value && <Format.Number value={item.value} format="GBP" />
              }
            />
            <Table.Column field="statusDescription" title="Status" width="6%" />
            <Table.Column
              title="Completed"
              width="1%"
              render={(item) =>
                item.isComplete && <Image src={CheckIcon} centered />
              }
            />
            <Table.Column
              title="Invoiced"
              width="1%"
              render={(item) =>
                item.status === JobStatus.Invoiced && (
                  <Image src={CheckIcon} centered />
                )
              }
            />
            <Table.Column
              hoverable
              align="right"
              width="1%"
              render={(item) =>
                item.status === JobStatus.Draft && (
                  <ContractJobTableActions
                    job={item}
                    contractId={contractId}
                    onRefresh={onReset}
                  />
                )
              }
            />
          </TablePanel>
        );
      }}
    />
  );
};

export default ContractJobsPanel;
