import { ContractStatus, EnquiryStatus, JobStatus } from "constants/enums";

import HourGlass from "assets/icons/hour-glass.svg";
import PoundSterling from "assets/icons/pound-sterling.svg";
import Check from "assets/icons/check.svg";
import Calendar from "assets/icons/calendar.svg";
import Cross from "assets/icons/cross.svg";

export const EnquiryStatusTintMapping = {
  [EnquiryStatus.Open]: "red",
  [EnquiryStatus.InProgress]: "orange",
  [EnquiryStatus.Quoted]: "green",
  [EnquiryStatus.Converted]: "light-blue",
  [EnquiryStatus.Closed]: "grey",
};

export const JobStatusTintMapping = {
  [JobStatus.ToBeScheduled]: "red",
  [JobStatus.Pending]: "light-blue",
  [JobStatus.Scheduled]: "orange",
  [JobStatus.Completed]: "green",
  [JobStatus.Invoiced]: "blue",
  [JobStatus.Cancelled]: "grey",
};

export const JobStatusIconMapping = {
  [JobStatus.ToBeScheduled]: HourGlass,
  [JobStatus.Pending]: HourGlass,
  [JobStatus.Scheduled]: Calendar,
  [JobStatus.Completed]: Check,
  [JobStatus.Invoiced]: PoundSterling,
  [JobStatus.Cancelled]: Cross,
};

export const ContractStatusTintMapping = {
  [ContractStatus.New]: "red",
  [ContractStatus.Quoted]: "green",
  [ContractStatus.OnHold]: "yellow",
  [ContractStatus.InProgress]: "orange",
  [ContractStatus.Completed]: "light-blue",
  [ContractStatus.Cancelled]: "grey",
};
