import { DateTime } from "luxon";
import { apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/DETAILS/VISITS";

export const ActionTypes = {
  GetEnquiryVisits: `${NAMESPACE}/GET_ENQUIRY_VISITS`,
  AddEnquiryVisit: `${NAMESPACE}/ADD_ENQUIRY_VISIT`,
  NoVisitRequired: `${NAMESPACE}/NO_VISIT_REQUIRED`,
  GetCalendarConflicts: `${NAMESPACE}/GET_CALENDAR_CONFLICTS`,
  UpdateEnquiryVisits: `${NAMESPACE}/UPDATE_ENQUIRY_VISITS`,
};

export const getEnquiryVisits = ({ enquiryId }) =>
  apiGet(ActionTypes.GetEnquiryVisits, `enquiries/${enquiryId}/visits`);

export const addEnquiryVisit = (data, { enquiryId }) => {
  const timeComponents = data.startTime.split(":");

  let date = DateTime.fromISO(data.startDate);
  if (!data.isAllDay) {
    date = date.set({
      hour: parseInt(timeComponents[0]),
      minute: parseInt(timeComponents[1]),
    });
  }
  return apiPost(
    ActionTypes.AddEnquiryVisit,
    `enquiries/${enquiryId}/visits`, //data
    {
      estimatorId: data.estimatorId,
      startDateTime: date.toFormat("yyyy-MM-dd'T'T:ss"),
      duration: !data.isAllDay ? data.duration : null,
      isAllDay: data.isAllDay,
    }
  );
};

export const noVisitRequired = (data, { enquiryId }) => {
  return apiPut(
    ActionTypes.NoVisitRequired,
    `enquiries/${enquiryId}/no-visit-required`,
    { noVisitRequired: data.noVisitRequired }
  );
};

export const getCalendarConflicts = (data) => {
  const timeComponents = data.startTime.split(":");

  let date = DateTime.fromISO(data.startDate);
  if (!data.isAllDay) {
    date = date.set({
      hour: parseInt(timeComponents[0]),
      minute: parseInt(timeComponents[1]),
    });
  }

  return apiGet(
    ActionTypes.GetCalendarConflicts,
    `outlook-calendar/conflicts`,
    {
      userId: data.estimatorId,
      startDateTime: date.toFormat("yyyy-MM-dd'T'T:ss"),
      duration: !data.isAllDay ? data.duration : null,
      isAllDay: data.isAllDay,
    }
  );
};

export const updateEnquiryVisits = (data, { enquiryId }) => {
  data.visits.forEach((visit) => {
    const timeComponents = visit.startTime.split(":");
    if (timeComponents.length === 2) {
      visit.startTime = `${visit.startTime}:00`;
    }
  });

  return apiPut(
    ActionTypes.UpdateEnquiryVisits,
    `enquiries/${enquiryId}/visits`,
    data
  );
};
