import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "SYSTEM/LOGIN";
export const REQUEST_LOGIN = `${NAMESPACE}/REQUEST`;
export const REFRESH_JWT = `${NAMESPACE}/REFRESH_JWT`;

export const onRefreshJwt = (token) =>
  apiPost(
    REFRESH_JWT,
    "auth/token/refresh",
    { token },
    { auth: false, preventErrorNotification: true }
  );
