import React from "react";
import { ActionBar } from "@redriver/cinnamon";

const HeaderBar = ({ name, children, rightContent, isSearchBar }) => {
  const rightItems = Array.isArray(rightContent)
    ? rightContent
    : rightContent != null
    ? [rightContent]
    : [];
  return (
    <ActionBar className="header-bar">
      <ActionBar.Item className="header-bar-title">{name}</ActionBar.Item>
      <ActionBar.Item className={isSearchBar ? "search-filter-display" : ""}>
        {children}
      </ActionBar.Item>
      <ActionBar.Space />
      {rightItems.map((x, i) => (
        <ActionBar.Item
          key={i}
          className="header-bar-right"
          fluid
          align="right"
        >
          {x}
        </ActionBar.Item>
      ))}
    </ActionBar>
  );
};

export default HeaderBar;
