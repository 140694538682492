import React from "react";
import CustomerBanner from "./CustomerBanner";
import { getCustomer } from "./actions";
import { ContentBuilder, useAuthClaims } from "@redriver/cinnamon";
import { claimExists } from "components/auth/utils";
import { TabbedContainer } from "components/containers";
import CustomerDashboard from "./CustomerOverview";
import CustomerAddresses from "./CustomerAddresses";
import CustomerEnquiries from "./CustomerEnquiries";
import CustomerQuotes from "./CustomerQuotes";
import CustomerJobs from "./CustomerJobs";
import CustomerInvoices from "./CustomerInvoices";
import { Targets, Actions } from "constants/Permissions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const CustomerOverview = ({ customerId }) => {
  const { permissions } = useAuthClaims();
  const { search } = useLocation();
  const { newCustomerAddress } = queryString.parse(search);

  const tabs = [
    {
      key: "overview",
      title: "Overview",
      renderContent: ({ onRefresh }, { customer }) => (
        <CustomerDashboard customer={customer} onRefresh={onRefresh} />
      ),
    },
    {
      key: "addresses",
      title: "Addresses",
      renderContent: ({ onRefresh }, { customer }) => (
        <CustomerAddresses onRefresh={onRefresh} customer={customer} />
      ),
    },
    {
      key: "enquiries",
      title: "Enquiries",
      renderContent: ({ onRefresh }, { customer }) => (
        <CustomerEnquiries
          customer={{ id: customerId, ...customer }}
          onRefresh={onRefresh}
        />
      ),
      target: Targets.Enquiry,
      action: Actions.View,
    },
    {
      key: "quotes",
      title: "Quotes",
      renderContent: ({ customer }) => (
        <CustomerQuotes customer={{ id: customerId, ...customer }} />
      ),
      target: Targets.Quote,
      action: Actions.View,
    },
    {
      key: "jobs",
      title: "Jobs",
      renderContent: ({ customer }) => (
        <CustomerJobs customer={{ id: customerId, ...customer }} />
      ),
      target: Targets.Job,
      action: Actions.View,
    },
    {
      key: "invoices",
      title: "Invoices",
      renderContent: ({ customer }) => (
        <CustomerInvoices customer={{ id: customerId, ...customer }} />
      ),
      target: Targets.Invoice,
      action: Actions.View,
    },
  ];

  const filteredTabs = tabs.filter(
    (t) =>
      (!t.target && !t.action) || claimExists(permissions, t.target, t.action)
  );

  const defaultTabIndex = newCustomerAddress
    ? tabs.findIndex((x) => x.key === "addresses")
    : 0;

  return (
    <ContentBuilder
      loadAction={getCustomer}
      loadParams={{ customerId }}
      initialData={{ address: {} }}
      renderLoading={false}
      renderContent={(customer, state, events) => (
        <React.Fragment>
          <CustomerBanner
            customer={{ ...customer, id: customerId }}
            onRefresh={events.onRefresh}
            loading={state.loading}
          />
          <TabbedContainer
            customer={{ ...customer, id: customerId }}
            onRefresh={events.onRefresh}
            tabs={filteredTabs}
            initialTabIndex={defaultTabIndex}
          />
        </React.Fragment>
      )}
    />
  );
};

export default CustomerOverview;
