import React from "react";
import { Actions, Targets } from "constants/Permissions";
import { addContractComment, getContractComments } from "./actions";
import { CommentPanel } from "components/comments";
import { ContentBuilder } from "@redriver/cinnamon";

const ContractCommentPanel = ({ contractId }) => (
  <ContentBuilder
    loadAction={getContractComments}
    loadParams={{ contractId }}
    renderContent={(data, state, events) => (
      <CommentPanel
        header="History &amp; Comments"
        addPermissionTarget={Targets.Contract}
        addPermissionAction={Actions.Edit}
        submitAction={addContractComment}
        submitParams={{ contractId }}
        placeholder="Add comment"
        noItemsFoundText="No comments found."
        items={data}
        onSubmitted={events.onRefresh}
        loading={state.loading}
      />
    )}
  />
);

export default ContractCommentPanel;
