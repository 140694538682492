import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SchedulingRoutes } from "constants/routes";
import JobSchedulingPage from "./JobSchedulingPage";
import ResourceSchedulingPage from "./ResourceSchedulingPage";

const SchedulingRouter = () => (
  <Switch>
    <Route
      path={SchedulingRoutes.Resources}
      component={ResourceSchedulingPage}
    />
    <Route exact path={SchedulingRoutes.Jobs} component={JobSchedulingPage} />
    <Route render={() => <Redirect to={SchedulingRoutes.Jobs} />} />
  </Switch>
);

export default SchedulingRouter;
