import { Modal, ModalBuilder } from "@redriver/cinnamon";
import { Menu } from "semantic-ui-react";
import { addContractPlot } from "./actions";
import ContractPlotForm from "./ContractPlotForm";
import React from "react";

const AddContractPlot = ({
  as: As = Menu.Item,
  contractId,
  pricingType,
  onAdded,
  ...props
}) => (
  <ModalBuilder
    withForm
    submitAction={addContractPlot}
    submitParams={{ contractId, pricingType }}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Plot">
        <ContractPlotForm formProps={formProps} pricingType={pricingType} />
      </Modal.Add>
    )}
  />
);

export default AddContractPlot;
