import React, { useMemo } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

const Donut = ({
  data,
  title,
  onGroupClick,
  highlightValue,
  highlightFieldName,
  renderCustomLabel,
  renderCustomLegend,
}) => {
  const total = useMemo(() => {
    return data.reduce((acc, item) => acc + item.value, 0) || 0;
  }, [data]);

  const renderDefaultLabel = ({ total }) => {
    return `${total} ${title}`;
  };

  const renderDefaultLegend = (value, entry) => (
    <span
      className="legend-text"
      style={{ cursor: onGroupClick ? "pointer" : "auto" }}
    >{`${value} (${entry.payload.value})`}</span>
  );

  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          formatter={
            renderCustomLegend ? renderCustomLegend : renderDefaultLegend
          }
          onClick={(data) => {
            if (onGroupClick) {
              onGroupClick(data.payload?.payload?.payload); // item data is nested several layers down
            }
          }}
        />
        <Pie
          dataKey="value"
          data={data}
          innerRadius={60}
          outerRadius={100}
          legendType="circle"
        >
          {data.map((x) => (
            <Cell
              key={x.name}
              fill={x.colour}
              opacity={
                highlightValue &&
                highlightFieldName &&
                x[highlightFieldName] != highlightValue
                  ? 0.25
                  : 1
              }
              onClick={() => {
                if (onGroupClick) {
                  onGroupClick(x);
                }
              }}
              style={{ cursor: onGroupClick ? "pointer" : "auto" }}
            />
          ))}
          <Label position="center">
            {renderCustomLabel
              ? renderCustomLabel({ total })
              : renderDefaultLabel({ total })}
          </Label>
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Donut;
