import React from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import CheckIcon from "assets/icons/check.svg";
import { HeaderBar } from "components/containers";
import { JobFulfilmentOfWorksItemType } from "constants/enums";
import {
  Icon,
  Image,
  Popup,
  Segment,
  Table as STable,
} from "semantic-ui-react";
import { getJobFulfilmentOfWorksItems } from "./actions";
import JobFulfilmentOfWorksNotesPanel from "./JobFulfilmentOfWorksNotesPanel";

const JobFulfilmentOfWorksPanel = ({ job }) => {
  const isContractJob = !!job.contractId;

  return (
    <div className="job-fulfilment-of-works-panel">
      <section>
        <ContentBuilder
          loadAction={getJobFulfilmentOfWorksItems}
          loadParams={job.id}
          loadTransform={(data) => {
            const tableData = Object.keys(data).length !== 0 ? data : [];
            const groupedData = tableData.reduce((acc, item) => {
              return {
                ...acc,
                [item.visitDate]: tableData.filter(
                  (x) => x.visitDate == item.visitDate
                ),
              };
            }, {});
            return groupedData;
          }}
          renderContent={(data) => {
            const hasNoData =
              Object.keys(data).length == 0 ||
              Object.keys(data).every((k) => data[k].length == 0);

            return (
              <Segment>
                <HeaderBar name="Fulfilment of Works" />

                <STable>
                  <STable.Header>
                    <STable.HeaderCell style={{ width: "35%" }}>
                      Description
                    </STable.HeaderCell>
                    <STable.HeaderCell style={{ width: "20%" }}>
                      Name
                    </STable.HeaderCell>
                    <STable.HeaderCell style={{ width: "35%" }}>
                      Signature
                    </STable.HeaderCell>
                    <STable.HeaderCell style={{ width: "10%" }}>
                      Completed
                    </STable.HeaderCell>
                  </STable.Header>
                  <STable.Body>
                    {hasNoData && (
                      <STable.Row>
                        <STable.Cell colSpan="4">
                          No fulfilment of works found
                        </STable.Cell>
                      </STable.Row>
                    )}
                    {!hasNoData &&
                      Object.keys(data).map((k) => (
                        <React.Fragment key={k}>
                          <STable.Row>
                            <STable.Cell colSpan="4">
                              <p style={{ fontWeight: "bold" }}>
                                <Format.Date value={k} format="DD MMMM yyyy" />
                              </p>
                            </STable.Cell>
                          </STable.Row>
                          {data[k].map((item) => (
                            <STable.Row key={k + item.type}>
                              <STable.Cell>
                                <p>
                                  {item.typeName}
                                  {item.type ==
                                    JobFulfilmentOfWorksItemType.RiskAssessmentCompleted &&
                                    !isContractJob && (
                                      <React.Fragment>
                                        {" "}
                                        <Popup
                                          content="All operatives must have signed the Risk Assessment for the Supervisor to sign"
                                          trigger={<Icon name="info circle" />}
                                        />
                                      </React.Fragment>
                                    )}
                                </p>
                              </STable.Cell>
                              <STable.Cell>{item.userName}</STable.Cell>
                              <STable.Cell>
                                {item.signatureDateUtc ? (
                                  <p>
                                    {`Signed${
                                      item.type ==
                                      JobFulfilmentOfWorksItemType.RiskAssessmentCompleted
                                        ? " by Supervisor"
                                        : ""
                                    }: `}
                                    <Format.DateTime
                                      value={item.signatureDateUtc}
                                      format="DD/MM/yyyy HH:mm"
                                    />
                                  </p>
                                ) : null}
                              </STable.Cell>
                              <STable.Cell>
                                {item.isCompleted && <Image src={CheckIcon} />}
                              </STable.Cell>
                            </STable.Row>
                          ))}
                        </React.Fragment>
                      ))}
                  </STable.Body>
                </STable>
              </Segment>
            );
          }}
        />
      </section>
      <section style={{ paddingBottom: 10 }}>
        <JobFulfilmentOfWorksNotesPanel jobId={job.id} />
      </section>
    </div>
  );
};

export default JobFulfilmentOfWorksPanel;
