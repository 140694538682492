import React from "react";
import { Form, FormBuilder, useAuthClaims } from "@redriver/cinnamon";
import { getEnquiryVisits, noVisitRequired } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { claimExists } from "components/auth/utils";
import AddVisit from "./AddVisit";
import EditVisit from "./EditVisit";

const EnquiryVisitsPanel = ({ enquiry }) => {
  const { permissions } = useAuthClaims();
  const canEdit = claimExists(permissions, Targets.Enquiry, Actions.Edit);
  return (
    <FormBuilder
      loadAction={getEnquiryVisits}
      loadParams={{ enquiryId: enquiry.id }}
      submitParams={{ enquiryId: enquiry.id }}
      submitAction={noVisitRequired}
      renderForm={(formProps, formState, formEvents) => (
        <div className="enquiry-visits">
          <div className="actions">
            <Form {...formProps}>
              <Form.Checkbox
                field="noVisitRequired"
                label="No Visit Required"
                disabled={formState.submitting || !canEdit}
              />
              <Form.Trigger
                action={() => (canEdit ? formEvents.onSubmit() : null)}
                field="noVisitRequired"
              />
            </Form>
            <PermissionCheck target={Targets.Enquiry} action={Actions.Edit}>
              <AddVisit
                content="Add Visit"
                onAdded={() => {
                  document.getElementById("enquiryVisitsIframe").src =
                    document.getElementById("enquiryVisitsIframe").src;
                }}
                enquiry={enquiry}
              />
              <EditVisit
                content="Edit Visit"
                onEdited={() => {
                  document.getElementById("enquiryVisitsIframe").src =
                    document.getElementById("enquiryVisitsIframe").src;
                }}
                enquiry={enquiry}
              />
            </PermissionCheck>
          </div>
          <iframe
            id="enquiryVisitsIframe"
            title="Enquiry Visits Calendar"
            src={formProps.value.calendarUrl}
          />
        </div>
      )}
    />
  );
};

export default EnquiryVisitsPanel;
