import React, { useMemo } from "react";
import classNames from "classnames";
import { Image, Popup } from "semantic-ui-react";
import CheckIcon from "assets/icons/check.svg";
import CheckGreyIcon from "assets/icons/check-grey.svg";
import {
  PartOfDay,
  ResourceSchedulingState,
  ShortenedResourceSchedulingState,
} from "constants/enums";

const AvailabilityBlock = ({
  job,
  resource,
  onAdd,
  onRemove,
  className,
  editable,
  otherJobAssignments,
  gridRow,
  gridColumn,
  ...otherProps
}) => {
  const jobSheetId = job.jobSheetId;
  const isAssigned = useMemo(
    () => resource.assignedJobSheets.some((x) => x.jobSheetId == jobSheetId),
    [jobSheetId, resource]
  );

  if (!isAssigned && otherJobAssignments.length == 0) {
    return (
      <AvailabilityDisplay
        className={className}
        isAssigned={isAssigned}
        absences={resource.absences}
        gridRow={gridRow}
        gridColumn={gridColumn}
        style={{
          backgroundColor: job.departmentDisplayColour,
        }}
        editable={editable}
        onClick={() =>
          editable
            ? isAssigned
              ? onRemove(resource.id, jobSheetId)
              : onAdd(resource.id, jobSheetId)
            : null
        }
        {...otherProps}
      />
    );
  }

  return (
    <Popup
      content={`${resource.name} (${resource.department}) is assigned to ${
        isAssigned
          ? job.jobRef
          : otherJobAssignments.length > 0
          ? otherJobAssignments.map((x) => x.jobRef).join(", ")
          : job.jobRef
      } (${
        isAssigned
          ? job.department
          : otherJobAssignments.length > 0
          ? otherJobAssignments.map((x) => x.department).join(", ")
          : job.department
      })`}
      mouseEnterDelay={1000}
      on="hover"
      basic
      trigger={
        <AvailabilityDisplay
          className={className}
          isAssigned={isAssigned}
          absences={resource.absences}
          style={{
            gridRow,
            gridColumn,
            backgroundColor:
              resource.department != job.department
                ? resource.departmentDisplayColour
                : job.departmentDisplayColour,
          }}
          assignedToOther={otherJobAssignments.length > 0}
          editable={editable}
          onClick={() =>
            editable
              ? isAssigned
                ? onRemove(resource.id, jobSheetId)
                : onAdd(resource.id, jobSheetId)
              : null
          }
          {...otherProps}
        />
      }
    />
  );
};

const AvailabilityDisplay = ({
  className,
  style,
  isAssigned,
  editable,
  assignedToOther,
  absences,
  gridRow,
  gridColumn,
  ...otherProps
}) => {
  const hasAbsences = absences != null && absences.length > 0;
  const hasPartialAbsences =
    hasAbsences &&
    absences.some(
      (a) => a.partOfDay != PartOfDay.FullDay && a.partOfDay != null
    );
  const fullDayAbsence = absences.find(
    (a) => a.partOfDay == PartOfDay.FullDay || a.partOfDay == null
  );
  const amAbsence = absences.find((x) => x.partOfDay == PartOfDay.Am);
  const pmAbsence = absences.find((x) => x.partOfDay == PartOfDay.Pm);

  return (
    <div
      className={classNames("allocation", className)}
      style={{ gridRow, gridColumn }}
      {...otherProps}
    >
      <div
        className={classNames(
          "part-of-day",
          "am",
          "no-select",
          hasAbsences ? "has-absences" : "",
          fullDayAbsence?.availability,
          amAbsence?.availability
        )}
        style={{
          ...style,
          backgroundColor:
            amAbsence != null || fullDayAbsence != null
              ? null
              : style.backgroundColor,
          width: !hasPartialAbsences ? "100%" : null,
          cursor: editable ? "pointer" : "default",
        }}
      >
        <AvailabilityBlockContent
          isAssigned={isAssigned}
          assignedToOther={assignedToOther}
          isUnavailable={absences.some(
            (x) =>
              x.partOfDay == PartOfDay.Am ||
              x.partOfDay == PartOfDay.FullDay ||
              x.partOfDay == null
          )}
          partOfDay={PartOfDay.Am}
          absence={absences.find(
            (x) =>
              x.partOfDay == PartOfDay.Am ||
              x.partOfDay == PartOfDay.FullDay ||
              x.partOfDay == null
          )}
          hasPartialAbsences={hasPartialAbsences}
        />
      </div>
      {hasPartialAbsences && (
        <div
          className={classNames(
            "part-of-day",
            "pm",
            "has-absences",
            "no-select",
            pmAbsence?.availability
          )}
          style={{
            ...style,
            backgroundColor: pmAbsence != null ? null : style.backgroundColor,
            cursor: editable ? "pointer" : "default",
          }}
        >
          <AvailabilityBlockContent
            isAssigned={isAssigned}
            assignedToOther={assignedToOther}
            isUnavailable={absences.some((x) => x.partOfDay == PartOfDay.Pm)}
            partOfDay={PartOfDay.Pm}
            absence={pmAbsence}
            hasPartialAbsences={hasPartialAbsences}
          />
        </div>
      )}
    </div>
  );
};

const AvailabilityBlockContent = ({
  isAssigned,
  assignedToOther,
  isUnavailable,
  absence,
  partOfDay,
  hasPartialAbsences,
}) => {
  const tickIfAssigned =
    absence?.partOfDay != partOfDay ||
    absence?.partOfDay == PartOfDay.FullDay ||
    absence?.availability == ResourceSchedulingState.Unavailable;

  const text = hasPartialAbsences
    ? ShortenedResourceSchedulingState[absence?.availability]
    : ResourceSchedulingState[absence?.availability];

  return (isAssigned || assignedToOther) && tickIfAssigned ? (
    <Image src={assignedToOther ? CheckGreyIcon : CheckIcon} />
  ) : isUnavailable ? (
    `${text ?? ResourceSchedulingState.Unavailable}${
      hasPartialAbsences ? ` (${partOfDay.toUpperCase()})` : ""
    }`
  ) : null;
};

export default AvailabilityBlock;
