import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RoleRoutes } from "constants/routes";
import RoleListPage from "./RoleListPage";

const RoleRouter = () => (
  <Switch>
    <Route exact path={RoleRoutes.Roles} component={RoleListPage} />
    <Route render={() => <Redirect to={RoleRoutes.Roles} />} />
  </Switch>
);

export default RoleRouter;
