import React, { useState, useEffect, useMemo, useContext } from "react";
import { Segment, Loader } from "semantic-ui-react";
import classNames from "classnames";
import { FormBuilder, Form, ContentBuilder } from "@redriver/cinnamon";
import Donut from "./Donut";
import { DashboardContext } from "./DashboardContext";

const DonutWidget = ({
  id,
  className,
  title,
  headerTitle,
  filterContent,
  action,
  filterParams = {},
  transform = (x) => x,
  headerContent,
  ...donutOptions
}) => {
  const [filters, setFilters] = useState(filterParams);

  useEffect(() => {
    if (filterParams) {
      // if any of the filterParams keys have different values than
      // the current filters then we need to update our filters
      const keys = Object.keys(filterParams);
      for (let i = 0; i < keys.length; i++) {
        if (filterParams[keys[i]] !== filters[keys[i]]) {
          setFilters({ ...filters, ...filterParams });
          break;
        }
      }
    }
  }, [filterParams, filters]);

  return (
    <Segment className={classNames("graph-widget", className)}>
      <ContentBuilder
        loadAction={action}
        loadParams={filterParams}
        loadTransform={transform}
        className="stuff"
        renderContent={(data, state, events) => {
          return (
            <DonutContent
              id={id}
              data={data}
              donutOptions={donutOptions}
              filterContent={filterContent}
              filters={filters}
              headerContent={headerContent}
              headerTitle={headerTitle}
              loading={state.loading}
              onRefresh={events.onRefresh}
              setFilters={setFilters}
              title={title}
            />
          );
        }}
      />
    </Segment>
  );
};

const DonutContent = ({
  id,
  onRefresh,
  data,
  title,
  donutOptions,
  headerTitle,
  filterContent,
  filters,
  setFilters,
  headerContent,
  loading,
}) => {
  const { registerReload, unregisterReload } = useContext(DashboardContext);

  useEffect(() => {
    if (id) {
      registerReload(id, onRefresh);
    }
    return () => unregisterReload(id);
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  const hasData = useMemo(
    () => data.length > 0 && data.some((x) => x.value > 0),
    [data]
  );

  return (
    <section
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <header style={{ position: "absolute" }}>
        <h4 className="title">{headerTitle || title}</h4>
        {filterContent && (
          <div className="departments-filter">
            <FormBuilder
              value={filters}
              onChange={(next) => {
                setFilters(next);
              }}
              renderForm={(formProps) => (
                <Form {...formProps}>{filterContent}</Form>
              )}
            />
          </div>
        )}
        {headerContent}
      </header>
      <footer>
        {loading ? (
          <Loader active />
        ) : hasData ? (
          <Donut
            data={data}
            title={title.toLowerCase()}
            {...(donutOptions || {})}
          />
        ) : (
          <span className="no-data">No {title.toLowerCase()} found</span>
        )}
      </footer>
    </section>
  );
};

export default DonutWidget;
