import React from "react";
import { Grid } from "semantic-ui-react";
import { ModalBuilder, Form, Modal } from "@redriver/cinnamon";
import { addCustomer } from "./actions";
import { TitlesDropdown } from "components/forms";
import { CustomerTypesLookupDropdown } from "../CustomerTypesLookup";
import { CustomerRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import { CustomerTypes } from "constants/enums";

const AddCustomer = ({ renderTrigger }) => {
  const phoneOrEmailError = "Phone or Email is required";
  const history = useHistory();
  return (
    <ModalBuilder
      withForm
      submitAction={addCustomer}
      onSubmitted={(id) => history.push(CustomerRoutes.Customer(id))}
      renderTrigger={renderTrigger}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="Add Customer">
          <Form {...formProps}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <CustomerTypesLookupDropdown
                    field="type"
                    label="Type"
                    required
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.If
                    condition={({ fields }) =>
                      fields.type != null &&
                      fields.type != CustomerTypes.Residential
                    }
                  >
                    <Form.Input
                      field="businessName"
                      label="Business Name"
                      required
                      fluid
                    />
                  </Form.If>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <TitlesDropdown
                    field="titleId"
                    label="Title"
                    placeholder=""
                    clearable
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    field="firstName"
                    label="First Name"
                    required={formProps.value.type == CustomerTypes.Residential}
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Form.Input
                    field="lastName"
                    label="Last Name"
                    fluid
                    required={formProps.value.type == CustomerTypes.Residential}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Form.Email
                    required={
                      !formProps.value.phoneNumber &&
                      !formProps.value.mobileNumber
                    }
                    emailValidation={!!formProps.value.emailAddress}
                    requiredError={phoneOrEmailError}
                    field="emailAddress"
                    label="Email"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    field="phoneNumber"
                    label="Phone"
                    requiredError={phoneOrEmailError}
                    required={
                      !formProps.value.emailAddress &&
                      !formProps.value.mobileNumber
                    }
                    fluid
                  />
                </Grid.Column>
                <Grid.Column>
                  <Form.Input
                    field="mobileNumber"
                    label="Mobile"
                    requiredError={phoneOrEmailError}
                    required={
                      !formProps.value.emailAddress &&
                      !formProps.value.phoneNumber
                    }
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddCustomer;
