import React from "react";
import Filter from "assets/white-filter.svg";
import { IconButton } from "components/buttons";

const FilterButton = ({ onClick, ...props }) => (
  <IconButton
    size="small"
    {...props}
    icon={Filter}
    onClick={onClick}
    className="filter-header-button"
  />
);

export default FilterButton;
