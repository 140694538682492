import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import ActivateUser from "./ActivateUser";
import LockOutUser from "./LockOutUser";
import EditUser from "../EditUser";
import DeleteUser from "./DeleteUser";
import UploadSignature from "./UploadSignature";

const UserActions = ({ user, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.User} action={Actions.Edit}>
      <EditUser as={Table.MenuItem} onUpdated={onRefresh} userId={user.id}>
        Edit User
      </EditUser>
      <UploadSignature as={Table.MenuItem} onAdded={onRefresh} userId={user.id}>
        Upload Signature
      </UploadSignature>
      <PermissionCheck target={Targets.User} action={Actions.Delete}>
        <DeleteUser as={Table.MenuItem} onDeleted={onRefresh} user={user}>
          Delete User
        </DeleteUser>
      </PermissionCheck>
      <Dropdown.Divider />
      {user.isDisabled ? (
        <ActivateUser user={user} onActivated={onRefresh} as={Table.MenuItem}>
          Activate Account
        </ActivateUser>
      ) : (
        <React.Fragment>
          <LockOutUser user={user} onLockOut={onRefresh} as={Table.MenuItem}>
            Lock Out
          </LockOutUser>
        </React.Fragment>
      )}
    </PermissionCheck>
  </Table.Menu>
);

export default UserActions;
