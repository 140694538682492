import React, { useState, useEffect } from "react";
import { ContentBuilder } from "@redriver/cinnamon";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getXero, authorizeXero, disconnectXero } from "./actions";
import { Button, Header } from "semantic-ui-react";
import queryString from "query-string";

const XeroConfiguration = () => {
  return (
    <React.Fragment>
      <Header as="h4">Configure Xero Connection</Header>
      <ContentBuilder
        loadAction={getXero}
        initialData={{ address: {} }}
        renderContent={(data, state, events) => (
          <XeroConfigurationDetails events={events} data={data} />
        )}
      />
    </React.Fragment>
  );
};

const XeroConfigurationDetails = ({ events, data }) => {
  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const query = queryString.parse(search);

    history.replace({
      search: null,
    });

    if (query.state && query.code) {
      setBusy(true);
      dispatch(
        authorizeXero({
          code: query.code,
          state: query.state,
          callbackUrl: window.location.href,
        })
      )
        .then(() => events.onRefresh())
        .finally(() => setBusy(false));
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <Button
        disabled={busy}
        onClick={() => {
          if (data.configured) {
            setBusy(true);
            dispatch(disconnectXero())
              .then(() => events.onRefresh())
              .finally(() => setBusy(false));
          } else {
            setBusy(true);
            window.location.href =
              "https://login.xero.com/identity/connect/authorize?" +
              queryString.stringify({
                response_type: "code",
                client_id: data.clientId,
                redirect_uri: window.location.href,
                scope: data.scopes,
                state: data.state,
              });
          }
        }}
      >
        {!data.configured ? "Connect to" : "Disconnect from"} Xero
      </Button>
    </React.Fragment>
  );
};

export default XeroConfiguration;
