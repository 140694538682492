import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/EQUIPMENT";

export const ActionTypes = {
  GetEnquiryEquipment: `${NAMESPACE}/GET_ENQUIRY_EQUIPMENT`,
  UpdateEnquiryEquipment: `${NAMESPACE}/UPDATE_ENQUIRY_EQUIPMENT`,
};

export const getEnquiryEquipment = ({ enquiryId }) =>
  apiGet(ActionTypes.GetEnquiryEquipment, `enquiries/${enquiryId}/equipment`);

export const updateEnquiryEquipment = ({ data, enquiryId }) =>
  apiPut(
    ActionTypes.UpdateEnquiryEquipment,
    `enquiries/${enquiryId}/equipment`,
    data
  );
