import React, { useState } from "react";
import { getCustomerInvoices } from "./actions";
import {
  ListBuilder,
  FormBuilder,
  Form,
  Table,
  Format,
} from "@redriver/cinnamon";
import { TablePanel, HeaderBar } from "components/containers";
import { Link } from "react-router-dom";
import { ContractRoutes, JobRoutes } from "constants/routes";
import { Targets, Actions } from "constants/Permissions";
import { PermissionCheck } from "components/auth";
import { Icon } from "semantic-ui-react";
import { ContractTabKeys } from "constants";

const CustomerInvoices = ({ customer }) => {
  const defaultFilters = {};
  const [searchFilters, setFilters] = useState(defaultFilters);

  return (
    <ListBuilder
      withTable
      loadAction={getCustomerInvoices}
      loadParams={{ customerId: customer.id, searchFilters }}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: `highlight-${item.customerType.toLowerCase()}`,
        })),
      })}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => (
        <React.Fragment>
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            className="invoices-table"
            customHeader={
              <HeaderBar name="Invoices" isSearchBar>
                <FormBuilder
                  value={searchFilters}
                  onChange={setFilters}
                  renderForm={(formProps) => (
                    <Form {...formProps}>
                      <Form.Input
                        field="search"
                        placeholder="Search"
                        icon="search"
                      />
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            expandRows="onClick"
            maxExpandedRows={1}
            renderExpandedRow={(item) => (
              <Table
                data={item.invoiceLines}
                dataKey="id"
                className="invoice-lines-table"
              >
                <Table.Column field="quantity" title="Quantity" width="7.5%" />
                <Table.Column
                  field="productName"
                  title="Product Name"
                  width="30%"
                />
                <Table.Column
                  field="description"
                  title="Description"
                  width="30%"
                />
                <Table.Column
                  component={NetPrice}
                  title="Net Price"
                  width="7.5%"
                />
                <Table.Column component={Vat} title="VAT" width="7.5%" />
                <Table.Column
                  component={GrossPrice}
                  title="Total"
                  width="7.5%"
                />
              </Table>
            )}
            emptyMessage="No Invoices found"
            selectable
          >
            <Table.Column
              title="Job/Invoice Ref"
              className="job-link"
              render={(invoice) => (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <PermissionCheck
                    target={Targets.Job}
                    action={Actions.View}
                    renderFailure={() =>
                      invoice.jobReference || invoice.reference
                    }
                  >
                    {invoice.customerId ? (
                      invoice.reference
                    ) : (
                      <Link
                        to={
                          invoice.jobId
                            ? JobRoutes.Job(invoice.jobId)
                            : `${ContractRoutes.Contract(
                                invoice.contractId
                              )}?initialTab=${ContractTabKeys.JobManagement}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invoice.jobReference || invoice.reference}
                      </Link>
                    )}
                  </PermissionCheck>
                </span>
              )}
            />
            <Table.Column field="department" title="Department" />
            <Table.Column component={NetTotal} title="Net Price" />
            <Table.Column component={VatTotal} title="VAT" />
            <Table.Column component={GrossTotal} title="Total" />
            <Table.Column component={DueDate} title="Due Date" />
            <Table.Column component={ExportedCheck} title="Exported" />
            <Table.Column component={ExportedDate} title="Exported On" />
            <Table.Column field="exportedBy" title="Exported By" />
          </TablePanel>
        </React.Fragment>
      )}
    />
  );
};

const NetTotal = ({ item }) => {
  return <Format.Number value={item.netTotal} format="GBP" />;
};

const VatTotal = ({ item }) => {
  return <Format.Number value={item.vatTotal} format="GBP" />;
};

const GrossTotal = ({ item }) => {
  return <Format.Number value={item.grossTotal} format="GBP" />;
};

const DueDate = ({ item }) => {
  return <Format.Date value={item.dueDate} format="DD MMM YYYY" />;
};

const NetPrice = ({ item }) => {
  return <Format.Number value={item.netPrice} format="GBP" />;
};

const Vat = ({ item }) => {
  return <Format.Number value={item.vat} format="GBP" />;
};

const GrossPrice = ({ item }) => {
  return <Format.Number value={item.grossPrice} format="GBP" />;
};

const ExportedDate = ({ item }) => (
  <Format.DateTime value={item.exportedUtc} format="DD MMM YYYY" />
);

const ExportedCheck = ({ item }) => item.exported && <Icon name="check" />;

export default CustomerInvoices;
