import React, { useState, useMemo, useContext } from "react";
import {
  Input as SInput,
  Dropdown as SDropdown,
  Message,
} from "semantic-ui-react";
import SearchIcon from "assets/icons/magnifying-glass-white.svg";
import { IconButton } from "components/buttons";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { searchPostcode } from "./actions";
import { FormContext } from "@redriver/cinnamon";

// default mapping of what form fields should be updated
const defaultFieldNameMappings = {
  //[lookupFieldName]: [formFieldName]
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  addressLine3: "addressLine3",
  townOrCity: "townOrCity",
  countyOrState: "countyOrState",
  postcode: "postcode",
};

const PostcodeSearch = ({
  className,
  onSelected,
  fieldNameMappings = defaultFieldNameMappings,
}) => {
  const css = classNames("postcode-search", className);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const form = useContext(FormContext);

  const mappedOptions = useMemo(() => {
    if (options == null) return null;
    return options.map((a, i) => ({
      text: collapseAddress(a),
      value: i,
    }));
  }, [options]);

  return (
    <section className={css}>
      <div className="search-field">
        <SInput
          value={search}
          onChange={(ev, data) => setSearch(data.value)}
          placeholder="Enter Postcode"
          action={
            <IconButton
              size="small"
              icon={SearchIcon}
              onClick={() => {
                if (search && !loading) {
                  setOptions(null);
                  setSelected(null);
                  setError(null);
                  setLoading(true);
                  dispatch(searchPostcode(search)).then(
                    ({ response, success }) => {
                      if (success) {
                        if (response.length == 0) {
                          setError("No address found");
                        } else {
                          setOptions(response);
                        }
                      } else {
                        setError("Error looking up postcode");
                      }

                      setLoading(false);
                    }
                  );
                }
              }}
            />
          }
        />
      </div>
      <div className="result-fields">
        {mappedOptions != null && !error && (
          <SDropdown
            fluid
            selection
            placeholder="Please Select Address"
            options={mappedOptions}
            value={selected}
            selectOnBlur={false}
            onChange={(_, { value }) => {
              setSelected(value);
              const item = options[value];
              if (onSelected) onSelected(item);

              // we want to trigger an update for each form field. but keep track of the updates as so nothing gets lost (especially in controlled forms)
              const updates = {};
              Object.keys(item).forEach((k) => {
                const fieldName = fieldNameMappings[k];
                if (fieldName) {
                  updates[fieldName] = item[k];
                  form.onChange(fieldName, item[k], (prev) => ({
                    ...prev,
                    ...updates,
                  }));
                }
              });
            }}
          />
        )}
        {error && (
          <Message negative>
            <Message.Content>{error}</Message.Content>
          </Message>
        )}
      </div>
    </section>
  );
};

const collapseAddress = (address) => {
  const {
    addressLine1,
    addressLine2,
    addressLine3,
    townOrCity,
    countyOrState,
    postcode,
  } = address;
  return [
    addressLine1,
    addressLine2,
    addressLine3,
    townOrCity,
    countyOrState,
    postcode,
  ]
    .filter((x) => !!x)
    .join(", ");
};

export default PostcodeSearch;
