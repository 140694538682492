import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteJobItem } from "./actions";

const DeleteJobItemModal = ({ jobId, jobItemId, onSubmitted }) => {
  return (
    <ModalBuilder
      submitAction={deleteJobItem}
      submitParams={{ jobId, jobItemId }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => (
        <Button compact icon="trash" onClick={showModal} />
      )}
      renderModal={(modalProps) => (
        <Modal.Delete {...modalProps} header="Delete Job Item">
          Are you sure you wish to delete this job item?
        </Modal.Delete>
      )}
    />
  );
};

export default DeleteJobItemModal;
