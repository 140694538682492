import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { setupApp } from "./setup";
import Shell from "./pages/Shell";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Shell />
    </BrowserRouter>
  </Provider>
);

const root = createRoot(document.getElementById("root"));
root.render(<App />);
