import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { addCustomerAddress } from "./actions";
import CustomerAddressForm from "./CustomerAddressForm";
import { AddressTypes, CustomerTypes } from "constants/enums";

const AddCustomerAddress = ({
  renderTrigger,
  onRefresh,
  customerId,
  firstAddress,
  customerType,
}) => {
  const initialData = {
    isPrimaryAddress: firstAddress,
    type:
      customerType == CustomerTypes.Residential
        ? firstAddress
          ? AddressTypes.Home
          : AddressTypes.Site
        : firstAddress
        ? AddressTypes.Billing
        : null,
  };
  return (
    <ModalBuilder
      withForm
      submitAction={addCustomerAddress}
      onSubmitted={(addressId) => {
        onRefresh(addressId);
      }}
      submitParams={{ customerId }}
      renderTrigger={renderTrigger}
      initialData={initialData}
      renderModal={(modalProps, formProps) => (
        <Modal.Add {...modalProps} header="Add Address">
          <Form {...formProps}>
            <CustomerAddressForm />
          </Form>
        </Modal.Add>
      )}
    />
  );
};

export default AddCustomerAddress;
