import React from "react";
import { Icon } from "semantic-ui-react";

const DashboardWidgetClear = ({ onClear, left }) => {
  return (
    <React.Fragment>
      {!left && <div style={{ flexGrow: 1 }}></div>}
      <a className="main" onClick={onClear}>
        <Icon name="close" />
        Clear filters
      </a>
    </React.Fragment>
  );
};

export default DashboardWidgetClear;
