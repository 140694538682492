import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const InvoiceActions = ({
  invoice: invoice,
  onEdit,
  onDelete,
  onExportToXero,
  onCreditInvoice,
  disabled,
}) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.Invoice} action={Actions.Edit}>
      <React.Fragment>
        <Table.MenuItem
          onClick={() => onEdit(invoice)}
          disabled={invoice.exported || disabled}
        >
          Edit Invoice
        </Table.MenuItem>

        <Table.MenuItem disabled>Download</Table.MenuItem>
      </React.Fragment>
    </PermissionCheck>
    <PermissionCheck target={Targets.Invoice} action={Actions.Delete}>
      <Table.MenuItem
        onClick={() => onDelete(invoice)}
        disabled={invoice.exported || disabled}
      >
        Delete Invoice
      </Table.MenuItem>
    </PermissionCheck>
    {invoice.exported ? (
      <PermissionCheck target={Targets.Invoice} action={Actions.Credit}>
        <Table.MenuItem
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onCreditInvoice();
          }}
        >
          Credit Invoice
        </Table.MenuItem>
      </PermissionCheck>
    ) : (
      <PermissionCheck target={Targets.Invoice} action={Actions.Export}>
        <Table.MenuItem
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onExportToXero();
          }}
        >
          Export to Xero
        </Table.MenuItem>
      </PermissionCheck>
    )}
    <PermissionCheck target={Targets.Invoice} action={Actions.Edit}>
      <React.Fragment>
        <Table.MenuItem disabled>Email to Client</Table.MenuItem>
        <Table.MenuItem disabled>Copy</Table.MenuItem>
      </React.Fragment>
    </PermissionCheck>
  </Table.Menu>
);

export default InvoiceActions;
