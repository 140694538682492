import React from "react";
import { Button } from "semantic-ui-react";
import { downloadTemplateDocument } from "./actions";
import { TriggerBuilder, notifyError } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";

const DownloadTemplateDocument = ({ as: As = Button, id }) => {
  return (
    <TriggerBuilder
      submitAction={downloadTemplateDocument}
      submitParams={{ id }}
      onSubmitted={(submitResponse) => {
        submitResponse
          ? saveFile({ response: submitResponse }, document.fileName)
          : notifyError("Failed to download template document");
      }}
      renderTrigger={(fireTrigger) => (
        <As content="Download Document" onClick={fireTrigger} />
      )}
    />
  );
};

export default DownloadTemplateDocument;
