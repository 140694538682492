import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";

const VatRateActions = ({ rate: rate, onEdit, onDelete }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.VatRate} action={Actions.Edit}>
      <Table.MenuItem onClick={() => onEdit(rate)}>
        Edit VAT Rate
      </Table.MenuItem>
    </PermissionCheck>
    <PermissionCheck target={Targets.VatRate} action={Actions.Delete}>
      <Table.MenuItem onClick={() => onDelete(rate)}>
        Delete VAT Rate
      </Table.MenuItem>
    </PermissionCheck>
  </Table.Menu>
);

export default VatRateActions;
