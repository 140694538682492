import { apiPost } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/CREATE";

const ActionTypes = {
  AddEnquiry: `${NAMESPACE}/ADD_ENQUIRY`,
};

export const addEnquiry = (data) => {
  return apiPost(ActionTypes.AddEnquiry, "enquiries", data);
};
