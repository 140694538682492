import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { deleteTemplateDocument } from "./actions";

const DeleteTemplateDocument = ({
  as: As = Button,
  document,
  onDeleted,
  ...props
}) => (
  <ModalBuilder
    submitAction={deleteTemplateDocument}
    submitParams={document.id}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete Template Document">
        Are you sure you wish to delete <b>{document.fileName}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteTemplateDocument;
