import React, { useEffect, useMemo } from "react";

import { debounce, useAuthClaims } from "@redriver/cinnamon";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { claimExists } from "components/auth/utils";
import { Targets, Actions } from "constants/Permissions";

import JobCard from "./JobCard";

import { getUnscheduledJobs } from "./actions";
import { UNSCHEDULED_JOBS_KEY } from "./constants";
import { getJobSchedulingState } from "./selectors";

const JobsToBeScheduled = ({ isDragging, jobCardWidth }) => {
  const { permissions } = useAuthClaims();

  const canEdit = claimExists(permissions, Targets.JobScheduling, Actions.Edit);

  const unscheduledJobs = useSelector(getJobSchedulingState).schedule[
    UNSCHEDULED_JOBS_KEY
  ];
  const departmentsFilter = useSelector(
    getJobSchedulingState
  ).departmentsFilter;
  const searchFilter = useSelector(getJobSchedulingState).searchFilter;
  const contractManagerFilter = useSelector(
    getJobSchedulingState
  ).contractManagerFilter;
  const dispatch = useDispatch();

  const debouncedGetUnscheduledJobs = useMemo(
    () =>
      debounce(
        (departments, search, contractManager) =>
          dispatch(getUnscheduledJobs(departments, search, contractManager)),
        250
      ),
    [dispatch]
  );

  useEffect(() => {
    debouncedGetUnscheduledJobs(
      departmentsFilter,
      searchFilter,
      contractManagerFilter.value
    );
  }, [
    departmentsFilter,
    searchFilter,
    contractManagerFilter.value,
    debouncedGetUnscheduledJobs,
  ]);

  return isDragging ? (
    <Segment className="jobs-to-be-scheduled">
      <h4>Jobs to be Scheduled</h4>
      <Droppable droppableId={UNSCHEDULED_JOBS_KEY}>
        {(droppableProvided) => (
          <div
            className="unscheduled-jobs unschedule-column"
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            <p>Drop here to unschedule</p>
          </div>
        )}
      </Droppable>
    </Segment>
  ) : (
    <Segment className="jobs-to-be-scheduled" loading={unscheduledJobs == null}>
      <header>
        <h4>Jobs to be Scheduled</h4>
      </header>
      <Droppable droppableId={UNSCHEDULED_JOBS_KEY} isDropDisabled={true}>
        {(droppableProvided) => (
          <div
            style={{ height: "100%" }}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            <div className="unscheduled-jobs">
              {unscheduledJobs &&
                unscheduledJobs.map((job, i) => (
                  <Draggable
                    isDragDisabled={!canEdit}
                    draggableId={job.id}
                    index={i}
                    key={job.id}
                  >
                    {(provided) => (
                      <JobCard
                        noSpread
                        job={job}
                        innerRef={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={
                          provided.draggableProps && provided.dragHandleProps
                            ? jobCardWidth
                              ? {
                                  ...provided.draggableProps.style,
                                  ...provided.dragHandleProps.style,
                                  width: jobCardWidth,
                                }
                              : {
                                  ...provided.draggableProps.style,
                                  ...provided.dragHandleProps.style,
                                }
                            : jobCardWidth
                            ? {
                                width: jobCardWidth,
                              }
                            : {}
                        }
                      />
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </Segment>
  );
};
export default JobsToBeScheduled;
