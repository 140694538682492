import { Form, SidePanel } from "@redriver/cinnamon";
import { FilterButton } from "components/buttons";
import { ClearLabel } from "components/controls";
import {
  ContractJobTypesDropdown,
  ContractStatusesDropdown,
  UsersTypeAhead,
} from "components/forms";
import React from "react";
import { Grid } from "semantic-ui-react";

export const FieldNames = {
  Search: "search",
  Status: "status",
  JobTypes: "jobTypes",
  ContractManager: "contractManagerId",
  ContractManagerDisplay: "contractManager",
};

const ContractsFilters = ({ formProps, resetFilters }) => (
  <SidePanel closeIcon trigger={<FilterButton />} className="filter-side-panel">
    <Form {...formProps}>
      <div className="ui header filters-title">
        <div className="title-text">Filters</div>
        <ClearLabel onClick={resetFilters} />
      </div>
      <Grid columns={2}>
        <Grid.Column>
          <ContractJobTypesDropdown
            field={FieldNames.JobTypes}
            label="Job Types"
            placeholder="Select job types"
            multiple
            clearable
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <ContractStatusesDropdown
            field={FieldNames.Status}
            label="Status"
            placeholder="Select status"
            multiple
            clearable
            fluid
          />
        </Grid.Column>
        <Grid.Column>
          <UsersTypeAhead
            label="Contract Manager"
            placeholder="Select contract manager"
            field={FieldNames.ContractManager}
            textField={FieldNames.ContractManagerDisplay}
            fluid
            clearable
          />
        </Grid.Column>
      </Grid>
    </Form>
  </SidePanel>
);

export default ContractsFilters;
