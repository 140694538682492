import { SidePanel } from "@redriver/cinnamon";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";

const ContractActions = ({ onSelectAddress, onEditDetails }) => {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  return (
    <React.Fragment>
      <Button compact onClick={() => setSidePanelOpen(true)}>
        Actions
      </Button>
      <SidePanel
        closeIcon
        onClose={() => setSidePanelOpen(false)}
        open={sidePanelOpen}
        className="actions-side-panel"
      >
        <div className="ui header filters-title">
          <div className="title-text">Actions</div>
        </div>
        <div className="actions">
          <div>
            <a
              onClick={() => {
                onSelectAddress();
                setSidePanelOpen(false);
              }}
            >
              Select Site Address
            </a>
          </div>
          <div>
            <a
              onClick={() => {
                onEditDetails();
                setSidePanelOpen(false);
              }}
            >
              Edit Contract Details
            </a>
          </div>
        </div>
      </SidePanel>
    </React.Fragment>
  );
};

export default ContractActions;
