import { apiDelete, apiGet, apiPost, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/DETAILS/DOCUMENTS";

export const ActionTypes = {
  GetEnquiryDocuments: `${NAMESPACE}/GET_ENQUIRY_DOCUMENTS`,
  GetEnquiryDocument: `${NAMESPACE}/GET_ENQUIRY_DOCUMENT`,
  AddEnquiryDocument: `${NAMESPACE}/ADD_ENQUIRY_DOCUMENT`,
  DeleteEnquiryDocument: `${NAMESPACE}/DELETE_ENQUIRY_DOCUMENT`,
  UpdateEnquiryDocument: `${NAMESPACE}/UPDATE_ENQUIRY_DOCUMENT`,
};

export const getEnquiryDocuments = ({ pagination }, { enquiryId }) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetEnquiryDocuments,
    `enquiries/${enquiryId}/documents`,
    {
      pageSize,
      pageNumber,
    }
  );
};

export const getEnquiryDocument = ({ enquiryId, docId }) => {
  return apiGet(
    ActionTypes.GetEnquiryDocument,
    `enquiries/${enquiryId}/documents/${docId}/download`,
    {},
    { preventResponseHandling: true }
  );
};

export const addEnquiryDocument = (data, { enquiryId }) => {
  const formData = new FormData();
  formData.append("name", data.name ?? "");
  formData.append("description", data.description ?? "");
  formData.append("type", data.type);
  (data.files || []).forEach((f) => {
    formData.append(`files`, f);
  });

  return apiPost(
    ActionTypes.AddEnquiryDocument,
    `enquiries/${enquiryId}/documents`,
    formData,
    { contentType: null }
  );
};

export const deleteEnquiryDocument = ({ enquiryId, docId }) => {
  return apiDelete(
    ActionTypes.DeleteEnquiryDocument,
    `enquiries/${enquiryId}/documents/${docId}/delete`
  );
};

export const updateEnquiryDocument = (formData, { enquiryId, documentId }) => {
  const { type, description } = formData;
  return apiPut(
    ActionTypes.UpdateEnquiryDocument,
    `enquiries/${enquiryId}/documents/${documentId}`,
    {
      type,
      description,
    }
  );
};
