import React from "react";
import { ContentBuilder, Format } from "@redriver/cinnamon";
import { getEnquiryEmail } from "./actions";
import { LightPanel } from "components/containers";

const EnquiryEmailPanel = ({ enquiryId }) => {
  return (
    <ContentBuilder
      loadAction={getEnquiryEmail}
      loadParams={{ enquiryId }}
      renderContent={(email) => {
        return (
          <LightPanel
            headerText={email.subject}
            rightHeaderContent={
              <span>
                Received on{" "}
                <Format.DateTime
                  value={email.receivedUtc}
                  format="DD MMM YYYY \a\t HH:mm"
                />
              </span>
            }
          >
            <pre>{email.body}</pre>
          </LightPanel>
        );
      }}
    />
  );
};

export default EnquiryEmailPanel;
