import { apiGet, apiPut, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "ENQUIRIES/DETAILS";

export const ActionTypes = {
  GetEnquiry: `${NAMESPACE}/GET_ENQUIRY`,
  UpdateSelectedAddress: `${NAMESPACE}/UPDATE_SELECTED_ADDRESS`,
  UpdateStatus: `${NAMESPACE}/UPDATE_STATUS`,
  GetEnquiryComments: `${NAMESPACE}/GET_ENQUIRY_COMMENTS`,
  AddEnquiryComments: `${NAMESPACE}/ADD_ENQUIRY_COMMENT`,
  GetEnquiryScopeOfWork: `${NAMESPACE}/GET_ENQUIRY_SCOPE_OF_WORK`,
  AddEnquiryScopeOfWork: `${NAMESPACE}/ADD_ENQUIRY_SCOPE_OF_WORK`,
  GetEnquiryDescription: `${NAMESPACE}/GET_ENQUIRY_DESCRIPTION`,
  UpdateEnquiryDescription: `${NAMESPACE}/ADD_ENQUIRY_DESCRIPTION`,
  UpdateAssignedUser: `${NAMESPACE}/UPDATE_ASSIGNED_USER`,
  UpdateAssignedDepartment: `${NAMESPACE}/UPDATE_ASSIGNED_DEPARTMENT`,
  UpdateSource: `${NAMESPACE}/UPDATE_SOURCE`,
  GetEnquiryEmail: `${NAMESPACE}/GET_ENQUIRY_EMAIL`,
  LinkEnquiry: `${NAMESPACE}/LINK_ENQUIRY`,
  UpdateSOWEntry: `${NAMESPACE}/UPDATE_SOW_ENTRY`,
  DeleteSOWEntry: `${NAMESPACE}/DELETE_SOW_ENTRY`,
  UpdateEnquiryDetails: `${NAMESPACE}/UPDATE_ENQUIRY_DETAILS`,
};

export const getEnquiry = ({ enquiryId }) =>
  apiGet(ActionTypes.GetEnquiry, `enquiries/${enquiryId}`);

export const updateSelectedAddress = (enquiryId, formData) =>
  apiPut(ActionTypes.UpdateSelectedAddress, `enquiries/${enquiryId}/address`, {
    ...formData,
    customerAddressId: formData.selectedAddressId,
  });

export const updateEnquiryDetails = (data, { enquiryId }) =>
  apiPut(ActionTypes.UpdateEnquiryDetails, `enquiries/${enquiryId}`, data);

export const getEnquiryComments = ({ enquiryId }) =>
  apiGet(ActionTypes.GetEnquiryComments, `enquiries/${enquiryId}/comments`);

export const addEnquiryComment = ({ comment }, { enquiryId }) => {
  return apiPost(
    ActionTypes.AddEnquiryComments,
    `enquiries/${enquiryId}/comments`,
    {
      text: comment,
    }
  );
};

export const getEnquiryDescription = ({ enquiryId }) =>
  apiGet(
    ActionTypes.GetEnquiryDescription,
    `enquiries/${enquiryId}/description`
  );

export const updateEnquiryDescription = ({ data, enquiryId }) => {
  return apiPut(
    ActionTypes.UpdateEnquiryDescription,
    `enquiries/${enquiryId}/description`,
    data.description
  );
};

export const getEnquiryEmail = ({ enquiryId }) =>
  apiGet(ActionTypes.GetEnquiryEmail, `enquiries/${enquiryId}/email`);

export const linkEnquiry = (data, { enquiryId }) => {
  return apiPut(
    ActionTypes.LinkEnquiry,
    `enquiries/${enquiryId}/customer`,
    data
  );
};
