import { apiGet } from "@redriver/cinnamon";

const NAMESPACE = "CUSTOMERS/DETAILS/INVOICES";

const ActionTypes = {
  GetCustomerJobs: `${NAMESPACE}/GET_CUSTOMER_INVOICES`,
};

export const getCustomerInvoices = (
  { pagination },
  { customerId, searchFilters: { search } }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(
    ActionTypes.GetCustomerJobs,
    `customers/${customerId}/invoices`,
    {
      search,
      pageSize,
      pageNumber,
    }
  );
};
