import { Modal, ModalBuilder } from "@redriver/cinnamon";
import React from "react";
import { Button } from "semantic-ui-react";
import { editContractPlot } from "./actions";
import ContractPlotForm from "./ContractPlotForm";

const EditContractPlot = ({
  as: As = Button,
  plot,
  contractId,
  pricingType,
  onUpdated,
  ...props
}) => (
  <ModalBuilder
    withForm
    initialData={{
      number: plot.number,
      reference: plot.reference,
      description: plot.description,
      additionalComments: plot.additionalComments,
    }}
    submitAction={editContractPlot}
    submitParams={{ contractId, plotId: plot.id }}
    onSubmitted={onUpdated}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps, formProps) => (
      <Modal.Edit {...modalProps} header="Edit Plot">
        <ContractPlotForm formProps={formProps} pricingType={pricingType} />
      </Modal.Edit>
    )}
  />
);

export default EditContractPlot;
