import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import {
  DeleteDocumentModal,
  DownloadDocumentModal,
  EditDocumentModal,
} from "components/modals";
import { Actions, Targets } from "constants/Permissions";
import React from "react";
import {
  deleteContractDocument,
  getContractDocument,
  updateContractDocument,
} from "./actions";
import { ContractDocumentTypesDropdown } from "components/forms";

const ContractDocumentTableActions = ({ document, contractId, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.ContractDocument} action={Actions.View}>
      <DownloadDocumentModal
        document={document}
        action={getContractDocument}
        actionParams={{ contractId }}
        as={Table.MenuItem}
      >
        Download Document
      </DownloadDocumentModal>
    </PermissionCheck>
    <PermissionCheck target={Targets.ContractDocument} action={Actions.Edit}>
      <EditDocumentModal
        document={document}
        submitAction={updateContractDocument}
        submitParams={{ contractId }}
        onUpdated={onRefresh}
        documentTypeDropdown={
          <ContractDocumentTypesDropdown
            field="type"
            label="Type"
            placeholder="Select document type"
            required
            fluid
          />
        }
        as={Table.MenuItem}
      >
        Edit Document
      </EditDocumentModal>
    </PermissionCheck>
    <PermissionCheck target={Targets.ContractDocument} action={Actions.Delete}>
      <DeleteDocumentModal
        document={document}
        action={deleteContractDocument}
        actionParams={{ contractId }}
        onDeleted={onRefresh}
        as={Table.MenuItem}
      >
        Delete Document
      </DeleteDocumentModal>
    </PermissionCheck>
  </Table.Menu>
);

export default ContractDocumentTableActions;
