import React, { useState } from "react";
import { getJobs, downloadJobSheets } from "./actions";
import { ListBuilder, Table, TriggerBuilder, Format } from "@redriver/cinnamon";
import { Image, Button } from "semantic-ui-react";
import { TablePanel } from "components/containers";
import JobsFilters from "./JobsFilters";
import JobsFilterDisplay from "./JobsFilterDisplay";
import { JobStatus } from "constants/enums";
import { useHistory } from "react-router-dom";
import { JobRoutes } from "constants/routes";
import classNames from "classnames";
import { JobStatusTintMapping, JobStatusIconMapping } from "constants/mappings";
import { PreferenceKeys } from "constants/preferences";
import { FilterBar } from "components/filters";
import { DateTime } from "luxon";

const ListJobs = () => {
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState({
    selected: [],
    deselected: [],
  });

  return (
    <ListBuilder
      withTable
      loadAction={getJobs}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: classNames(
            `highlight-${
              item.contractId
                ? item.contractJobType.toLowerCase()
                : item.customerType.toLowerCase()
            }`,
            `tint-${JobStatusTintMapping[item.status]}`
          ),
        })),
      })}
      persistFilters={PreferenceKeys.ListJobsFilters}
      renderList={(tableProps, state, events) => {
        const selectedIds = tableProps.data
          .filter(
            (x) =>
              (selectedItems.selected.includes(x.id) ||
                selectedItems.allSelected) &&
              !selectedItems.deselected.includes(x.id) &&
              !x.jobItemId
          )
          .map((x) => x.id);
        return (
          <React.Fragment>
            <TablePanel
              {...tableProps}
              dataKey="id"
              pageSizePosition="footer"
              pageSizeOptions={[10, 25, 50, 100]}
              pagination={state.pagination}
              onPaginationChange={events.onChangePagination}
              totalResults={state.total}
              className="jobs-table"
              onRowClick={(item) => {
                history.push(JobRoutes.Job(item.id));
              }}
              customHeader={
                <FilterBar
                  name="Jobs"
                  filters={state.filters}
                  onChange={(updated) => {
                    let startDateToUtc = updated.startDateToUtc;
                    if (
                      updated.startDateFromUtc &&
                      updated.startDateFromUtc != state.filters.startDateFromUtc
                    ) {
                      startDateToUtc = DateTime.fromISO(
                        updated.startDateFromUtc
                      )
                        .setZone("utc")
                        .plus({ days: 7 })
                        .toISO();
                    } else if (startDateToUtc) {
                      startDateToUtc = DateTime.fromISO(startDateToUtc)
                        .setZone("utc")
                        .toISO();
                    }

                    events.onChangeFilters({
                      ...updated,
                      startDateToUtc,
                    });
                  }}
                  display={
                    <JobsFilterDisplay
                      filters={state.filters}
                      resetFilters={() => events.onChangeFilters({})}
                    />
                  }
                  rightContent={
                    <TriggerBuilder
                      submitAction={downloadJobSheets}
                      submitParams={selectedIds}
                      onSubmitted={events.onRefresh}
                      loading={state.loading}
                      renderTrigger={(fireTrigger, state) => (
                        <Button
                          compact
                          content="Print Job Sheets"
                          onClick={fireTrigger}
                          loading={state.submitting}
                        />
                      )}
                    />
                  }
                >
                  <JobsFilters onChangeFilters={events.onChangeFilters} />
                </FilterBar>
              }
            >
              <Table.SelectableColumn
                value={selectedItems}
                onChange={setSelectedItems}
                totalItems={state.total}
                header="page"
                canSelect={(job) =>
                  job.status == JobStatus.Scheduled && job.plannedStartDate
                }
              />
              <Table.Column field="ref" title="Ref" />
              <Table.Column field="customer" title="Customer" />
              <Table.Column
                title="Site Address"
                render={(job) => {
                  return job.siteAddress ? job.siteAddress.shortAddress : "";
                }}
              />
              <Table.Column
                title="Status"
                render={(job) => (
                  <span className="icon-text-column">
                    <Image inline src={JobStatusIconMapping[job.status]} />{" "}
                    {job.status}
                  </span>
                )}
              />
              <Table.Column
                title="Planned Start Date"
                render={(job) => (
                  <Format.DateTime
                    value={job.plannedStartDate}
                    format="DD MMM YYYY"
                  />
                )}
              />
              <Table.Column field="department" title="Department" />

              <Table.Column
                title="Contract Manager"
                render={(job) =>
                  job.contractId ? job.contractManager : job.ownerName
                }
              />
            </TablePanel>
          </React.Fragment>
        );
      }}
    />
  );
};

export default ListJobs;
