import React from "react";
import { Form } from "@redriver/cinnamon";
import { getContractPlots } from "./actions";

const ContractPlotsTypeAhead = ({ contractId, jobId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getContractPlots}
    lookupParams={{ contractId, jobId }}
  />
);

export default ContractPlotsTypeAhead;
