import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { Button } from "semantic-ui-react";
import { addProduct } from "./actions";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { ProductCategoriesTypeAhead, VATDropdown } from "components/forms";

const AddProduct = ({ as: As = Button, onAdded, ...props }) => (
  <ModalBuilder
    withForm
    submitAction={addProduct}
    onSubmitted={onAdded}
    renderTrigger={(showModal) => (
      <PermissionCheck target={Targets.Product} action={Actions.Create}>
        <As onClick={showModal} compact {...props} />
      </PermissionCheck>
    )}
    renderModal={(modalProps, formProps) => (
      <Modal.Add {...modalProps} header="Add Product">
        <Form {...formProps}>
          <Form.Group widths="equal">
            <Form.Input label="Name" field="name" fluid required />
            <ProductCategoriesTypeAhead
              label="Category"
              field="categoryId"
              fluid
              clearable
            />
          </Form.Group>
          <Form.Input label="Description" field="description" fluid />
          <Form.Group widths="equal">
            <Form.Numeric
              label="Cost"
              field="cost"
              decimalPlaces={2}
              formatValue
              subLabel="£"
              subLabelPosition="left"
              fluid
            />
            <Form.Numeric
              label="Price"
              field="price"
              decimalPlaces={2}
              formatValue
              subLabel="£"
              subLabelPosition="left"
              fluid
            />
            <VATDropdown field="vatRateId" label="Vat Rate" fluid clearable />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input label="Unit" field="unit" fluid />
            <div className="field ui" />
            <div className="field ui" />
          </Form.Group>
        </Form>
      </Modal.Add>
    )}
  />
);

export default AddProduct;
