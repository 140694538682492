import React from "react";
import classNames from "classnames";
import Logo from "assets/logo.png";

const PublicContentWrapper = ({ children, className }) => (
  <div className={classNames("public-content-wrapper", className)}>
    <div className="logo-wrapper">
      <img src={Logo} alt="KPS Logo" />
    </div>
    {children}
  </div>
);

export default PublicContentWrapper;
