import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import DeleteContractJob from "./DeleteContractJob";

const ContractJobTableActions = ({ job, contractId, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.ContractJob} action={Actions.Delete}>
      <DeleteContractJob
        as={Table.MenuItem}
        content="Delete Job"
        job={job}
        contractId={contractId}
        onUpdated={onRefresh}
      />
    </PermissionCheck>
  </Table.Menu>
);

export default ContractJobTableActions;
