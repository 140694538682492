import {
  TriggerBuilder,
  ListBuilder,
  FormBuilder,
  Form,
  Table,
  Format,
} from "@redriver/cinnamon";
import React from "react";
import { getOrCreateWorkWalletId } from "./actions";
import { Button } from "semantic-ui-react";
import { getContractDocuments } from "../Documents/actions";
import {
  ContractDocumentCategory,
  ContractDocumentType,
} from "constants/enums";
import { Actions, Targets } from "constants/Permissions";
import ContractDocumentTableActions from "../Documents/ContractDocumentTableActions";
import { MultiPermissionCheck } from "components/auth";
import { HeaderBar, TablePanel } from "components/containers";

const CreatedAt = ({ item }) => {
  return <Format.DateTime value={item.createdUtc} format="DD/MM/YY HH:mm" />;
};

const ContractRiskAssessment = ({ contractId }) => {
  return (
    <ListBuilder
      withTable
      loadAction={getContractDocuments}
      loadParams={{
        contractId,
        category: ContractDocumentCategory.Contract,
        type: ContractDocumentType.RiskAssessment,
      }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      renderList={(tableProps, state, events) => {
        return (
          <TablePanel
            pageSizePosition="footer"
            pageSizeOptions={[10, 25, 50, 100]}
            pagination={state.pagination}
            onPaginationChange={events.onChangePagination}
            totalResults={state.total}
            customHeader={
              <HeaderBar
                isSearchBar
                name="Risk Assessments"
                rightContent={
                  <TriggerBuilder
                    submitAction={getOrCreateWorkWalletId}
                    submitParams={{ contractId }}
                    onSubmitted={() => {
                      window
                        .open(
                          `${process.env.WORK_WALLET_BASE_URL}/HealthandSafety/RiskAssessment/Create`, // cspell:disable-line
                          "_blank"
                        )
                        .focus();
                    }}
                    renderTrigger={(fireTrigger, state) => (
                      <Button
                        primary
                        content="Start Risk Assessment"
                        onClick={fireTrigger}
                        loading={state.submitting}
                      />
                    )}
                  />
                }
              >
                <FormBuilder
                  value={state.filters}
                  onChange={events.onChangeFilters}
                  renderForm={(formProps) => (
                    <Form className="list-filters" {...formProps}>
                      <Form.Group widths="equal">
                        <Form.Input
                          field="search"
                          placeholder="Search"
                          icon="search"
                          fluid
                        />
                      </Form.Group>
                    </Form>
                  )}
                />
              </HeaderBar>
            }
            {...tableProps}
            dataKey="id"
            emptyMessage="No documents found"
          >
            <Table.Column field="fileName" title="File Name" />
            <Table.Column field="description" title="Description" width="40%" />
            <Table.Column field="typeDescription" title="Type" />
            <Table.Column field="createdBy" title="Created By" />
            <Table.Column title="Created At" component={CreatedAt} />
            <MultiPermissionCheck
              checks={[
                { target: Targets.ContractDocument, action: Actions.View },
                {
                  target: Targets.ContractDocument,
                  action: Actions.Delete,
                },
              ]}
            >
              <Table.Column
                hoverable
                align="right"
                render={(item) => (
                  <ContractDocumentTableActions
                    document={item}
                    contractId={contractId}
                    onRefresh={events.onRefresh}
                  />
                )}
              />
            </MultiPermissionCheck>
          </TablePanel>
        );
      }}
    />
  );
};

export default ContractRiskAssessment;
