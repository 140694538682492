import { Format, ListBuilder, Table } from "@redriver/cinnamon";
import classNames from "classnames";
import { TablePanel } from "components/containers";
import { ContractStatus } from "constants/enums";
import { ContractStatusTintMapping } from "constants/mappings";
import { ContractRoutes } from "constants/routes";
import React from "react";
import { useHistory } from "react-router-dom";
import { getContracts } from "./actions";

const ContractsTable = ({ filters, updated }) => {
  const history = useHistory();

  return (
    <ListBuilder
      withTable
      loadAction={getContracts}
      loadParams={{ updated, ...filters }}
      dataTransform={(data) => data.results}
      totalTransform={(data) => data.totalResults}
      loadTransform={(data) => ({
        ...data,
        results: data.results.map((item) => ({
          ...item,
          className: classNames(
            `highlight-${item.jobType.toLowerCase()}`,
            `tint-${ContractStatusTintMapping[ContractStatus[item.status]]}`
          ),
        })),
      })}
      initialPagination={{ pageSize: 25, pageNumber: 1 }}
      renderList={(tableProps, state, events) => (
        <TablePanel
          {...tableProps}
          dataKey="id"
          emptyMessage="No contracts found"
          pageSizePosition="footer"
          pageSizeOptions={[10, 25, 50, 100]}
          pagination={state.pagination}
          totalResults={state.total}
          onPaginationChange={events.onChangePagination}
          selectable
          className="contracts-table"
          onRowClick={(item) => {
            history.push(ContractRoutes.Contract(item.id));
          }}
        >
          <Table.Column
            title="Reference"
            render={(contract) => contract.reference}
            width="5%"
          />
          <Table.Column
            title="Customer Name"
            render={(contract) => contract.customerName}
            width="8%"
          />
          <Table.Column
            title="Job Type"
            render={(contract) => contract.jobTypeDescription}
            width="8%"
          />
          <Table.Column
            title="Site Address"
            render={(contract) => contract.siteAddress}
            width="15%"
          />
          <Table.Column
            title="KPS Contract Number"
            render={(contract) => contract.contractNumber}
            width="8%"
          />
          <Table.Column
            title="Status"
            render={(contract) => contract.statusDescription}
            width="5%"
          />
          <Table.Column
            title="Contract Total Value"
            render={(contract) =>
              contract.contractValue ? (
                <Format.Number value={contract.contractValue} format="GBP" />
              ) : null
            }
            width="5%"
          />
        </TablePanel>
      )}
    />
  );
};

export default ContractsTable;
