import { apiGet, apiPut, apiPost, apiDelete } from "@redriver/cinnamon";

const NAMESPACE = "ADMIN/SYSTEM";

export const ActionTypes = {
  GetCalendarOptions: `${NAMESPACE}/GET_CALENDAR_OPTIONS`,
  GetCalendarDetails: `${NAMESPACE}/GET_CALENDAR_DETAILS`,
  UpdateCalendarDetails: `${NAMESPACE}/UPDATE_CALENDAR_DETAILS`,
  GetXero: `${NAMESPACE}/GET_XERO`,
  AuthorizeXero: `${NAMESPACE}/AUTHORIZE_XERO`,
  DisconnectXero: `${NAMESPACE}/DISCONNECT_XERO`,
};

export const getCalendarOptions = () => {
  return apiGet(
    ActionTypes.GetCalendarOptions,
    "calendar-configuration/calendar-options"
  );
};

export const getCalendarDetails = () => {
  return apiGet(ActionTypes.GetCalendarDetails, "calendar-configuration");
};

export const updateCalendarDetails = (data) => {
  return apiPut(
    ActionTypes.UpdateCalendarDetails,
    "calendar-configuration",
    data
  );
};

export const getXero = () => {
  return apiGet(ActionTypes.GetXero, `xero`);
};

export const authorizeXero = (data) => {
  return apiPost(ActionTypes.AuthorizeXero, `xero/authorize`, data);
};

export const disconnectXero = () => {
  return apiDelete(ActionTypes.DisconnectXero, `xero/authorize`);
};
