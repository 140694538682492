import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "READY_TO_INVOICE/LIST";

const ActionTypes = {
  GetJobs: `${NAMESPACE}/GET_READY_TO_INVOICE`,
  GetJobInvoice: `${NAMESPACE}/GET_JOB_INVOICE`,
  CreateInvoice: `${NAMESPACE}/CREATE_INVOICE`,
  CreateCombinedInvoice: `${NAMESPACE}/CREATE_COMBINED_INVOICE`,
};

export const getJobs = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetJobs, "jobs/ready-to-invoice", {
    pageSize,
    pageNumber,
    ...filters,
  });
};

export const getJobInvoice = (jobId) =>
  apiGet(ActionTypes.GetJobInvoice, `jobs/${jobId}/invoice`);

export const createInvoice = (data) => {
  const lines = data.lines.map((line) => ({
    ...line,
    id: Number.isInteger(line.id) ? null : line.id,
  }));

  return apiPost(ActionTypes.CreateInvoice, "invoices", { ...data, lines });
};

export const createCombinedInvoice = (data) =>
  apiPost(ActionTypes.CreateCombinedInvoice, `invoices/combined`, {
    ...data,
  });
