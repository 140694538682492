import React from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";

const ScrollbarPanel = ({ children, className, ...otherProps }) => {
  return (
    <div className={classNames("scrollbar-wrapper", className)} {...otherProps}>
      <SimpleBar forceVisible autoHide={false} className="scrollbar">
        {children}
      </SimpleBar>
    </div>
  );
};

export default ScrollbarPanel;
