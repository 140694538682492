import { apiGet, apiPost, apiPut, notifyError } from "@redriver/cinnamon";
import { saveFile } from "modules/helpers";
import { UNSCHEDULED_JOBS_KEY } from "./constants";
import { getJobSchedulingState } from "./selectors";

const NAMESPACE = "SCHEDULING/JOBS";

export const ActionTypes = {
  GetDailyOverview: `${NAMESPACE}/GET_DAILY_OVERVIEW`,
  GetDepartments: `${NAMESPACE}/GET_DEPARTMENTS`,
  GetScheduledJobs: `${NAMESPACE}/GET_SCHEDULED_JOBS`,
  GetUnscheduledJobs: `${NAMESPACE}/GET_UNSCHEDULED_JOBS`,
  JobScheduled: `${NAMESPACE}/JOB_SCHEDULED`,
  JobUnscheduled: `${NAMESPACE}/JOB_UNSCHEDULED`,
  ResourceAssigned: `${NAMESPACE}/RESOURCE_ASSIGNED`,
  ResourceUnassigned: `${NAMESPACE}/RESOURCE_UNASSIGNED`,
  ScheduleJob: `${NAMESPACE}/SCHEDULE_JOB`,
  ScheduleJobBackend: `${NAMESPACE}/SCHEDULE_JOB_BACKEND`,
  SelectWeek: `${NAMESPACE}/SELECT_WEEK`,
  SetDepartmentFilter: `${NAMESPACE}/SET_DEPARTMENT_FILTER`,
  SetSearchFilter: `${NAMESPACE}/SET_SEARCH_FILTER`,
  SetContractManagerFilter: `${NAMESPACE}/SET_CONTRACT_MANAGER_FILTER`,
  SpreadJob: `${NAMESPACE}/SPREAD_JOB`,
  CompleteJobItems: `${NAMESPACE}/COMPLETE_JOB_ITEMS`,
  MarkItemsAsCompleted: `${NAMESPACE}/MARK_ITEMS_AS_COMPLETED`,
  GenerateJobSheet: `${NAMESPACE}/GENERATE_JOB_SHEET`,
  JobSheetsMerged: `${NAMESPACE}/JOB_SHEETS_MERGED`,
  MergeJobSheets: `${NAMESPACE}/MERGE_JOB_SHEETS`,
  RepeatJob: `${NAMESPACE}/REPEAT_JOB`,
  JobStatusUpdated: `${NAMESPACE}/JOB_STATUS_UPDATED`,
  RepeatJobFrequencies: `${NAMESPACE}/REPEAT_JOB_FREQUENCIES`,
  MarkItemsAsUncompleted: `${NAMESPACE}/MARK_ITEMS_AS_UNCOMPLETED`,
  MarkItemsAsInvoiceable: `${NAMESPACE}/MARK_ITEMS_AS_INVOICEABLE`,
};

export const getDailyOverview = (
  startDate,
  endDate,
  departmentsFilter,
  searchFilter,
  contractManagerId
) => {
  return apiGet(
    ActionTypes.GetDailyOverview,
    `scheduling/jobs-daily-overview`,
    {
      startDate,
      endDate,
      departments: departmentsFilter ?? [],
      search: searchFilter,
      contractManagerId: contractManagerId,
    },
    {
      actionCustomData: {
        startDate,
        endDate,
      },
    }
  );
};

export const getDepartments = () =>
  apiGet(ActionTypes.GetDepartments, `lookups/departments`, {
    filterForSchedulableDepartments: true,
  });

export const getScheduledJobs = (
  weekCommencing,
  departmentsFilter,
  searchFilter,
  contractManagerFilter
) => {
  return apiGet(
    ActionTypes.GetScheduledJobs,
    `jobs/scheduled`,
    {
      departments: departmentsFilter,
      startOfWeek: weekCommencing,
      search: searchFilter,
      contractManagerId: contractManagerFilter,
    },
    {
      actionCustomData: { weekCommencing },
    }
  );
};

export const getUnscheduledJobs = (
  departmentsFilter,
  searchFilter,
  contractManagerFilter
) => {
  return apiGet(ActionTypes.GetUnscheduledJobs, `jobs/to-schedule`, {
    departments: departmentsFilter,
    search: searchFilter,
    contractManagerId: contractManagerFilter,
  });
};

export const scheduleJob =
  (jobId, scheduledDate, previousDate) => (dispatch) => {
    dispatch({
      type: ActionTypes.ScheduleJob,
      jobId,
      scheduledDate,
      previousDate,
    });
    return dispatch(
      apiPost(ActionTypes.ScheduleJobBackend, `scheduling/jobs`, {
        jobOrJobSheetId: jobId,
        date: scheduledDate == UNSCHEDULED_JOBS_KEY ? null : scheduledDate,
      })
    );
  };

export const jobScheduled = (data) => ({
  type: ActionTypes.JobScheduled,
  data,
});

export const jobUnscheduled = (data) => ({
  type: ActionTypes.JobUnscheduled,
  data,
});

export const resourceAssigned = (data) => ({
  type: ActionTypes.ResourceAssigned,
  data,
});

export const resourceUnassigned = (data) => ({
  type: ActionTypes.ResourceUnassigned,
  data,
});

export const selectWeek = (weekCommencing) => ({
  type: ActionTypes.SelectWeek,
  weekCommencing,
});

export const setDepartmentFilter = (filter) => ({
  type: ActionTypes.SetDepartmentFilter,
  filter,
});

export const setSearchFilter = (filter) => ({
  type: ActionTypes.SetSearchFilter,
  filter,
});

export const setContractManagerFilter = (filter) => {
  return { type: ActionTypes.SetContractManagerFilter, filter };
};

export const spreadJob = (request) =>
  apiPost(ActionTypes.SpreadJob, `scheduling/spread-job`, request);

export const repeatJob = ({ jobId, jobSheetId, jobItemIds, visits }) => {
  return apiPost(ActionTypes.RepeatJob, `scheduling/repeat-job`, {
    jobId,
    jobSheetId,
    jobItemIds,
    visits,
  });
};

export const completeItems = (jobId, selectedIds) =>
  apiPut(ActionTypes.CompleteJobItems, `jobs/${jobId}/items/complete`, {
    selectedIds,
  });

export const markItemsAsCompleted = (jobId, completedIds) => ({
  type: ActionTypes.MarkItemsAsCompleted,
  jobId,
  completedIds,
});

export const jobStatusUpdated = (jobId, jobStatus) => ({
  type: ActionTypes.JobStatusUpdated,
  jobId,
  jobStatus,
});

export const generateJobSheet =
  ({ jobId, jobSheetId }) =>
  async (dispatch) => {
    const result = await dispatch(
      apiPost(
        ActionTypes.GenerateJobSheet,
        `jobs/${jobId}/sheets/${jobSheetId}/generate`,
        { jobId, jobSheetId },
        { preventResponseHandling: true }
      )
    );

    if (result) {
      saveFile(result);
    }
    return result;
  };

export const jobSheetsMerged = (data) => ({
  type: ActionTypes.JobSheetsMerged,
  data,
});

export const mergeJobSheets =
  (sourceSheetId, sourceDate, targetSheetId, targetDate) =>
  (dispatch, getState) => {
    const { schedule } = getJobSchedulingState(getState());
    const sourceJob = schedule[sourceDate].find((j) => j.id == sourceSheetId);
    const targetJob = schedule[targetDate].find((j) => j.id == targetSheetId);

    if (
      sourceJob &&
      (sourceJob?.jobId == targetJob?.jobId ||
        sourceJob?.quoteId == targetJob?.quoteId)
    ) {
      dispatch(
        jobSheetsMerged({
          sourceDate,
          sourceSheetId,
          targetDate,
          targetSheetId,
          targetJobRef: targetJob.jobRef,
          sourceJobId: sourceJob.jobId,
          sourceSheetPrice: sourceJob.price,
        })
      );
      return dispatch(
        apiPost(ActionTypes.MergeJobSheets, `scheduling/merge-sheets`, {
          sourceDate,
          sourceSheetId,
          targetDate,
          targetSheetId,
        })
      );
    }
    dispatch(notifyError("Can not merge job sheets for unrelated jobs"));
  };

export const getRepeatJobFrequencies = () =>
  apiGet(ActionTypes.RepeatJobFrequencies, "lookups/repeat-job-frequencies");

export const markItemsAsUncompleted = (jobId, uncompletedIds) => ({
  type: ActionTypes.MarkItemsAsUncompleted,
  jobId,
  uncompletedIds,
});

export const markItemsAsInvoiceable = (jobId, invoiceableItemIds) => ({
  type: ActionTypes.MarkItemsAsInvoiceable,
  jobId,
  invoiceableItemIds,
});
