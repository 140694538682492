import React, { useState } from "react";
import {
  Format,
  Table,
  ContentBuilder,
  ActionMenu,
  ModalBuilder,
  Modal,
  useAuthClaims,
} from "@redriver/cinnamon";
import { Button, Menu, Image } from "semantic-ui-react";

import { HeaderBar, TablePanel, LightPanel } from "components/containers";
import {
  getEnquiryCurrentQuote,
  markAsQuote,
  generateQuote,
  convertToJob,
  generateEstimate,
  downloadRiskAssessment,
  getQuoteNotes,
} from "./actions";
import EnquiryEditCurrentQuotePanel from "./EnquiryEditCurrentQuotePanel";
import EmailQuoteModal from "./EmailQuoteModal";
import { PermissionCheck } from "components/auth";
import { Targets, Actions } from "constants/Permissions";
import { useDispatch } from "react-redux";
import { JobRoutes } from "constants/routes";
import { useHistory } from "react-router-dom";
import QuoteFooter from "./QuoteFooter";
import { claimExists } from "components/auth/utils";
import TreeIcon from "assets/icons/tree-silhouette.svg";
import SpadeIcon from "assets/icons/spade-and-soil.svg";

const EnquiryCurrentQuotePanel = ({ enquiry, onRefresh }) => {
  const [editing, setEditing] = useState(false);
  return editing ? (
    <EnquiryEditCurrentQuotePanel
      enquiry={enquiry}
      onRefresh={onRefresh}
      setEditing={setEditing}
    />
  ) : (
    <EnquiryViewCurrentQuotePanel
      enquiry={enquiry}
      setEditing={setEditing}
      onRefresh={onRefresh}
    />
  );
};

const Price = ({ item }) => {
  return <Format.Number value={item.unitPrice * item.quantity} format="GBP" />;
};

const Vat = ({ item }) => {
  return <Format.Number value={item.vat * item.quantity} format="GBP" />;
};

const Cost = ({ item }) => {
  return <Format.Number value={item.unitCost * item.quantity} format="GBP" />;
};

const Total = ({ item }) => {
  return (
    <Format.Number
      value={(item.unitPrice + item.vat) * item.quantity}
      format="GBP"
    />
  );
};

const EnquiryViewCurrentQuotePanel = ({ enquiry, setEditing, onRefresh }) => {
  const { permissions } = useAuthClaims();

  const [selectedItems, setSelectedItems] = useState({
    selected: [],
    deselected: [],
  });
  const [convertModalVisible, setConvertModalVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ContentBuilder
      loadAction={getEnquiryCurrentQuote}
      loadParams={{ enquiryId: enquiry.id }}
      initialData={{ items: [] }}
      renderContent={(data, state, events) => {
        const selectedIds = data.items
          .filter(
            (x) =>
              (selectedItems.selected.includes(x.id) ||
                selectedItems.allSelected) &&
              !selectedItems.deselected.includes(x.id) &&
              !x.jobItemId
          )
          .map((x) => x.id);
        const dataWithNumbers = {
          ...data,
          items: data.items.map((item, i) => ({
            ...item,
            itemNumber: i + 1,
          })),
        };

        const hasActionItems =
          claimExists(permissions, Targets.Enquiry, Actions.View) ||
          (!data.isEstimate &&
            (claimExists(permissions, Targets.Quote, Actions.Edit) ||
              claimExists(permissions, Targets.Job, Actions.Create)));

        return (
          <React.Fragment>
            <TablePanel
              customHeader={
                <HeaderBar
                  name={`Scope of Work${
                    data.reference ? ` - ${data.reference}` : ""
                  }`}
                  rightContent={
                    <span className="right-content enquiry-quote-right">
                      {hasActionItems && (
                        <ActionMenu>
                          <PermissionCheck
                            target={Targets.Enquiry}
                            action={Actions.View}
                          >
                            <Menu.Item
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                  generateEstimate({
                                    enquiryId: enquiry.id,
                                  })
                                ).then(() => {
                                  events.onRefresh();
                                  onRefresh();
                                });
                              }}
                            >
                              Generate Estimate
                            </Menu.Item>
                            {enquiry.department && (
                              <Menu.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(
                                    downloadRiskAssessment({
                                      enquiryId: enquiry.id,
                                    })
                                  ).then(() => {
                                    events.onRefresh();
                                    onRefresh();
                                  });
                                }}
                              >
                                Download Risk Assessment
                              </Menu.Item>
                            )}
                          </PermissionCheck>
                          {data.isEstimate && (
                            <PermissionCheck
                              target={Targets.Quote}
                              action={Actions.Edit}
                            >
                              <ActionMenu.Item
                                text="Mark as Quote"
                                onClick={() =>
                                  dispatch(
                                    markAsQuote({ quoteId: data.id })
                                  ).then(() => {
                                    events.onRefresh();
                                    onRefresh();
                                  })
                                }
                              />
                            </PermissionCheck>
                          )}
                          {!data.isEstimate && selectedIds.length > 0 && (
                            <PermissionCheck
                              target={Targets.Job}
                              action={Actions.Create}
                            >
                              <ActionMenu.Item
                                text="Convert to Job"
                                onClick={() => setConvertModalVisible(true)}
                              />
                            </PermissionCheck>
                          )}
                          {!data.isEstimate && (
                            <PermissionCheck
                              target={Targets.Quote}
                              action={Actions.View}
                            >
                              <Menu.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(
                                    generateQuote({
                                      quoteId: data.id,
                                    })
                                  ).then(() => {
                                    events.onRefresh();
                                    onRefresh();
                                  });
                                }}
                                disabled={data.hasQuoteDocument}
                              >
                                Generate Quote (PDF)
                              </Menu.Item>
                              <Menu.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(
                                    generateQuote({
                                      quoteId: data.id,
                                      asDocx: true,
                                    })
                                  ).then(() => {
                                    events.onRefresh();
                                    onRefresh();
                                  });
                                }}
                                disabled={data.hasQuoteDocument}
                              >
                                Generate Quote (WORD)
                              </Menu.Item>
                              {data.hasQuoteDocument && (
                                <Menu.Item
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setEmailModalVisible(true);
                                  }}
                                  disabled={!data.canEmailQuote}
                                >
                                  Email Quote
                                </Menu.Item>
                              )}
                            </PermissionCheck>
                          )}
                        </ActionMenu>
                      )}
                      {(data.isEstimate
                        ? claimExists(
                            permissions,
                            Targets.Enquiry,
                            Actions.Edit
                          )
                        : claimExists(
                            permissions,
                            Targets.Quote,
                            Actions.Edit
                          )) && (
                        <Button
                          className="compact"
                          icon="pencil"
                          onClick={() => setEditing(true)}
                        />
                      )}
                    </span>
                  }
                />
              }
              customFooter={
                <div className="scope-of-work-view-footer">
                  <QuoteFooter quantityText="Total" tableItems={data.items} />
                </div>
              }
              data={dataWithNumbers.items}
              dataKey="id"
              emptyMessage="No quote items found"
              className="scope-of-work-view-table"
            >
              <Table.Column
                width={4}
                render={(item) => (
                  <div className="sow-icons">
                    {item.tpoCheckInProgress ? (
                      <Image src={TreeIcon} title="TPO/CA Check" />
                    ) : item.tpoApplicationInProgress ? (
                      <Image src={TreeIcon} title="TPO/CA Application" />
                    ) : null}
                    {item.breakingGround && (
                      <Image src={SpadeIcon} title="Breaking Ground" />
                    )}
                  </div>
                )}
              />
              <PermissionCheck target={Targets.Enquiry} action={Actions.Edit}>
                <Table.SelectableColumn
                  value={selectedItems}
                  onChange={setSelectedItems}
                  totalItems={
                    dataWithNumbers.items ? dataWithNumbers.items.length : 0
                  }
                  header="all"
                  canSelect={(item) => !item.jobItemId}
                />
              </PermissionCheck>
              <Table.Column field="itemNumber" title="Item" />
              <Table.Column field="productName" title="Product" />
              <Table.Column field="description" title="Detail" />
              <Table.Column field="quantity" title="Quantity" width="7.5%" />
              <Table.Column title="Price" component={Price} width="7.5%" />
              <Table.Column title="VAT" component={Vat} width="7.5%" />
              <Table.Column title="Cost" component={Cost} width="7.5%" />
              <Table.Column title="Total" component={Total} width="7.5%" />
            </TablePanel>
            <LightPanel headerText="Quote Notes" className="quote-notes-panel">
              {enquiry.hasQuote && (
                <ContentBuilder
                  loadAction={getQuoteNotes}
                  loadParams={{ enquiryId: enquiry.id }}
                  initialData={{}}
                  renderContent={(data) => {
                    return <pre>{data.notes}</pre>;
                  }}
                />
              )}
            </LightPanel>
            {convertModalVisible && (
              <ModalBuilder
                onSubmit={() => {
                  return dispatch(
                    convertToJob({ selectedIds, quoteId: data.id })
                  ).then((result) => {
                    if (result.success) {
                      history.push(JobRoutes.Job(result.response));
                    } else {
                      setConvertModalVisible(false);
                    }
                  });
                }}
                renderModal={(modalProps) => (
                  <Modal.Confirmation {...modalProps} header="Convert Job">
                    Please confirm that you would like to convert{" "}
                    {data.reference} to a job
                  </Modal.Confirmation>
                )}
                onCancel={() => setConvertModalVisible(false)}
                visible={convertModalVisible}
              />
            )}
            <EmailQuoteModal
              currentQuote={data}
              text="Email Quote"
              visible={emailModalVisible}
              closeModal={() => setEmailModalVisible(false)}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export default EnquiryCurrentQuotePanel;
