import { AddressTypes } from "constants/enums";
const FormatAddress = (
  {
    addressLine1,
    addressLine2,
    addressLine3,
    townOrCity,
    countyOrState,
    postcode,
    type,
    contactName,
    contactDetail,
  },
  separator,
  showContact
) => {
  let address = [
    addressLine1,
    addressLine2,
    addressLine3,
    townOrCity,
    countyOrState,
    postcode,
  ]
    .filter((f) => !!f)
    .join(separator || ", ");

  if (
    showContact &&
    type == AddressTypes.Site &&
    (contactName || contactDetail)
  ) {
    address = `${address}\nContact: ${[contactName, contactDetail]
      .filter((c) => !!c)
      .join(" - ")}`;
  }

  return address;
};

export default FormatAddress;
