import React from "react";
import { Table } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/Permissions";
import DeleteCustomer from "./DeleteCustomer";
import ReinstateCustomer from "./ReinstateCustomer";

const CustomerActions = ({ customer, onRefresh }) => (
  <Table.Menu openOnFocus>
    <PermissionCheck target={Targets.Customer} action={Actions.Delete}>
      {customer.isDeleted ? (
        <ReinstateCustomer
          as={Table.MenuItem}
          onSubmitted={onRefresh}
          customer={customer}
        >
          Reinstate Customer
        </ReinstateCustomer>
      ) : (
        <DeleteCustomer
          as={Table.MenuItem}
          onDeleted={onRefresh}
          customer={customer}
        >
          Archive Customer
        </DeleteCustomer>
      )}
    </PermissionCheck>
  </Table.Menu>
);

export default CustomerActions;
