import React from "react";
import { ModalBuilder, Modal, Form } from "@redriver/cinnamon";
import { cancelJob } from "./actions";
import { CancelType } from "constants/enums";

export const CancelJobForm = ({ formProps, showCancelType }) => (
  <Form {...formProps} className="cancel-job-form">
    {showCancelType && (
      <div className="cancel-types">
        <Form.RadioGroup
          field="cancelType"
          options={[
            { value: CancelType.Single, text: "Just this job" },
            { value: CancelType.Series, text: "The entire series" },
          ]}
          required
          requiredError="A cancel type must be selected as this job belongs to a repeat schedule"
          fluid
          inline
        />
      </div>
    )}
    <Form.Input label="Cancelled Reason" field="cancelledReason" fluid />
  </Form>
);

const JobCancelModal = ({ job, visible, closeModal }) => {
  return (
    <ModalBuilder
      submitAction={cancelJob}
      onSubmitted={() => closeModal(true)}
      onCancel={closeModal}
      submitParams={{ jobId: job.id }}
      withForm
      visible={visible}
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Confirmation
            header="Cancel Job"
            cancelLabel="Cancel"
            submitLabel="Confirm"
            {...modalProps}
          >
            <CancelJobForm
              formProps={formProps}
              showCancelType={job.repeatJobScheduleId}
            />
          </Modal.Confirmation>
        );
      }}
    />
  );
};

export default JobCancelModal;
