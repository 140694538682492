import { apiGet, apiPut } from "@redriver/cinnamon";

const NAMESPACE = "JOBS/DETAILS/FULFILMENT_OF_WORKS";

const ActionTypes = {
  GetJobFulfilmentOfWorksItems: `${NAMESPACE}/GET_JOB_FULFILMENT_OF_WORKS_ITEMS`,
  GetJobFulfilmentOfWorksNotes: `${NAMESPACE}/GET_JOB_FULFILMENT_OF_WORKS_NOTES`,
  EditJobFulfilmentOfWorksItem: `${NAMESPACE}/EDIT_JOB_FULFILMENT_OF_WORKS_ITEM`,
  EditJobFulfilmentOfWorksNotes: `${NAMESPACE}/EDIT_JOB_FULFILMENT_OF_WORKS_NOTES`,
};

export const getJobFulfilmentOfWorksItems = (jobId) =>
  apiGet(
    ActionTypes.GetJobFulfilmentOfWorksItems,
    `jobs/${jobId}/fulfilment-of-works/items`
  );

export const getJobFulfilmentOfWorksNotes = (jobId) =>
  apiGet(
    ActionTypes.GetJobFulfilmentOfWorksNotes,
    `jobs/${jobId}/fulfilment-of-works/notes`
  );

export const editJobFulfilmentOfWorksItem = (formData, { jobId, itemId }) =>
  apiPut(
    ActionTypes.EditJobFulfilmentOfWorksItem,
    `jobs/${jobId}/fulfilment-of-works/items/${itemId}`,
    formData
  );

export const editJobFulfilmentOfWorksNotes = (formData, jobId) =>
  apiPut(
    ActionTypes.EditJobFulfilmentOfWorksNotes,
    `jobs/${jobId}/fulfilment-of-works/notes`,
    formData
  );
