import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Segment } from "semantic-ui-react";

const ContentPanel = ({
  children,
  className,
  highlight,
  tint,
  ...otherProps
}) => {
  const highlightClass = highlight ? "highlight-" + highlight : "";
  const tintClass = tint ? tint + "-tint" : "";
  const css = classNames("content-panel", className, highlightClass, tintClass);

  return (
    <Segment className={css} {...otherProps}>
      {children}
    </Segment>
  );
};

ContentPanel.propTypes = {
  highlight: PropTypes.oneOf(["red", "orange", "green"]),
  tint: PropTypes.oneOf(["red", "orange", "green"]),
};

export default ContentPanel;
