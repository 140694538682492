import React from "react";
import { DataList, Format } from "@redriver/cinnamon";
import { ContractContactPointsDropdown } from "components/display";
import {
  ContractPricingInvoicingType,
  ContractPricingType,
  ContractPricingTypeFields,
} from "constants/enums";
import { Header } from "semantic-ui-react";

const ContractDetailsDataList = ({ contract }) => {
  const contractPricing = contract?.contractPricing;

  return (
    <DataList>
      <DataList.Item title="Quantity Surveyor" width="50%">
        <div className="contract-contact-display">
          <span>{contract?.quantitySurveyor?.name ?? "-"}</span>
          {contract.quantitySurveyor?.contactPoints?.length > 0 && (
            <ContractContactPointsDropdown
              contactPoints={contract.quantitySurveyor.contactPoints}
              direction="right"
            />
          )}
        </div>
      </DataList.Item>
      <DataList.Item title="Accounts Contact" width="50%">
        <div className="contract-contact-display">
          <span>{contract?.accountsContact?.name ?? "-"}</span>
          {contract.accountsContact?.contactPoints?.length > 0 && (
            <ContractContactPointsDropdown
              contactPoints={contract.accountsContact.contactPoints}
              direction="right"
            />
          )}
        </div>
      </DataList.Item>
      <DataList.Item title="Planned Start Date" width="50%">
        {contract.plannedStartDate ? (
          <Format.Date value={contract.plannedStartDate} format="DD/MM/YYYY" />
        ) : (
          "-"
        )}
      </DataList.Item>
      <DataList.Item title="Estimated Completion Date" width="50%">
        {contract.estimatedCompletionDate ? (
          <Format.Date
            value={contract.estimatedCompletionDate}
            format="DD/MM/YYYY"
          />
        ) : (
          "-"
        )}
      </DataList.Item>
      <DataList.Item title="Description" width="100%">
        {contract.description ?? "-"}
      </DataList.Item>
      {contractPricing && (
        <div className="invoicing-options">
          <Header style={{ fontWeight: "normal" }}>Contract Pricing</Header>
          <React.Fragment>
            <DataList.Item title="Contract Total Value" width="100%">
              {contract.totalValue ? (
                <Format.Number value={contract.totalValue} format="GBP" />
              ) : (
                "-"
              )}
            </DataList.Item>
            {contractPricing.PlotWorksFencing && (
              <ContractPricingItemDisplay
                item={contractPricing.PlotWorksFencing}
                name={ContractPricingType.PlotWorksFencing}
              />
            )}
            {contractPricing.PlotWorksLandscaping && (
              <ContractPricingItemDisplay
                item={contractPricing.PlotWorksLandscaping}
                name={ContractPricingType.PlotWorksLandscaping}
              />
            )}
            {contractPricing.PosWorksFencing && (
              <ContractPricingItemDisplay
                item={contractPricing.PosWorksFencing}
                name={ContractPricingType.PosWorksFencing}
              />
            )}
            {contractPricing.PosWorksLandscaping && (
              <ContractPricingItemDisplay
                item={contractPricing.PosWorksLandscaping}
                name={ContractPricingType.PosWorksLandscaping}
              />
            )}
            {contractPricing.AdditionalWorks && (
              <ContractPricingItemDisplay
                item={contractPricing.AdditionalWorks}
                name={ContractPricingType.AdditionalWorks}
              />
            )}
            {contractPricing.InfrastructureWorks && (
              <ContractPricingItemDisplay
                item={contractPricing.InfrastructureWorks}
                name={ContractPricingType.InfrastructureWorks}
              />
            )}
            {contractPricing.MaintenanceWorks && (
              <ContractPricingItemDisplay
                item={contractPricing.MaintenanceWorks}
                name={ContractPricingType.MaintenanceWorks}
              />
            )}
          </React.Fragment>
        </div>
      )}
    </DataList>
  );
};

const ContractPricingItemDisplay = ({ item, name }) => (
  <div className="contract-pricing-item">
    {name}
    {" - "}
    <Format.Number value={item.value} format="GBP" />
    {" - "}
    {ContractPricingInvoicingType[item.invoicingType]}
    {item.pricePerPlot && (
      <span style={{ marginLeft: "2em" }}>
        {item.type === ContractPricingTypeFields.InfrastructureWorks ||
        item.type === ContractPricingTypeFields.MaintenanceWorks
          ? "Price Per Claim -"
          : "Price Per Plot -"}{" "}
        <Format.Number value={item.pricePerPlot} format="GBP" />
      </span>
    )}
  </div>
);

export default ContractDetailsDataList;
