import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { UserRoutes } from "constants/routes";
import UserListPage from "./UserListPage";
import UserPage from "./UserPage";

const UserRouter = () => (
  <Switch>
    <Route exact path={UserRoutes.Users} component={UserListPage} />
    <Route path={UserRoutes.User()} component={UserPage} />
    <Route render={() => <Redirect to={UserRoutes.Users} />} />
  </Switch>
);

export default UserRouter;
