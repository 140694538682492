import React from "react";
import { ListInvoices } from "features/Finance";
import { KpsPageContent } from "components/containers";

const InvoiceListPage = () => (
  <KpsPageContent>
    <ListInvoices />
  </KpsPageContent>
);

export default InvoiceListPage;
