import React from "react";
import { Form } from "@redriver/cinnamon";
import { getProducts } from "./actions";

const ProductsTypeAhead = (props) => {
  const { categoryId, ...otherProps } = props;
  return (
    <Form.TypeAhead
      {...otherProps}
      lookupAction={getProducts}
      lookupParams={{ categoryId }}
    />
  );
};

export default ProductsTypeAhead;
