import { apiGet, apiPost } from "@redriver/cinnamon";

const NAMESPACE = "CONTRACTS/LIST_CONTRACTS";

export const ActionTypes = {
  AddContract: `${NAMESPACE}/ADD_CONTRACT`,
  GetContracts: `${NAMESPACE}/GET_CONTRACTS`,
  GetSwimlaneContracts: (status) =>
    `${NAMESPACE}/GET_SWIMLANE_CONTRACTS/${status}`,
};

export const addContract = (data) => {
  const { contractPricing, ...formData } = data;
  let pricings = [];
  if (contractPricing) {
    Object.entries(contractPricing).map((p) =>
      p[1] && p[1].value
        ? pricings.push({
            type: p[0],
            ...p[1],
          })
        : null
    );
  }

  return apiPost(ActionTypes.AddContract, "contracts", {
    pricings,
    ...formData,
    totalValue: contractPricing?.totalValue,
  });
};

export const getContracts = ({ pagination }, filters) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetContracts, "contracts", {
    pageSize,
    pageNumber,
    ...filters,
  });
};

export const getSwimlaneContracts = (
  { pagination },
  { status, ...filters }
) => {
  const { pageSize, pageNumber } = pagination;
  return apiGet(ActionTypes.GetSwimlaneContracts(status), "contracts", {
    pageSize,
    pageNumber,
    status,
    ...filters,
  });
};
