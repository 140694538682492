import React from "react";
import { Form } from "@redriver/cinnamon";
import { getProductCategories } from "./actions";

const ProductCategoriesTypeAhead = (props) => {
  const { departmentId, ...otherProps } = props;

  return (
    <Form.TypeAhead
      {...otherProps}
      lookupAction={getProductCategories}
      lookupParams={{ departmentId }}
    />
  );
};

export default ProductCategoriesTypeAhead;
