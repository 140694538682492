import React from "react";
import PropTypes from "prop-types";
import { ClaimCheck } from "@redriver/cinnamon";
import { claimExists } from "./utils";

const MultiPermissionCheck = ({
  checks = [],
  enforceAll = false,
  renderFailure,
  children,
}) => (
  <ClaimCheck
    claim="permissions"
    value={(claimData) => {
      const valid = enforceAll
        ? checks.every((x) => claimExists(claimData, x.target, x.action, false))
        : checks.some((x) => claimExists(claimData, x.target, x.action, false));
      return valid;
    }}
    renderFailure={renderFailure}
  >
    {children}
  </ClaimCheck>
);

MultiPermissionCheck.propTypes = {
  checks: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
    })
  ),
  enforceAll: PropTypes.bool,
  renderFailure: PropTypes.func,
};

MultiPermissionCheck.defaultProps = {
  enforceAll: false,
};

export default MultiPermissionCheck;
