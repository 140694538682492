import React from "react";
import { Image } from "semantic-ui-react";
import { LinePanel } from "components/containers";
import ResidentialGreen from "assets/icons/residential-green.svg";
import CommercialBlue from "assets/icons/commercial-blue.svg";
import LocationIcon from "assets/icons/location.svg";
import PhoneIcon from "assets/icons/phone.svg";
import { Link } from "react-router-dom";
import { CustomerRoutes } from "constants/routes";
import { CustomerTypes } from "constants/enums";
import ContractRed from "assets/icons/contract-light-red.svg";

const CustomerTile = ({ item }) => {
  return (
    <Link to={CustomerRoutes.Customer(item.id)} className="basic">
      <LinePanel
        className={"customer-tile"}
        highlight={
          item.type === CustomerTypes.Residential
            ? "residential-green"
            : item.type === CustomerTypes.Commercial
            ? "commercial-blue"
            : "contract-red"
        }
        backgroundUrl={
          item.type === CustomerTypes.Residential
            ? ResidentialGreen
            : item.type === CustomerTypes.Commercial
            ? CommercialBlue
            : ContractRed
        }
      >
        <header>{item.displayName}</header>
        <p
          className={"customer-tile-line"}
          title={item.address ? item.address.shortAddress : null}
        >
          <Image inline src={LocationIcon} />
          {item.address ? item.address.shortAddress : null}
        </p>
        <p className={"customer-tile-line"}>
          <Image inline src={PhoneIcon} />
          {item.phoneNumber}
        </p>
      </LinePanel>
    </Link>
  );
};

export default CustomerTile;
