import React, { useState } from "react";
import { Actions, Targets } from "constants/Permissions";
import { Button, Segment } from "semantic-ui-react";
import { getContractDetails, editContractDetails } from "./actions";
import { LightPanel } from "components/containers";
import { FormBuilder, useAuthClaims } from "@redriver/cinnamon";
import { PermissionCheck } from "components/auth";
import { claimExists } from "components/auth/utils";
import ContractDetailsDataList from "./ContractDetailsDataList";
import { ContractPricingTypeFields } from "constants/enums";
import { EditContractForm } from "../ContractForm";

const ContractDetailsPanel = ({ contractId, onRefresh }) => {
  const { permissions } = useAuthClaims();
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <FormBuilder
      loadAction={getContractDetails}
      loadParams={contractId}
      loadTransform={(data) => ({
        ...data,
        siteManager: data.siteManager && {
          ...data.siteManager,
          contactPoints: data.siteManager.contactPoints.map((p) => {
            return { ...p, key: p.id };
          }),
        },
        quantitySurveyor: data.quantitySurveyor && {
          ...data.quantitySurveyor,
          contactPoints: data.quantitySurveyor.contactPoints.map((p) => {
            return { ...p, key: p.id };
          }),
        },
        accountsContact: data.accountsContact && {
          ...data.accountsContact,
          contactPoints: data.accountsContact.contactPoints.map((p) => {
            return { ...p, key: p.id };
          }),
        },
        contractPricing: data.pricings.length > 0 && {
          totalValue: data.totalValue,
          PlotWorksFencing: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.PlotWorksFencing
          ),
          PlotWorksLandscaping: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.PlotWorksLandscaping
          ),
          PosWorksFencing: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.PosWorksFencing
          ),
          PosWorksLandscaping: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.PosWorksLandscaping
          ),
          AdditionalWorks: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.AdditionalWorks
          ),
          InfrastructureWorks: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.InfrastructureWorks
          ),
          MaintenanceWorks: data.pricings.find(
            (x) => x.type === ContractPricingTypeFields.MaintenanceWorks
          ),
        },
      })}
      submitAction={editContractDetails}
      submitParams={contractId}
      onSubmitted={() => {
        setIsEditMode(false);
        if (onRefresh) {
          onRefresh();
        }
      }}
      renderForm={(formProps, state, events) => {
        const onSubmit = () =>
          events.onSubmit({ reload: true, reset: ["data"] });
        const onCancel = () => {
          setIsEditMode(false);
          events.onRefresh();
        };
        return (
          <LightPanel
            headerText="Contract Details"
            className="contract-details"
            rightHeaderContent={
              <PermissionCheck target={Targets.Contract} action={Actions.Edit}>
                {isEditMode ? (
                  <React.Fragment>
                    <Button
                      compact
                      content="Cancel"
                      onClick={onCancel}
                      className="cancel-button"
                    />
                    <Button
                      compact
                      content="Save"
                      onClick={onSubmit}
                      loading={state.submitting}
                    />
                  </React.Fragment>
                ) : (
                  <Button
                    compact
                    icon="pencil"
                    onClick={() => setIsEditMode(true)}
                  />
                )}
              </PermissionCheck>
            }
          >
            <Segment>
              {claimExists(permissions, Targets.Contract, Actions.Edit) &&
              isEditMode ? (
                <EditContractForm formProps={formProps} />
              ) : (
                <ContractDetailsDataList contract={formProps.value} />
              )}
            </Segment>
          </LightPanel>
        );
      }}
    />
  );
};

export default ContractDetailsPanel;
