import React from "react";
import { apiGet, Form } from "@redriver/cinnamon";

const NAMESPACE = "JOBS/JOB_PENDING_REASON_LOOKUP";

const ActionTypes = {
  GetStatuses: `${NAMESPACE}/GET_STATUSES`,
};

const getTpoStatuses = () =>
  apiGet(ActionTypes.GetStatuses, `lookups/tpo-statuses`);

export const JobTpoStatusesLookupDropdown = ({ ...props }) => (
  <Form.Dropdown {...props} lookupAction={getTpoStatuses} />
);
