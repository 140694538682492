import React from "react";
import { ContentBuilder, useAuthClaims } from "@redriver/cinnamon";
import { getUserRoles } from "./actions";
import { Grid } from "semantic-ui-react";

const UserDetails = () => {
  const { userName, userEmail } = useAuthClaims();

  return (
    <ContentBuilder
      loadAction={getUserRoles}
      renderContent={(data) => (
        <React.Fragment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <b>Name:</b> {userName}
              </Grid.Column>
              <Grid.Column>
                <b>Email:</b> {userEmail}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1} style={{ paddingTop: 0 }}>
              <Grid.Column>
                <b>Roles:</b>{" "}
                {Array.isArray(data) && data.length > 0
                  ? data.join(", ")
                  : "No Roles Applied"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}
    />
  );
};

export default UserDetails;
