import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { requestLogin } from "./actions";

const AzureAdSignIn = ({ code }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestLogin(code));
  }, [dispatch, code]);
  return <div>Please wait</div>;
};

export default AzureAdSignIn;
