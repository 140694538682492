export const Scheduling = {
  ScheduleJob: "ScheduleJob",
  UnscheduleJob: "UnscheduleJob",
  AssignResource: "AssignResource",
  UnassignResource: "UnassignResource",
  AssignJobItem: "AssignJobItem",
  UnassignJobItem: "UnassignJobItem",
  AssignJobItemToJobSheet: "AssignJobItemToJobSheet",
  UnassignJobItemToJobSheet: "UnassignJobItemToJobSheet",
  MarkJobItemsAsCompleted: "MarkJobItemsAsCompleted",
  MergeJobSheets: "MergeJobSheets",
  JobStatusUpdated: "JobStatusUpdated",
  MarkJobItemsAsUncompleted: "MarkJobItemsAsUncompleted",
  MarkJobItemsAsInvoiceable: "MarkJobItemsAsInvoiceable",
};
