import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";
import { QuoteItemForm } from "components/enquiries";
import { addQuoteItem } from "./actions";

const AddQuoteItemModal = ({
  enquiryId,
  departmentId,
  departmentName,
  showModifiers,
  onSubmitted,
}) => {
  return (
    <ModalBuilder
      initialData={{
        departmentId: departmentId,
        departmentName: departmentName,
        quantity: 1,
      }}
      onSubmitted={onSubmitted}
      renderTrigger={(showModal) => {
        return (
          <Button compact onClick={showModal}>
            Add Item
          </Button>
        );
      }}
      submitAction={addQuoteItem}
      submitParams={{ enquiryId }}
      withForm
      renderModal={(modalProps, formProps) => {
        return (
          <Modal.Add {...modalProps} header="Add Quote Item" size="large">
            <div className={"sow-item-modal"}>
              <QuoteItemForm {...formProps} showJobModifiers={showModifiers} />
            </div>
          </Modal.Add>
        );
      }}
    />
  );
};

export default AddQuoteItemModal;
