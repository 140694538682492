import React from "react";
import { Table } from "@redriver/cinnamon";
import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";

const RoleActions = ({ role, onRefresh }) => (
  <Table.Menu openOnFocus>
    <EditRole role={role} onUpdated={onRefresh} as={Table.MenuItem}>
      Edit Role
    </EditRole>
    {role.totalUsers == 0 && (
      <DeleteRole role={role} onDeleted={onRefresh} as={Table.MenuItem}>
        Delete Role
      </DeleteRole>
    )}
  </Table.Menu>
);

export default RoleActions;
