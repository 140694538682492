import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBuilder, Modal } from "@redriver/cinnamon";

const DeleteDocumentModal = ({
  as: As = Button,
  document,
  action,
  actionParams,
  onDeleted,
  ...props
}) => (
  <ModalBuilder
    submitAction={action}
    submitParams={{ ...actionParams, docId: document.id }}
    onSubmitted={onDeleted}
    renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
    renderModal={(modalProps) => (
      <Modal.Confirmation {...modalProps} header="Delete Document">
        Are you sure you wish to delete <b>{document.fileName}</b>?
      </Modal.Confirmation>
    )}
  />
);

export default DeleteDocumentModal;
