import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { FinanceRoutes } from "constants/routes";
import InvoiceListPage from "./InvoiceListPage";
import ReadyToInvoicePage from "./ReadyToInvoicePage";

const FinanceRouter = () => (
  <Switch>
    <Route exact path={FinanceRoutes.Invoices} component={InvoiceListPage} />
    <Route
      exact
      path={FinanceRoutes.ReadyToInvoice}
      component={ReadyToInvoicePage}
    />
    <Route render={() => <Redirect to={FinanceRoutes.Invoices} />} />
  </Switch>
);

export default FinanceRouter;
