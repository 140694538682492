import {
  Form,
  ListBuilder,
  Modal,
  ModalBuilder,
  TileGrid,
} from "@redriver/cinnamon";
import CommercialBlue from "assets/icons/commercial-blue.svg";
import ResidentialGreen from "assets/icons/residential-green.svg";
import { AddressTile } from "components/addresses";
import { LinePanel } from "components/containers";
import { AddressTypes, CustomerTypes } from "constants/enums";
import { getCustomerAddresses } from "features/Customers/CustomerDetails/CustomerAddresses/actions";
import CustomerAddressForm from "features/Customers/CustomerDetails/CustomerAddresses/CustomerAddressForm";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const SelectAddressModal = ({
  visible,
  entity,
  address,
  header,
  action,
  closeModal,
  allowAdditions = false,
}) => {
  const initialFormData = {
    createNew: false,
    selectedAddressId: address?.id,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formValid, setFormValid] = useState(true);
  const [modalSubmitting, setModalSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (address && address.id) {
      setFormData((prevState) => ({
        ...prevState,
        selectedAddressId: address.id,
      }));
    }
  }, [address]);

  const onFormChange = (field, data, applyChanges) => {
    setFormData(applyChanges);
  };

  return (
    <ModalBuilder
      visible={visible}
      onSubmit={() => {
        setModalSubmitting(true);
        dispatch(action(entity.id, formData)).then(() => {
          setModalSubmitting(false);
          setFormData({
            ...initialFormData,
            selectedAddressId: address ? address.id : null,
          });
          closeModal(true);
        });
      }}
      onCancel={() => {
        setFormData({
          ...initialFormData,
          selectedAddressId: address ? address.id : null,
        });
        closeModal();
      }}
      renderModal={(modalProps) => {
        const selectAddress = (id) => {
          setFormData({ ...formData, selectedAddressId: id, createNew: false });
        };
        const highlight =
          entity.customerType == CustomerTypes.Residential
            ? "faded-green"
            : "faded-blue";

        return (
          <Modal
            {...modalProps}
            submitting={modalSubmitting}
            header={header}
            submitLabel="Save"
            className="select-address-modal"
            size="small"
            submitDisabled={!formValid}
          >
            <Form
              value={formData}
              onChange={onFormChange}
              onValidated={setFormValid}
            >
              <header>Select Existing Address</header>
              <ListBuilder
                withTiles
                loadAction={getCustomerAddresses}
                onLoaded={(result) => {
                  if (result && result.length == 0) {
                    setFormData({
                      ...formData,
                      type:
                        entity.customerType == CustomerTypes.Residential
                          ? AddressTypes.Home
                          : AddressTypes.Billing,
                      isPrimaryAddress: true,
                      createNew: true,
                    });
                  }
                }}
                loadParams={{ customerId: entity.customerId }}
                renderList={(tileProps) => {
                  return (
                    <TileGrid
                      {...tileProps}
                      data={
                        allowAdditions
                          ? [...tileProps.data, { id: "0", newAddress: true }]
                          : tileProps.data
                      }
                      dataKey="id"
                      size="large"
                      render={(item) =>
                        item.newAddress ? (
                          <LinePanel
                            highlight={formData.createNew ? highlight : "blue"}
                            tint="blue"
                            className="new-address"
                            dashed={!formData.createNew}
                            highlightFull={true}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                selectedAddressId: null,
                                createNew: true,
                              });
                            }}
                          >
                            Create New Address
                          </LinePanel>
                        ) : (
                          <AddressTile
                            highlight={highlight}
                            address={item.address}
                            onClick={() => selectAddress(item.id)}
                            highlightFull={
                              item.id == formData.selectedAddressId
                            }
                            primaryAddress={item.isPrimaryAddress}
                            backgroundUrl={
                              entity.customerType == CustomerTypes.Residential
                                ? ResidentialGreen
                                : CommercialBlue
                            }
                          />
                        )
                      }
                    />
                  );
                }}
              />
              <Form.If
                condition={({ fields }) => fields.createNew && allowAdditions}
              >
                <header>Create New Address</header>
                <CustomerAddressForm />
              </Form.If>
            </Form>
          </Modal>
        );
      }}
    />
  );
};

export default SelectAddressModal;
